import {
  Button,
  Checkbox,
  Group,
  GroupLabel,
  useCheckboxStore,
} from "@ariakit/react"
import { Localized } from "@fluent/react"
import { type NodeViewComponentProps } from "@nytimes/react-prosemirror"
import {
  type Dispatch,
  type SetStateAction,
  createContext,
  forwardRef,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react"

import quizStyles from "./quiz.module.css"

import { generateGuid } from "@/components/editor/plugins/guid"

type QuizContextValue = {
  selectedAnswers: Set<string>
  setSelectedAnswers: Dispatch<SetStateAction<Set<string>>>
  submitted: boolean
  setSubmitted: Dispatch<SetStateAction<boolean>>
}

const QuizContext = createContext<QuizContextValue>(
  null as unknown as QuizContextValue
)

export const QuizAnswer = forwardRef<HTMLLIElement, NodeViewComponentProps>(
  function QuizAnswer({ children, nodeProps, ...props }, ref) {
    const id = useRef(generateGuid()).current

    const { node } = nodeProps
    const value = node.textContent

    const { selectedAnswers, setSelectedAnswers, submitted } =
      useContext(QuizContext)

    const store = useCheckboxStore({
      value: selectedAnswers.has(value),
      setValue: (checked) => {
        if (checked) {
          setSelectedAnswers((previous) => {
            const next = new Set(previous)
            next.add(value)
            return next
          })
        } else {
          setSelectedAnswers((previous) => {
            const next = new Set(previous)
            next.delete(value)
            return next
          })
        }
      },
    })

    return (
      <li ref={ref} {...props} className={quizStyles["answer"]}>
        <div className={quizStyles["checkboxWrapper"]} contentEditable={false}>
          <Checkbox id={id} disabled={submitted} store={store} />
        </div>
        <label htmlFor={id} className={quizStyles["inputWrapper"]}>
          {children}
        </label>
      </li>
    )
  }
)

export const QuizAnswers = forwardRef<HTMLOListElement, NodeViewComponentProps>(
  function QuizAnswers({ children, nodeProps: _, ...props }, ref) {
    return (
      <ol ref={ref} {...props} className={quizStyles["answers"]}>
        {children}
      </ol>
    )
  }
)

export const QuizQuestion = forwardRef<HTMLDivElement, NodeViewComponentProps>(
  function QuizQuestion({ children, nodeProps: _, ...props }, ref) {
    return (
      <div ref={ref} {...props}>
        <Localized id={"quiz-question"}>
          <span contentEditable={false} className={quizStyles["label"]}>
            Question
          </span>
        </Localized>
        <GroupLabel>{children}</GroupLabel>
      </div>
    )
  }
)

export const QuizExplanation = forwardRef<
  HTMLDivElement,
  NodeViewComponentProps
>(function QuizExplanation({ children, nodeProps: _, ...props }, ref) {
  const { submitted } = useContext(QuizContext)

  if (!submitted) return null

  return (
    <div ref={ref} {...props}>
      <Localized id={"quiz-explanation"}>
        <span className={quizStyles["label"]}>Explanation</span>
      </Localized>
      {children}
    </div>
  )
})

export const Quiz = forwardRef<HTMLDivElement, NodeViewComponentProps>(
  function Quiz({ children, nodeProps, ...props }, ref) {
    const { node } = nodeProps

    const [submitted, setSubmitted] = useState(false)
    const [selectedAnswers, setSelectedAnswers] = useState<Set<string>>(
      new Set<string>()
    )

    const correctAnswers = useMemo(() => {
      const results: string[] = []

      node.descendants(({ type, textContent, attrs }) => {
        if (type === type.schema.nodes["quiz_answers"]) return true
        if (type === type.schema.nodes["quiz_answer"] && attrs["isCorrect"]) {
          results.push(textContent)
        }
        return false
      })

      return results
    }, [node])

    const isCorrect =
      selectedAnswers.size === correctAnswers.length &&
      correctAnswers.every((answer) => selectedAnswers.has(answer))

    return (
      <div
        ref={ref}
        {...props}
        data-guid={node.attrs.guid}
        data-margin-number={node.attrs.marginNumber}
        data-type="quiz"
        className={quizStyles["quizWrapper"]}
      >
        <QuizContext.Provider
          value={{
            selectedAnswers,
            setSelectedAnswers,
            submitted,
            setSubmitted,
          }}
        >
          <Group>{children}</Group>
          {submitted ? (
            isCorrect ? (
              <Localized id={"quiz-correct-answer"}>
                <div
                  className={`${quizStyles["result"]} ${quizStyles["correct"]}`}
                >
                  Your answer is correct! 🎉
                </div>
              </Localized>
            ) : (
              <>
                <Localized id={"quiz-wrong-answer"}>
                  <div
                    className={`${quizStyles["result"]} ${quizStyles["wrong"]}`}
                  >
                    Your answer is wrong.
                  </div>
                </Localized>
                <Localized id={"quiz-try-again"}>
                  <Button
                    className={quizStyles["submitButton"]}
                    onClick={() => {
                      setSubmitted(false)
                      setSelectedAnswers(new Set<string>())
                    }}
                  >
                    Try again
                  </Button>
                </Localized>
              </>
            )
          ) : (
            <Localized id={"quiz-submit-answer"}>
              <Button
                className={quizStyles["submitButton"]}
                onClick={() => setSubmitted(true)}
              >
                Submit answer
              </Button>
            </Localized>
          )}
        </QuizContext.Provider>
      </div>
    )
  }
)
