import { ToolbarItem } from "@ariakit/react"
import { useEditorState } from "@nytimes/react-prosemirror"

import styles from "@/components/editor/toolbar/toolbar.module.css"
import { DocumentSettings } from "@/components/icons"
import { useAppDispatch } from "@/store/hooks"
import { Dialog } from "@/store/slices/dialogs"
import { dialogOpened } from "@/store/store"

export function ChapterSettings() {
  const dispatch = useAppDispatch()

  const editorState = useEditorState()
  const disabled = !editorState

  return (
    <ToolbarItem
      className={styles["editorToolbarItem"]}
      onClick={() => dispatch(dialogOpened(Dialog.SETTINGS_CHAPTER))}
      disabled={disabled}
      aria-label="Chapter Settings"
    >
      <DocumentSettings />
    </ToolbarItem>
  )
}
