import * as AriaKit from "@ariakit/react"
import { Localized } from "@fluent/react"
import { useEditorEventCallback } from "@nytimes/react-prosemirror"
import { useContext } from "react"

import styles from "./SettingsChapter.module.css"

import { RevisionContext } from "@/contexts/RevisionContext"
import { DocAttrStep } from "@/steps/DocAttrStep"
import { useAppSelector } from "@/store/hooks"
import { HeadingStyles } from "@/utils/headingStyles"

export function HeadingStylesSettings() {
  return (
    <div className={styles["selectSection"]}>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((headingLevel) => (
        <HeadingStyleSelect key={headingLevel} headingLevel={headingLevel} />
      ))}
    </div>
  )
}

const defaultHeadingStyles = {
  h1: HeadingStyles.upperAlpha,
  h2: HeadingStyles.upperRoman,
  h3: HeadingStyles.decimal,
  h4: HeadingStyles.lowerAlpha,
  h5: HeadingStyles.doubleLowerAlpha,
  h6: HeadingStyles.lowerRoman,
  h7: HeadingStyles.bracketedLowerAlpha,
  h8: HeadingStyles.bracketedDoubleLowerAlpha,
  h9: HeadingStyles.bracketedTripleLowerAlpha,
}

const headingStylesDisplayMapping = {
  [HeadingStyles.upperAlpha]: "A.",
  [HeadingStyles.upperRoman]: "I.",
  [HeadingStyles.decimal]: "1.",
  [HeadingStyles.lowerAlpha]: "a)",
  [HeadingStyles.bracketedLowerAlpha]: "(a)",
  [HeadingStyles.doubleLowerAlpha]: "aa)",
  [HeadingStyles.bracketedDoubleLowerAlpha]: "(aa)",
  [HeadingStyles.bracketedTripleLowerAlpha]: "(aaa)",
  [HeadingStyles.lowerRoman]: "i)",
  [HeadingStyles.bracketedLowerRoman]: "(i)",
  [HeadingStyles.paragraph]: "§ 1",
  [HeadingStyles.none]: "None",
}

function HeadingStyleSelect({ headingLevel }: { headingLevel: number }) {
  const headingStyleKeys = Object.keys(HeadingStyles)
  const { revisionId } = useContext(RevisionContext)
  const currentHeadingStyles = useAppSelector(
    (state) =>
      state.revisions.revisions[revisionId]?.editorState?.doc.attrs
        .headingStyles || defaultHeadingStyles
  )
  const onChange = useEditorEventCallback((view, value) => {
    const { tr } = view.state
    const newHeadingStyles = {
      ...currentHeadingStyles,
      [`h${headingLevel}`]: value,
    }
    view.dispatch(tr.step(new DocAttrStep("headingStyles", newHeadingStyles)))
  })
  // Merge the default heading styles with the current heading styles to ensure
  // that all heading styles are present in the object, even on documents that
  // have not been updated to include the new heading styles.
  const currentHeadingStylesWithDefault = {
    ...defaultHeadingStyles,
    ...currentHeadingStyles,
  }
  const selectStore = AriaKit.useSelectStore({
    defaultValue: currentHeadingStylesWithDefault[`h${headingLevel}`],
    setValue: onChange,
  })

  const currentHeadingStyle =
    currentHeadingStyles[`h${headingLevel}`] ||
    defaultHeadingStyles[
      `h${headingLevel}` as keyof typeof defaultHeadingStyles
    ]

  return (
    <div className={styles["selectBlock"]}>
      <AriaKit.SelectLabel store={selectStore}>
        <Localized id={"heading-level"} vars={{ headingLevel }}>
          <span>{"Heading Level { $headingLevel }"}</span>
        </Localized>
      </AriaKit.SelectLabel>
      <AriaKit.Select store={selectStore}>
        {
          headingStylesDisplayMapping[
            currentHeadingStyle as keyof typeof headingStylesDisplayMapping
          ]
        }
        <AriaKit.SelectArrow />
      </AriaKit.Select>
      <AriaKit.SelectPopover store={selectStore} className={styles["popover"]}>
        {headingStyleKeys.map((styleKey) => {
          const value = HeadingStyles[styleKey as keyof typeof HeadingStyles]
          return (
            <AriaKit.SelectItem
              className={styles["selectItem"]}
              key={styleKey}
              store={selectStore}
              value={value}
            >
              {headingStylesDisplayMapping[value]}
            </AriaKit.SelectItem>
          )
        })}
      </AriaKit.SelectPopover>
    </div>
  )
}
