import {
  Popover,
  PopoverProvider,
  type PopoverStoreProps,
  usePopoverContext,
  usePopoverStore,
} from "@ariakit/react/popover"
import {
  useEditorEventCallback,
  useEditorEventListener,
  useEditorState,
} from "@nytimes/react-prosemirror"
import { AllSelection, TextSelection } from "prosemirror-state"
import type { EditorView } from "prosemirror-view"
import {
  type ComponentProps,
  type ReactNode,
  useCallback,
  useMemo,
  useRef,
} from "react"

import { debounce } from "@/utils/debounce"
import { posToDOMRect } from "@/utils/prosemirror"

export function useTextSelectionMenu(
  {
    placement,
  }: {
    placement: PopoverStoreProps["placement"]
  } = { placement: "bottom" }
) {
  const ref = useRef<HTMLDivElement | null>(null)

  const editorState = useEditorState()
  const popover = usePopoverStore({ placement })

  const getAnchorRect = useEditorEventCallback((view) => {
    return view
      ? posToDOMRect(view, view.state.selection.from, view.state.selection.to)
      : null
  })

  const updatePopover = useCallback(
    (view: EditorView) => {
      if (!view) return

      const { selection } = view.state

      if (
        !selection.empty &&
        (selection instanceof TextSelection ||
          selection instanceof AllSelection)
      ) {
        popover.render()
        popover.setOpen(true)
      } else {
        popover.setOpen(false)
      }
    },
    [popover]
  )

  const schedulePopoverUpdate = useMemo(
    () => debounce(updatePopover, 100),
    [updatePopover]
  )
  useEditorEventListener("mouseup", (view) => {
    if (view) schedulePopoverUpdate(view)
  })

  useEditorEventListener("mousedown", (view) => {
    // This handles the case when you make two subsequent selections.
    // Since the first selection will open the popover, we need to close
    // it on the next mousedown event so that it doesn't stay open
    // when making the next selection.
    if (view) popover.setOpen(false)
  })

  useEditorEventListener("keyup", (view) => {
    if (view) schedulePopoverUpdate(view)
  })

  const Wrapper = useCallback(
    ({ children, className }: { children: ReactNode; className?: string }) => {
      return (
        <PopoverProvider store={popover}>
          <Popover
            className={className}
            ref={ref}
            getAnchorRect={
              getAnchorRect as ComponentProps<typeof Popover>["getAnchorRect"]
            }
            autoFocusOnShow={false}
            hideOnInteractOutside={true}
            hideOnEscape={true}
            unmountOnHide={true}
          >
            {children}
          </Popover>
        </PopoverProvider>
      )
    },
    [popover, getAnchorRect]
  )

  if (!editorState) return undefined

  return { store: popover, Root: Wrapper }
}

export function useTextSelectionMenuContext() {
  return usePopoverContext()
}
