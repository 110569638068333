import { clsx } from "clsx"
import { Step } from "prosemirror-transform"
import { useEffect, useMemo } from "react"
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels"

import appStyles from "./app.module.css"
import { AppLocalizationProvider } from "./i18n.tsx"
import { Notepad as NotepadIcon } from "./icons.tsx"
import { ResizeablePanel } from "./panels/ResizeablePanel.tsx"
import panelStyles from "./panels/panel.module.css"
import { SnackbarProvider } from "./snackbar/SnackbarProvider.tsx"

import { useMediaQuery } from "@/hooks/useMediaQuery.tsx"
import { Note } from "@/panels/Notebook/Note.tsx"
import notepadStyles from "@/panels/Notebook/components/Notepad.module.css"
import { NotepadNoteSelector } from "@/panels/Notebook/components/NotepadNoteSelector.tsx"
import { DocAttrStep } from "@/steps/DocAttrStep.ts"
import { useAppDispatch, useAppSelector } from "@/store/hooks.ts"
import {
  getRootPanelState,
  getStackPanelState,
} from "@/store/selectors/panelSelectors.ts"
import { panelsSlice } from "@/store/slices/panels.ts"
import { loadInitialPanels } from "@/store/store.ts"
import { getInitialStateFromDOM } from "@/utils/initialState.ts"

Step.jsonID("DocAttrStep", DocAttrStep)

const MINIMUM_PANEL_SIZE = 600

export function App() {
  const initialState = useMemo(() => getInitialStateFromDOM(), [])
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(loadInitialPanels(initialState))
  }, [dispatch, initialState])

  const fitsTwoPanels = useMediaQuery(
    `(min-width: ${MINIMUM_PANEL_SIZE * 2}px)`
  )
  useEffect(() => {
    dispatch(panelsSlice.actions.panelLayoutUpdated(fitsTwoPanels))
  }, [dispatch, fitsTwoPanels])

  const rootPanelState = useAppSelector(getRootPanelState)
  const stackPanelState = useAppSelector(getStackPanelState)

  const { showNotepad, notepadNoteId: noteId } = useAppSelector(
    (state) => state.panels
  )

  useEffect(() => {
    const toggleNotepad = (e: KeyboardEvent) => {
      if (e.code === "IntlBackslash" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        dispatch(panelsSlice.actions.notepadToggled(!showNotepad))
      }
    }
    document.addEventListener("keydown", toggleNotepad)
    return () => {
      document.removeEventListener("keydown", toggleNotepad)
    }
  }, [dispatch, showNotepad])

  const rootPanel =
    fitsTwoPanels && rootPanelState ? (
      <ResizeablePanel id={"rootPanel"} order={1} panelState={rootPanelState} />
    ) : null

  const stackPanel = stackPanelState ? (
    <ResizeablePanel
      id={"stackPanel"}
      order={fitsTwoPanels ? 2 : 1}
      panelState={stackPanelState}
    />
  ) : null

  return (
    <AppLocalizationProvider>
      <SnackbarProvider>
        <div className={appStyles["appContainer"]}>
          <PanelGroup direction="horizontal">
            <Panel id={"stackWrapperPanel"}>
              <PanelGroup direction="horizontal">
                {rootPanel}
                {stackPanel && (
                  <PanelResizeHandle className={panelStyles["panelResizer"]} />
                )}
                {stackPanel}
              </PanelGroup>
            </Panel>
            {showNotepad ? (
              <>
                <PanelResizeHandle
                  className={clsx(
                    panelStyles["panelResizer"],
                    panelStyles["notebookResizer"]
                  )}
                />
                <Panel
                  id={"notepadPanel"}
                  defaultSize={25}
                  maxSize={50}
                  minSize={10}
                  className={notepadStyles["notepadPanel"]}
                >
                  {noteId ? (
                    <Note noteId={noteId} asNotepad={true} />
                  ) : (
                    <NotepadNoteSelector />
                  )}
                </Panel>
              </>
            ) : (
              <div
                className={notepadStyles["notepadCornerButton"]}
                onClick={() =>
                  dispatch(panelsSlice.actions.notepadToggled(true))
                }
              >
                <NotepadIcon />
              </div>
            )}
          </PanelGroup>
        </div>
      </SnackbarProvider>
    </AppLocalizationProvider>
  )
}
