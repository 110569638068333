import styles from "./FlashcardStudyView.module.css"
import { FlashcardStudyViewReportButton } from "./FlashcardStudyViewReportButton.tsx"

import { FlashcardReportLevel, type StudySession } from "@/types/api.ts"

export function FlashcardStudyViewReportButtons({
  intervals,
}: {
  intervals: StudySession["nextFlashcardIntervals"]
}) {
  return (
    <div className={styles["reportButtonsWrapper"]}>
      <FlashcardStudyViewReportButton
        level={FlashcardReportLevel.AGAIN}
        intervals={intervals}
      />
      <FlashcardStudyViewReportButton
        level={FlashcardReportLevel.HARD}
        intervals={intervals}
      />
      <FlashcardStudyViewReportButton
        level={FlashcardReportLevel.GOOD}
        intervals={intervals}
      />
      <FlashcardStudyViewReportButton
        level={FlashcardReportLevel.EASY}
        intervals={intervals}
      />
    </div>
  )
}
