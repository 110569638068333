import type { Action, ThunkDispatch } from "@reduxjs/toolkit"

import type { UUID } from "@/store/UUID.ts"
import { type PanelState, panelsSlice } from "@/store/slices/panels"
import { createAppThunk } from "@/store/store"

export function updateScrollPositionsFromDom(
  dispatch: ThunkDispatch<unknown, unknown, Action<unknown>>,
  getState: () => { panels: { stack: PanelState[] } }
) {
  // Find all rendered panels, get their scroll position and update the panelState
  const domPanels = document.querySelectorAll("[id$='-panel-outlet']")
  // Iterate over panels
  for (let i = 0; i < domPanels.length; i++) {
    const domPanel = domPanels[i]
    if (!domPanel) continue
    const panelId = domPanel.id.replace("-panel-outlet", "") as UUID
    // Find the panel in the stack
    const panelState = getState().panels.stack.find(
      (panel: PanelState) => panel.panelId === panelId
    )
    if (!panelState) continue
    // If the panel is found, update the scroll position
    dispatch(
      panelsSlice.actions.panelScrollPositionUpdated({
        panelId,
        scrollPosition: domPanel.scrollTop,
      })
    )
  }
}

/*
 * This thunk is called when a panel is opened. We need this, to get the scrolling position
 * of all other panels and update their panelState in the stack accordingly.
 */
export const panelOpened = createAppThunk(
  "panels/panelOpened",
  (
    {
      panel,
      index,
    }: {
      panel: PanelState
      index?: number
    },
    { dispatch, getState }
  ) => {
    // Update scroll positions of all panels
    updateScrollPositionsFromDom(dispatch, getState)
    // Run actual opening action on the panelsSlice
    dispatch(panelsSlice.actions.panelOpened({ panel, index }))
  }
)
