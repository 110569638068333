import { VisuallyHidden } from "@ariakit/react"
import { useEditorEventCallback } from "@nytimes/react-prosemirror"
import {
  type FocusEvent,
  type KeyboardEvent,
  useCallback,
  useRef,
  useState,
} from "react"

import styles from "./editabletitle.module.css"

import { Checkmark } from "@/components/icons"
import { useLocalizedString } from "@/hooks/useLocalizedString"

/**
 * A component that renders a text field to update the title
 * of a PDF node. Should only be used inside a PDF node view.
 */

export function EditableTitle({
  pos,
  initialValue,
  onExit,
  width,
}: {
  pos: number
  initialValue: string
  onExit: () => void
  width?: number
}) {
  const [value, setValue] = useState(initialValue)
  const ref = useRef<HTMLButtonElement | null>(null)

  const inputLabel = useLocalizedString({
    id: "update-pdf-title",
    fallback: "Update PDF title",
  })

  const buttonLabel = useLocalizedString({
    id: "save",
    fallback: "Save",
  })

  const updateNode = useEditorEventCallback<[number, string], void>(
    (view, pos, value) => {
      view.dispatch(view.state.tr.setNodeAttribute(pos, "title", value))
      onExit()
    }
  )

  const onBlur = useCallback(
    (event: FocusEvent<HTMLInputElement, Element>) => {
      if (!event.relatedTarget?.closest('div[role="dialog"]')) {
        onExit()
        setValue(initialValue)
      }
    },
    [initialValue, onExit]
  )

  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>, pos: number, value: string) => {
      if (event.key === "Enter") {
        updateNode(pos, value)
      }
    },
    [updateNode]
  )

  return (
    <div
      className={styles.editableTitle}
      style={{ width: width ? width + 10 : undefined }}
    >
      <VisuallyHidden>
        <label id="editable-title-input">{inputLabel}</label>
      </VisuallyHidden>
      <input
        autoFocus
        value={value}
        aria-labelledby="editable-title-input"
        placeholder={inputLabel}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => onKeyDown(e, pos, value)}
        onBlur={onBlur}
      />
      <button
        ref={ref}
        onClick={() => updateNode(pos, value)}
        disabled={value.trim().length === 0}
        aria-label={buttonLabel}
      >
        <Checkmark />
      </button>
    </div>
  )
}
