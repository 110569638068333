import { redo, undo } from "prosemirror-history"

import { CommandItem } from "@/components/editor/toolbar/items/CommandItem"
import { Redo, Undo } from "@/components/icons"

export function HistorySection() {
  return (
    <>
      <CommandItem command={undo}>
        <Undo />
      </CommandItem>
      <CommandItem command={redo}>
        <Redo />
      </CommandItem>
    </>
  )
}
