import { Button } from "@ariakit/react"
import { Localized } from "@fluent/react"
import type { MutationActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate"
import type { BaseQueryFn, MutationDefinition } from "@reduxjs/toolkit/query"
import { clsx } from "clsx"

import styles from "./Permissions.module.css"

import sharedStyle from "@/components/shared.module.css"
import type { UUID } from "@/store/UUID.ts"
import {
  type DocumentContainerPermissionRecord,
  type PermissionEntity,
  PermissionEntityType,
  PermissionType,
} from "@/types/api.ts"

export type PermissionEntitiesCategoryProps = {
  resourceId: UUID
  permissionRecords: DocumentContainerPermissionRecord[]
  categoryName: string
  removeEntity: (args: {
    resourceId: UUID
    entity: number
    entityType: PermissionEntityType
  }) => void
  updatePermission: (args: {
    resourceId: UUID
    entity: number
    entityType: PermissionEntityType
    permission: PermissionType
  }) => MutationActionCreatorResult<
    MutationDefinition<PermissionEntity, BaseQueryFn, string, void>
  >
}

export function PermissionEntitiesCategory({
  resourceId,
  permissionRecords,
  categoryName,
  removeEntity,
  updatePermission,
}: PermissionEntitiesCategoryProps) {
  const handleRemoveClick = (
    entity: number,
    entityType: PermissionEntityType
  ) => {
    if (!resourceId) return
    removeEntity({
      resourceId: resourceId,
      entity: entity,
      entityType: entityType,
    })
  }

  const handleEditorToggle = (
    entity: number,
    entityType: PermissionEntityType,
    isEditor: boolean
  ) => {
    const permission = isEditor ? PermissionType.VIEW : PermissionType.CHANGE
    if (!resourceId) return
    updatePermission({
      resourceId: resourceId,
      entity: entity,
      entityType: entityType,
      permission: permission,
    })
  }

  if (!permissionRecords || permissionRecords.length === 0) return null

  return (
    <li className={styles["entitiesListCategory"]}>
      <span className={styles["entitiesListCategoryLabel"]}>
        {categoryName}
      </span>
      <ul className={styles["entitiesListCategoryList"]}>
        {permissionRecords.map((permissionRecord) => {
          // If permission record has firstname and lastname, it's a user
          const { entity, permissions, entityType } = permissionRecord
          const isEditor = permissions.includes("change_deck")
          const isOwner = entityType === "user" && entity.isOwner
          const canToggleEditor =
            permissionRecord.entityType === "user" && !isOwner
          const label =
            "firstName" in entity
              ? `${entity.firstName} ${entity.lastName}`
              : entity.name
          return (
            <li
              key={permissionRecord.entity.id}
              className={styles["entitiesListCategoryListEntry"]}
            >
              {label}
              <div style={{ display: "flex", gap: "0.25rem" }}>
                {canToggleEditor && (
                  <Button
                    onClick={() =>
                      handleEditorToggle(
                        permissionRecord.entity.id,
                        permissionRecord.entityType,
                        isEditor
                      )
                    }
                    className={clsx(
                      sharedStyle["defaultButton"],
                      sharedStyle["secondaryButton"],
                      sharedStyle["microButton"]
                    )}
                  >
                    {!isEditor ? (
                      <Localized id={"permissions-make-editor"}>
                        Make editor
                      </Localized>
                    ) : (
                      <Localized id={"permissions-remove-editor"}>
                        Remove as editor
                      </Localized>
                    )}
                  </Button>
                )}
                <Button
                  onClick={() =>
                    handleRemoveClick(
                      permissionRecord.entity.id,
                      permissionRecord.entityType
                    )
                  }
                  className={clsx(
                    sharedStyle["defaultButton"],
                    sharedStyle["secondaryButton"],
                    sharedStyle["microButton"]
                  )}
                  disabled={isOwner}
                >
                  {isOwner ? (
                    <Localized id={"permissions-owner"}>Owner</Localized>
                  ) : (
                    <Localized id={"remove"}>Remove</Localized>
                  )}
                </Button>
              </div>
            </li>
          )
        })}
      </ul>
    </li>
  )
}
