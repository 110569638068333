import { useContext } from "react"

import { Flashcard } from "./Flashcard"

import { RevisionContext } from "@/contexts/RevisionContext"
import type { UUID } from "@/store/UUID"
import { useAppSelector } from "@/store/hooks"

export function Flashcards() {
  const { revisionId } = useContext(RevisionContext)

  const flashcards = useAppSelector((state) =>
    Object.entries(state.flashcards[revisionId] ?? {})
  )

  return (
    <>
      {flashcards
        .filter(([, { isOpen }]) => isOpen)
        .map(([highlightId, { flashcardId }]) => (
          <Flashcard
            key={highlightId}
            flashcardId={flashcardId}
            highlightId={highlightId as UUID}
          />
        ))}
    </>
  )
}
