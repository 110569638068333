import { Localized } from "@fluent/react"
import { useContext, useMemo } from "react"

import { useEditorSyncStatus } from "@/components/editor/hooks/useEditorSyncStatus"
import editorToolbarStyles from "@/components/editor/toolbar/toolbar.module.css"
import { RevisionContext } from "@/contexts/RevisionContext"

export function ChapterSyncStatus() {
  const { documentId, documentType } = useContext(RevisionContext)
  const status = useEditorSyncStatus({ documentId, documentType })

  const statusMessage = useMemo(() => {
    if (status === "saving")
      return <Localized id={"editor-status-saving"}>Saving...</Localized>

    if (status === "error")
      return <Localized id={"editor-status-error"}>Saving failed!</Localized>

    if (status === "saved")
      return (
        <Localized id={"editor-status-saved"}>All changes saved.</Localized>
      )

    return null
  }, [status])

  return (
    <span className={editorToolbarStyles.editorToolbarChapterSyncStatus}>
      {statusMessage}
    </span>
  )
}
