import {
  type EditorState,
  NodeSelection,
  type Selection,
} from "prosemirror-state"
import { insertPoint } from "prosemirror-transform"
import type { EditorView } from "prosemirror-view"

import { generateGuid } from "@/components/editor/plugins/guid.ts"
import type { UUID } from "@/store/UUID"
import { store, toggleAllFootnoteVisibility } from "@/store/store.ts"

export function isFootnoteSelected(
  selection: Selection
): selection is NodeSelection {
  return (
    selection instanceof NodeSelection &&
    selection.node.type === selection.node.type.schema.nodes["footnote"]
  )
}

export function insertFootnote(
  state: EditorState,
  dispatch?: EditorView["dispatch"]
) {
  if (isFootnoteSelected(state.selection)) return false
  if (!state.schema.nodes["footnote"]) return false

  const validInsertPoint = insertPoint(
    state.doc,
    state.selection.from,
    state.schema.nodes["footnote"]
  )

  if (validInsertPoint === null) return false

  const $from =
    validInsertPoint === state.selection.from
      ? state.selection.$from
      : state.doc.resolve(validInsertPoint)
  const $to =
    validInsertPoint === state.selection.from ? state.selection.$to : $from

  const { empty } = state.selection
  const isValidFootnoteContent =
    $from.sameParent($to) && $from.parent.inlineContent

  const content =
    isValidFootnoteContent && !empty
      ? $from.parent.content.cut($from.parentOffset, $to.parentOffset)
      : null

  if (dispatch) {
    const guid = generateGuid() as UUID
    const tr = state.tr
    tr.replaceSelectionWith(
      state.schema.nodes["footnote"].create({ guid: guid }, content)
    )
    let insertedPos: number | null = null
    tr.doc.nodesBetween(0, tr.selection.anchor, (node, pos) => {
      if (node.type.name === "footnote") insertedPos = pos
      return true
    })
    if (insertedPos) {
      tr.setSelection(NodeSelection.create(tr.doc, insertedPos))
    }
    tr.setMeta("footnoteInserted", guid)
    dispatch(tr)
  }
  return true
}

/**
 * Command to walk through all footnotes and toggle their visibility
 * by dispatching the toggleFootnoteVisibility action
 * @param state EditorState
 */
export function toggleAllFootnotes(state: EditorState) {
  const footnoteIds: UUID[] = []
  state.doc.descendants((node) => {
    if (node.type.name === "footnote") {
      footnoteIds.push(node.attrs.guid)
    }
    return true
  })
  store.dispatch(toggleAllFootnoteVisibility({ footnoteIds }))
  return true
}
