import * as AriaKit from "@ariakit/react"
import { Localized, useLocalization } from "@fluent/react"
import { useState } from "react"

import { HeadingStylesSettings } from "./HeadingStylesSettings"
import { MarginNumberSettings } from "./MarginNumberSettings"

import styles from "@/components/editor/dialogs/dialog.module.css"
import { Cancel } from "@/components/icons"
import { useAppDispatch, useAppSelector } from "@/store/hooks"
import { Dialog } from "@/store/slices/dialogs"
import { dialogClosed } from "@/store/store"
import { Disclosure } from "@/ui/Disclosure"

const SETTINGS = [
  {
    id: "settings-heading-styles",
    heading: {
      label: "Heading Styles",
      fluentId: "change-heading-style",
    },
    content: <HeadingStylesSettings />,
  },
  {
    id: "settings-margin-numbers",
    heading: {
      label: "Margin Numbers",
      fluentId: "margin-numbers",
    },
    content: <MarginNumberSettings />,
  },
]

export function SettingsChapter() {
  const [openSection, setOpenSection] = useState<number | undefined>()
  const dispatch = useAppDispatch()

  const isOpen = useAppSelector(
    (state) => state.dialogs.openDialog === Dialog.SETTINGS_CHAPTER
  )
  const { l10n } = useLocalization()

  const dialogStore = AriaKit.useDialogStore({
    open: isOpen,
    setOpen(open) {
      if (!open) {
        dispatch(dialogClosed())
      }
    },
  })

  return (
    <AriaKit.Dialog
      store={dialogStore}
      portal
      className={styles["dialog"]}
      backdrop={<div className={styles["backdrop"]} />}
    >
      <div className={styles["dialogHeader"]}>
        <AriaKit.DialogHeading>
          <Localized id={"settings-chapter-heading"}>
            Chapter Settings
          </Localized>
        </AriaKit.DialogHeading>
        <AriaKit.DialogDismiss className={styles["iconButton"]}>
          <Cancel />
          <AriaKit.VisuallyHidden>
            <Localized id={"close"}>Close</Localized>
          </AriaKit.VisuallyHidden>
        </AriaKit.DialogDismiss>
      </div>
      <div className={styles["dialogContent"]}>
        {SETTINGS.map(({ id, heading, content }, index) => {
          return (
            <Disclosure
              key={id}
              heading={l10n.getString(heading.fluentId, null, heading.label)}
              open={openSection === index}
              onOpenChange={(open) => {
                if (open) setOpenSection(index)
              }}
            >
              {content}
            </Disclosure>
          )
        })}
      </div>
    </AriaKit.Dialog>
  )
}
