import { getVersion } from "prosemirror-collab"
import type { EditorState } from "prosemirror-state"
import { useCallback, useEffect } from "react"

import {
  type LongPollConnectionConfig,
  ReconnectingLongPolling,
} from "./LongPollClient"

import type { UUID } from "@/store/UUID"

type DocumentId = UUID

type ConnectionCallback = (props: { editorState: EditorState }) => void

type LongPollConnection = {
  isActive: boolean
  isReconnecting(): boolean
  close: () => void
  getLastServerVersion(): number | undefined
}

const CONNECTIONS: Map<DocumentId, LongPollConnection> = new Map()

/**
 * Establish a connection to the server to receive real-time updates for steps.
 * If chapterId changes, it automatically closes the old connection.
 */

export function useLongPollClient(
  documentType: "chapter" | "flashcard" | "note",
  documentId: DocumentId,
  config?: LongPollConnectionConfig
) {
  const connect = useCallback<ConnectionCallback>(
    ({ editorState }) => {
      const version = getVersion(editorState)
      const client = new ReconnectingLongPolling(
        documentType,
        editorState.schema,
        documentId
      )
      if (!config) throw new Error("Can't connect long polling without config")

      client.connect(version, config)
      CONNECTIONS.set(documentId, makeConnectionInterface(client, documentId))
    },
    [documentType, documentId, config]
  )

  useEffect(
    () => () => {
      const connection = CONNECTIONS.get(documentId)
      if (documentId && connection?.isActive) {
        connection?.close()
      }
    },
    [documentId]
  )

  return [connect, CONNECTIONS.get(documentId)] as const
}

function makeConnectionInterface(
  client: ReconnectingLongPolling,
  chapterId: DocumentId
): LongPollConnection {
  return {
    isActive: true,
    isReconnecting: () => client.isReconnecting,
    getLastServerVersion: () => client.lastServerVersion,
    close: () => {
      client.close()
      CONNECTIONS.delete(chapterId)
    },
  }
}
