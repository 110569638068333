import {
  DialogDescription,
  DialogDismiss,
  DialogHeading,
  Dialog as DialogRoot,
  useDialogStore,
} from "@ariakit/react"
import { Localized } from "@fluent/react"
import { useRef } from "react"

import dialogStyles from "./dialog.module.css"

import { useLocalizedString } from "@/hooks/useLocalizedString"
import { useAppSelector } from "@/store/hooks"
import { Dialog } from "@/store/slices/dialogs"
import { makeSupportMailLink } from "@/utils/email"

export function ConflictingChangesDialog() {
  const url = useRef(() => window.location.href)
  const emailBody = useLocalizedString({ id: "conflicting-changes-email-body" })
  const emailSubject = useLocalizedString({
    id: "conflicting-changes-email-subject",
  })

  const isOpen = useAppSelector(
    (state) => state.dialogs.openDialog === Dialog.ERROR_CONFLICTING_CHANGES
  )

  const dialogStore = useDialogStore({
    open: isOpen,
  })

  const link = makeSupportMailLink({
    subject: emailSubject,
    body: emailBody ? `${emailBody} ${url.current()}` : undefined,
  })

  return (
    <DialogRoot
      store={dialogStore}
      portal
      className={dialogStyles["dialog"]}
      hideOnInteractOutside={false}
      hideOnEscape={false}
    >
      <DialogHeading>
        <Localized id={"conflicting-changes-title"}>
          We couldn&apos;t save your changes.
        </Localized>
      </DialogHeading>
      <DialogDescription>
        <Localized
          id={"conflicting-changes-description"}
          elems={{
            contact: <a href={link}></a>,
          }}
        >
          <span>
            {
              "Please refresh the page to restore a working connection. If you continue to see this message, <contact>please contact support</contact>."
            }
          </span>
        </Localized>
      </DialogDescription>
      <DialogDismiss
        onClick={() => {
          window.location.reload()
        }}
        className={"blue-button"}
      >
        <Localized id={"conflicting-changes-action"}>Refresh Page</Localized>
      </DialogDismiss>
    </DialogRoot>
  )
}
