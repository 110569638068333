import { useEditorState } from "@nytimes/react-prosemirror"

export function useEditorChapterSettings() {
  const state = useEditorState()

  return {
    marginNumbers: state?.doc.attrs.marginNumbers,
    headingStyles: state?.doc.attrs.headingStyles,
  }
}
