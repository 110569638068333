import {
  Select,
  SelectArrow,
  SelectItem,
  SelectPopover,
  SelectProvider,
} from "@ariakit/react"
import { useCallback, useState } from "react"

import styles from "./flashcard.module.css"

import type { UUID } from "@/store/UUID"
import {
  useGetFlashcardDecksQuery,
  useGetFlashcardQuery,
  useUpdateFlashcardMutation,
} from "@/store/slices/api"

type Props = {
  flashcardId: UUID
}

/*
Component to select the deck a flashcard belongs to when the flashcard is shown
in alongside the text in view-mode.
 */
export function DeckSelect({ flashcardId }: Props) {
  const { data: flashcard } = useGetFlashcardQuery(flashcardId)

  const { data: decks, isLoading } = useGetFlashcardDecksQuery()

  const [updateFlashcardDeck] = useUpdateFlashcardMutation()

  const [value, setValue] = useState(flashcard?.deckId ?? "")

  const onValueChange = useCallback(
    (newValue: UUID) => {
      setValue(newValue)
      updateFlashcardDeck({ flashcardId, deckId: newValue })
    },
    [flashcardId, updateFlashcardDeck]
  )

  return (
    <SelectProvider value={value} setValue={onValueChange}>
      <Select className={styles["deckSelect"]} disabled={isLoading}>
        {decks?.find((deck) => deck.id === value)?.title ?? "Loading..."}
        <SelectArrow />
      </Select>
      <SelectPopover
        gutter={4}
        sameWidth
        className={styles["deckSelectPopover"]}
      >
        {decks?.map((deck) => (
          <SelectItem key={deck.id} value={deck.id}>
            {deck.title}
          </SelectItem>
        ))}
      </SelectPopover>
    </SelectProvider>
  )
}
