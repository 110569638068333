import {
  Tooltip,
  TooltipAnchor,
  TooltipArrow,
  TooltipProvider,
} from "@ariakit/react"
import { Localized } from "@fluent/react"
import { clsx } from "clsx"

import styles from "./FlashcardDeck.module.css"

import type { FlashcardDeck } from "@/types/api.ts"

export function FlashcardDeckProgressIndicator({
  deck,
}: {
  deck?: FlashcardDeck
}) {
  if (!deck) return null

  const { flashcards } = deck

  const countQueue = (queue: number) => {
    if (queue === 0)
      return flashcards.filter(
        (card) =>
          card.knowledge &&
          (card.knowledge.queue === undefined || card.knowledge.queue === 0)
      ).length

    return flashcards.filter(
      (card) => card.knowledge && card.knowledge.queue === queue
    ).length
  }

  const newCount = countQueue(0)
  const learningCount = countQueue(1)
  const reviewCount = countQueue(2)

  const relativeCount = (queue: number) => {
    return Math.round((countQueue(queue) / flashcards.length) * 100)
  }
  const newRelative = relativeCount(0)
  const learningRelative = relativeCount(1)
  const reviewRelative = relativeCount(2)

  return (
    <TooltipProvider timeout={100} placement={"bottom"}>
      <TooltipAnchor>
        <div className={styles["flashcardDeckProgressIndicatorWrapper"]}>
          <div className={styles["flashcardDeckProgressIndicatorLabel"]}>
            <Localized id={"flashcard-deck-progress"}>Your Progress</Localized>
          </div>
          <div className={styles["flashcardDeckProgressIndicator"]}>
            <FlashcardDeckProgressIndicatorBar
              width={newRelative}
              className={"new"}
            />
            <FlashcardDeckProgressIndicatorBar
              width={learningRelative}
              className={"learning"}
            />
            <FlashcardDeckProgressIndicatorBar
              width={reviewRelative}
              className={"review"}
            />
          </div>
        </div>
      </TooltipAnchor>
      <Tooltip
        className={styles["flashcardDeckProgressIndicatorTooltip"]}
        gutter={1}
      >
        <TooltipArrow />
        <Localized id={"flashcard-deck-progress"}>
          <strong>Progress</strong>
        </Localized>
        {newCount > 0 && (
          <Localized id={"flashcard-deck-progress-new"} vars={{ newCount }}>
            <p>{newCount} new cards</p>
          </Localized>
        )}
        {learningCount > 0 && (
          <Localized
            id={"flashcard-deck-progress-learning"}
            vars={{ learningCount }}
          >
            <p>{learningCount} learning cards</p>
          </Localized>
        )}
        {reviewCount > 0 && (
          <Localized
            id={"flashcard-deck-progress-review"}
            vars={{ reviewCount }}
          >
            <p>{reviewCount} cards to review</p>
          </Localized>
        )}
      </Tooltip>
    </TooltipProvider>
  )
}

export function FlashcardDeckProgressIndicatorBar({
  width,
  className,
}: {
  width: number
  className?: string
}) {
  if (width === 0) return null
  return (
    <div
      className={clsx(
        styles["flashcardDeckProgressIndicatorBar"],
        styles[className as keyof typeof styles]
      )}
      style={{ width: `${width}%` }}
    />
  )
}
