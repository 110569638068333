import { Switch } from "@/ui/Switch"

export function ToggleWithLabel({
  label,
  checked = false,
  onChange,
}: {
  label: string
  checked: boolean
  onChange: (checked: boolean) => void
}) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <p style={{ margin: 0 }}>{label}</p>
      <Switch checked={checked} onChange={onChange} />
    </div>
  )
}
