import { Schema } from "prosemirror-model"

import { quizSchema } from "@/components/editor/nodeViews/Quiz/Quiz.schema"
import { formattingSchema, textSchema } from "@/schemas/base-schema"

export const magicQuizSchema = new Schema({
  nodes: {
    doc: { content: "quiz" },
    ...textSchema(),
    ...quizSchema(),
  },
  marks: {
    ...formattingSchema(),
  },
})
