import { useEditorState } from "@nytimes/react-prosemirror"
import { liftListItem, sinkListItem } from "prosemirror-schema-list"

import { setListType } from "@/commands/lists.js"
import { CommandItem } from "@/components/editor/toolbar/items/CommandItem"
import { BulletList, Lift, OrderedList, Sink } from "@/components/icons"

export function ListSection() {
  const editorState = useEditorState()
  if (!editorState) return null

  return (
    <>
      {editorState.schema.nodes["bullet_list"] && (
        <CommandItem
          command={setListType(editorState.schema.nodes["bullet_list"])}
          hide={true}
        >
          <BulletList />
        </CommandItem>
      )}
      {editorState.schema.nodes["ordered_list"] && (
        <CommandItem
          command={setListType(editorState.schema.nodes["ordered_list"])}
          hide={true}
        >
          <OrderedList />
        </CommandItem>
      )}
      {editorState.schema.nodes["list_item"] && (
        <>
          <CommandItem
            command={liftListItem(editorState.schema.nodes["list_item"])}
            hide={true}
          >
            <Lift />
          </CommandItem>
          <CommandItem
            command={sinkListItem(editorState.schema.nodes["list_item"])}
            hide={true}
          >
            <Sink />
          </CommandItem>
        </>
      )}
    </>
  )
}
