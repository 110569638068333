import {
  Separator,
  Toolbar,
  ToolbarContainer,
  useToolbarStore,
} from "@ariakit/react"

import { ProgressBar } from "./items/ProgressBar.tsx"
import { RevisionMenuItem } from "./items/RevisionMenuItem.tsx"
import { ToggleFootnotesItem } from "./items/ToggleFootnotesItem.tsx"
import { ToggleMarkerMode } from "./items/ToggleMarkerMode.tsx"
import { ToggleStepThroughItem } from "./items/ToggleStepThroughItem.tsx"
import { ToggleThreadsItem } from "./items/ToggleThreadsItem.tsx"

import { ChapterTitle } from "@/components/editor/toolbar/items/ChapterTitle.tsx"
import { ToggleTableOfContentsItem } from "@/components/editor/toolbar/items/ToggleTableOfContentsItem.tsx"
import styles from "@/components/editor/toolbar/toolbar.module.css"
import { useMediaQuery } from "@/hooks/useMediaQuery.tsx"

export function ViewerToolbar() {
  const toolbarStore = useToolbarStore({
    orientation: "horizontal",
  })

  const isMobile = useMediaQuery("(max-width: 768px)")

  return (
    <Toolbar className={styles["editorToolbar"]} store={toolbarStore}>
      <ProgressBar />
      <ToolbarContainer data-toolbar-slot="controls">
        <ChapterTitle show={true} animate={false} />
        {!isMobile && <Separator style={{ marginRight: "0.25rem" }} />}
        <RevisionMenuItem />
      </ToolbarContainer>
      <ToolbarContainer data-toolbar-slot="formatting">
        <ToggleTableOfContentsItem />
        <ToggleMarkerMode />
        {!isMobile && (
          <>
            <ToggleThreadsItem />
            <ToggleFootnotesItem />
            <ToggleStepThroughItem />
          </>
        )}
      </ToolbarContainer>
    </Toolbar>
  )
}
