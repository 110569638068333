import { type EditorState, TextSelection } from "prosemirror-state"
import type { EditorView } from "prosemirror-view"

export function moveCursorToFlashcardFront(
  state: EditorState,
  dispatch?: EditorView["dispatch"]
) {
  const { $from } = state.selection
  // If the block we are in is not a flashcard `front`, do nothing
  const blockNode = $from.node(1)
  if (!blockNode || blockNode.type !== state.schema.nodes["back"]) {
    return false
  }
  if (dispatch) {
    // Find the position before the blockNode
    const start = $from.before(1) - 2
    dispatch(state.tr.setSelection(TextSelection.create(state.doc, start)))
    return true
  }
  return false
}

/*
Command to move the cursor to the back of a flashcard if the cursor is
currently in the front of a flashcard.

This is used to implement tabbing between the front and back of a flashcard.
 */
export function moveCursorToFlashcardBack(
  state: EditorState,
  dispatch?: EditorView["dispatch"]
) {
  const { $from } = state.selection
  // If the block we are in is not a flashcard `front`, do nothing
  const blockNode = $from.node(1)
  if (!blockNode || blockNode.type !== state.schema.nodes["front"]) {
    return false
  }
  if (dispatch) {
    // Find the position after the blockNode
    const start = $from.after(1) + 2
    dispatch(state.tr.setSelection(TextSelection.create(state.doc, start)))
    return true
  }
  return false
}
