import * as AriaKit from "@ariakit/react"
import { Localized } from "@fluent/react"
import {
  useEditorEffect,
  useEditorEventCallback,
} from "@nytimes/react-prosemirror"
import { isEqual } from "lodash"
import { useEffect, useState } from "react"

import styles from "./SettingsChapter.module.css"

import {
  disableMarginNumbers,
  enableMarginNumbers,
  setMarginNumbers,
  unsetMarginNumbers,
} from "@/commands/marginNumbers"
import { useEditorChapterSettings } from "@/components/editor/hooks/useEditorChapterSettings"
import { useAppDispatch } from "@/store/hooks"
import { Snackbar, enqueueSnackbar } from "@/store/reducers/snackbars"
import { dialogClosed } from "@/store/store"
import { Alert } from "@/ui/Alert"
import { Checkbox } from "@/ui/Checkbox"
import { Switch } from "@/ui/Switch"
import { Tooltip } from "@/ui/Tooltip"
import { padLogger } from "@/utils/debug"
import { sendErrorToSentry } from "@/utils/sentry"

export function MarginNumberSettings() {
  const [isEnabled, setIsEnabled] = useState(false)
  const [alert, setAlert] = useState<AlertType>(null)

  const commands = useMarginNumberCommands()
  const chapterSettings = useEditorChapterSettings()

  const form = AriaKit.useFormStore({
    defaultValues: { startingNumber: 1, ...makeDefaultBlockSettings() },
  })

  const hasChapterExistingMarginNumbers = useEditorEventCallback((view) => {
    let hasMarginNumbers = false

    view.state.doc.descendants((node) => {
      if (!!node.type.spec.attrs?.marginNumber && !!node.attrs.marginNumber) {
        hasMarginNumbers = true
        return false
      } else {
        return true
      }
    })

    return hasMarginNumbers
  })

  const handleSwitchChange = useEditorEventCallback<[boolean], void>(
    (_, checked) => {
      if (checked) {
        const { startingNumber: _, ...blocks } = form.getState().values
        commands.enableMarginNumbers?.(blocks)
      } else {
        if (hasChapterExistingMarginNumbers()) setAlert({ type: "isDeleting" })
        else commands.disableMarginNumbers?.()
      }
    }
  )

  form.useValidate((state) => {
    const { startingNumber: _, ...blocks } = state.values
    const hasMarginNumbers = chapterSettings.marginNumbers

    // If the block configuration has changed, we update the doc attr accordingly
    if (hasMarginNumbers && !isEqual(blocks, chapterSettings.marginNumbers)) {
      commands.enableMarginNumbers?.(blocks)
    }
  })

  useEditorEffect((view) => {
    const { marginNumbers } = view.state.doc.attrs

    if ((!marginNumbers && !isEnabled) || (!!marginNumbers && !!isEnabled))
      return

    // If the doc attr gets populated, we initialize the form with it
    if (marginNumbers) {
      setIsEnabled(true)
      form.setValues({ ...marginNumbers, startingNumber: 1 })
    } else {
      setIsEnabled(false)
      form.setValues({ ...makeDefaultBlockSettings(), startingNumber: 1 })
    }
  })

  return (
    <div className={styles.settingsMarginNumber}>
      <AriaKit.DialogDescription
        className={styles.description}
        render={<div />}
      >
        <Localized id="settings-margin-number-description">
          To insert margin numbers, specify which blocks you want to include in
          the numbering and provide a starting number.
        </Localized>
        <Switch
          checked={isEnabled}
          onChange={handleSwitchChange}
          layout="horizontal"
          label={
            isEnabled ? (
              <Localized id="enabled">Enabled</Localized>
            ) : (
              <Localized id="disabled">Disabled</Localized>
            )
          }
        />
      </AriaKit.DialogDescription>
      <AriaKit.FormProvider store={form}>
        <AriaKit.Form
          className={!isEnabled ? styles.disabled : undefined}
          onSubmit={(event) => {
            event.preventDefault() // Prevent form reset

            const { startingNumber } = form.getState().values

            if (hasChapterExistingMarginNumbers()) {
              setAlert({ type: "isInserting", data: { startingNumber } })
            } else {
              commands.setMarginNumbers?.(startingNumber)
            }
          }}
        >
          <div className={styles.blocks}>
            {MARGIN_NUMBER_BLOCKS.map(({ name, nodeType }) => (
              <Checkbox
                key={nodeType}
                name={nodeType}
                checked={form.getValue(nodeType)}
                disabled={!isEnabled}
                defaultChecked={form.getValue(nodeType)}
                onChange={(e) => form.setValue(nodeType, e.target.checked)}
              >
                <Localized id={name.fluentId}>{name.label}</Localized>
              </Checkbox>
            ))}
          </div>
          <div className={styles.submitArea}>
            <div className={styles.startingNumber}>
              <AriaKit.FormLabel name={form.names.startingNumber}>
                <Localized id="settings-margin-number-starting-at">
                  Start numbering at:
                </Localized>
              </AriaKit.FormLabel>
              <AriaKit.FormInput
                name={form.names.startingNumber}
                disabled={!isEnabled}
                pattern="^[0-9]{1,5}$"
                type="text"
                required
              />
            </div>
            <div className={styles.actions}>
              <Tooltip
                title={
                  <Localized id="settings-margin-number-insert-tooltip">
                    Insert margin numbers in chapter
                  </Localized>
                }
              >
                <AriaKit.FormSubmit
                  className="blue-button"
                  disabled={!isEnabled}
                >
                  <Localized id="settings-margin-number-insert">
                    Insert Margin Numbers
                  </Localized>
                </AriaKit.FormSubmit>
              </Tooltip>
            </div>
          </div>
        </AriaKit.Form>
      </AriaKit.FormProvider>
      <AlertDialog alert={alert} setAlert={setAlert} />
    </div>
  )
}

type AlertType =
  | { type: "isDeleting" }
  | {
      type: "isInserting"
      data: { startingNumber: number }
    }
  | null

function AlertDialog({
  alert,
  setAlert,
}: {
  alert: AlertType
  setAlert: (alert: AlertType) => void
}) {
  const commands = useMarginNumberCommands()

  const alertDialog = AriaKit.useDialogStore({
    open: !!alert,
    setOpen: (open) => {
      if (!open) setAlert(null)
    },
  })

  useEffect(() => {
    if (alert) alertDialog.show()
    else alertDialog.hide()
  }, [alert, alertDialog])

  return (
    <Alert
      store={alertDialog}
      title={<Localized id="are-you-sure">Are you sure?</Localized>}
      description={
        alert?.type === "isDeleting" ? (
          <Localized id="alert-margin-numbers-deletion-description">
            Disabling margin numbers will remove existing margin numbers in this
            chapter.
          </Localized>
        ) : (
          <Localized id="alert-margin-numbers-insertion-description">
            Inserting margin numbers will override existing margin numbers in
            this chapter.
          </Localized>
        )
      }
      actions={[
        {
          label: <Localized id="cancel">Cancel</Localized>,
          variant: "outline",
          hideOnClick: true,
        },
        ...(alert?.type === "isInserting"
          ? [
              {
                label: (
                  <Localized id="alert-margin-numbers-insertion-label">
                    Replace Margin Numbers
                  </Localized>
                ),
                hideOnClick: true,
                variant: "danger" as const,
                beforeHide: () => {
                  const { startingNumber } = alert.data
                  commands.setMarginNumbers?.(startingNumber)
                },
              },
            ]
          : [
              {
                label: (
                  <Localized id="alert-margin-numbers-deletion-label">
                    Remove Margin Numbers
                  </Localized>
                ),
                hideOnClick: true,
                variant: "danger" as const,
                beforeHide: () => {
                  commands.unsetMarginNumbers?.()
                  commands.disableMarginNumbers?.()
                },
              },
            ]),
      ]}
    />
  )
}

/**
 * This hook exposes helper functions to mutate the `marginNumbers`
 * attribute of the chapter document.
 */

function useMarginNumberCommands() {
  const dispatch = useAppDispatch()

  return {
    enableMarginNumbers: useEditorEventCallback(
      (view, blocks: Record<string, boolean>) => {
        return enableMarginNumbers(blocks)(view.state, view.dispatch)
      }
    ),
    disableMarginNumbers: useEditorEventCallback((view) => {
      return disableMarginNumbers(view.state, view.dispatch)
    }),
    unsetMarginNumbers: useEditorEventCallback((view) => {
      return unsetMarginNumbers(view.state, view.dispatch)
    }),
    setMarginNumbers: useEditorEventCallback((view, startingNumber: number) => {
      try {
        setMarginNumbers(startingNumber)(view.state, view.dispatch)
        enqueueSnackbar(Snackbar.MarginNumbersSetSuccess)
        dispatch(dialogClosed())
      } catch (e) {
        enqueueSnackbar(Snackbar.MarginNumbersSetError)
        padLogger("Error setting margin numbers", e)
        sendErrorToSentry("Error setting margin numbers", e)
      }
    }),
  }
}

function makeDefaultBlockSettings() {
  return MARGIN_NUMBER_BLOCKS.reduce((prev, item) => {
    return { ...prev, [item.nodeType]: item.default }
  }, {} as Record<string, boolean>)
}

const MARGIN_NUMBER_BLOCKS: {
  default: boolean
  nodeType: string
  name: {
    fluentId: string
    label: string
  }
}[] = [
  {
    default: true,
    nodeType: "heading",
    name: {
      fluentId: "heading-generic",
      label: "Heading",
    },
  },
  {
    default: true,
    nodeType: "paragraph",
    name: {
      fluentId: "paragraph",
      label: "Paragraph",
    },
  },
  {
    default: true,
    nodeType: "blockquote",
    name: {
      fluentId: "blockquote",
      label: "Blockquote",
    },
  },
  {
    default: true,
    nodeType: "callout",
    name: {
      fluentId: "callout",
      label: "Callout",
    },
  },
  {
    default: true,
    nodeType: "details",
    name: {
      fluentId: "detail",
      label: "Detail",
    },
  },
  {
    default: true,
    nodeType: "pdf",
    name: {
      fluentId: "pdf",
      label: "PDF",
    },
  },
  {
    default: true,
    nodeType: "quiz",
    name: {
      fluentId: "quiz",
      label: "Quiz",
    },
  },
  {
    default: true,
    nodeType: "ordered_list",
    name: {
      fluentId: "ordered-list",
      label: "Ordered List",
    },
  },
  {
    default: true,
    nodeType: "bullet_list",
    name: {
      fluentId: "bulleted-list",
      label: "Bulleted List",
    },
  },
  {
    default: true,
    nodeType: "table",
    name: {
      fluentId: "table",
      label: "Table",
    },
  },
  {
    default: true,
    nodeType: "definition",
    name: {
      fluentId: "definition",
      label: "Definition",
    },
  },
  {
    default: true,
    nodeType: "review_schema",
    name: {
      fluentId: "review-schema",
      label: "Review Schema",
    },
  },
  {
    default: true,
    nodeType: "image",
    name: {
      fluentId: "image",
      label: "Image",
    },
  },
  {
    default: true,
    nodeType: "foldable",
    name: {
      fluentId: "foldable",
      label: "Foldable",
    },
  },
  {
    default: true,
    nodeType: "boxed",
    name: {
      fluentId: "boxed",
      label: "Boxed",
    },
  },
  {
    default: true,
    nodeType: "instruction",
    name: {
      fluentId: "instruction",
      label: "Instruction",
    },
  },
]
