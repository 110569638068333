import { useEditorState } from "@nytimes/react-prosemirror"
import { lift } from "prosemirror-commands"

import { isNodeTypeSelected } from "@/commands/selection"
import { CommandItem } from "@/components/editor/toolbar/items/CommandItem"
import { RemoveDetail } from "@/components/icons"

export function DetailsSection() {
  const editorState = useEditorState()

  if (!isNodeTypeSelected(editorState, editorState?.schema.nodes["details"])) {
    return null
  }

  return (
    <>
      <CommandItem command={lift}>
        <RemoveDetail />
      </CommandItem>
    </>
  )
}
