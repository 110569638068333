import {
  Select,
  SelectArrow,
  SelectItem,
  SelectPopover,
  useSelectStore,
} from "@ariakit/react"
import {
  useEditorEventCallback,
  useEditorState,
} from "@nytimes/react-prosemirror"
import {
  Fragment,
  NodeRange,
  NodeType,
  type ResolvedPos,
  Slice,
} from "prosemirror-model"
import type { Command, EditorState, Transaction } from "prosemirror-state"
import { ReplaceAroundStep, canJoin, liftTarget } from "prosemirror-transform"
import { useCallback, useEffect, useState } from "react"

import { deleteBlockNode } from "@/commands/deleteBlockNode.ts"
import { isFootnoteSelected } from "@/commands/footnotes"
import {
  addReviewItemBelow,
  removeReviewItem,
  setReviewSchemaListStyle,
} from "@/commands/reviewSchemas.ts"
import { isNodeTypeSelected } from "@/commands/selection"
import { CommandItem } from "@/components/editor/toolbar/items/CommandItem.tsx"
import styles from "@/components/editor/toolbar/toolbar.module.css"
import { Lift, Minus, Plus, Sink, Trash } from "@/components/icons"
import { chapterSchema } from "@/schemas/chapter/schema"
import { findAncestor } from "@/utils/prosemirror"

export function ReviewSchemaSection() {
  const editorState = useEditorState()

  const findClosestReviewSchema = useCallback(($from: ResolvedPos) => {
    return findAncestor($from, (node) => node?.type.name === "review_schema")
  }, [])

  const [currentLitStyle, setCurrentLitStyle] = useState<string>()

  useEffect(() => {
    if (!editorState) return
    const { node } = findClosestReviewSchema(editorState.selection.$from) ?? {}
    setCurrentLitStyle(node ? node.attrs["listStyle"] : "")
  }, [editorState, findClosestReviewSchema])

  const onChange = useEditorEventCallback((view, value) => {
    const { selection, schema, tr } = view.state
    if (!isNodeTypeSelected(view.state, schema.nodes["review_schema"])) return

    const { pos } = findClosestReviewSchema(selection.$from) ?? {}
    if (!pos) return

    view.dispatch(tr.setNodeAttribute(pos, "listStyle", value))
  })

  const selectStore = useSelectStore({
    value: currentLitStyle,
    setValue: onChange,
  })
  const value = selectStore.useState("value")

  if (
    !editorState ||
    !editorState.schema.nodes["review_schema"] ||
    !editorState.schema.nodes["review_item"] ||
    !isNodeTypeSelected(
      editorState,
      editorState.schema.nodes["review_schema"]
    ) ||
    isFootnoteSelected(editorState.selection)
  ) {
    return null
  }

  return (
    <>
      <CommandItem command={deleteBlockNode(chapterSchema.nodes.review_schema)}>
        <Trash />
      </CommandItem>
      <CommandItem command={addReviewItemBelow()}>
        <Plus />
      </CommandItem>
      <CommandItem command={removeReviewItem()}>
        <Minus />
      </CommandItem>
      <CommandItem
        command={sinkReviewItem(editorState.schema.nodes["review_item"])}
      >
        <Sink />
      </CommandItem>
      <CommandItem
        command={liftReviewItem(editorState.schema.nodes["review_item"])}
      >
        <Lift />
      </CommandItem>
      <Select store={selectStore} className={styles["editorToolbarItem"]}>
        <span className={styles["editorToolbarItemLabel"]}>
          {value === "upper-alpha"
            ? "A."
            : value === "upper-roman"
            ? "I."
            : value === "decimal"
            ? "1."
            : value === "lower-alpha"
            ? "a)"
            : value === "lower-alpha-double"
            ? "aa)"
            : value === "lower-roman"
            ? "i)"
            : value === "decimal-bracketed"
            ? "(1)"
            : value === "lower-alpha-bracketed"
            ? "(a)"
            : value === "lower-alpha-double-bracketed"
            ? "(aa)"
            : value === "lower-roman-bracketed"
            ? "(i)"
            : ""}
        </span>
        <SelectArrow />
      </Select>
      <SelectPopover
        store={selectStore}
        className={styles["editorToolbarDropdownMenu"]}
      >
        <CommandItem
          command={setReviewSchemaListStyle("upper-alpha")}
          as={SelectItem}
          className={styles["editorToolbarDropdownItem"]}
        >
          A.
        </CommandItem>{" "}
        <CommandItem
          command={setReviewSchemaListStyle("upper-roman")}
          as={SelectItem}
          className={styles["editorToolbarDropdownItem"]}
        >
          I.
        </CommandItem>
        <CommandItem
          command={setReviewSchemaListStyle("decimal")}
          as={SelectItem}
          className={styles["editorToolbarDropdownItem"]}
        >
          1.
        </CommandItem>
        <CommandItem
          command={setReviewSchemaListStyle("lower-alpha")}
          as={SelectItem}
          className={styles["editorToolbarDropdownItem"]}
        >
          a)
        </CommandItem>
        <CommandItem
          command={setReviewSchemaListStyle("lower-alpha-double")}
          as={SelectItem}
          className={styles["editorToolbarDropdownItem"]}
        >
          aa)
        </CommandItem>
        <CommandItem
          command={setReviewSchemaListStyle("lower-roman")}
          as={SelectItem}
          className={styles["editorToolbarDropdownItem"]}
        >
          i)
        </CommandItem>
        <CommandItem
          command={setReviewSchemaListStyle("decimal-bracketed")}
          as={SelectItem}
          className={styles["editorToolbarDropdownItem"]}
        >
          (1)
        </CommandItem>
        <CommandItem
          command={setReviewSchemaListStyle("lower-alpha-bracketed")}
          as={SelectItem}
          className={styles["editorToolbarDropdownItem"]}
        >
          (a)
        </CommandItem>
        <CommandItem
          command={setReviewSchemaListStyle("lower-alpha-double-bracketed")}
          as={SelectItem}
          className={styles["editorToolbarDropdownItem"]}
        >
          (aa)
        </CommandItem>
        <CommandItem
          command={setReviewSchemaListStyle("lower-roman-bracketed")}
          as={SelectItem}
          className={styles["editorToolbarDropdownItem"]}
        >
          (i)
        </CommandItem>
      </SelectPopover>
    </>
  )
}

/**
 * The commands below are customized commands based on ProseMirror's native implementation:
 * https://github.com/ProseMirror/prosemirror-schema-list/blob/master/src/schema-list.ts
 *
 * They enable the `review_schema` node to have a title, description, and review items on the same level.
 * While ProseMirror's native implementation checks for `node.firstChild` when creating a range,
 * we check for `node.lastChild` since a review schema starts with an optional title node.
 *
 * Reference: https://github.com/dskrpt/dskrpt_app/pull/747
 */

function liftReviewItem(itemType: NodeType): Command {
  return function (state: EditorState, dispatch?: (tr: Transaction) => void) {
    const { $from, $to } = state.selection
    let range = $from.blockRange(
      $to,
      (node) =>
        node.childCount > 0 &&
        !!node.lastChild &&
        node.lastChild.type == itemType
    )
    if (!range) return false

    const parent = $from.node(range.depth - 1)
    if (parent.type.name === "doc") return false

    if (!dispatch) return true
    if (parent.type == itemType) {
      const tr = state.tr,
        end = range.end,
        endOfList = range.$to.end(range.depth)
      if (end < endOfList) {
        // There are siblings after the lifted items, which must become
        // children of the last item
        tr.step(
          new ReplaceAroundStep(
            end - 1,
            endOfList,
            end,
            endOfList,
            new Slice(
              Fragment.from(itemType.create(null, range.parent.copy())),
              1,
              0
            ),
            1,
            true
          )
        )
        range = new NodeRange(
          tr.doc.resolve(range.$from.pos),
          tr.doc.resolve(endOfList),
          range.depth
        )
      }
      const target = liftTarget(range)
      if (target == null) return false
      tr.lift(range, target)
      const after = tr.mapping.map(end, -1) - 1
      if (canJoin(tr.doc, after)) tr.join(after)
      dispatch(tr.scrollIntoView())
      return true
    } else {
      return false
    }
  }
}

function sinkReviewItem(itemType: NodeType): Command {
  return function (state, dispatch) {
    const { $from, $to } = state.selection
    const range = $from.blockRange(
      $to,
      (node) =>
        node.childCount > 0 &&
        !!node.lastChild &&
        node.lastChild.type == itemType
    )

    if (!range) return false
    const startIndex = range.startIndex

    if (startIndex == 0) return false
    const parent = range.parent,
      nodeBefore = parent.child(startIndex - 1)
    if (nodeBefore.type != itemType) return false

    if (dispatch) {
      const nestedBefore =
        nodeBefore.lastChild && nodeBefore.lastChild.type == parent.type
      const inner = Fragment.from(nestedBefore ? itemType.create() : null)
      const slice = new Slice(
        Fragment.from(
          itemType.create(null, Fragment.from(parent.type.create(null, inner)))
        ),
        nestedBefore ? 3 : 1,
        0
      )
      const before = range.start,
        after = range.end
      dispatch(
        state.tr
          .step(
            new ReplaceAroundStep(
              before - (nestedBefore ? 3 : 1),
              after,
              before,
              after,
              slice,
              1,
              true
            )
          )
          .scrollIntoView()
      )
    }
    return true
  }
}
