import { reactKeys } from "@nytimes/react-prosemirror"
import { history } from "prosemirror-history"
import { EditorState } from "prosemirror-state"

import { magicQuizSchema } from "./schema"

import type { MagicQuiz } from "@/types/api"

/**
 * Creates a new editor state from a message.
 * Based on the message schema and message plugin configuration.
 */

export function createEditorState(quiz?: MagicQuiz) {
  return EditorState.create({
    schema: magicQuizSchema,
    plugins: [history(), reactKeys()],
    doc: magicQuizSchema.nodes.doc.create(null, createQuiz(quiz)),
  })
}

function createQuiz(quiz?: MagicQuiz) {
  const question = magicQuizSchema.nodes["quiz_question"].create(
    null,
    quiz ? magicQuizSchema.text(quiz.question) : null
  )

  const answerList = quiz
    ? quiz.answers.map((answer) =>
        magicQuizSchema.nodes["quiz_answer"].create(
          { isCorrect: answer.correct },
          answer.text ? magicQuizSchema.text(answer.text) : null
        )
      )
    : [
        magicQuizSchema.nodes["quiz_answer"].create(),
        magicQuizSchema.nodes["quiz_answer"].create(),
        magicQuizSchema.nodes["quiz_answer"].create(),
      ]

  const answers = magicQuizSchema.nodes["quiz_answers"].create(null, answerList)

  const explanation = magicQuizSchema.nodes["quiz_explanation"].create(
    null,
    quiz?.explanation ? magicQuizSchema.text(quiz.explanation) : null
  )

  return magicQuizSchema.nodes["quiz"].create({}, [
    question,
    answers,
    explanation,
  ])
}
