import { MessageEditor } from "./MessageEditor.tsx"
import { MessagesList } from "./MessagesList.tsx"
import styles from "./messages.module.css"

import { useAsidePortal } from "@/hooks/useAsidePortal.ts"
import { useHighlightAttachment } from "@/hooks/useHighlightAttachment.ts"
import type { UUID } from "@/store/UUID.ts"

type ThreadProps = {
  highlightId: UUID
}

export function Thread({ highlightId }: ThreadProps) {
  const { top, ref: threadRef } =
    useHighlightAttachment<HTMLDivElement>(highlightId)

  return useAsidePortal(
    <div
      ref={threadRef}
      className={styles["messagesWrapper"]}
      data-preferred-top={top}
      data-is-anchor={false}
      data-tie-breaker={1}
    >
      <MessagesList highlightId={highlightId} />
      <MessageEditor highlightId={highlightId} />
    </div>,
    highlightId
  )
}
