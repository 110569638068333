import {
  DialogDescription,
  DialogDismiss,
  DialogHeading,
  Dialog as DialogRoot,
  useDialogStore,
} from "@ariakit/react"
import { Localized } from "@fluent/react"

import dialogStyles from "./dialog.module.css"

import { useAppSelector } from "@/store/hooks"
import { Dialog } from "@/store/slices/dialogs"

export function ExpiredSessionDialog() {
  const isOpen = useAppSelector(
    (state) => state.dialogs.openDialog === Dialog.ERROR_EXPIRED_SESSION
  )

  const dialogStore = useDialogStore({
    open: isOpen,
  })

  return (
    <DialogRoot
      store={dialogStore}
      portal
      className={dialogStyles["dialog"]}
      hideOnInteractOutside={false}
      hideOnEscape={false}
    >
      <DialogHeading>
        <Localized id={"expired-session"}>Your session has expired.</Localized>
      </DialogHeading>
      <DialogDescription>
        <Localized id={"expired-session-description"}>
          To continue using dskrpt, please log in again.
        </Localized>
      </DialogDescription>
      <DialogDismiss
        onClick={() => {
          window.location.href = makeLoginUrlWithRedirect()
        }}
        className={"blue-button"}
      >
        <Localized id={"expired-session-action"}>Go to Login</Localized>
      </DialogDismiss>
    </DialogRoot>
  )
}

function makeLoginUrlWithRedirect() {
  const url = new URL(window.location.href)
  url.pathname = "/accounts/login/"
  url.searchParams.set("next", window.location.pathname)
  return url.toString()
}
