import { Button } from "@ariakit/react"
import { useLocalization } from "@fluent/react"
import {
  type NodeViewComponentProps,
  ProseMirror,
  ProseMirrorDoc,
} from "@nytimes/react-prosemirror"
import { skipToken } from "@reduxjs/toolkit/query"
import { clsx } from "clsx"
import {
  type ComponentProps,
  forwardRef,
  useContext,
  useEffect,
  useState,
} from "react"

import styles from "./FlashcardStudyView.module.css"
import { FlashcardStudyViewReportButtons } from "./FlashcardStudyViewReportButtons.tsx"

import { SelectLinkHandler } from "@/components/editor/plugins/SelectLinkHandler.tsx"
import { generateGuid } from "@/components/editor/plugins/guid.ts"
import sharedStyles from "@/components/shared.module.css"
import { FlashcardContext } from "@/contexts/FlashcardDeckContext.ts"
import {
  getBackContentNode,
  getFrontContentNode,
} from "@/schemas/flashcard/schema.ts"
import type { UUID } from "@/store/UUID.ts"
import { useAppDispatch, useAppSelector } from "@/store/hooks.ts"
import { getRevisionEditorState } from "@/store/selectors/documentsSelectors.ts"
import {
  useGetFlashcardLatestStepsQuery,
  useGetFlashcardQuery,
  useGetFlashcardRevisionQuery,
} from "@/store/slices/api.ts"
import { PanelType } from "@/store/slices/panels.ts"
import { panelOpened } from "@/store/thunks/panelOpened.ts"
import type { StudySession } from "@/types/api.ts"

export const FlashcardFront = forwardRef<
  HTMLDivElement,
  NodeViewComponentProps
>(function FlashcardFront({ children, nodeProps: _, ...props }, ref) {
  return (
    <div ref={ref} {...props} className={styles["flashcard"]}>
      {children}
    </div>
  )
})

export const FlashcardBack = forwardRef<HTMLDivElement, NodeViewComponentProps>(
  function FlashcardBack({ children, nodeProps: _, ...props }, ref) {
    const { showBackside } = useContext(FlashcardContext)
    if (!showBackside) return null
    return (
      <div ref={ref} {...props} className={styles["flashcard"]}>
        {children}
      </div>
    )
  }
)

const reactNodeViews: ComponentProps<typeof ProseMirror>["nodeViews"] = {
  front: FlashcardFront,
  back: FlashcardBack,
}

export function FlashcardStudyViewCard({
  flashcardId,
  revisionId,
  sessionId,
  intervals,
}: {
  flashcardId: UUID
  revisionId: UUID
  sessionId: UUID
  intervals: StudySession["nextFlashcardIntervals"]
}) {
  const [showBackside, setShowBackside] = useState(false)
  const { l10n } = useLocalization()
  const dispatch = useAppDispatch()

  const { data: flashcard } = useGetFlashcardQuery(flashcardId)

  const { data: revision } = useGetFlashcardRevisionQuery(
    !revisionId
      ? skipToken
      : {
          flashcardId: flashcardId,
          revisionId: revisionId,
        }
  )

  useGetFlashcardLatestStepsQuery(
    revision
      ? {
          flashcardId: flashcardId,
          revisionId: revision.id,
          version: revision.snapshot?.version ?? 0,
        }
      : skipToken
  )

  const editorState = useAppSelector(
    (state) => revision && getRevisionEditorState(state, revision.id)
  )

  // Listen to space bar keypress to show/hide backside
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.code === "Space") {
        event.preventDefault()
        setShowBackside(!showBackside)
      }
    }
    window.addEventListener("keydown", handleKeyPress)
    return () => window.removeEventListener("keydown", handleKeyPress)
  }, [showBackside])

  const handleShowSourceClicked = () => {
    if (!flashcard?.highlight) return
    dispatch(
      panelOpened({
        panel: {
          panelId: generateGuid(),
          panelType: PanelType.VIEWER,
          documentId: flashcard.highlight?.documentId,
          scrollToHighlight: flashcard.highlight?.id,
        },
      })
    )
  }

  if (!editorState) return null
  const frontContent = getFrontContentNode(editorState.doc)
  const backContent = getBackContentNode(editorState.doc)

  if (!frontContent || !backContent) return null
  return (
    <FlashcardContext.Provider
      value={{
        flashcardId: flashcardId,
        sessionId: sessionId,
        showBackside: showBackside,
        toggleBackside: () => {
          setShowBackside(false)
        },
      }}
    >
      <div className={styles["flashcardWrapper"]} key={flashcardId}>
        <ProseMirror
          state={editorState}
          nodeViews={reactNodeViews}
          editable={() => false}
          attributes={{
            "data-variant": "flashcard",
            "data-flashcard-view": "side-by-side",
          }}
        >
          <SelectLinkHandler />
          <ProseMirrorDoc />
        </ProseMirror>
      </div>
      <div className={styles["flashcardButtons"]}>
        <Button
          className={sharedStyles["defaultButton"]}
          onClick={() => setShowBackside(!showBackside)}
        >
          {!showBackside
            ? l10n.getString("flashcard-learning-show-back")
            : l10n.getString("flashcard-learning-hide-back")}
        </Button>
        {flashcard?.highlight && (
          <Button
            className={clsx(sharedStyles["defaultButton"])}
            onClick={handleShowSourceClicked}
          >
            {l10n.getString("flashcard-show-source")}
          </Button>
        )}
      </div>
      <FlashcardStudyViewReportButtons intervals={intervals} />
    </FlashcardContext.Provider>
  )
}
