import {
  DialogDescription,
  DialogDismiss,
  DialogHeading,
  Dialog as DialogRoot,
  useDialogStore,
} from "@ariakit/react/dialog"
import { Localized } from "@fluent/react"
import {
  ProseMirror,
  ProseMirrorDoc,
  useEditorEventCallback,
  useEditorState,
} from "@nytimes/react-prosemirror"
import type { Node } from "prosemirror-model"
import {
  type EditorState,
  NodeSelection,
  type Transaction,
} from "prosemirror-state"
import { insertPoint } from "prosemirror-transform"
import { useCallback, useEffect, useState } from "react"

import dialogStyles from "./dialog.module.css"

import { Keymap } from "@/components/Keymap"
import {
  QuizAnswer,
  QuizAnswers,
  QuizExplanation,
  QuizQuestion,
} from "@/components/editor/nodeViews/Quiz/Quiz.node"
import { Cancel, MagicWand, Plus, Warning } from "@/components/icons"
import { createEditorState } from "@/schemas/magic-quiz/createEditorState"
import { useAppDispatch, useAppSelector } from "@/store/hooks"
import { useGenerateMagicQuizMutation } from "@/store/slices/api"
import { Dialog } from "@/store/slices/dialogs"
import { dialogClosed } from "@/store/store"
import { sendErrorToSentry } from "@/utils/sentry"

export function GenerateQuizDialog() {
  const [shouldGenerateQuiz, setShouldGenerateQuiz] = useState(true)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [editorState, setEditorState] = useState<EditorState | null>(null)
  const outerEditorState = useEditorState()

  const dispatchTransaction = useCallback((tr: Transaction) => {
    setEditorState((currentState) => {
      if (!currentState) return null

      try {
        return currentState.apply(tr)
      } catch (error) {
        sendErrorToSentry(`Could not apply MagicQuiz editor transaction`, error)
        return currentState
      }
    })
  }, [])

  const [generateQuiz, { isLoading: isGenerating, isError }] =
    useGenerateMagicQuizMutation()

  const dispatch = useAppDispatch()
  const isOpen = useAppSelector(
    (state) => state.dialogs.openDialog === Dialog.INSERT_MAGIC_QUIZ
  )

  const dialogStore = useDialogStore({
    open: isOpen,
  })

  useEffect(() => {
    let pendingRequest: ReturnType<typeof generateQuiz> | null = null

    if (isOpen && outerEditorState && shouldGenerateQuiz) {
      const { from, to } = outerEditorState.selection
      const selectedText = outerEditorState.doc.textBetween(from, to, "\n\n")

      pendingRequest = generateQuiz({ selectedText })

      pendingRequest
        .unwrap()
        .then((result) => {
          setErrorMessage(null)
          setEditorState(createEditorState(result))
        })
        .catch((err) => {
          if (err?.data?.text && Array.isArray(err.data.text)) {
            setErrorMessage(err.data.text.join(" \n "))
          }
          sendErrorToSentry(`Could not generate magic quiz`, err)
        })
        .finally(() => {
          setShouldGenerateQuiz(false)
        })
    }

    return () => {
      if (pendingRequest) pendingRequest.abort()
    }
    // We omit dependencies since we only want to run this effect when explicitly triggered
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldGenerateQuiz])

  const findInsertPos = useCallback((state: EditorState) => {
    const { schema, doc, selection } = state
    if (!schema.nodes["quiz"]) return null

    const insertPos = insertPoint(doc, selection.head, schema.nodes["quiz"])
    if (insertPos !== null) return insertPos

    const endOfCurrentBlock = selection.$from.end()
    return insertPoint(doc, endOfCurrentBlock, schema.nodes["quiz"])
  }, [])

  const onConfirm = useEditorEventCallback<{ quiz: Node }[], void>(
    (view, { quiz: quizNode }) => {
      if (!view) return false

      const insertPos = findInsertPos(view.state)
      if (insertPos === null) return false

      if (view.dispatch) {
        const { tr, schema } = view.state
        // `.toJSON()` is necessary since the node stems from different editor
        tr.insert(insertPos, schema.nodeFromJSON(quizNode.toJSON()))
        tr.setSelection(NodeSelection.create(tr.doc, insertPos))
        tr.scrollIntoView()
        view.dispatch(tr)
      }

      setTimeout(() => {
        // Focus editor on next tick to make sure the
        // dialog is closed + node is inserted
        view.focus()
      })

      return true
    }
  )

  const isLoading = isGenerating || (!editorState && !isError)

  return (
    <DialogRoot
      store={dialogStore}
      portal
      className={dialogStyles["dialog"]}
      onClose={() => dispatch(dialogClosed())}
    >
      <DialogHeading>
        <Localized id={"insert-magic-quiz-heading"}>
          Insert a Magic Quiz
        </Localized>
      </DialogHeading>
      <DialogDescription>
        <Localized id={"insert-magic-quiz-description"}>
          We generated the following quiz from the text you selected. You can
          re-generate or edit it below.
        </Localized>
      </DialogDescription>
      {!isLoading && !isError && (
        <MagicQuizEditor
          state={editorState}
          dispatchTransaction={dispatchTransaction}
        />
      )}
      {(isLoading || isError) && (
        <div className={dialogStyles["magicQuizEditorArea"]}>
          {isLoading && !isError && (
            <>
              <MagicQuizEditorSkeleton />
              <div
                className={dialogStyles["stateMessage"]}
                style={{ opacity: 0.5 }}
              >
                <Localized id={"insert-magic-quiz-generating"}>
                  Generating quiz...
                </Localized>
              </div>
            </>
          )}
          {!isLoading && isError && (
            <div
              className={dialogStyles["stateMessage"]}
              style={{ maxWidth: "400px", margin: "0 auto" }}
            >
              <Warning />
              {errorMessage ?? (
                <Localized id={"insert-magic-quiz-error"}>
                  An error occurred while generating the quiz.
                </Localized>
              )}
            </div>
          )}
        </div>
      )}
      <div className={dialogStyles["dialogActions"]}>
        <DialogDismiss className={"blue-button outlined"}>
          <Cancel />
          <Localized id={"cancel"}>Cancel</Localized>
        </DialogDismiss>
        {!isLoading && !isError && editorState?.doc.firstChild && (
          <>
            <button
              className={"purple-button"}
              onClick={() => {
                setShouldGenerateQuiz(true)
              }}
            >
              <MagicWand />
              <Localized id={"re-generate"}>Re-generate</Localized>
            </button>
            <button
              className={"blue-button"}
              onClick={() => {
                dispatch(dialogClosed())
                if (editorState?.doc.firstChild) {
                  onConfirm({ quiz: editorState.doc.firstChild })
                }
              }}
            >
              <Plus />
              <Localized id={"insert"}>Insert</Localized>
            </button>
          </>
        )}
      </div>
    </DialogRoot>
  )
}

const reactNodeViews = {
  quiz_answer: QuizAnswer,
  quiz_answers: QuizAnswers,
  quiz_explanation: QuizExplanation,
  quiz_question: QuizQuestion,
}

type MagicQuizEditorProps = {
  state: EditorState | null
  dispatchTransaction: (tr: Transaction) => void
}

function MagicQuizEditor({ state, dispatchTransaction }: MagicQuizEditorProps) {
  return (
    <ProseMirror
      state={state || createEditorState()}
      nodeViews={reactNodeViews}
      dispatchTransaction={dispatchTransaction}
      attributes={{ "data-variant": "magic-quiz" }}
    >
      <Keymap />
      <ProseMirrorDoc />
    </ProseMirror>
  )
}

function MagicQuizEditorSkeleton() {
  return (
    <div className={dialogStyles["magicQuizEditorSkeleton"]}>
      {[1, 2, 3].map((i) => (
        <div
          key={i}
          style={{
            margin: "0.5rem 0",
            width: "100%",
            height: "40px",
            border: "1px solid var(--colorGray200)",
            borderRadius: "4px",
            backgroundColor: "white",
          }}
        />
      ))}
    </div>
  )
}
