import {
  useEditorEventCallback,
  useEditorState,
} from "@nytimes/react-prosemirror"
import { type Command } from "prosemirror-state"
import {
  type ComponentType,
  type MouseEvent,
  type ReactNode,
  useContext,
} from "react"

import { EditorToolbarItem } from "./EditorToolbarItem"

import { AtomViewContext } from "@/contexts/AtomViewContext"
import { RevisionContext } from "@/contexts/RevisionContext"
import { useFocusView } from "@/hooks/useFocusView"
import { useAppSelector } from "@/store/hooks"
import { getAtomState } from "@/store/selectors/documentsSelectors"

type Props = {
  children: ReactNode
  command: Command
  active?: boolean
  hide?: boolean
  className?: string
  as?: ComponentType<{
    children: ReactNode
    onClick: (event: MouseEvent) => void
    disabled: boolean
    active?: boolean | undefined
    className?: string | undefined
  }>
}

export function CommandItem({
  children,
  command,
  active,
  hide,
  className,
  as: Component = EditorToolbarItem,
}: Props) {
  const { revisionId } = useContext(RevisionContext)
  const { atomView } = useContext(AtomViewContext)
  const atomState = useAppSelector((state) => getAtomState(state, revisionId))
  const focusView = useFocusView()

  const onClick = useEditorEventCallback((view) => {
    command(atomState ?? view.state, atomView?.dispatch ?? view.dispatch)
    focusView()
  })

  const editorState = useEditorState()
  const disabled = !editorState || !command(atomState ?? editorState)

  if (disabled && hide) return null

  return (
    <Component
      active={active}
      onClick={onClick}
      disabled={disabled}
      className={className}
    >
      {children}
    </Component>
  )
}
