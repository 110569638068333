import { createContext } from "react"

import type { UUID } from "@/store/UUID"

export type RevisionContextValue = {
  documentType: "chapter" | "flashcard" | "note"
  documentId: UUID
  revisionId: UUID
}

export const RevisionContext = createContext<RevisionContextValue>(
  null as unknown as RevisionContextValue
)
