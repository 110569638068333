import { generateGuid } from "@/components/editor/plugins/guid.ts"
import type { UUID } from "@/store/UUID.ts"
import { getChapter } from "@/store/selectors/chaptersSelectors"
import { getNotes } from "@/store/selectors/notesSelectors.ts"
import { PanelType, panelsSlice } from "@/store/slices/panels"
import { createAppThunk } from "@/store/store"

export const panelClosed = createAppThunk(
  "panels/panelClosed",
  ({ panelId }: { panelId: UUID }, { dispatch, getState }) => {
    // If panelClosed is called from a primary panel that is a viewer or editor, redirect to the script URL
    const panel = getState().panels.stack.find(
      (panel) => panel.panelId === panelId
    )
    const isLastPanel = getState().panels.stack.length === 1

    // If the panel is the last panel and it is a viewer or editor, redirect to the script URL
    if (panel && isLastPanel && Object.hasOwn(panel, "documentId")) {
      if (
        panel.panelType === PanelType.VIEWER ||
        panel.panelType === PanelType.EDITOR
      ) {
        const document = getChapter(getState(), panel.documentId)
        if (!document || !document.scriptUrl) return
        window.location.href = document.scriptUrl
        return
      } else if (panel.panelType === PanelType.NOTE) {
        const document = getNotes(getState(), panel.documentId)
        if (!document || !document.notebookUrl) return
        window.location.href = document.notebookUrl
        return
      }
    }

    // If panelClosed is called from a study view, replace the panel with a deck view
    if (panel?.panelType === PanelType.FLASHCARD_STUDY_VIEW) {
      window.location.hash = ""
      if (!panel.documentId) return
      dispatch(
        panelsSlice.actions.panelsReplaced({
          panelType: PanelType.FLASHCARD_DECK,
          documentId: panel.documentId,
          panelId: generateGuid() as UUID,
        })
      )
    } else {
      dispatch(panelsSlice.actions.panelClosed({ panelId }))
    }
  }
)
