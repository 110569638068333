import { Button } from "@ariakit/react"
import { Localized, useLocalization } from "@fluent/react"
import { clsx } from "clsx"

import { Plus, Trash } from "@/components/icons.tsx"
import sharedStyles from "@/components/shared.module.css"
import listStyles from "@/micro_apps/components/defaultList.module.css"
import {
  useCreateNoteMutation,
  useDeleteNoteMutation,
} from "@/store/slices/api.ts"
import type { Note, Notebook } from "@/types/api.ts"
import { IconButton } from "@/ui/IconButton"
import { Subheader } from "@/ui/Subheader/subheader.tsx"

export function NoteList({ notebook }: { notebook: Notebook }) {
  const [createNote] = useCreateNoteMutation()

  const { l10n } = useLocalization()

  const handleCreateNoteClicked = () => {
    createNote({
      notebookId: notebook.id,
      title: "New Note",
      description: "",
    })
  }

  return (
    <>
      <Subheader
        text={l10n.getString("notebook-notes", null, "Notes")}
        buttons={
          <>
            <Button
              onClick={handleCreateNoteClicked}
              className={clsx(sharedStyles["textButton"])}
            >
              <Plus />
              <Localized id={"notebook-note-new"}>New Note</Localized>
            </Button>
          </>
        }
      />
      <ul className={listStyles["defaultList"]}>
        {notebook.notes.map((note) => (
          <NoteListEntry note={note} key={note.id} />
        ))}
        {notebook.notes.length === 0 && (
          <li>
            <Localized id={"notebook-no-notes"}>
              <p>
                You do not have any notes yet. Click the button above to create
                a new note.
              </p>
            </Localized>
          </li>
        )}
      </ul>
    </>
  )
}

export function NoteListEntry({ note }: { note: Note }) {
  const { title, description } = note

  const { l10n } = useLocalization()
  const [deleteNote] = useDeleteNoteMutation()

  return (
    <li
      className={clsx(listStyles["defaultListItem"], listStyles["borderLeft"])}
    >
      <div className={listStyles["defaultListItemContentWrapper"]}>
        <div className={listStyles["defaultListItemTitle"]}>
          <a href={note.url}>{title}</a>
        </div>
        {description && (
          <div className={listStyles["defaultListItemDescription"]}>
            {description}
          </div>
        )}
      </div>
      <div className={listStyles["defaultListItemControls"]}>
        <IconButton
          label={l10n.getString("delete", null, "Delete")}
          icon={<Trash />}
          size={"medium"}
          onClick={() => {
            confirm(l10n.getString("notebook-delete-note-confirm")) &&
              deleteNote({ notebookId: note.notebookId, noteId: note.id })
          }}
        />
      </div>
    </li>
  )
}
