import type { EditorState } from "prosemirror-state"
import { TextSelection } from "prosemirror-state"
import { insertPoint } from "prosemirror-transform"
import type { EditorView } from "prosemirror-view"

export function insertFoldable(
  state: EditorState,
  dispatch?: EditorView["dispatch"]
) {
  if (
    !(
      state.schema.nodes["foldable"] &&
      state.schema.nodes["foldable_summary"] &&
      state.schema.nodes["foldable_content"] &&
      state.schema.nodes["paragraph"]
    )
  ) {
    return false
  }

  const validInsertPoint = insertPoint(
    state.doc,
    state.selection.from,
    state.schema.nodes["foldable"]
  )

  if (validInsertPoint === null) return false

  if (dispatch) {
    const { tr } = state
    const summary = state.schema.nodes["foldable_summary"].create({})
    const content = state.schema.nodes["foldable_content"].create(
      {},
      state.schema.nodes["paragraph"].create({})
    )
    const foldable = state.schema.nodes["foldable"].create({}, [
      summary,
      content,
    ])
    tr.insert(validInsertPoint, foldable)

    // Set selection to the summary
    tr.setSelection(TextSelection.create(tr.doc, validInsertPoint + 1))

    dispatch(tr)
  }
  return true
}
