import {
  Disclosure,
  DisclosureContent,
  type DisclosureStore,
  useDisclosureStore,
} from "@ariakit/react"
import {
  type NodeViewComponentProps,
  useEditorEffect,
} from "@nytimes/react-prosemirror"
import { createContext, forwardRef, useContext } from "react"

import foldableStyles from "./foldable.module.css"

const DisclosureContext = createContext(null as unknown as DisclosureStore)

export const FoldableContent = forwardRef<
  HTMLDivElement,
  NodeViewComponentProps
>(function FoldableContent({ children, nodeProps: _, ...props }, ref) {
  const disclosureStore = useContext(DisclosureContext)

  return (
    <div ref={ref} {...props}>
      <DisclosureContent
        store={disclosureStore}
        className={foldableStyles.foldableContent}
      >
        {children}
      </DisclosureContent>
    </div>
  )
})

export const FoldableSummary = forwardRef<
  HTMLDivElement,
  NodeViewComponentProps
>(function FoldableSummary({ children, nodeProps: _, ...props }, ref) {
  const disclosureStore = useContext(DisclosureContext)
  const isOpen = disclosureStore.useState("open")
  const className = `${foldableStyles["foldableSummary"]} ${
    isOpen ? foldableStyles["open"] : ""
  }`
  return (
    <div className={foldableStyles.foldableSummary} ref={ref} {...props}>
      <div contentEditable={false}>
        <Disclosure store={disclosureStore} className={className}></Disclosure>
      </div>
      <div className={foldableStyles["foldableSummaryInput"]}>{children}</div>
    </div>
  )
})

export const Foldable = forwardRef<HTMLDivElement, NodeViewComponentProps>(
  function Foldable({ children, nodeProps, ...props }, ref) {
    const { node } = nodeProps

    useEditorEffect((view) => {
      const dataVariant = view.dom.getAttribute("data-variant")
      disclosureStore.setOpen(dataVariant !== "viewer")
    }, [])

    const disclosureStore = useDisclosureStore({
      defaultOpen: node.attrs["open"],
    })

    return (
      <div
        ref={ref}
        {...props}
        className={foldableStyles.foldable}
        data-guid={node.attrs.guid}
        data-margin-number={node.attrs.marginNumber}
        data-type="foldable"
      >
        <DisclosureContext.Provider value={disclosureStore}>
          <div>{children}</div>
        </DisclosureContext.Provider>
      </div>
    )
  }
)
