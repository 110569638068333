import { MenuItem, ToolbarItem } from "@ariakit/react"
import {
  useEditorEventCallback,
  useEditorState,
} from "@nytimes/react-prosemirror"
import { setBlockType } from "prosemirror-commands"
import type { Attrs, NodeType } from "prosemirror-model"
import { type Selection, TextSelection } from "prosemirror-state"
import { NodeSelection } from "prosemirror-state"
import type { ComponentType, MouseEvent, ReactNode } from "react"

import { isFootnoteSelected } from "@/commands/footnotes"
import styles from "@/components/editor/toolbar/toolbar.module.css"

type Props = {
  nodeType: NodeType | undefined
  attrs?: Attrs
  children: ReactNode
  as?: ComponentType<{
    className: string
    children: ReactNode
    onClick: (event: MouseEvent) => void
    disabled: boolean
    active?: boolean | undefined
  }>
}

function selectionHasMarkup(
  selection: Selection,
  nodeType: NodeType,
  attrs?: Attrs
) {
  if (
    selection instanceof TextSelection &&
    !selection.$from.sameParent(selection.$to)
  ) {
    return false
  }

  const node =
    selection instanceof NodeSelection ? selection.node : selection.$from.parent
  const { nodeGuid: _, ...genericNodeAttrs } = node.attrs
  const { guid: __, ...genericAttrs } = attrs ?? {}
  return (
    node.type === nodeType &&
    genericNodeAttrs["level"] === genericAttrs["level"]
  )
}

export function SetNodeTypeItem({
  nodeType,
  attrs,
  children,
  as: Component = MenuItem,
}: Props) {
  const onClick = useEditorEventCallback((view) => {
    command(view.state, view.dispatch)
  })

  const editorState = useEditorState()

  if (!nodeType) return null

  const command = setBlockType(nodeType, attrs)
  const disabled =
    !editorState ||
    isFootnoteSelected(editorState.selection) ||
    !command(editorState) ||
    selectionHasMarkup(editorState.selection, nodeType, attrs)

  const active =
    editorState && selectionHasMarkup(editorState.selection, nodeType, attrs)

  let className =
    Component === ToolbarItem
      ? styles["editorToolbarItem"] ?? ""
      : styles["editorToolbarDropdownItem"] ?? ""

  if (active) className += ` ${styles["editorToolbarItemActive"] ?? ""}`

  return (
    <Component className={className} onClick={onClick} disabled={disabled}>
      {children}
    </Component>
  )
}
