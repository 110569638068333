import { ToolbarItem } from "@ariakit/react"
import { useEditorState } from "@nytimes/react-prosemirror"
import { useCallback, useContext } from "react"

import styles from "@/components/editor/toolbar/toolbar.module.css"
import { Toc } from "@/components/icons"
import { PanelContext } from "@/contexts/PanelContext"
import { useAppDispatch, useAppSelector } from "@/store/hooks"
import { isPanelTableOfContentsOpen } from "@/store/selectors/panelSelectors.ts"
import { panelsSlice } from "@/store/slices/panels.ts"

export function ToggleTableOfContentsItem() {
  const dispatch = useAppDispatch()
  const { panelId } = useContext(PanelContext) ?? {}
  const isOpen = useAppSelector(
    (state) => panelId && isPanelTableOfContentsOpen(state, panelId)
  )

  const onClick = useCallback(() => {
    panelId &&
      dispatch(
        panelsSlice.actions.tableOfContentsToggled({ panelId, force: null })
      )
  }, [panelId, dispatch])

  const editorState = useEditorState()
  const disabled = !editorState

  return (
    <ToolbarItem
      className={`${styles["editorToolbarItem"]} ${
        isOpen ? styles["editorToolbarActive"] : ""
      }`}
      onClick={onClick}
      disabled={disabled}
    >
      <Toc />
    </ToolbarItem>
  )
}
