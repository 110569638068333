import { Localized } from "@fluent/react"

import { LoadingPlaceholder } from "./Placeholder"

import styles from "@/panels/ResourcesViewer/ResourcesPanel.module.css"
import { useGetCourtDecisionQuery } from "@/store/slices/api"

type CourtDecisionProps = {
  url: string
}

export function CourtDecision({ url }: CourtDecisionProps) {
  const {
    data: courtDecision,
    isError,
    isSuccess,
  } = useGetCourtDecisionQuery(url)

  if (isError) throw new Error(`Error loading court decision: ${url}`)

  return isSuccess ? (
    <div className={styles["courtDecisionPanel"]}>
      <h2>
        {courtDecision.courtName} {courtDecision.docketNumber}
      </h2>
      {courtDecision.title && (
        <p className="courtDecision-name">{courtDecision.title}</p>
      )}
      {courtDecision.decisionType && courtDecision.date && (
        <p>
          <span>{courtDecision.decisionType}</span>
          <span>, </span>
          <span>
            {new Date(courtDecision.date as string).toLocaleDateString()}
          </span>
          <span>
            {courtDecision.ecli ? "," : null} {courtDecision.ecli}
          </span>
        </p>
      )}
      {courtDecision.guidingPrinciples && (
        <div>
          <Localized id={"courtDecision-guiding-principles"}>
            <h3>Guiding Principles</h3>
          </Localized>
          <div
            dangerouslySetInnerHTML={{
              __html: courtDecision.guidingPrinciples,
            }}
          ></div>
        </div>
      )}
      {courtDecision.operative && (
        <div>
          <Localized id={"courtDecision-operative"}>
            <h3>Operative</h3>
          </Localized>
          <div
            dangerouslySetInnerHTML={{
              __html: courtDecision.operative,
            }}
          ></div>
        </div>
      )}
      <Localized id={"courtDecision-decision"}>
        <h3>Decision</h3>
      </Localized>
      <div className="decision-body">
        {!courtDecision.facts && !courtDecision.reasoning && (
          <Localized id={"courtDecision-no-decision-available"}>
            <p style={{ opacity: 0.5, fontStyle: "italic" }}>
              No decision available.
            </p>
          </Localized>
        )}
        {courtDecision.facts && (
          <>
            <Localized id={"courtDecision-facts"}>
              <h4>Facts</h4>
            </Localized>
            <div
              dangerouslySetInnerHTML={{
                __html: courtDecision.facts,
              }}
            ></div>
          </>
        )}
        {courtDecision.reasoning && (
          <>
            <Localized id={"courtDecision-reasoning"}>
              <h4>Reasoning</h4>
            </Localized>
            <div
              dangerouslySetInnerHTML={{
                __html: courtDecision.reasoning,
              }}
            />
          </>
        )}
      </div>
    </div>
  ) : (
    <LoadingPlaceholder />
  )
}
