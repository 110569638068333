import { NodeType } from "prosemirror-model"
import { type Command, EditorState, TextSelection } from "prosemirror-state"
import type { EditorView } from "prosemirror-view"

/**
 * Returns a command to toggle a list type (bullet_list or ordered_list).
 * @param type - the list type to toggle to
 * @returns Command
 */
export function setListType(type: NodeType): Command {
  return function (state: EditorState, dispatch?: EditorView["dispatch"]) {
    const { selection } = state
    const range = state.selection.$from.blockRange(state.selection.$to)
    if (!range) return false

    const parent = range.$from.node(-1)
    if (parent?.type !== state.schema.nodes["list_item"]) return false

    const grandparent = range.$from.node(-2)
    if (grandparent.type === type) return false

    const from = range.$from.posAtIndex(0, -2)
    const to = from + grandparent.nodeSize - 1

    const newList = type.create(
      grandparent.attrs,
      grandparent.content,
      grandparent.marks
    )
    let tr = state.tr
    tr = tr.replaceRangeWith(from, to, newList)

    if (!dispatch) return true

    tr.setSelection(TextSelection.create(tr.doc, selection.from, selection.to))
    dispatch(tr)
    return true
  }
}

/**
 * Command to insert another paragraph in a list item.
 * This is bound to Shift-Enter inside a list_item.
 */
export function insertParagraphInListItem(
  state: EditorState,
  dispatch?: EditorView["dispatch"]
) {
  const { selection, tr } = state
  const paragraphNode = state.schema.nodes["paragraph"]
  if (!paragraphNode) return false

  tr.insert(selection.from, paragraphNode.create())
  tr.setSelection(TextSelection.create(tr.doc, selection.from + 1))
  if (dispatch) dispatch(tr)
  return true
}
