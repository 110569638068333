import { Schema } from "prosemirror-model"

import { mentionSchema } from "@/components/viewer/notes/Mention.schema"
import {
  formattingSchema,
  linkSchema,
  listSchema,
  paragraphSchema,
  textSchema,
} from "@/schemas/base-schema"

export const messageSchema = new Schema({
  nodes: {
    doc: { content: "block+" },
    ...paragraphSchema(),
    ...listSchema(),
    ...textSchema(),
    ...mentionSchema(),
  },
  marks: {
    ...linkSchema(),
    ...formattingSchema(),
  },
})
