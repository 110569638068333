import {
  DialogDescription,
  DialogDismiss,
  DialogHeading,
  Dialog as DialogRoot,
  useDialogStore,
} from "@ariakit/react"
import { Localized } from "@fluent/react"

import dialogStyles from "./dialog.module.css"

import { useAppSelector } from "@/store/hooks"
import { Dialog } from "@/store/slices/dialogs"

export function LostConnectionDialog() {
  const isOpen = useAppSelector(
    (state) => state.dialogs.openDialog === Dialog.ERROR_CONNECTION_LOST
  )

  const dialogStore = useDialogStore({
    open: isOpen,
  })

  return (
    <DialogRoot
      store={dialogStore}
      portal
      className={dialogStyles["dialog"]}
      hideOnInteractOutside={false}
      hideOnEscape={false}
    >
      <DialogHeading>
        <Localized id={"lost-connection-to-server"}>
          Lost connection to server.
        </Localized>
      </DialogHeading>
      <DialogDescription>
        <Localized id={"lost-connection-to-server-description"}>
          To make sure you don&apos;t lose any work, please refresh the page.
        </Localized>
      </DialogDescription>
      <DialogDismiss
        onClick={() => {
          window.location.reload()
        }}
        className={"blue-button"}
      >
        <Localized id={"lost-connection-to-server-action"}>
          Refresh Page
        </Localized>
      </DialogDismiss>
    </DialogRoot>
  )
}
