import { Button } from "@ariakit/react"
import { useLocalization } from "@fluent/react"
import { clsx } from "clsx"
import { createRef, useContext, useEffect, useState } from "react"

import styles from "./FlashcardStudyView.module.css"

import { AgainFace, EasyFace, GoodFace, HardFace } from "@/components/icons.tsx"
import { FlashcardContext } from "@/contexts/FlashcardDeckContext.ts"
import { useUpdateFlashcardKnowledgeMutation } from "@/store/slices/api.ts"
import { FlashcardReportLevel, type StudySession } from "@/types/api.ts"

export function FlashcardStudyViewReportButton({
  level,
  intervals,
}: {
  level: FlashcardReportLevel
  intervals: StudySession["nextFlashcardIntervals"]
}) {
  const { l10n } = useLocalization()

  const generateSublabel = (timeInMinutes: number) => {
    return timeInMinutes < 1440
      ? l10n.getString("flashcard-learning-in-n-minutes", {
          minutes: timeInMinutes,
        })
      : l10n.getString("flashcard-learning-in-n-days", {
          days: timeInMinutes / 1440,
        })
  }

  const levelMap = {
    [FlashcardReportLevel.AGAIN]: {
      color: "reportButtonRed",
      label: l10n.getString("flashcard-learning-report-again"),
      subLabel: generateSublabel(intervals.again),
      icon: <AgainFace />,
      key: "Digit1",
    },
    [FlashcardReportLevel.HARD]: {
      color: "reportButtonOrange",
      label: l10n.getString("flashcard-learning-report-hard"),
      subLabel: generateSublabel(intervals.hard),
      icon: <HardFace />,
      key: "Digit2",
    },
    [FlashcardReportLevel.GOOD]: {
      color: "reportButtonGreen",
      label: l10n.getString("flashcard-learning-report-good"),
      subLabel: generateSublabel(intervals.good),
      icon: <GoodFace />,
      key: "Digit3",
    },
    [FlashcardReportLevel.EASY]: {
      color: "reportButtonBlue",
      label: l10n.getString("flashcard-learning-report-easy"),
      subLabel: generateSublabel(intervals.easy),
      icon: <EasyFace />,
      key: "Digit4",
    },
  }

  const [animateButton, setAnimateButton] = useState(false)
  const color = levelMap[level].color as keyof typeof styles
  const { sessionId, flashcardId, toggleBackside } =
    useContext(FlashcardContext)
  const buttonRef = createRef<HTMLButtonElement>()

  const [submitAnswer] = useUpdateFlashcardKnowledgeMutation()

  const handleReport = () => {
    toggleBackside()
    submitAnswer({
      flashcardId,
      sessionId,
      ease: level,
    })
  }

  // Register event listener to allow input from keyboard
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.code === levelMap[level].key) {
        event.preventDefault()
        toggleBackside()
        animate()
        setTimeout(() => {
          handleReport()
        }, 300)
      }
    }
    window.addEventListener("keypress", handleKeyPress)
    return () => {
      window.removeEventListener("keypress", handleKeyPress)
    }
  })

  const animate = () => {
    const button = buttonRef.current
    if (button) {
      setAnimateButton(true)
      setTimeout(() => {
        setAnimateButton(false)
      }, 100)
    }
  }

  return (
    <div className={clsx(styles["reportButtonWrapper"], styles[color])}>
      <Button
        ref={buttonRef}
        className={clsx(styles["reportButton"], styles[color], {
          [styles["reportButtonActive"] as string]: animateButton,
        })}
        onClick={handleReport}
      >
        {levelMap[level].icon}
      </Button>
      <div>
        <p className={styles["reportButtonLabel"]}>{levelMap[level].label}</p>
        <p className={styles["reportButtonSubLabel"]}>
          {levelMap[level].subLabel}
        </p>
      </div>
    </div>
  )
}
