import { useDialogStore } from "@ariakit/react/dialog"
import { Localized, useLocalization } from "@fluent/react"
import * as RadixSwitch from "@radix-ui/react-switch"
import { skipToken } from "@reduxjs/toolkit/dist/query"
import clsx from "clsx"
import { useCallback, useContext, useMemo, useState } from "react"

import { generateGuid } from "@/components/editor/plugins/guid.ts"
import { Link, Pencil, Plus, Trash } from "@/components/icons"
import { ProseMirrorRenderer } from "@/components/prosemirrorRenderer/ProseMirrorRenderer"
import { FlashcardDeckContext } from "@/contexts/FlashcardDeckContext"
import { useLocalizedString } from "@/hooks/useLocalizedString"
import styles from "@/panels/FlashcardDeck/FlashcardDeck.module.css"
import {
  flashcardSchema,
  getBackContentNode,
  getFrontContentNode,
  isEmptyFlashcardSide,
} from "@/schemas/flashcard/schema"
import type { UUID } from "@/store/UUID"
import { useAppDispatch, useAppSelector } from "@/store/hooks"
import { Snackbar, enqueueSnackbar } from "@/store/reducers/snackbars"
import { getRevisionEditorState } from "@/store/selectors/documentsSelectors"
import {
  useCreateFlashcardMutation,
  useDeleteFlashcardMutation,
  useGetFlashcardLatestStepsQuery,
  useGetFlashcardRevisionQuery,
} from "@/store/slices/api"
import { PanelType } from "@/store/slices/panels.ts"
import { panelOpened } from "@/store/thunks/panelOpened.ts"
import type { Flashcard } from "@/types/api"
import { Alert } from "@/ui/Alert"
import { IconButton } from "@/ui/IconButton"
import { Tooltip } from "@/ui/Tooltip"
import { sendErrorToSentry } from "@/utils/sentry"

type FlashcardProps = {
  flashcard: Flashcard
  openFlashcardEditor: (flashcardId: UUID) => void
}

export function Flashcard({ flashcard, openFlashcardEditor }: FlashcardProps) {
  const [side, setSide] = useState<"front" | "back">("front")

  const dispatch = useAppDispatch()

  const alert = useDialogStore()
  const [deleteFlashcard] = useDeleteFlashcardMutation()

  const { data: revision } = useGetFlashcardRevisionQuery(
    !flashcard.draftRevisionId
      ? skipToken
      : {
          flashcardId: flashcard.id,
          revisionId: flashcard.draftRevisionId,
        }
  )

  useGetFlashcardLatestStepsQuery(
    revision
      ? {
          flashcardId: flashcard.id,
          revisionId: revision.id,
          version: revision.snapshot?.version ?? 0,
        }
      : skipToken
  )

  const editorState = useAppSelector(
    (state) => revision && getRevisionEditorState(state, revision.id)
  )

  const onDeleteFlashcard = useCallback(
    async (id: UUID) => {
      try {
        await deleteFlashcard({ flashcardId: id }).unwrap()
        enqueueSnackbar(Snackbar.FlashcardDeletedSuccess)
      } catch (error) {
        enqueueSnackbar(Snackbar.FlashcardDeletedError)
        sendErrorToSentry("Failed to delete flashcard", error)
      }
    },
    [deleteFlashcard]
  )

  const contentNode = useMemo(() => {
    if (!editorState?.doc) return undefined

    const frontContent = getFrontContentNode(editorState.doc)
    const backContent = getBackContentNode(editorState.doc)

    if (side === "front" && isEmptyFlashcardSide(editorState.doc, "front")) {
      return undefined
    }

    if (side === "back" && isEmptyFlashcardSide(editorState.doc, "back")) {
      return undefined
    }

    return side === "front" ? frontContent : backContent
  }, [editorState?.doc, side])

  const deleteLabel = useLocalizedString({ id: "delete" })
  const editLabel = useLocalizedString({ id: "edit" })
  const sourceLabel = useLocalizedString({
    id: "flashcard-show-source",
    fallback: "Show source document",
  })

  const handleSourceClicked = () => {
    if (!flashcard.highlight) return
    dispatch(
      panelOpened({
        panel: {
          panelId: generateGuid(),
          panelType: PanelType.VIEWER,
          documentId: flashcard.highlight?.documentId,
          scrollToHighlight: flashcard.highlight?.id,
        },
      })
    )
  }

  return (
    <div
      className={clsx(styles.flashcard, {
        [styles["flashcardBack"] as string]: side === "back",
      })}
    >
      <Alert
        store={alert}
        title={<Localized id="are-you-sure">Are you sure?</Localized>}
        description={
          <Localized id="flashcard-confirm-deletion">
            This action cannot be undone. Please confirm that you want to delete
            this flashcard.
          </Localized>
        }
        actions={[
          {
            label: <Localized id="cancel">Cancel</Localized>,
            variant: "outline",
            hideOnClick: true,
          },
          {
            label: <Localized id="delete">Delete</Localized>,
            variant: "danger",
            hideOnClick: true,
            beforeHide: () => onDeleteFlashcard(flashcard.id),
          },
        ]}
      />
      <div className={styles.flashcardActions}>
        {flashcard.highlight && (
          <Tooltip title={sourceLabel}>
            <IconButton
              icon={<Link />}
              onClick={handleSourceClicked}
              label={sourceLabel ?? "Show source document"}
              size={"small"}
            />
          </Tooltip>
        )}
        <Tooltip title={deleteLabel}>
          <IconButton
            icon={<Trash />}
            label={deleteLabel ?? "Delete"}
            size="small"
            onClick={() => alert.show()}
          />
        </Tooltip>
        <Tooltip title={editLabel}>
          <IconButton
            icon={<Pencil />}
            label={editLabel ?? "Edit"}
            size="small"
            onClick={() => openFlashcardEditor(flashcard.id)}
          />
        </Tooltip>
      </div>
      {contentNode ? (
        <ProseMirrorRenderer
          className={styles.flashcardContent}
          variant="flashcard"
          node={contentNode}
          schema={flashcardSchema}
        />
      ) : (
        <div className={styles.flashcardPlaceholder}>
          <Localized id="flashcard-empty-side">Empty</Localized>
        </div>
      )}
      <FlashcardKnowledgeIndicator flashcard={flashcard} />
      <FrontBackSwitch value={side} setValue={setSide} />
    </div>
  )
}

function FlashcardKnowledgeIndicator({ flashcard }: { flashcard: Flashcard }) {
  const { l10n } = useLocalization()
  const { knowledge } = flashcard

  if (!knowledge) return null

  let label: string
  let queueColor: string
  switch (knowledge.queue) {
    case 0 || undefined:
      label = l10n.getString("flashcard-queue-new")
      queueColor = "new"
      break
    case 1:
      label = l10n.getString("flashcard-queue-learning")
      queueColor = "learning"
      break
    case 2:
      label = l10n.getString("flashcard-queue-review")
      queueColor = "review"
      break
    default:
      label = ""
      queueColor = ""
  }

  return (
    <div
      className={clsx(
        styles["flashcardKnowledgeIndicator"],
        styles[queueColor as keyof typeof styles]
      )}
    >
      <span>{label}</span>
    </div>
  )
}

function FrontBackSwitch({
  value,
  setValue,
}: {
  value: "front" | "back"
  setValue: (value: "front" | "back") => void
}) {
  return (
    <div className={styles.frontBackSwitch}>
      <RadixSwitch.Root
        className={styles.frontBackSwitchRoot}
        checked={value === "back"}
        onCheckedChange={(checked) => setValue(checked ? "back" : "front")}
      >
        <RadixSwitch.SwitchThumb className={styles.frontBackSwitchThumb} />
        <div className={styles.frontBackSwitchLabel}>
          <span data-active={value === "front"}>
            <Localized id="front">Front</Localized>
          </span>
          <span data-active={value === "back"}>
            <Localized id="back">Back</Localized>
          </span>
        </div>
      </RadixSwitch.Root>
    </div>
  )
}

export function FlashcardSkeleton() {
  const [createFlashcard] = useCreateFlashcardMutation()
  const { deckId } = useContext(FlashcardDeckContext)

  const onClick = async () => {
    try {
      await createFlashcard({ deckId }).unwrap()
    } catch (error) {
      enqueueSnackbar(Snackbar.FlashcardCreatedError)
      sendErrorToSentry("Failed to create flashcard", error)
    }
  }

  return (
    <div className={clsx(styles.flashcard, styles.skeleton)}>
      <button onClick={onClick}>
        <Plus />
      </button>
      <Localized id="flashcard-create-first">
        <span>Create your first flashcard</span>
      </Localized>
    </div>
  )
}
