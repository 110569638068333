import {
  Button,
  Tooltip,
  TooltipAnchor,
  TooltipArrow,
  TooltipProvider,
} from "@ariakit/react"
import { Localized } from "@fluent/react"
import { clsx } from "clsx"
import type { ReactNode } from "react"

import hoverMenuStyles from "./hoverMenu.module.css"

export function HoverMenu(props: { children: ReactNode }) {
  return <div className={hoverMenuStyles["hoverMenu"]}>{props.children}</div>
}

type BaseHoverMenuItemProps = {
  description: string
  descriptionFluentId: string
  icon: ReactNode
  iconSide?: "left" | "right"
  disabled?: boolean
  handleAddClick?: () => void
}

type HoverMenuItemProps =
  | ({
      label: string
      labelFluentId: string
    } & BaseHoverMenuItemProps)
  | BaseHoverMenuItemProps

export function HoverMenuItem(props: HoverMenuItemProps) {
  return (
    <TooltipProvider>
      <TooltipAnchor
        render={
          <Button
            className={hoverMenuStyles["hoverMenuButton"]}
            disabled={props.disabled}
            onClick={props.handleAddClick}
          >
            <span className={hoverMenuStyles["hoverMenuButtonFront"]}>
              {props.iconSide === "left" ||
                (props.iconSide === undefined && props.icon)}
              {"label" in props && (
                <span className={hoverMenuStyles["hoverMenuButtonFrontLabel"]}>
                  <Localized id={props.labelFluentId}>{props.label}</Localized>
                </span>
              )}
              {props.iconSide === "right" && props.icon}
            </span>
          </Button>
        }
      />
      <Tooltip className={clsx(hoverMenuStyles["hoverMenuTooltip"])} gutter={2}>
        <TooltipArrow />
        <Localized id={props.descriptionFluentId}>
          {props.description}
        </Localized>
      </Tooltip>
    </TooltipProvider>
  )
}
