import {
  Button,
  Dialog,
  DialogDismiss,
  DialogHeading,
  type DialogStore,
  useFormStore,
} from "@ariakit/react"
import { Localized, useLocalization } from "@fluent/react"
import { clsx } from "clsx"
import { useContext } from "react"

import { NotebookPermissions } from "./NotebookPermissions.tsx"

import sharedStyles from "@/components/shared.module.css"
import { NotebookContext } from "@/contexts/NotebookContext.ts"
import {
  Form,
  FormField,
  FormInput,
  FormSubmit,
} from "@/micro_apps/components/forms/form.tsx"
import { useAppDispatch } from "@/store/hooks.ts"
import {
  useGetNotebookQuery,
  useUpdateNotebookMutation,
} from "@/store/slices/api.ts"
import { notebookDeleteClicked } from "@/store/thunks/notebookDeleteClicked.ts"

export function NotebookSettingsDialog({ store }: { store: DialogStore }) {
  const { notebookId } = useContext(NotebookContext)
  const { data, isLoading } = useGetNotebookQuery({
    notebookId: notebookId,
  })

  const dispatch = useAppDispatch()

  const { l10n } = useLocalization()

  const formStore = useFormStore({
    defaultValues: {
      title: data?.title,
      description: data?.description,
    },
  })

  const [updateNotebook] = useUpdateNotebookMutation()

  formStore.useSubmit(async (state) => {
    if (!state.values.title) return
    await updateNotebook({
      notebookId: notebookId,
      title: state.values.title,
      description: state.values.description || "",
    })
    store.hide()
  })

  const handleDeleteClick = () => {
    if (
      confirm(
        l10n.getString(
          "notebook-delete-confirm",
          null,
          "Are you sure you want to delete this notebook? This action cannot be undone."
        )
      )
    ) {
      dispatch(() => dispatch(notebookDeleteClicked({ notebookId })))
    }
  }

  if (isLoading || !data) return null

  return (
    <Dialog store={store} className={sharedStyles.dialog} portal>
      <DialogHeading className={sharedStyles["dialogHeading"]}>
        <Localized id={"notebook-settings"}>Notebook Settings</Localized>
      </DialogHeading>
      <Form store={formStore}>
        <FormField
          style={{ gridColumnStart: 1, gridColumnEnd: 5 }}
          name={formStore.names.title}
          label={l10n.getString("title", null, "Title")}
        >
          <FormInput
            name={formStore.names.title}
            disabled={!data.canUserEdit}
          />
        </FormField>
        <FormField
          style={{ gridColumnStart: 1, gridColumnEnd: 5 }}
          name={formStore.names.description}
          label={l10n.getString("description", null, "Description")}
        >
          <FormInput
            name={formStore.names.description}
            disabled={!data.canUserEdit}
          />
        </FormField>
      </Form>
      {data.canUserEdit && <NotebookPermissions notebookId={notebookId} />}
      <div className={sharedStyles["dialogButtons"]}>
        {data.canUserEdit && (
          <FormSubmit
            store={formStore}
            className={sharedStyles["defaultButton"]}
            onClick={() => formStore.submit()}
          >
            <Localized id={"save"}>Save</Localized>
          </FormSubmit>
        )}
        <DialogDismiss
          className={clsx(
            sharedStyles["defaultButton"],
            sharedStyles["secondaryButton"]
          )}
          store={store}
        >
          <Localized id={"cancel"}>Cancel</Localized>
        </DialogDismiss>
        {data.canUserEdit && (
          <Button
            className={clsx(
              sharedStyles["defaultButton"],
              sharedStyles["warning"]
            )}
            onClick={handleDeleteClick}
          >
            <Localized id={"notebook-delete"}>Delete Notebook</Localized>
          </Button>
        )}
      </div>
    </Dialog>
  )
}
