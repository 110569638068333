import { useEditorState } from "@nytimes/react-prosemirror"
import { lift } from "prosemirror-commands"

import { isFootnoteSelected } from "@/commands/footnotes"
import { isNodeTypeSelected } from "@/commands/selection"
import { CommandItem } from "@/components/editor/toolbar/items/CommandItem"
import { RemoveBoxed } from "@/components/icons"

export function BoxedSection() {
  const editorState = useEditorState()

  if (
    !editorState ||
    !isNodeTypeSelected(editorState, editorState.schema.nodes["boxed"]) ||
    isFootnoteSelected(editorState.selection)
  ) {
    return null
  }

  return (
    <>
      <CommandItem command={lift}>
        <RemoveBoxed />
      </CommandItem>
    </>
  )
}
