import {
  Combobox,
  ComboboxItem,
  ComboboxList,
  Dialog,
  DialogDescription,
  DialogDismiss,
  DialogHeading,
  useComboboxStore,
  useDialogStore,
} from "@ariakit/react"
import { Localized } from "@fluent/react"
import { FluentContext } from "@fluent/react/esm/context"
import { useEditorEventCallback } from "@nytimes/react-prosemirror"
import { useContext, useEffect, useMemo, useState } from "react"

import dialogStyles from "./dialog.module.css"
import comboboxStyles from "./insertlink.module.css"

import { insertEmbed } from "@/commands/embeds"
import { Cancel } from "@/components/icons"
import { useAppDispatch, useAppSelector } from "@/store/hooks"
import { isInsertVideoDialogOpen } from "@/store/selectors/dialogsSelectors"
import { useLazySearchVideosQuery } from "@/store/slices/api"
import { dialogClosed } from "@/store/store"
import type { VideoSearchResult } from "@/types/api"
import { VideoPlatform } from "@/types/api"
import { debounce } from "@/utils/debounce"
import { getVideoEmbedUrl } from "@/utils/url"

type Props = {
  onConfirm: () => void
}

export function InsertVideoDialog({ onConfirm }: Props) {
  const dispatch = useAppDispatch()
  const isOpen = useAppSelector(isInsertVideoDialogOpen)

  const [searchVideos, { data: videos, isError, error }] =
    useLazySearchVideosQuery()
  const [extraError, setExtraError] = useState<string[] | null>(null)

  const debouncedSearchVideos = useMemo(
    () => debounce(searchVideos, 500),
    [searchVideos]
  )

  const comboboxStore = useComboboxStore()
  const comboboxValue = comboboxStore.useState("value")

  const i18n = useContext(FluentContext)
  if (!i18n) throw new Error("FluentContext not found")

  useEffect(() => {
    debouncedSearchVideos(comboboxValue)
  }, [comboboxValue, debouncedSearchVideos])

  useEffect(() => {
    if (videos?.success == 1 && videos?.has_error == 1) {
      setExtraError(videos.errors)
    } else {
      setExtraError(null)
    }
  }, [videos, setExtraError])

  const handleConfirm = useEditorEventCallback(
    (
      view,
      platform: VideoPlatform,
      videoId: string,
      timeCode: string | null
    ) => {
      insertEmbed({ src: getVideoEmbedUrl(platform, videoId, timeCode) })(
        view.state,
        view.dispatch
      )
      dialogStore.hide()
      onConfirm()
    }
  )

  const dialogStore = useDialogStore({
    open: isOpen,
    setOpen(open) {
      if (!open) dispatch(dialogClosed())
    },
  })

  if (!isOpen) return null

  return (
    <Dialog className={dialogStyles["dialog"]} portal store={dialogStore}>
      <DialogHeading>
        <Localized id={"insert-video"}>Insert video</Localized>
      </DialogHeading>
      <DialogDescription>
        <Localized id={"insert-video-description"}>
          You can search Youtube or paste URLs of videos from Youtube or Vimeo.
        </Localized>
      </DialogDescription>
      <div className={comboboxStyles["comboboxWrapper"]}>
        <Combobox
          className={comboboxStyles["comboboxInput"]}
          store={comboboxStore}
          type={"search"}
          placeholder={i18n.getString(
            "search-videos",
            null,
            "Search or paste URL"
          )}
          aria-label="Search or paste URL"
        />
        {isError && !extraError && renderCriticalError()}
        {!isError && extraError && renderExtraError()}
        {!isError && !extraError && renderItems()}
      </div>
      <div>
        <DialogDismiss className={"blue-button"}>
          <Cancel />
          <Localized id={"cancel"}>Cancel</Localized>
        </DialogDismiss>
      </div>
    </Dialog>
  )

  function renderCriticalError() {
    const _errors = error as VideoSearchResult
    return _errors.errors.map((error, index) => (
      <Localized id={`error-loading-videos`} key={`critical-error-${index}`}>
        {error}
      </Localized>
    ))
  }

  function renderExtraError() {
    return extraError?.map((err, index) => (
      <div
        className={comboboxStyles["comboboxItemExtraError"]}
        key={`extra-error-${index}`}
      >
        {err}
      </div>
    ))
  }

  function renderItems() {
    return (
      <ComboboxList
        store={comboboxStore}
        className={comboboxStyles["comboboxList"]}
      >
        {videos?.items.map((result) => (
          <ComboboxItem
            key={result.videoId}
            className={comboboxStyles["comboboxItem"]}
            onClick={() =>
              handleConfirm(result.platform, result.videoId, result.timeCode)
            }
          >
            <img src={result.snippet.thumbnail} alt="" />
            <div>
              <div>{result.snippet.title}</div>
              <div>{result.snippet.channelTitle}</div>
            </div>
          </ComboboxItem>
        ))}
      </ComboboxList>
    )
  }
}
