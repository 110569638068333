import { useLocalization } from "@fluent/react"

import permissionStyles from "./Permissions.module.css"

import type { DocumentContainerPermissionRecord } from "@/types/api.ts"

export function ReadOnlyPermissionsList({
  users,
  usergroups,
  institutions,
}: {
  users: DocumentContainerPermissionRecord[]
  usergroups: DocumentContainerPermissionRecord[]
  institutions: DocumentContainerPermissionRecord[]
}) {
  const { l10n } = useLocalization()

  return (
    <ul className={permissionStyles["entitiesList"]}>
      {users.length > 0 && (
        <ReadOnlyPermissionsListEntry
          permissionRecords={users}
          categoryName={l10n.getString("users", null, "Users")}
        />
      )}
      {usergroups.length > 0 && (
        <ReadOnlyPermissionsListEntry
          permissionRecords={usergroups}
          categoryName={l10n.getString("groups", null, "Groups")}
        />
      )}
      {institutions.length > 0 && (
        <ReadOnlyPermissionsListEntry
          permissionRecords={institutions}
          categoryName={l10n.getString("institutions", null, "Institutions")}
        />
      )}
    </ul>
  )
}

export function ReadOnlyPermissionsListEntry({
  permissionRecords,
  categoryName,
}: {
  permissionRecords: DocumentContainerPermissionRecord[]
  categoryName: string
}) {
  return (
    <li className={permissionStyles["entitiesListCategory"]}>
      <span className={permissionStyles["entitiesListCategoryLabel"]}>
        {categoryName}
      </span>
      <ul className={permissionStyles["entitiesListCategoryList"]}>
        {permissionRecords.map((permissionRecord) => {
          // If permission record has firstname and lastname, it's a user
          const { entity } = permissionRecord
          const label =
            "firstName" in entity
              ? `${entity.firstName} ${entity.lastName}`
              : entity.name
          return (
            <li
              key={permissionRecord.entity.id}
              className={permissionStyles["entitiesListCategoryListEntry"]}
            >
              {label}
            </li>
          )
        })}
      </ul>
    </li>
  )
}
