import {
  type NodeViewComponentProps,
  useEditorEffect,
  useEditorEventCallback,
} from "@nytimes/react-prosemirror"
import { TextSelection } from "prosemirror-state"
import { forwardRef, useState } from "react"

import reviewStyles from "./reviewschema.module.css"

import { createReviewItem } from "@/commands/reviewSchemas"
import { useIsEditorEditable } from "@/components/editor/hooks/useIsEditorEditable"
import { Plus } from "@/components/icons"
import { useLocalizedString } from "@/hooks/useLocalizedString"
import { findAncestor } from "@/utils/prosemirror"

type ReviewSchemaActionProps = {
  isEditable: boolean
  onClick: () => void
}

function AddReviewSchemaDescriptionButton({
  isEditable,
  onClick,
}: ReviewSchemaActionProps) {
  const buttonText = useLocalizedString({
    id: "review-schema-no-description-button",
  })

  if (!isEditable) return null

  return (
    <div>
      <button className={reviewStyles.noDescButton} onClick={onClick}>
        <Plus width={14} strokeWidth={2} /> {buttonText}
      </button>
      <div className={reviewStyles.noDescButtonBorder} />
    </div>
  )
}

function AddReviewItemButton({ isEditable, onClick }: ReviewSchemaActionProps) {
  const buttonText = useLocalizedString({ id: "review-schema-no-items-button" })
  const fallbackText = useLocalizedString({
    id: "review-schema-no-items-fallback",
  })

  if (!isEditable)
    return <span className={reviewStyles.noItemsFallback}>{fallbackText}</span>

  return (
    <button className={reviewStyles.noItemsButton} onClick={onClick}>
      <Plus width={16} strokeWidth={2} /> {buttonText}
    </button>
  )
}

export const ReviewSchema = forwardRef<HTMLDivElement, NodeViewComponentProps>(
  function ReviewSchema({ children, nodeProps, ...props }, ref) {
    const { node, pos } = nodeProps
    const [isNestedSchema, setIsNestedSchema] = useState(false)

    const isEditable = useIsEditorEditable()

    const onInsertItem = useEditorEventCallback((view) => {
      const { tr, schema } = view.state
      const insertPos = pos + node.nodeSize - 1
      tr.insert(insertPos, createReviewItem(schema))
      tr.setSelection(new TextSelection(tr.doc.resolve(insertPos + 1)))
      view.dispatch(tr)
    })

    const onInsertDescription = useEditorEventCallback((view) => {
      const { tr, schema } = view.state

      if (!schema.nodes["review_schema_title"]) return
      if (!schema.nodes["review_schema_description"]) return
      if (!schema.nodes["paragraph"]) return

      const insertPos = pos + 1
      tr.insert(insertPos, [
        schema.nodes["review_schema_title"].create(),
        schema.nodes["review_schema_description"].create(
          null,
          schema.nodes["paragraph"].create()
        ),
      ])
      tr.setSelection(new TextSelection(tr.doc.resolve(insertPos + 1)))
      view.dispatch(tr)
    })

    useEditorEffect(
      (view) => {
        const parentSchema = findAncestor(
          view.state.doc.resolve(pos),
          (node) => {
            return node?.type.name === "review_schema"
          }
        )

        setIsNestedSchema(!!parentSchema)
      },
      [pos]
    )

    const hasReviewItem = node.content.lastChild?.type.name === "review_item"
    const hasOptionalReviewSchemaDescription =
      node.content.childCount >= 2 &&
      node.content.child(0)?.type.name === "review_schema_title" &&
      node.content.child(1)?.type.name === "review_schema_description"

    return (
      <div
        className={reviewStyles.reviewSchemaWrapper}
        {...props}
        ref={ref}
        data-guid={node.attrs.guid}
        data-margin-number={node.attrs.marginNumber}
        data-type="review-schema"
      >
        <div
          className={reviewStyles.reviewSchema}
          data-list-style={node.attrs.listStyle}
        >
          {!hasOptionalReviewSchemaDescription && !isNestedSchema && (
            <AddReviewSchemaDescriptionButton
              isEditable={isEditable}
              onClick={onInsertDescription}
            />
          )}
          <ol className={reviewStyles.reviewSchemaList}>{children}</ol>
          {!hasReviewItem && (
            <AddReviewItemButton
              isEditable={isEditable}
              onClick={onInsertItem}
            />
          )}
        </div>
      </div>
    )
  }
)
