import {
  Menu,
  MenuButton,
  MenuButtonArrow,
  MenuItem,
  ToolbarItem,
  useMenuStore,
} from "@ariakit/react"
import { Localized } from "@fluent/react"
import {
  useEditorEventCallback,
  useEditorState,
} from "@nytimes/react-prosemirror"
import { clsx } from "clsx"
import { useCallback, useContext } from "react"

import styles from "@/components/editor/toolbar/toolbar.module.css"
import { Link, MagicLink, MagicLinkAll } from "@/components/icons.tsx"
import { RevisionContext } from "@/contexts/RevisionContext"
import { referenceParsingPluginKey } from "@/plugins/referenceParsing/referenceParsingPlugin"
import { useAppDispatch } from "@/store/hooks"
import { Dialog } from "@/store/slices/dialogs.ts"
import { revisionsSlice } from "@/store/slices/revisions"
import { dialogOpened } from "@/store/store.ts"
import { autoLinkAllClicked } from "@/store/thunks/autoLinkAllClicked.ts"

export function AutoLinkItem() {
  const { revisionId, documentId, documentType } = useContext(RevisionContext)
  const editorState = useEditorState()
  const dispatch = useAppDispatch()

  const onClickToggleLink = useCallback(() => {
    dispatch(dialogOpened(Dialog.INSERT_LINK))
  }, [dispatch])

  const onClickToggleMagicLinking = useEditorEventCallback((view) => {
    dispatch(revisionsSlice.actions.toggleReferenceParsing({ revisionId }))
    view.focus()
  })

  const onClickMagicLinkAll = useEditorEventCallback((view) => {
    dispatch(autoLinkAllClicked({ documentType, documentId, revisionId }))
    view.focus()
  })

  const menuStore = useMenuStore()

  const active =
    editorState && !!referenceParsingPluginKey.getState(editorState)

  return (
    <>
      <MenuButton
        className={clsx(
          styles["editorToolbarItem"],
          styles["editorToolbarMenuButton"],
          {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            [styles["editorToolbarActive"]!]: active,
          }
        )}
        store={menuStore}
        render={<ToolbarItem />}
      >
        {active ? (
          <MagicLink style={{ padding: 0, height: "1.5rem" }} />
        ) : (
          <Link style={{ padding: 0, height: "1.5rem" }} />
        )}
        <MenuButtonArrow />
      </MenuButton>
      <Menu store={menuStore} className={styles["editorToolbarDropdownMenu"]}>
        <MenuItem
          className={styles["editorToolbarDropdownItem"]}
          onClick={onClickToggleLink}
        >
          <Link />
          <Localized id={"insert-link"}>Insert Link</Localized>
        </MenuItem>
        <MenuItem
          className={clsx(styles["editorToolbarDropdownItem"], {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            [styles["editorToolbarActive"]!]: active,
          })}
          onClick={onClickToggleMagicLinking}
        >
          <MagicLink />
          {active ? (
            <Localized id={"auto-link-disable"}>
              Disable Magic Linking
            </Localized>
          ) : (
            <Localized id={"auto-link-enable"}>Enable Magic Linking</Localized>
          )}
        </MenuItem>
        <MenuItem
          className={styles["editorToolbarDropdownItem"]}
          onClick={onClickMagicLinkAll}
        >
          <MagicLinkAll />
          <Localized id={"auto-link-all"}>Magic Link everything</Localized>
        </MenuItem>
      </Menu>
    </>
  )
}
