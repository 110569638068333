import { Localized, useLocalization } from "@fluent/react"
import type { MutationActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate"
import type { BaseQueryFn, MutationDefinition } from "@reduxjs/toolkit/query"

import { ToggleWithLabel } from "@/micro_apps/components/forms/ToggleWithLabel.tsx"
import type { UUID } from "@/store/UUID.ts"
import {
  type PermissionEntity,
  PermissionEntityType,
  PermissionType,
} from "@/types/api.ts"

export type GlobalPermissionsProps = {
  canPublishGlobally: boolean
  isOpenAccess: boolean
  isPlatformWide: boolean
  openAccessGroupId: number
  platformWideGroupId: number
  resourceId: UUID
  addEntity: (args: {
    resourceId: UUID
    permission: PermissionType
    entityType: PermissionEntityType
    entity: number
  }) => MutationActionCreatorResult<
    MutationDefinition<PermissionEntity, BaseQueryFn, string, void>
  >
  removeEntity: (args: {
    resourceId: UUID
    entity: number
    entityType: PermissionEntityType
  }) => void
}

export function GlobalPermissions({
  canPublishGlobally,
  isOpenAccess,
  isPlatformWide,
  openAccessGroupId,
  platformWideGroupId,
  resourceId,
  addEntity,
  removeEntity,
}: GlobalPermissionsProps) {
  const { l10n } = useLocalization()
  const handleGlobalGroupChange = (checked: boolean, groupId: number) => {
    if (checked) {
      addEntity({
        resourceId: resourceId,
        permission: PermissionType.VIEW,
        entityType: PermissionEntityType.GROUP,
        entity: groupId,
      })
    } else {
      removeEntity({
        resourceId: resourceId,
        entityType: PermissionEntityType.GROUP,
        entity: groupId,
      })
    }
  }

  return (
    <>
      {canPublishGlobally ? (
        <>
          <ToggleWithLabel
            label={l10n.getString(
              "permissions-publish-open-access",
              null,
              "Publish Open Access"
            )}
            checked={isOpenAccess}
            onChange={(checked) =>
              handleGlobalGroupChange(checked, openAccessGroupId)
            }
          />
          <ToggleWithLabel
            label={l10n.getString(
              "permissions-publish-platform-wide",
              null,
              "Publish Platform-wide"
            )}
            checked={isPlatformWide}
            onChange={(checked) =>
              handleGlobalGroupChange(checked, platformWideGroupId)
            }
          />
        </>
      ) : (
        <div>
          <Localized id={"permissions-verification-required"}>
            <p>
              Verified Authors can publish platform-wide and Open Access. To
              learn more, <a href="#">click here</a>.
            </p>
          </Localized>
        </div>
      )}
    </>
  )
}
