import type { Schema } from "prosemirror-model"
import { Step } from "prosemirror-transform"

import type { UUID } from "@/store/UUID"
import { clientID } from "@/store/clientID"
import type { StepsResponse } from "@/types/api"
import { padLogger } from "@/utils/debug"

export type LongPollConnectionConfig = {
  onConnectionError?: () => void
  onReceiveSteps: (results: { steps: Step[]; clientIDs: UUID[] }) => void
}

/**
 * LongPollClient to enable real-time updates for steps.
 * For usage inside components, see `useLongPollClient.ts`.
 */

export class ReconnectingLongPolling {
  private abortController: AbortController
  private reconnectInterval = 150
  private reconnectAttempts: number
  private reconnectTimeout: NodeJS.Timeout | undefined
  private maxReconnectAttempts = 5
  private baseUrlPath: string

  public lastServerVersion: number | undefined

  get isReconnecting() {
    return this.reconnectTimeout !== undefined
  }

  constructor(
    documentType: "chapter" | "flashcard" | "note",
    private schema: Schema,
    private documentId: UUID
  ) {
    this.abortController = new AbortController()
    this.reconnectAttempts = 0
    this.baseUrlPath =
      documentType === "chapter"
        ? `/api/pad/chapters/${this.documentId}`
        : documentType === "flashcard"
        ? `/api/flashcard/${this.documentId}`
        : `/api/note/${this.documentId}`
  }

  private getDebugState() {
    return {
      documentId: this.documentId,
      lastServerVersion: this.lastServerVersion,
    }
  }

  private async poll(version: number) {
    let responseCode: null | number = null
    while (responseCode !== 200) {
      const response = await fetch(
        `${this.baseUrlPath}/new-version?min-version=${version}`,
        {
          signal: this.abortController.signal,
        }
      )

      responseCode = response.status

      if (!(responseCode >= 200 && responseCode < 300)) {
        padLogger("received unexpected response from polling endpoint", {
          status: responseCode,
          message: await response.text(),
        })
      }
    }
    return await this.fetchSteps(version)
  }

  private async fetchSteps(version: number) {
    const response = await fetch(
      `${this.baseUrlPath}/steps?version=${version}`,
      { signal: this.abortController.signal }
    )

    const results = (await response.json()) as StepsResponse
    const latestVersion = results[results.length - 1]?.version
    this.lastServerVersion = latestVersion ?? version

    const remoteResults = results
      // We can skip our own steps; we receive those as soon as they're confirmed
      .filter((result) => result.client_id !== clientID)
    const steps = remoteResults.map(({ value }) =>
      Step.fromJSON(this.schema, value)
    )
    const clientIDs = remoteResults.map(({ client_id }) => client_id)
    return { latestVersion, steps, clientIDs }
  }

  async connect(version: number, config: LongPollConnectionConfig) {
    padLogger("long-polling: connecting...", this.getDebugState())

    let latestVersion = version

    try {
      const results = await this.fetchSteps(version)
      latestVersion = results.latestVersion ?? latestVersion

      config.onReceiveSteps(results)

      padLogger(
        "long-polling: received initial steps, start polling...",
        this.getDebugState()
      )
      while (!this.abortController.signal.aborted) {
        const results = await this.poll(latestVersion)
        padLogger("long-polling: received result", results)
        latestVersion = results.latestVersion ?? latestVersion
        config.onReceiveSteps(results)

        this.reconnectAttempts = 0
      }
    } catch (e) {
      if (e instanceof Error && e.name === "AbortError") {
        if (this.reconnectTimeout) clearTimeout(this.reconnectTimeout)
        return
      }

      if (this.reconnectAttempts >= this.maxReconnectAttempts) {
        this.close()
        config.onConnectionError?.()
        return
      }

      padLogger(
        `long-polling: error, trying to reconnect in ${this.reconnectInterval}ms`,
        { ...this.getDebugState(), error: e }
      )

      this.reconnectTimeout = setTimeout(() => {
        this.reconnectAttempts++
        this.connect(latestVersion, config)
      }, this.reconnectInterval * 2 ** this.reconnectAttempts)
    }
  }

  close() {
    this.abortController.abort()
    padLogger("long-polling: closed connection", this.getDebugState())
  }
}
