export async function isOnline() {
  if (!window.navigator.onLine) return false

  // Since navigator.onLine is not reliable across browsers,
  // we perform a simple request against our own server to check
  // if we are actually online. By using HEAD we don't have to
  // download the whole response body. The param avoids caching.
  try {
    const url = new URL(window.location.origin)

    const id = Date.now().toString()
    url.searchParams.set("isOnlineCheck", id)

    const res = await fetch(url.toString(), { method: "HEAD" })

    return res.ok
  } catch {
    return false
  }
}
