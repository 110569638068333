import {
  Combobox,
  ComboboxGroup,
  ComboboxGroupLabel,
  ComboboxItem,
  ComboboxList,
  Dialog,
  DialogDismiss,
  DialogHeading,
  useComboboxStore,
  useDialogStore,
} from "@ariakit/react"
import { Localized } from "@fluent/react"
import { FluentContext } from "@fluent/react/esm/context"
import { useEditorEventCallback } from "@nytimes/react-prosemirror"
import { toggleMark } from "prosemirror-commands"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"

import dialogStyles from "./dialog.module.css"
import comboboxStyles from "./insertlink.module.css"

import { Cancel } from "@/components/icons"
import { AtomViewContext } from "@/contexts/AtomViewContext"
import { RevisionContext } from "@/contexts/RevisionContext"
import { useLocalizedString } from "@/hooks/useLocalizedString"
import type { UUID } from "@/store/UUID.ts"
import { useAppDispatch, useAppSelector } from "@/store/hooks"
import { isAddLinkDialogOpen } from "@/store/selectors/dialogsSelectors"
import { getAtomState } from "@/store/selectors/documentsSelectors"
import { useLazySearchResourcesQuery } from "@/store/slices/api"
import { dialogClosed } from "@/store/store"
import type { ResourceSearchResults } from "@/types/api.ts"
import { debounce } from "@/utils/debounce"
import { makeSupportMailLink } from "@/utils/email"
import { isValidURL } from "@/utils/url"

type LinkDialogValue = {
  url: string
  fullName?: string
  uuid?: UUID
}

type Props = {
  onConfirm: () => void
}

export function InsertLinkDialog({ onConfirm }: Props) {
  const dispatch = useAppDispatch()
  const isOpen = useAppSelector(isAddLinkDialogOpen)
  const { revisionId, documentId: chapterId } = useContext(RevisionContext)
  const [blockId, setBlockId] = useState<UUID | null>(null)
  const { atomView } = useContext(AtomViewContext)
  const atomState = useAppSelector((state) => getAtomState(state, revisionId))

  const [results, setResults] = useState<ResourceSearchResults>()
  const [searchResources] = useLazySearchResourcesQuery()

  const dialogStore = useDialogStore({
    open: isOpen,
    setOpen(open) {
      if (!open) {
        setResults({ courtDecisions: [], sections: [], references: [] })
        dispatch(dialogClosed())
      }
    },
  })
  const dialogOpen = dialogStore.useState("open")
  const comboboxStore = useComboboxStore()
  const comboboxValue = comboboxStore.useState("value")

  const i18n = useContext(FluentContext)
  if (!i18n) throw new Error("FluentContext not found")

  const onOpen = useEditorEventCallback((view) => {
    if (atomState) {
      const { from, to } = atomState.selection
      const selectedText = atomState.doc.textBetween(from, to)
      comboboxStore.setState("value", selectedText)
    } else {
      const { from, to } = view.state.selection
      const selectedText = view.state.doc.textBetween(from, to)
      comboboxStore.setState("value", selectedText)
    }
    const block = view.state.selection.$from.node(1)
    if (block) setBlockId(block.attrs.guid)
  })

  useEffect(() => {
    if (dialogOpen) onOpen()
  }, [dialogOpen, onOpen])

  const setRemoteResources = useCallback(
    async (query: string) => {
      const { data: resources } = await searchResources(query)

      if (!resources) {
        comboboxStore.hide()
        return
      }

      setResults(resources)
      comboboxStore.show()
    },
    [comboboxStore, searchResources]
  )

  const debouncedSetRemoteResources = useMemo(
    () => debounce(setRemoteResources, 500),
    [setRemoteResources]
  )

  useEffect(() => {
    if (!comboboxValue) return
    debouncedSetRemoteResources(comboboxValue)
  }, [comboboxStore, comboboxValue, debouncedSetRemoteResources])

  const handleConfirm = useEditorEventCallback<[LinkDialogValue], void>(
    (view, link) => {
      if (!view.state.schema.marks["link"]) return

      toggleMark(view.state.schema.marks["link"], {
        href: link.url,
        title: link.fullName,
      })(atomState ?? view.state, atomView?.dispatch ?? view.dispatch)

      dialogStore.hide()

      onConfirm()
    }
  )

  const location = `${window.location.origin}/${chapterId}/?r=${revisionId}#${blockId}`
  const subject = useLocalizedString({ id: "missing-reference" })
  const body = useLocalizedString({ id: "missing-reference-body" })
  const mailTo = makeSupportMailLink({
    subject: subject,
    body: `${body} "${comboboxValue}"%0D%0A%0D%0A(${location})`,
  })

  // `autoFocusOnHide={false}` is necessary to prevent the scrolled
  // text from jumping when Ariakit is trying to restore the focus
  return (
    <Dialog
      className={dialogStyles["dialog"]}
      portal
      store={dialogStore}
      autoFocusOnHide={false}
    >
      <DialogHeading>
        <Localized id={"insert-resource-or-link"}>
          Insert resource or link to URL
        </Localized>
      </DialogHeading>
      <div className={comboboxStyles["comboboxWrapper"]}>
        <Combobox
          className={comboboxStyles["comboboxInput"]}
          store={comboboxStore}
          type={"search"}
          placeholder={i18n.getString(
            "paste-link-or-search",
            null,
            "Paste link or search resource database"
          )}
          aria-label={`Paste link or search resource database`}
        />
        {isValidURL(comboboxValue) ? (
          <div
            className={comboboxStyles["comboboxItem"]}
            onClick={() => {
              handleConfirm({
                url: comboboxValue,
                fullName: comboboxValue,
              })
            }}
          >
            {comboboxValue}
          </div>
        ) : (
          <InsertLinkDialogSearchResults
            results={results}
            comboboxStore={comboboxStore}
            handleConfirm={handleConfirm}
          />
        )}
      </div>
      <p>
        <Localized id={"reference-not-found"}>
          Could not find the citation you were looking for?
        </Localized>
        {` `}
        <Localized id={"reference-click-to-request"}>
          <a href={mailTo}>Click here to request it.</a>
        </Localized>
      </p>
      <DialogDismiss className={"blue-button"}>
        <Cancel />
        <Localized id={"cancel"}>Cancel</Localized>
      </DialogDismiss>
    </Dialog>
  )
}

export function InsertLinkDialogSearchResults({
  results,
  comboboxStore,
  handleConfirm,
}: {
  results?: ResourceSearchResults
  comboboxStore: ReturnType<typeof useComboboxStore>
  handleConfirm: (result: LinkDialogValue) => void
}) {
  if (!results) return null

  return (
    <ComboboxList
      store={comboboxStore}
      className={comboboxStyles["comboboxList"]}
    >
      {results["courtDecisions"].length > 0 && (
        <ComboboxGroup>
          <ComboboxGroupLabel className={comboboxStyles["comboboxCategory"]}>
            <Localized id={"court-decisions"}>Court decisions</Localized>
          </ComboboxGroupLabel>
          {results["courtDecisions"].map((result) => (
            <ComboboxItem
              key={result.uuid}
              className={comboboxStyles["comboboxItem"]}
              onClick={() => {
                handleConfirm(result)
              }}
            >
              {result.courtName}, {result.docketNumber}, {result.decisionType}{" "}
              {result.decisionType ? "vom " : ""}
              {new Date(result.date).toLocaleDateString()}{" "}
              {result.title ? "–" : ""} {result.title}
            </ComboboxItem>
          ))}
        </ComboboxGroup>
      )}
      {results["references"].length > 0 && (
        <ComboboxGroup>
          <ComboboxGroupLabel className={comboboxStyles["comboboxCategory"]}>
            <Localized id={"references"}>References</Localized>
          </ComboboxGroupLabel>
          {results["references"].map((result) => (
            <ComboboxItem
              key={result.uuid}
              className={comboboxStyles["comboboxItem"]}
              onClick={() => {
                handleConfirm(result)
              }}
            >
              {result.courtName} {result.journalAbbreviation} {result.volume}
              {", "}
              {result.page}
              {result.title ? " – " : ""} {result.title}
            </ComboboxItem>
          ))}
        </ComboboxGroup>
      )}
      {results["sections"].length > 0 && (
        <ComboboxGroup>
          <ComboboxGroupLabel className={comboboxStyles["comboboxCategory"]}>
            <Localized id={"statute-sections"}>Sections</Localized>
          </ComboboxGroupLabel>
          {results["sections"].map((result) => (
            <ComboboxItem
              key={result.uuid}
              className={comboboxStyles["comboboxItem"]}
              onClick={() => {
                handleConfirm(result)
              }}
            >
              {`${result.referer} ${result.statuteAbbreviation}${
                result.title ? " – " : ""
              }${result.title}`}
            </ComboboxItem>
          ))}
        </ComboboxGroup>
      )}
    </ComboboxList>
  )
}
