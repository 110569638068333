import ReconnectingWebSocket, {
  type CloseEvent,
  type ErrorEvent,
  type Options,
} from "reconnecting-websocket"

import type { UUID } from "@/store/UUID"
import type {
  HighlightResponse,
  MessageResponse,
  ResponseMeta,
} from "@/types/api"
import { debug as createDebug } from "@/utils/debug"
import { isOnline } from "@/utils/online"
import { sendErrorToSentry } from "@/utils/sentry"

export enum SocketEvents {
  NewHighlight = "annotation.highlight_created",
  HighlightShared = "annotation.highlight_shared",
  HighlightDeleted = "annotation.highlight_deleted",
  ThreadStarted = "annotation.highlight_thread_started",
  ThreadDeleted = "annotation.highlight_thread_deleted",
  NewMessage = "annotation.highlight_message_added",
  MessageUpdated = "annotation.highlight_message_updated",
  MessageDeleted = "annotation.highlight_message_deleted",
}

type EventType = (typeof SocketEvents)[keyof typeof SocketEvents]

export type SocketPayload =
  | {
      type: SocketEvents.NewHighlight | SocketEvents.HighlightShared
      meta: ResponseMeta
      data: HighlightResponse
    }
  | {
      type: SocketEvents.HighlightDeleted
      meta: ResponseMeta
    }
  | {
      type: SocketEvents.ThreadStarted
      meta: ResponseMeta
      data: { id: UUID; messages: MessageResponse[] }
    }
  | {
      type: SocketEvents.ThreadDeleted
      meta: ResponseMeta
    }
  | {
      type: SocketEvents.NewMessage | SocketEvents.MessageUpdated
      meta: ResponseMeta
      data: MessageResponse
    }
  | {
      type: SocketEvents.MessageDeleted
      meta: ResponseMeta
      data: { id: UUID }
    }

export type SubscriptionCallback = (data: SocketPayload) => void

export const SocketTopics = {
  Highlights: [
    SocketEvents.NewHighlight,
    SocketEvents.HighlightDeleted,
    SocketEvents.HighlightShared,
  ],
  Threads: [
    SocketEvents.ThreadStarted,
    SocketEvents.ThreadDeleted,
    SocketEvents.NewMessage,
    SocketEvents.MessageUpdated,
    SocketEvents.MessageDeleted,
  ],
} as const

export type TopicType = (typeof SocketTopics)[keyof typeof SocketTopics]

const protocol = location.protocol === "https:" ? "wss:" : "ws:"

const debug = createDebug("websocketClient")

export type WebsocketClientConnection = {
  url: string
  subscriptions: Map<EventType, SubscriptionCallback>
  /**
   * The maximum number of reconnect attempts before it calls `onConnectionError`.
   * The client itself will not stop trying to reconnect.
   */
  readonly maxReconnectAttempts: number
  reconnectAttempts: () => number
  reconnect: () => void
  subscribe: (topic: TopicType, callback: SubscriptionCallback) => void
  unsubscribe: (topic: TopicType) => void
  closeConnection: () => void
}

type WebsocketClientOptions = {
  /**
   * If set, the connection will be closed if the client stays idle for a certain amount of time.
   * It is automatically reopened when the client becomes active again. If you use this option,
   * make sure to refetch websocket-dependent data via RTK when the client reconnects.
   */
  pauseConnectionOnIdleClient?: boolean
  /**
   * Time in milliseconds after which the client is considered idle. Default is 5 minutes.
   */
  pauseConnectionDelay?: number
  onConnectionError?: () => void
  onReconnectSuccess?: () => void
}

export const websocketClient = {
  connections: {} as Record<UUID, WebsocketClientConnection>,
  connect(
    chapterID: UUID,
    revisionId: UUID,
    options?: WebsocketClientOptions
  ): WebsocketClientConnection {
    const existingConnection = this.connections[revisionId]
    if (existingConnection) {
      debug("Already connected. Reusing: " + existingConnection.url)
      return existingConnection
    }

    const url = `${protocol}//${location.host}/ws/pad/chapters/${chapterID}?revision-id=${revisionId}`
    const socketProtocols = undefined
    const socketOptions: Options = {
      // Default is 2000ms. To avoid timeouts on slow connections, we increase it.
      connectionTimeout: 4000,
    }

    const websocket = new ReconnectingWebSocket(
      url,
      socketProtocols,
      socketOptions
    )

    const subscriptions = new Map<EventType, SubscriptionCallback>()

    this.connections[revisionId] = {
      url,
      subscriptions,
      maxReconnectAttempts: 3,
      reconnectAttempts: () => websocket.retryCount,
      reconnect: () => websocket.reconnect(),
      subscribe: (topic, callback) => {
        topic.forEach((event) => {
          subscriptions.set(event, callback)
        })
      },
      unsubscribe: (topic) => {
        topic.forEach((event) => {
          subscriptions.delete(event)
        })
      },
      closeConnection: () => {
        websocket.close()
      },
    }

    if (options?.pauseConnectionOnIdleClient) {
      setupPageVisibilityListener(websocket, options.pauseConnectionDelay)
    }

    websocket.addEventListener("open", (event) => {
      debug(`Connection established: ${event.target.url}`)

      const connection = this.connections[revisionId]
      if (!connection) return

      if (connection.reconnectAttempts() >= connection.maxReconnectAttempts) {
        options?.onReconnectSuccess?.()
      }
    })

    websocket.addEventListener("message", (message) => {
      try {
        const incoming = JSON.parse(message.data) as SocketPayload
        debug("Received message", incoming)
        subscriptions.get(incoming.type as EventType)?.(incoming)
      } catch (error) {
        sendErrorToSentry("Could not process incoming websocket message", error)
      }
    })

    websocket.addEventListener("error", (error: ErrorEvent) => {
      debug("error", error)

      const connection = this.connections[revisionId]
      if (!connection) return

      if (connection.reconnectAttempts() === connection.maxReconnectAttempts) {
        options?.onConnectionError?.()

        // If errors repeatedly occur and the client is online, we assume that
        // there is an issue with the websocket server and send the error to Sentry.
        // If the client has connectivity issues, we don't want to spam Sentry.
        isOnline().then((online) => {
          if (online) sendErrorToSentry(error.message, error.error)
        })
      }
    })

    websocket.addEventListener("close", (close: CloseEvent) => {
      debug("closed connection", { url: close.target.url, code: close.code })
    })

    return this.connections[revisionId] as WebsocketClientConnection
  },
}

/**
 * This function sets up a listener that closes the connection for idle clients.
 * An idle client is defined as a client who has a visibility state of "hidden"
 * for a certain amount of time. This is useful to avoid timeouts and unnecessary
 * websocket connections.
 *
 * Note: A disconnected client automatically reconnects when the visibility state
 * changes to "visible" again.
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event
 */

function setupPageVisibilityListener(
  ws: ReconnectingWebSocket,
  delayInMs?: number
) {
  const properties = getPageVisibilityProperties()
  if (!properties) return

  const { hidden, visibilityChange } = properties

  const DEFAULT_DELAY = 5 * 60 * 1000 // 5 minutes
  let timeoutId: number | undefined

  document.addEventListener(visibilityChange, () => {
    // Start the timeout for disconnecting the client
    if (document[hidden]) {
      timeoutId = window.setTimeout(() => {
        ws.close()
        timeoutId = undefined
      }, delayInMs ?? DEFAULT_DELAY)
    }

    // If the page becomes visible, and the timeout still runs
    if (!document[hidden] && timeoutId) {
      window.clearTimeout(timeoutId)
    }

    // If the page becomes visible, and the timeout has already run
    if (!document[hidden] && !timeoutId) {
      ws.reconnect()
    }
  })
}

function getPageVisibilityProperties():
  | { hidden: keyof Document; visibilityChange: string }
  | undefined {
  if (typeof document.hidden !== "undefined") {
    return { hidden: "hidden" as const, visibilityChange: "visibilitychange" }
  }

  // @ts-expect-error vendor-prefixed property
  if (typeof document.msHidden !== "undefined") {
    return {
      hidden: "msHidden" as "hidden",
      visibilityChange: "msvisibilitychange",
    }
  }

  // @ts-expect-error vendor-prefixed property
  if (typeof document.webkitHidden !== "undefined") {
    return {
      hidden: "webkitHidden" as "hidden",
      visibilityChange: "webkitvisibilitychange",
    }
  }

  return undefined
}
