import type { EditorState } from "prosemirror-state"
import { insertPoint } from "prosemirror-transform"
import type { EditorView } from "prosemirror-view"

export type EmbedAttrValues = {
  src: string
}

export function insertEmbed(attrValues?: EmbedAttrValues) {
  return function (state: EditorState, dispatch?: EditorView["dispatch"]) {
    if (!state.schema.nodes["embed"]) return false

    const validInsertPoint = insertPoint(
      state.doc,
      state.selection.from,
      state.schema.nodes["embed"]
    )

    if (validInsertPoint === null) return false

    if (dispatch) {
      dispatch(
        state.tr.insert(
          validInsertPoint,
          state.schema.nodes["embed"].create(attrValues)
        )
      )
    }
    return true
  }
}
