/* eslint-disable @typescript-eslint/no-extra-semi */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { type Attrs, type Node, NodeType } from "prosemirror-model"
import type { EditorState } from "prosemirror-state"
import { Step, StepResult } from "prosemirror-transform"

type NodeTypeWithAttrs = NodeType & { defaultAttrs: Attrs }
type MutableAttrs = { [s: string]: any }

export class DocAttrStep extends Step {
  stepType: string
  key: string
  value: any
  prevValue: any

  constructor(key: string, value: any, stepType = "DocAttrStep") {
    super()
    this.stepType = stepType
    this.key = key
    this.value = value
  }
  apply(doc: Node) {
    this.prevValue = doc.attrs[this.key]
    if (doc.attrs == (doc.type as NodeTypeWithAttrs).defaultAttrs) {
      ;(doc.attrs as MutableAttrs) = Object.assign({}, doc.attrs)
    }
    ;(doc.attrs as MutableAttrs)[this.key] = this.value
    return StepResult.ok(doc)
  }

  invert() {
    return new DocAttrStep(this.key, this.prevValue, "revertDocAttrStep")
  }
  map() {
    return null
  }
  toJSON() {
    return {
      stepType: this.stepType,
      key: this.key,
      value: this.value,
    }
  }
  static override fromJSON(
    _schema: EditorState["schema"],
    json: { [s: string]: any }
  ) {
    return new DocAttrStep(json.key, json.value, json.stepType)
  }
}
