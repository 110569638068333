import {msg} from "@lit/localize";
import {LitElement, css, html} from 'lit';
import {customElement, property, state} from 'lit/decorators.js';
import {classMap} from 'lit/directives/class-map.js';

import { sendErrorToSentry } from "@/utils/sentry"

type UnsplashResponseError = {
    errors: string[];
};

type UnsplashImageSearchResult = {
    total: number;
    total_pages: number;
    results: UnsplashImage[];
};

type UnsplashImage = {
    id: string;
    alt_description: string;
    urls: {
        raw: string;
        thumb: string;
    };
    user: {
        name: string;
        links: {
            html: string;
        };
    };
};

@customElement('unsplash-image-input')
class UnsplashImagePicker extends LitElement {

    static override styles = css`
      :host {
        display: block;
        border: 1px solid var(--colorGray300);
        border-radius: 5px;
        padding: 0.5rem;
        background-color: white;
        position: relative;
      }

      .input-container {
        display: flex;
        gap: 0.5rem;
        margin-top: 0.5rem;
      }

      .input-container input {
        flex: 1;
        border: 1px solid var(--colorGray300);
        border-radius: 5px;
        padding: 0.375rem 0.5rem;
        font-family: dskrptSans, sans-serif;
        font-size: 1rem;
      }

      .input-container button {
        background-color: var(--colorBrandColor);
        font-family: dskrptSans, sans-serif;
        font-weight: 500;
        font-size: var(--fontSizeXS);
        color: white;
        border: none;
        border-radius: 5px;
        padding: 0.375rem 1rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

      .input-container button svg {
        width: 1rem;
        height: 1rem;
      }

      .images-container {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        overflow-x: scroll;
        width: 100%;
      }

      .image-container {
        display: flex;
        flex-direction: column;
        width: 7rem;
        border: 1px solid transparent;
        border-radius: 5px;
        padding: 0.125rem;
      }

      .image-container.current {
        background-color: var(--colorGray200);
        color: black;
      }

      .image-container.selected {
        background-color: var(--colorBrandColor);
        color: white;
      }

      .image-label {
        font-size: 0.675rem;
        padding: 0 0.25rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
      }

      .image-label a {
        color: inherit;
        text-decoration: none;
      }

      img {
        box-sizing: border-box;
        object-fit: cover;
        cursor: pointer;
        aspect-ratio: 1/1;
        border-bottom: none;
        border-radius: 4px;
      }
    `;

    @property({type: String, attribute: 'current-image-id'}) currentImageId = '';
    @property() selectedImageData: UnsplashImage | undefined = undefined;

    @state() currentImageData: UnsplashImage | undefined = undefined;
    @state() searchQuery = '';
    @state() images: UnsplashImage[] = [];
    @state() errors: string[] = [];

    override connectedCallback() {
        super.connectedCallback();
        try {
            // Retrieve information for the selected image from Unsplash
            if (this.currentImageId) {
                fetch(`/api/unsplash/?picture_id=${this.currentImageId}`)
                    .then((response) => {
                        if(!response.ok) {
                            return Promise.reject(response.json())
                        }
                        return response.json()
                    })
                    .then((image : UnsplashImage) => {
                        this.currentImageData = image;
                        this.selectedImageData = image;
                        this.images = [image];
                        this.errors = [];
                    })
                    .catch((e) => {
                        if (e as UnsplashResponseError) {
                            console.error(`Could not retrieve information for the selected image from Unsplash: ${e.errors.join(', ')}`)
                            this.errors = e.errors
                        }
                        sendErrorToSentry(`Could not retrieve information for the selected image from Unsplash`, e)
                    });
            }
        } catch (e) {
            sendErrorToSentry(`Could not retrieve information for the selected image from Unsplash`, e)
        }
    }

    removeImage() {
        this.selectedImageData = undefined;
        this.currentImageData = undefined;
        this.currentImageId = '';
        this.images = [];
        this.dispatchEvent(
            new CustomEvent('image-selected', {
                detail: {imageId: null},
                bubbles: true,
                composed: true
            })
        );
    }

    /** Search images on Unsplash with search query */
    async searchImages() {
        if (!this.searchQuery) return;
        try {
            const response = await fetch(
                `/api/unsplash/?query=${encodeURIComponent(this.searchQuery)}&per_page=6`
            );
            if(response.ok) {
                const data : UnsplashImageSearchResult = await response.json();
                if (data.results.length === 0) {
                    this.errors = [`No images found for search query ${this.searchQuery}`];
                    return;
                }
                this.errors = [];
                if (this.currentImageData) {
                    this.images = [this.currentImageData,...data.results.splice(0, 6)];
                } else {
                    this.images = data.results.splice(0, 6)
                }
            } else {
                const error : UnsplashResponseError = await response.json();
                this.errors = error.errors;
            }
        } catch (e) {
            sendErrorToSentry(`Could not search images on Unsplash with search query`, e)
        }
    }

    selectImage(image: UnsplashImage) {
        this.selectedImageData = image;
        this.errors = [];
        this.dispatchEvent(
            new CustomEvent('image-selected', {
                detail: {imageId: image.id},
                bubbles: true,
                composed: true
            })
        );
    }


    renderImage(image : UnsplashImage) {
        if (image === undefined) {
            return;
        }

        const isCurrentImage = this.currentImageId === image.id;
        const isSelectedImage = this.selectedImageData && this.selectedImageData.id === image.id;
        let label;
        let classes;
        const attribution = html`
            <div class="image-label">
                <a href="${image.user.links.html + '?utm_source=dskrpt.de&utm_medium=referral'}" target="_blank">
                    ${image.user.name}
                </a>
            </div>`
        if (isSelectedImage) {
            classes = {'image-container': true, 'selected': true}
            label = html`
                <div class="image-label selected"><span>${msg('Selected')}</span></div>`
        } else if (isCurrentImage) {
            classes = {'image-container': true, 'current': true}
            label = html`
                <div class="image-label current"><span>${msg('Current')}</span></div>`
        } else {
            classes = {'image-container': true}
        }
        return html`
            <div class="${classMap(classes)}">
                ${label}
                <img alt="${image.alt_description}"
                     src="${image.urls.thumb}"
                     @click=${() => this.selectImage(image)}
                />
                ${attribution}
            </div>
        `
    }

    renderError() {
        if (this.errors.length === 0) {
            return;
        }
        return html`
            <div class="error">
                ${this.errors.map((error) => html`<div>${error}</div>`)}
            </div>
        `;
    }

    override render() {
        return html`
            <div class="images-container">
                ${this.images.map((image) => this.renderImage(image))}
                ${this.images.length === 0
                        ? html`
                            <div>${msg('No image selected. You can search by keyword below.')}</div>`
                        : undefined}
            </div>
            <div class="input-container">
                <input type="search"
                       placeholder="${msg('Search for images')}"
                    @input=${(e: Event) => (this.searchQuery = (e.currentTarget as HTMLInputElement).value)}
                    @keydown=${(e : KeyboardEvent) => e.key === 'Enter' ? this.searchImages() : undefined}
                />
                <button @click=${this.searchImages}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                        <path fill-rule="evenodd"
                              d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                              clip-rule="evenodd"/>
                    </svg>
                    ${msg('Search')}
                </button>
                <button @click=${this.removeImage}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                        <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"/>
                    </svg>
                    ${msg('Remove image')}
                </button>
            </div>
            ${this.renderError()}
        `;
    }
}

export default UnsplashImagePicker;
