import { useEditorEffect } from "@nytimes/react-prosemirror"
import { useRef, useState } from "react"

import editorStyles from "@/components/editor/editor.module.css"
import type { UUID } from "@/store/UUID"

export function useHighlightAttachment<E extends HTMLElement>(
  highlightId: UUID
) {
  const [top, setTop] = useState(0)
  const ref = useRef<E | null>(null)

  useEditorEffect((view) => {
    function getPreferredTop() {
      const highlight = document.getElementById(highlightId)
      if (!highlight) return
      const textContainer = view.dom
      const marginContainer = ref.current?.closest(
        `.${editorStyles["marginContainer"]}`
      )
      const offset =
        textContainer && marginContainer
          ? textContainer.getBoundingClientRect().top -
            marginContainer.getBoundingClientRect().top
          : 0

      let summedParentOffset = 0
      // sum the offset of all parents of the highlight until the element with the class .ProseMirror
      // this is necessary because the highlight might be nested in a blockquote or other element
      // that is not a direct child of the .ProseMirror element
      let parent = highlight.offsetParent as HTMLElement
      while (parent && !parent.classList.contains("ProseMirror")) {
        summedParentOffset += parent.offsetTop
        // This type assertion is necessary because offsetParent is typed as Element | null, but
        // offsetTop is only defined on HTMLElements.
        // `offsetParent` returns an Element | null because it also works for SVG elements.
        // In our case though, we know that the offsetParent is always an HTMLElement as there are
        // no SVG elements in the editor's body.
        parent = parent.offsetParent as HTMLElement
      }

      setTop(highlight.offsetTop + summedParentOffset + offset ?? 0)
    }
    const resizeObserver = new ResizeObserver(getPreferredTop)
    resizeObserver.observe(
      document.getElementById("aside-container") as HTMLDivElement
    )
    getPreferredTop()
    return () => resizeObserver.disconnect()
  }, [])

  return { top, ref }
}
