import { MenuItem } from "@ariakit/react"
import { Localized } from "@fluent/react"
import { useEditorState } from "@nytimes/react-prosemirror"
import { useCallback } from "react"

import { insertImage } from "@/commands/images"
import styles from "@/components/editor/toolbar/toolbar.module.css"
import { Image } from "@/components/icons.tsx"
import { useAppDispatch } from "@/store/hooks"
import { Dialog } from "@/store/slices/dialogs"
import { dialogOpened } from "@/store/store"

export function InsertImageItem() {
  const dispatch = useAppDispatch()

  const onClick = useCallback(() => {
    dispatch(dialogOpened(Dialog.INSERT_IMAGE))
  }, [dispatch])

  const editorState = useEditorState()

  const disabled = !editorState || !insertImage()(editorState)

  return (
    <>
      <MenuItem
        onClick={onClick}
        className={styles["editorToolbarDropdownItem"]}
        disabled={disabled}
      >
        <Image />
        <Localized id={"image"}>Image</Localized>
      </MenuItem>
    </>
  )
}
