import { useContext } from "react"

import { HoverMenu, HoverMenuItem } from "@/components/hoverMenu/HoverMenu.tsx"
import { Cog, Plus, Study } from "@/components/icons"
import { FlashcardDeckContext } from "@/contexts/FlashcardDeckContext"
import type { UUID } from "@/store/UUID.ts"
import { useAppDispatch } from "@/store/hooks.ts"
import { Snackbar, enqueueSnackbar } from "@/store/reducers/snackbars"
import {
  useCreateFlashcardMutation,
  useGetFlashcardDeckQuery,
} from "@/store/slices/api"
import { studyFlashcardsClicked } from "@/store/thunks/studyFlashcardsClicked.ts"
import { sendErrorToSentry } from "@/utils/sentry"

export function FlashcardDeckMenu(props: {
  openFlashcardEditor: (flashcardId: UUID) => void
  openSettings: (show: boolean) => void
}) {
  const { deckId } = useContext(FlashcardDeckContext)
  const [createFlashcard] = useCreateFlashcardMutation()
  const { data, isLoading } = useGetFlashcardDeckQuery({ deckId })
  const dispatch = useAppDispatch()

  const handleAddClick = async () => {
    try {
      const { id } = await createFlashcard({ deckId }).unwrap()
      props.openFlashcardEditor(id)
    } catch (error) {
      enqueueSnackbar(Snackbar.FlashcardCreatedError)
      sendErrorToSentry("Failed to create flashcard", error)
    }
  }

  const handleSettingsClick = () => {
    props.openSettings(true)
  }

  if (isLoading || !data) return null

  return (
    <HoverMenu>
      {data.canUserEdit && (
        <HoverMenuItem
          label={"Add Card"}
          labelFluentId={"flashcard-deck-add-card"}
          description={"Add another flashcard to this deck."}
          descriptionFluentId={"flashcard-deck-add-card-description"}
          handleAddClick={handleAddClick}
          icon={<Plus />}
        />
      )}
      <HoverMenuItem
        label={"Settings"}
        labelFluentId={"settings"}
        description={"Update name, description and permissions for this deck."}
        descriptionFluentId={"flashcard-deck-settings-description"}
        handleAddClick={handleSettingsClick}
        icon={<Cog />}
      />
      <HoverMenuItem
        label={"Study"}
        labelFluentId={"flashcard-deck-study"}
        description={"Study 30 cards from this deck."}
        descriptionFluentId={"flashcard-deck-study-description"}
        handleAddClick={() => dispatch(studyFlashcardsClicked({ deckId }))}
        icon={<Study />}
      />
    </HoverMenu>
  )
}
