import { NodeType } from "prosemirror-model"
import { EditorState, NodeSelection } from "prosemirror-state"
import { EditorView } from "prosemirror-view"

export function deleteBlockNode(blockType: NodeType) {
  return function (state: EditorState, dispatch?: EditorView["dispatch"]) {
    const { $from } = state.selection as NodeSelection
    const { tr } = state

    // If the selection is a NodeSelection, and the node type matches the
    // block type, delete the node.
    if (!(state.selection instanceof NodeSelection)) {
      const blockLevelNode = $from.node(1)
      const { pos } = $from.doc.resolve($from.before(1))

      if (blockLevelNode && blockLevelNode.type == blockType) {
        if (dispatch) {
          tr.setSelection(NodeSelection.create(state.doc, pos))
        }
      }
    }

    if (dispatch) {
      dispatch(tr.deleteSelection())
      return true
    }
    return true
  }
}
