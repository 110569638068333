import { clsx } from "clsx"

import styles from "./FlashcardStudyView.module.css"

import { FlashcardReportLevel, type StudySession } from "@/types/api.ts"

export function FlashcardStudyViewProgressIndicator({
  history,
}: {
  history: StudySession["history"]
}) {
  const colors = {
    [FlashcardReportLevel.AGAIN]: "var(--colorMidRed)",
    [FlashcardReportLevel.HARD]: "orange",
    [FlashcardReportLevel.GOOD]: "var(--colorMidGreen)",
    [FlashcardReportLevel.EASY]: "var(--colorBrandColor)",
  }
  return (
    <div className={styles["progressIndicatorWrapper"]}>
      {history.map(({ reportedEase, hasFollowUp }, index) => (
        <div
          key={index}
          style={{ backgroundColor: colors[reportedEase] }}
          className={clsx(styles["progressIndicatorItem"])}
        >
          {hasFollowUp && <span>•</span>}
        </div>
      ))}
    </div>
  )
}
