import { useEditorState } from "@nytimes/react-prosemirror"

import { insertFootnote } from "@/commands/footnotes"
import { AutoLinkItem } from "@/components/editor/toolbar/items/AutoLinkItem"
import { CommandItem } from "@/components/editor/toolbar/items/CommandItem"
import { ToggleMarkItem } from "@/components/editor/toolbar/items/ToggleMarkItem"
import { Bold, Footnote, Italic } from "@/components/icons"

type FormattingSectionProps = {
  disabled?: ["strong" | "em" | "footnote" | "link"]
}

export function FormattingSection(props: FormattingSectionProps) {
  const editorState = useEditorState()

  if (!editorState) return null

  return (
    <>
      {!props.disabled?.includes("strong") && (
        <ToggleMarkItem markType={editorState.schema.marks["strong"]}>
          {/* Make icon size visually more equal to adjacent icons */}
          <Bold style={{ height: "1rem" }} />
        </ToggleMarkItem>
      )}
      {!props.disabled?.includes("em") && (
        <ToggleMarkItem markType={editorState.schema.marks["em"]}>
          {/* Make icon size visually more equal to adjacent icons */}
          <Italic style={{ height: "1rem" }} />
        </ToggleMarkItem>
      )}
      {!props.disabled?.includes("footnote") && (
        <CommandItem command={insertFootnote}>
          <Footnote />
        </CommandItem>
      )}
      {!props.disabled?.includes("link") && <AutoLinkItem />}
    </>
  )
}
