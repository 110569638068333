import { ToolbarItem } from "@ariakit/react"
import { useEditorState } from "@nytimes/react-prosemirror"

import { isFootnoteSelected } from "@/commands/footnotes"
import { isNodeTypeSelected } from "@/commands/selection.ts"
import { SetNodeTypeItem } from "@/components/editor/toolbar/items/SetNodeTypeItem"
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  Heading7,
  Heading8,
  Heading9,
  RemoveHeading,
} from "@/components/icons"

export function HeadingSection() {
  const editorState = useEditorState()

  if (
    !editorState ||
    !isNodeTypeSelected(editorState, editorState.schema.nodes["heading"]) ||
    isFootnoteSelected(editorState.selection)
  ) {
    return null
  }

  return (
    <>
      <SetNodeTypeItem
        nodeType={editorState.schema.nodes["paragraph"]}
        as={ToolbarItem}
      >
        <RemoveHeading />
      </SetNodeTypeItem>{" "}
      <SetNodeTypeItem
        nodeType={editorState.schema.nodes["heading"]}
        attrs={{ level: 1 }}
        as={ToolbarItem}
      >
        <Heading1 />
      </SetNodeTypeItem>
      <SetNodeTypeItem
        nodeType={editorState.schema.nodes["heading"]}
        attrs={{ level: 2 }}
        as={ToolbarItem}
      >
        <Heading2 />
      </SetNodeTypeItem>
      <SetNodeTypeItem
        nodeType={editorState.schema.nodes["heading"]}
        attrs={{ level: 3 }}
        as={ToolbarItem}
      >
        <Heading3 />
      </SetNodeTypeItem>
      <SetNodeTypeItem
        nodeType={editorState.schema.nodes["heading"]}
        attrs={{ level: 4 }}
        as={ToolbarItem}
      >
        <Heading4 />
      </SetNodeTypeItem>
      <SetNodeTypeItem
        nodeType={editorState.schema.nodes["heading"]}
        attrs={{ level: 5 }}
        as={ToolbarItem}
      >
        <Heading5 />
      </SetNodeTypeItem>
      <SetNodeTypeItem
        nodeType={editorState.schema.nodes["heading"]}
        attrs={{ level: 6 }}
        as={ToolbarItem}
      >
        <Heading6 />
      </SetNodeTypeItem>
      <SetNodeTypeItem
        nodeType={editorState.schema.nodes["heading"]}
        attrs={{ level: 7 }}
        as={ToolbarItem}
      >
        <Heading7 />
      </SetNodeTypeItem>
      <SetNodeTypeItem
        nodeType={editorState.schema.nodes["heading"]}
        attrs={{ level: 8 }}
        as={ToolbarItem}
      >
        <Heading8 />
      </SetNodeTypeItem>
      <SetNodeTypeItem
        nodeType={editorState.schema.nodes["heading"]}
        attrs={{ level: 9 }}
        as={ToolbarItem}
      >
        <Heading9 />
      </SetNodeTypeItem>
    </>
  )
}
