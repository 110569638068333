import * as Ariakit from "@ariakit/react"
import { Localized } from "@fluent/react"
import {
  useEditorEventCallback,
  useEditorEventListener,
} from "@nytimes/react-prosemirror"
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react"

import styles from "./MentionSelect.module.css"

import { CallOut } from "@/components/icons.tsx"
import { RevisionContext } from "@/contexts/RevisionContext.ts"
import { useFocusView } from "@/hooks/useFocusView"
import { DEFAULT_SUGGESTION_CLASS, useSuggestion } from "@/hooks/useSuggestion"
import { useLazySearchUsersQuery } from "@/store/slices/api"
import type { UserSearchResult } from "@/types/api"
import { Avatar } from "@/ui/Avatar"
import { debounce } from "@/utils/debounce"
import { sendErrorToSentry } from "@/utils/sentry"

export type MentionKind = "user" | "group"

export function MentionSelect() {
  const [match] = useSuggestion({ char: "@", minLength: 3 })
  const [users, setUsers] = useState<UserSearchResult[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const { documentId: chapterId } = useContext(RevisionContext)
  const [searchUsersQuery] = useLazySearchUsersQuery()
  const focusView = useFocusView()

  const onSelect = useEditorEventCallback((view, userId: string) => {
    const user = users.find((user) => user.id === userId)
    const Mention = view.state.schema.nodes["mention"]
    if (!Mention || !match || !user) return

    const { from, to } = match.range
    const label = `${user.firstName} ${user.lastName}`
    const mention = Mention.create({
      entityType: "user",
      entityId: user.id,
      label,
    })
    view.dispatch(view.state.tr.replaceRangeWith(from, to, mention))
    focusView()

    setUsers([])
  })

  const combobox = Ariakit.useComboboxStore({
    value: match?.query,
    setValue: onSelect,
    setOpen: (open) => {
      if (!open) setUsers([])
    },
  })

  useEditorEventListener("keydown", (_, event) => {
    if (!match) return
    if (event.key === "ArrowDown") {
      event.preventDefault()
      combobox.setActiveId(combobox.down())
    }
    if (event.key === "ArrowUp") {
      event.preventDefault()
      combobox.setActiveId(combobox.up())
    }
    if (event.key === "Enter") {
      event.preventDefault()
      const selectedItem = combobox.item(combobox.getState().activeId)
      if (selectedItem?.value) onSelect?.(selectedItem.value)
    }
  })

  const searchUsers = useCallback(
    async (query: string) => {
      try {
        const { data: users } = await searchUsersQuery({ query, chapterId })
        setUsers(users ?? [])
      } catch (err) {
        setUsers([])
        sendErrorToSentry(`Could not fetch users for query: ${query}`, err)
      } finally {
        setIsLoading(false)
      }
    },
    [chapterId, searchUsersQuery]
  )

  const debouncedSearchUsers = useMemo(
    () => debounce(searchUsers, 250),
    [searchUsers]
  )

  useEffect(() => {
    if (match?.query) {
      setIsLoading(true)
      debouncedSearchUsers(match.query)
    }
  }, [match?.query, debouncedSearchUsers])

  useLayoutEffect(() => {
    combobox.setOpen(!!match)
    combobox.setActiveId(combobox.first())
  }, [users, match, combobox])

  if (!match || isLoading) return null

  return (
    <div className={styles.mentionSelect}>
      <Ariakit.ComboboxPopover
        store={combobox}
        className={styles.comboboxPopover}
        getAnchorRect={() => {
          const span = document.querySelector(`.${DEFAULT_SUGGESTION_CLASS}`)
          return span ? span.getBoundingClientRect() : null
        }}
      >
        <Ariakit.ComboboxGroup className={styles.comboboxGroup}>
          <Ariakit.ComboboxGroupLabel className={styles.comboboxGroupLabel}>
            <Localized id={"mention-select-users"}>Users</Localized>
          </Ariakit.ComboboxGroupLabel>
          {users.length ? (
            users.map(
              ({
                id,
                firstName,
                lastName,
                username,
                initials,
                profilePictureUrl,
              }) => (
                <Ariakit.ComboboxItem
                  key={id}
                  value={id}
                  className={styles.comboboxItem}
                >
                  <Avatar
                    size={24}
                    src={profilePictureUrl}
                    fallback={initials}
                  />
                  <span className={styles.comboboxItemTitle}>
                    {firstName} {lastName}
                  </span>
                  <span className={styles.comboboxItemUsername}>
                    @{username}
                  </span>
                </Ariakit.ComboboxItem>
              )
            )
          ) : (
            <Localized id={"mention-select-no-users-found"}>
              <span className={styles.comboboxGroupFallback}>
                No users found.
              </span>
            </Localized>
          )}
        </Ariakit.ComboboxGroup>
        <MentioningHintViewer />
      </Ariakit.ComboboxPopover>
    </div>
  )
}

function MentioningHintViewer() {
  return (
    <div className={styles["hintWrapper"]}>
      <CallOut />
      <Localized id={"mention-select-hint"}>
        <div className={styles.hint}>
          You can only mention users that have access to this script.
        </div>
      </Localized>
    </div>
  )
}
