import { useLocalization } from "@fluent/react"

import {
  PermissionEntitiesCategory,
  type PermissionEntitiesCategoryProps,
} from "./PermissionEntitiesCategory.tsx"
import styles from "./Permissions.module.css"

import type { UUID } from "@/store/UUID.ts"
import type { DocumentContainerPermissions } from "@/types/api.ts"

export function PermissionsEntitiesList({
  data,
  updatePermission,
  removeEntity,
  resourceId,
}: {
  resourceId: UUID
  data: DocumentContainerPermissions
  updatePermission: PermissionEntitiesCategoryProps["updatePermission"]
  removeEntity: PermissionEntitiesCategoryProps["removeEntity"]
}) {
  const { l10n } = useLocalization()

  return (
    <>
      <ul className={styles["entitiesList"]}>
        <PermissionEntitiesCategory
          updatePermission={updatePermission}
          removeEntity={removeEntity}
          resourceId={resourceId}
          permissionRecords={data.institutions}
          categoryName={l10n.getString("institutions", null, "Institutions")}
        />
        <PermissionEntitiesCategory
          updatePermission={updatePermission}
          removeEntity={removeEntity}
          resourceId={resourceId}
          permissionRecords={data.usergroups}
          categoryName={l10n.getString("groups", null, "Groups")}
        />
        <PermissionEntitiesCategory
          updatePermission={updatePermission}
          removeEntity={removeEntity}
          resourceId={resourceId}
          permissionRecords={data.users}
          categoryName={l10n.getString("users", null, "Users")}
        />
      </ul>
    </>
  )
}
