import { ToolbarItem } from "@ariakit/react"
import { useEditorState } from "@nytimes/react-prosemirror"

import { isNodeTypeSelected } from "@/commands/selection"
import { WrapInItem } from "@/components/editor/toolbar/items/WrapInItem"
import { BulletList, OrderedList } from "@/components/icons"

export function DefinitionContentSection() {
  const editorState = useEditorState()
  if (!editorState) return null

  if (
    !isNodeTypeSelected(editorState, editorState.schema.nodes["definition"])
  ) {
    return null
  }

  const { $from } = editorState.selection
  let listItem = false

  for (let d = $from.depth; d > 0; d--) {
    const node = $from.node(d)
    if (node.type.name === "list_item") {
      listItem = true
      break
    }
  }

  if (listItem) {
    return null
  }

  return (
    <>
      <WrapInItem
        nodeType={editorState.schema.nodes["bullet_list"]}
        as={ToolbarItem}
      >
        <BulletList />
      </WrapInItem>

      <WrapInItem
        nodeType={editorState.schema.nodes["ordered_list"]}
        as={ToolbarItem}
      >
        <OrderedList />
      </WrapInItem>
    </>
  )
}
