import {
  Separator,
  Toolbar,
  ToolbarContainer,
  ToolbarSeparator,
  useToolbarStore,
} from "@ariakit/react"
import { useCallback, useLayoutEffect, useState } from "react"

import { ChapterSettings } from "./items/ChapterSettings"
import { ChapterSyncStatus } from "./items/ChapterSyncStatus"
import { ChapterTitle } from "./items/ChapterTitle"
import { ToggleTableOfContentsItem } from "./items/ToggleTableOfContentsItem"
import { InsertBlockMenu } from "./menus/InsertBlockMenu"
import { ContextualSection } from "./sections/ContextualSection"
import { FormattingSection } from "./sections/FormattingSection"
import { HistorySection } from "./sections/HistorySection"
import toolbarStyles from "./toolbar.module.css"

import { FRONTMATTER_ID } from "@/components/frontmatter/Frontmatter"

export function EditorToolbar() {
  const [showTitle, setShowTitle] = useState(false)

  const toolbarStore = useToolbarStore({
    orientation: "horizontal",
  })

  const handleMatterVisibility = useCallback<IntersectionObserverCallback>(
    ([matter]) => {
      if (matter?.isIntersecting) {
        setShowTitle(false)
      } else {
        setShowTitle(true)
      }
    },
    []
  )

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(handleMatterVisibility)
    const targetElement = document.getElementById(FRONTMATTER_ID)
    if (targetElement) observer.observe(targetElement)
  }, [handleMatterVisibility])

  return (
    <Toolbar className={toolbarStyles.editorToolbar} store={toolbarStore}>
      <ToolbarContainer data-toolbar-slot="controls">
        <ChapterTitle show={showTitle} />
        {showTitle && <Separator />}
        <ToggleTableOfContentsItem />
        <ChapterSettings />
        <HistorySection />
        <ChapterSyncStatus />
      </ToolbarContainer>
      <ToolbarContainer data-toolbar-slot="formatting">
        <InsertBlockMenu />
        <ToolbarSeparator />
        <FormattingSection />
        <ToolbarSeparator />
        <ContextualSection />
      </ToolbarContainer>
    </Toolbar>
  )
}
