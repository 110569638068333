import { useEditorEffect } from "@nytimes/react-prosemirror"
import type { EditorView } from "prosemirror-view"
import { useEffect } from "react"

export type OnMountEditorCallback = (view: EditorView) => void
export type OnUnmountEditorCallback = () => void

type EditorLifecycleEventsProps = {
  onMount?: OnMountEditorCallback
  onUnmount?: OnUnmountEditorCallback
}

export function EditorLifecycleEvents({
  onMount,
  onUnmount,
}: EditorLifecycleEventsProps) {
  useEditorEffect(
    (view) => {
      onMount?.(view)
    },
    [onMount]
  )

  useEffect(() => {
    return () => {
      if (onUnmount) {
        onUnmount()
      }
    }
  }, [onUnmount])

  return null
}
