import { MenuItem } from "@ariakit/react"
import { useEditorState } from "@nytimes/react-prosemirror"
import { useCallback } from "react"

import { insertEmbed } from "@/commands/embeds"
import toolbarStyles from "@/components/editor/toolbar/toolbar.module.css"
import { Video } from "@/components/icons.tsx"
import { useAppDispatch } from "@/store/hooks"
import { Dialog } from "@/store/slices/dialogs"
import { dialogOpened } from "@/store/store"

export function InsertVideoItem() {
  const dispatch = useAppDispatch()

  const onClick = useCallback(() => {
    dispatch(dialogOpened(Dialog.INSERT_VIDEO))
  }, [dispatch])

  const editorState = useEditorState()

  const disabled = !editorState || !insertEmbed()(editorState)

  return (
    <>
      <MenuItem
        onClick={onClick}
        className={toolbarStyles["editorToolbarDropdownItem"]}
        disabled={disabled}
      >
        <Video />
        Video
      </MenuItem>
    </>
  )
}
