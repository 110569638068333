import { localized, msg, str } from "@lit/localize";
import {
  type EnrichedActivity,
  type FeedAPIResponse,
  type NotificationActivityEnriched,
  type RealTimeMessage,
  StreamClient,
  StreamFeed,
  connect
} from "getstream";
import { LitElement, css, html } from "lit";
import { customElement, property, state } from "lit/decorators.js";

import { sendErrorToSentry } from "@/utils/sentry";

enum ObjectType {
  actor = "actor",
  object = "object",
  target = "target"
}

/**
 * Custom Element to show notifications to the user.
 */
@localized()
@customElement("notification-menu")
export class NotificationMenu extends LitElement {
  @property({ type: String, attribute: "user-id" }) userId: string | null = null;
  @property({ type: String, attribute: "stream-token" }) streamToken: string | null = null;
  @property({ type: String, attribute: "stream-app-id" }) streamAppId: string | null = null;
  @property({ type: String, attribute: "stream-api-key" }) streamApiKey: string | null = null;
  @property({ type: Number, attribute: "unread-count" }) unreadCount = 0;

  @state() _showDropdown = false;
  @state() _hasError = false;
  @state() _errorMessage = "";
  @state() _feedItems: Array<NotificationActivityEnriched> = [];
  @state() _feed: StreamFeed | null = null;

  client: StreamClient | null = null;

  static override styles = css`
    :host {
      position: relative;
    }

    button.bell {
      height: 2rem;
      width: 2rem;
      border-radius: 10rem;
      padding: 0.25rem;
      border: 0;
      box-shadow: var(--shadow);
      font-size: 1rem;
      background-color: rgba(243, 244, 246, 1);
    }

    button:hover {
      background-color: rgba(229, 231, 235, 1);
    }

    div.dropdown {
      position: absolute;
      right: 0;
      background-color: white;
      border: 1px solid rgba(229, 231, 235, 1);
      box-shadow: var(--shadow);
      border-radius: 0.375rem;
      width: 30rem;
      transform: translateY(0.5rem);
      overflow: hidden;
    }

    div.dropdown > p.error {
      font-size: 1rem;
      margin: 10px 0 10px 0;
    }

    @media (max-width: 540px) {
      button.bell {
        background-color: transparent;
        color: white;
      }

      div.dropdown {
        width: 100vw;
        position: fixed;
      }
    }

    div.notification-icon {
      position: relative;
      display: flex;
      align-items: center;
    }

    div.notification-icon > div.counter {
      background-color: red;
      border-radius: 5rem;
      color: white;
      position: absolute;
      right: -5px;
      top: -5px;
      width: 1rem;
      height: 1rem;
      font-size: 0.675rem;
      text-align: center;
      padding: 0;
    }

    .dropdown > h3 {
      padding: 0.5rem 1rem;
      margin: 0;
      font-weight: 500;
      font-size: 0.875rem;
      background-color: rgba(243, 244, 246, 1);
      color: var(--colorGray900);
    }

    .dropdown > ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    .dropdown > ul > li {
      padding: 0.25rem 1rem;
      border-top: 1px solid white;
      font-size: 0.9rem;
      display: flex;
    }

    .dropdown > ul > li > a {
      color: black;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .datetime {
      font-size: 0.75rem;
      color: darkgray;
    }

    .dropdown > ul > li:hover {
      background-color: rgba(243, 244, 246, 1);
    }

    .dropdown > p {
      margin: 0;
      padding: 0.25rem;
      font-size: 0.75rem;
      text-align: center;
    }
  `;

  /**
   * Fetch notifications and subscribe to realtime updates from getstream
   */
  override async connectedCallback() {
    super.connectedCallback();
    if (!this.userId || !this.streamToken || !this.streamAppId || !this.streamApiKey) {
      sendErrorToSentry("Error initialization of NotificationMenu", new Error("Missing userId, streamToken, streamAppId or streamApiKey"));
      return;
    }
    try {
      // Connect to getstream to acquire client
      this.client = connect(
        this.streamApiKey,
        this.streamToken,
        this.streamAppId
      );

      // Define callback function for realtime updates from getstream
      const realtimeCallback = async (data: RealTimeMessage) => {
        this.unreadCount += data.new.length;
        await this.fetchFeedItems();
      };

      // Get feed of user using this.userId
      this._feed = this.client.feed("notifications", this.userId);

      // Subscribe to feed
      await this._feed.subscribe(realtimeCallback);

      // Fetch last 5 elements from notification feed and assign items
      await this.fetchFeedItems();
    } catch (error) {
      // Catching error on intialization of NotificationMenu
      this.setError(error, "Error initialization of NotificationMenu");
      sendErrorToSentry("Error initialization of NotificationMenu", error);
    }
  }

  async fetchFeedItems() {
    if (!this._feed) {
      sendErrorToSentry('Failed to fetch feed items because `_feed` was not available')
      return;
    }
    try {
      const feedResponse: FeedAPIResponse = await this._feed.get({
        limit: 10,
        enrich: true
      });
      this._feedItems = feedResponse.results as NotificationActivityEnriched[];
      this.unreadCount = feedResponse.unseen ?? 0;
    } catch (error) {
      // Catching error on fetching feed items
      this.setError(error, "Error fetching feed items");
      sendErrorToSentry("Error fetching feed items", error);
    }
  }

  override render() {
    return html`
      <div class="notification-icon">
        <button class="bell" @click="${this.toggleDropdown}">
          <!-- Heroicon name: bell -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
            ></path>
          </svg>
        </button>
        ${this.unreadCount > 0
          ? html`
            <div class="counter">${this.unreadCount}</div>`
          : undefined}
      </div>
      ${this._showDropdown ? this.renderDropdown() : undefined}
    `;
  }

  /**
   * Render dropdown with notification list
   */
  private renderDropdown() {
    if (this._hasError) {
      return html`
        <div class="dropdown">
          <h3>${msg("Notifications")}</h3>
          <p class="error">An error occured</p>
        </div>`;
    }
    return html`
      <div class="dropdown">
        <h3>${msg("Notifications")}</h3>
        ${this._feedItems.length === 0
          ? html`<p>No Notifications yet</p>`
          : html`
            <ul>
              ${this._feedItems.map(this.renderFeedItem.bind(this))}
            </ul>`}
      </div>`;
  }

  /**
   * Render feedItem into HTML <li> tag. For this we must render actor,
   * object and target into strings and pick the correct natural language phrase
   * @param feedItem
   */
  private renderFeedItem(feedItem: NotificationActivityEnriched) {
    // Get actor, object and target.
    const actor = this.getAttributeFromNotification(
      feedItem,
      ObjectType.actor,
      "name"
    );
    const object = this.getAttributeFromNotification(
      feedItem,
      ObjectType.object,
      "name"
    );
    const target = this.getAttributeFromNotification(
      feedItem,
      ObjectType.target,
      "name"
    );

    // Get phrase and url based on verb
    const _item = feedItem;
    let phrase;
    let url;
    switch (_item.verb) {
      case "added_as_co_editor":
        phrase = msg(str`${actor} added you as a co-editor to ${target}.`);
        url = this.getAttributeFromNotification(
          feedItem,
          ObjectType.target,
          "url"
        );
        break;
      case "notify_platform": {
        if (_item && _item.activities.length >= 1) {
          phrase = _item.activities[0]?.full_text;
          url = _item.activities[0]?.url;
        }
        break;
      }
      case "published_script":
        phrase = msg(str`${actor} published a new script titled ${object}.`);
        url = this.getAttributeFromNotification(
          feedItem,
          ObjectType.object,
          "url"
        );
        break;
      case "shared_thread":
        phrase = msg(str`${actor} shared a thread in ${target}`);
        url = this.getAttributeFromNotification(
          feedItem,
          ObjectType.object,
          "url"
        );
        break;
      case "shared_highlight": {
        phrase = msg(str`${actor} shared a highlight in ${target}`);
        if (_item && _item.activities.length >= 1) {
          url = _item.activities[0]?.url;
        }
        break;
      }
      case "replied_in_thread": {
        phrase = msg(str`${actor} replied in a thread.`);
        if (_item && _item.activities.length >= 1) {
          url = _item.activities[0]?.url;
        }
        break;
      }
      case "published_chapter":
        phrase = msg(str`${actor} published chapter ${object}.`);
        url = this.getAttributeFromNotification(
          feedItem,
          ObjectType.object,
          "url"
        );
        break;
      case 'published_revision':
        phrase = msg(str`${actor} published a new revision of ${object}.`);
        url = this.getAttributeFromNotification(
          feedItem,
          ObjectType.object,
          "url"
        );
        break;
      case "shared_script":
        phrase = msg(str`${actor} shared script ${object}.`);
        url = this.getAttributeFromNotification(
          feedItem,
          ObjectType.object,
          "url"
        );
        break;
    }
    return html`
      <li>
        <a href="${url}">
          <div class="title">${phrase}</div>
          <div class="datetime">
            ${feedItem.created_at ? new Date(_item.created_at).toLocaleString("de-DE", {
              timeZone: "Europe/Berlin",

              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric"
            }) : undefined}
          </div
          >
        </a>
      </li>`;
  }

  /**
   * Get attribute of object (actor, object, target) in activity(@see https://getstream.io/activity-feeds/docs/node/adding_activities/?language=js)
   *
   * @param activity Activity to extract the object's attribute
   * @param objectType Type of object (actor, object, target)
   * @param attribute Name of attribute to be used, usually `name`
   * @returns attribute value or null if not found
   */
  private getAttributeFromActivity(activity: EnrichedActivity, objectType: ObjectType, attribute: string) {
    if (activity == null) return null;
    if (!Object.keys(activity).includes(objectType)) return null;
    const objTypeValue = activity[objectType] as {
      data: object
    };
    if (objTypeValue == null) return null;
    const data = objTypeValue["data"] as {
      [key: string]: string
    };
    if (data == null) return null;
    if (!Object.keys(data).includes(attribute)) return null;
    try {
      return data[attribute];
    } catch {
      return null;
    }
  }

  /**
   * Get attribute of object (actor, object, target) in feedItem. Fetched feedItems have
   * a field `activities` which contains exactly one activity. feedItems from the websocket
   * don't have such a field.
   * This function can be extended to aggregate multiple objects in a grouped activity,
   * e.g. "Bob, Alice and two others"; currently grouping is turned off.
   * See: https://getstream.io/activity-feeds/docs/python/creating_feeds/?language=python
   *
   * @param feedItem feedItem to extract the object's attribute
   * @param objectType Type of object (actor, object, target)
   * @param attribute Name of attribute to be used, usually `name`
   */
  private getAttributeFromNotification(feedItem: NotificationActivityEnriched, objectType: ObjectType, attribute: string) {
    if (feedItem && feedItem.activities.length >= 1) {
      try {
        return this.getAttributeFromActivity(feedItem.activities[0] as EnrichedActivity, objectType, attribute);
      } catch {
        return null;
      }
    }
    return null;
  }

  /**
   * Show dropdown and set unreadCount to zero; triggered by notification-icon
   */
  async toggleDropdown() {
    this._showDropdown = !this._showDropdown;
    if (this._showDropdown) {
      document.addEventListener("click", this.handleClickWhenOpen.bind(this));
    }
    if (this.unreadCount > 0 && this._feed) {
      try {
        const response: FeedAPIResponse = await this._feed.get({ mark_seen: true });
        this.unreadCount = response.unseen ?? 0;
      } catch (error) {
        // Catching error on marking notifications as seen
        this.setError(error, "Error marking notifications as seen");
        sendErrorToSentry("Error marking notifications as seen", error);
      }
    }
  }

  /**
   * Set error message and hasError flag
   * @param error error to be shown
   * @param defaultMessage default message to be shown if error is not an instance of Error
   */
  private setError(error: unknown, defaultMessage: string) {
    this._hasError = true;
    if (error instanceof Error) {
      this._errorMessage = error.message;
    } else {
      this._errorMessage = msg(defaultMessage);
    }
  }

  /**
   * Listener function to close the dropdown if user clicks outside of it
   * @param event ClickEvent anywhere on the page
   */
  private handleClickWhenOpen(event: MouseEvent) {
    if (!(event.target as HTMLElement).closest("notification-menu")) {
      this._showDropdown = false;
      document.removeEventListener("click", this.handleClickWhenOpen);
    }
  }
}
