import { useCallback, useContext } from "react"

import styles from "./tableofcontents.module.css"

import { PanelContext } from "@/contexts/PanelContext"
import { useMediaQuery } from "@/hooks/useMediaQuery"
import { useAppDispatch } from "@/store/hooks"
import {
  type HeadingLevel,
  type HeadingNode,
  type NestedHeadings,
  isGapHeading,
} from "@/store/selectors/documentsSelectors"
import { panelsSlice } from "@/store/slices/panels.ts"

export function TableOfContentsList({
  headings,
  level,
}: {
  headings: NestedHeadings[]
  level: HeadingLevel
}) {
  const className: string = styles[`level${level}`] ?? ""
  const isMobile = useMediaQuery("(max-width: 768px)")
  const dispatch = useAppDispatch()
  const panel = useContext(PanelContext)

  const handleNavigate = useCallback(
    (guid: string) => {
      if (isMobile && panel)
        dispatch(
          panelsSlice.actions.tableOfContentsToggled({ panelId: panel.panelId })
        )

      window.location.hash = guid
    },
    [isMobile, panel, dispatch]
  )

  return (
    <ol className={className} lang="de">
      {headings.map((nestedHeading) => {
        return (
          <li key={getNextUuid(nestedHeading)}>
            {nestedHeading.heading.content ? (
              <a
                onClick={() => {
                  if (nestedHeading.heading.content)
                    handleNavigate(nestedHeading.heading.content?.attrs.guid)
                }}
              >
                {getTextContentWithoutFootnotes(nestedHeading.heading.content)}
              </a>
            ) : null}
            {nestedHeading.subheadings && (
              <TableOfContentsList
                headings={nestedHeading.subheadings}
                level={(level + 1) as HeadingLevel}
              />
            )}
          </li>
        )
      })}
    </ol>
  )
}

/**
 * Takes the textContent of a HeadingNode while ignoring any footnotes
 * @param content: HeadingNode
 */
function getTextContentWithoutFootnotes(content: HeadingNode): string {
  let textContent = ""
  content.descendants((node) => {
    if (node.type.name !== "footnote") {
      textContent += node.textContent
    }
    return false
  })
  return textContent
}

function getNextUuid(nestedHeading: NestedHeadings): string {
  if (!isGapHeading(nestedHeading)) {
    return nestedHeading.heading.content.attrs.guid
  } else if (nestedHeading.subheadings[0]) {
    return getNextUuid(nestedHeading.subheadings[0])
  }
  return ""
}
