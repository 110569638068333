import * as Ariakit from "@ariakit/react"
import clsx from "clsx"
import { type ReactNode } from "react"

import styles from "./Alert.module.css"

type AlertProps = {
  title: ReactNode
  description: ReactNode
  store?: Ariakit.DialogStore
  actions: {
    label: ReactNode
    hideOnClick?: boolean
    variant?: "outline" | "solid" | "danger"
    beforeHide?: () => void
  }[]
}

export function Alert({ title, description, actions, store }: AlertProps) {
  const dialog = Ariakit.useDialogStore({ store })
  return (
    <Ariakit.Dialog store={dialog} className={styles.alert}>
      <Ariakit.DialogHeading className={styles.alertHeading}>
        {title}
      </Ariakit.DialogHeading>
      <p className={styles.alertDescription}>{description}</p>
      <div className={styles.alertActions}>
        {actions.map((action, index) => (
          <button
            key={index}
            className={clsx("blue-button", {
              outlined: action.variant === "outline",
              danger: action.variant === "danger",
            })}
            onClick={() => {
              action.beforeHide?.()
              action.hideOnClick && dialog.hide()
            }}
          >
            {action.label}
          </button>
        ))}
      </div>
    </Ariakit.Dialog>
  )
}
