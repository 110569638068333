import { createContext } from "react"

import type { UUID } from "@/store/UUID"

export type NotebookContextValue = {
  notebookId: UUID
}

export const NotebookContext = createContext<NotebookContextValue>(
  null as unknown as NotebookContextValue
)
