import { QueryStatus } from "@reduxjs/toolkit/dist/query"
import { Mapping } from "prosemirror-transform"

import { transactionDispatched } from "./transactionDispatched"

import { referenceParsingPluginKey } from "@/plugins/referenceParsing/referenceParsingPlugin"
import { chapterSchema } from "@/schemas/chapter/schema"
import type { UUID } from "@/store/UUID"
import { getRevisionEditorState } from "@/store/selectors/documentsSelectors"
import { apiSlice } from "@/store/slices/api"
import { createAppThunk } from "@/store/store"
import {
  maybeMap,
  positionMatchedTextContent,
  rangeHasManualLink,
} from "@/utils/prosemirror"

export const autoLinkAllClicked = createAppThunk(
  "document/autoLinkAllClickd",
  async (
    {
      documentType,
      documentId,
      revisionId,
    }: {
      documentType: "chapter" | "flashcard" | "note"
      documentId: UUID
      revisionId: UUID
    },
    { dispatch, getState }
  ) => {
    const initialEditorState = getRevisionEditorState(getState(), revisionId)
    if (!initialEditorState) return

    const taskId = crypto.randomUUID() as UUID

    await dispatch(
      transactionDispatched({
        documentType,
        documentId,
        revisionId,
        fromAtom: false,
        transaction: initialEditorState.tr.setMeta(referenceParsingPluginKey, {
          type: "all",
          payload: [{ id: taskId }],
        }),
      })
    )

    const initialTextContent = positionMatchedTextContent(
      initialEditorState.doc
    )

    const { status, data } = await dispatch(
      apiSlice.endpoints.getReferenceLinks.initiate({
        text: initialTextContent,
        referenceType: "all",
      })
    )

    if (status !== QueryStatus.fulfilled || !data) return

    const editorState = getRevisionEditorState(getState(), revisionId)
    if (!editorState) return

    const textContent = positionMatchedTextContent(editorState.doc)

    const mapping =
      referenceParsingPluginKey.getState(editorState)?.[taskId] ?? new Mapping()

    const tr = editorState.tr

    for (const link of data.links) {
      const start = maybeMap(mapping, link.start)
      const end = maybeMap(mapping, link.end, -1)

      const initialText = initialTextContent.slice(link.start, link.end)
      const currentText = textContent.slice(start, end)

      if (currentText !== initialText) continue

      const linkMark = chapterSchema.marks.link.create({
        ...link.resource,
        autoLinked: true,
      })
      const existingLink = rangeHasManualLink(tr.doc, start, end)

      if (!existingLink) {
        tr.addMark(start, end, linkMark)
      }
    }

    tr.setMeta(referenceParsingPluginKey, {
      type: "cleared",
      payload: [taskId],
    })

    dispatch(
      transactionDispatched({
        revisionId,
        documentType,
        documentId,
        fromAtom: false,
        transaction: tr,
      })
    )
  }
)
