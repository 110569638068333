import { Button, useFormStore } from "@ariakit/react"
import { Localized, useLocalization } from "@fluent/react"
import { clsx } from "clsx"
import { useContext, useState } from "react"

import { FRONTMATTER_ID } from "@/components/frontmatter/Frontmatter.tsx"
import frontmatterStyles from "@/components/frontmatter/frontmatter.module.css"
import { Pencil } from "@/components/icons.tsx"
import sharedStyles from "@/components/shared.module.css"
import { RevisionContext } from "@/contexts/RevisionContext.ts"
import {
  Form,
  FormField,
  FormInput,
  FormSubmit,
} from "@/micro_apps/components/forms/form.tsx"
import { useGetNoteQuery, useUpdateNoteMutation } from "@/store/slices/api.ts"
import { IconButton } from "@/ui/IconButton"

export function NoteFrontmatter() {
  const [edit, setEdit] = useState(false)

  const { documentId } = useContext(RevisionContext)
  const { data: note, isSuccess, isLoading } = useGetNoteQuery(documentId)

  const { l10n } = useLocalization()

  if (!isSuccess || isLoading) return null

  return (
    <div id={FRONTMATTER_ID} className={frontmatterStyles["frontmatter"]}>
      {!edit ? (
        <>
          <a href={note.notebookUrl}>
            <span>«</span>
            <Localized
              id={"note-frontmatter-back"}
              vars={{ notebookTitle: note.notebookTitle || "" }}
            >
              {"Back to Notebook}"}
            </Localized>
          </a>
          <h1>
            {note.title}
            <IconButton
              style={{ display: "inline-block" }}
              size={"small"}
              label={l10n.getString("edit")}
              icon={<Pencil />}
              onClick={() => setEdit(true)}
            />
          </h1>
          {note.description && <p>{note.description}</p>}
        </>
      ) : (
        <NoteFrontmatterEdit setEdit={setEdit} />
      )}
    </div>
  )
}

export function NoteFrontmatterEdit({
  setEdit,
}: {
  setEdit: (edit: boolean) => void
}) {
  const { documentId } = useContext(RevisionContext)
  const { data: note, isSuccess, isLoading } = useGetNoteQuery(documentId)

  const formStore = useFormStore({
    defaultValues: {
      title: note?.title,
      description: note?.description,
    },
  })

  const [updateNote] = useUpdateNoteMutation()

  formStore.useSubmit(async (state) => {
    const { title, description } = state.values
    if (!title || !note) return
    updateNote({
      noteId: documentId,
      notebookId: note.notebookId,
      title: title,
      description: description || "",
    }).finally(() => setEdit(false))
  })

  if (!isSuccess || isLoading) return null

  return (
    <Form store={formStore}>
      <FormField name={"title"} label={"Title"}>
        <FormInput name={"title"} />
      </FormField>
      <FormField name={"description"} label={"Description"}>
        <FormInput name={"description"} />
      </FormField>
      <div style={{ display: "flex", gap: "0.5rem" }}>
        <FormSubmit store={formStore} className={sharedStyles["defaultButton"]}>
          <Localized id={"save"}>Save</Localized>
        </FormSubmit>
        <Button
          className={clsx(
            sharedStyles["defaultButton"],
            sharedStyles["secondaryButton"]
          )}
          onClick={() => setEdit(false)}
        >
          <Localized id={"cancel"}>Cancel</Localized>
        </Button>
      </div>
    </Form>
  )
}
