import { NodeType } from "prosemirror-model"
import { EditorState } from "prosemirror-state"

import { isFlashcardDocStructure } from "@/schemas/flashcard/schema"

export function isNodeTypeSelected(
  editorState: EditorState | null,
  type: NodeType | undefined
) {
  let depth = 1

  if (isFlashcardDocStructure(editorState?.doc)) {
    depth = 2
  }

  const selectedNode = editorState?.selection.$anchor.node(depth)
  return selectedNode?.type === type
}
