import { useCallback } from "react"

import {
  SocketEvents,
  type SubscriptionCallback,
} from "@/communication/websocket/websocketClient"
import type { UUID } from "@/store/UUID"
import { useAppDispatch } from "@/store/hooks"
import {
  addToHighlightsCache,
  removeFromHighlightsCache,
} from "@/store/slices/api"
import { threadRemoved } from "@/store/store"

export function useRemoteHighlights({
  revisionId,
}: {
  revisionId?: UUID | null
}) {
  const dispatch = useAppDispatch()

  return useCallback<SubscriptionCallback>(
    (payload) => {
      if (
        payload.type === SocketEvents.NewHighlight ||
        payload.type === SocketEvents.HighlightShared
      ) {
        const { chapter_id } = payload.meta
        const { revision_id } = payload.data
        dispatch(
          addToHighlightsCache({ chapter_id, revision_id }, payload.data)
        )
      }
      if (revisionId && payload.type === SocketEvents.HighlightDeleted) {
        const { chapter_id } = payload.meta
        dispatch(
          removeFromHighlightsCache(
            { chapter_id, revision_id: revisionId },
            payload.meta.highlight_id
          )
        )
        // We also need to remove the thread the aside panel if it is currently open.
        dispatch(threadRemoved({ highlightId: payload.meta.highlight_id }))
      }
    },
    [revisionId, dispatch]
  )
}
