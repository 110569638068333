import { createContext } from "react"

import type { UUID } from "@/store/UUID"

export type FlashcardDeckContextValue = {
  deckId: UUID
}

export type FlashcardContextValue = {
  flashcardId: UUID
  sessionId: UUID
  showBackside: boolean
  toggleBackside: () => void
}

export const FlashcardDeckContext = createContext<FlashcardDeckContextValue>(
  null as unknown as FlashcardDeckContextValue
)

export const FlashcardContext = createContext<FlashcardContextValue>(
  null as unknown as FlashcardContextValue
)
