import {
  Menu,
  MenuButton,
  MenuButtonArrow,
  ToolbarItem,
  useMenuStore,
} from "@ariakit/react"
import { Localized } from "@fluent/react"
import { useEditorState } from "@nytimes/react-prosemirror"

import { insertDefinition } from "@/commands/definitions.ts"
import { insertFoldable } from "@/commands/foldables.ts"
import { insertQuiz } from "@/commands/quizzes.ts"
import { insertReviewSchema } from "@/commands/reviewSchemas.ts"
import { insertTable } from "@/commands/tables.ts"
import { CommandItem } from "@/components/editor/toolbar/items/CommandItem.tsx"
import { EditorMenuItem } from "@/components/editor/toolbar/items/EditorMenuItem.tsx"
import { InsertImageItem } from "@/components/editor/toolbar/items/InsertImageItem.tsx"
import { InsertPDFItem } from "@/components/editor/toolbar/items/InsertPDFItem.tsx"
import { InsertVideoItem } from "@/components/editor/toolbar/items/InsertVideoItem.tsx"
import styles from "@/components/editor/toolbar/toolbar.module.css"
import {
  Foldable,
  InsertBlock,
  Quiz,
  ReviewSchema,
  Table,
} from "@/components/icons.tsx"

export function NoteInsertBlockMenu() {
  const menuStore = useMenuStore()
  const editorState = useEditorState()

  if (!editorState) return null

  return (
    <>
      <MenuButton
        className={`${styles["editorToolbarItem"]} ${styles["editorToolbarMenuButton"]}`}
        store={menuStore}
        render={<ToolbarItem aria-label="Insert Block" />}
      >
        <InsertBlock />
        <MenuButtonArrow />
      </MenuButton>
      <Menu store={menuStore} className={styles["editorToolbarDropdownMenu"]}>
        <InsertImageItem />
        <InsertPDFItem />
        <InsertVideoItem />
        <CommandItem command={insertQuiz} as={EditorMenuItem}>
          <Quiz />
          <Localized id={"quiz"}>Quiz</Localized>
        </CommandItem>
        <CommandItem command={insertTable} as={EditorMenuItem}>
          <Table />
          <Localized id={"table"}>Table</Localized>
        </CommandItem>
        <CommandItem command={insertFoldable} as={EditorMenuItem}>
          <Foldable />
          <Localized id={"foldable"}>Foldable</Localized>
        </CommandItem>
        <CommandItem command={insertReviewSchema} as={EditorMenuItem}>
          <ReviewSchema />
          <Localized id={"review-schema"}>Review Schema</Localized>
        </CommandItem>
        <CommandItem command={insertDefinition} as={EditorMenuItem}>
          <span className={styles.defIcon}>DEF</span>
          <Localized id={"definition"}>Definition</Localized>
        </CommandItem>
      </Menu>
    </>
  )
}
