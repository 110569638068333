import { Button } from "@ariakit/react"
import { Localized } from "@fluent/react"
import {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from "react"
import { createPortal } from "react-dom"

import { TableOfContentsList } from "./TableOfContentsList"
import styles from "./tableofcontents.module.css"

import { Cancel } from "@/components/icons.tsx"
import sharedStyles from "@/components/shared.module.css"
import { PanelContext } from "@/contexts/PanelContext"
import { RevisionContext } from "@/contexts/RevisionContext"
import { useAppDispatch, useAppSelector } from "@/store/hooks"
import {
  getRevisionEditorState,
  makeGetNestedDocumentHeadings,
} from "@/store/selectors/documentsSelectors"
import { type PanelState, panelsSlice } from "@/store/slices/panels.ts"
import { getHeadingStyleForTableOfContents } from "@/utils/headingStyles.ts"

export function TableOfContents() {
  const { revisionId } = useContext(RevisionContext)
  const getNestedDocumentHeadings = useMemo(makeGetNestedDocumentHeadings, [])
  const nestedHeadings = useAppSelector((state) =>
    getNestedDocumentHeadings(state, revisionId)
  )
  const dispatch = useAppDispatch()

  const editorState = useAppSelector(
    (state) => revisionId && getRevisionEditorState(state, revisionId)
  )

  const { panelId } = useContext(PanelContext) as unknown as PanelState
  const onClickHide = useCallback(() => {
    dispatch(
      panelsSlice.actions.tableOfContentsToggled({ panelId, force: null })
    )
  }, [panelId, dispatch])

  return (
    <div className={styles["tableOfContents"]} role="navigation">
      <div className={styles["headingSection"]}>
        <Localized id={"table-of-contents"}>
          <h3>Table of Contents</h3>
        </Localized>
        <Button
          onClick={onClickHide}
          className={sharedStyles["buttonIconOnly"]}
        >
          <Cancel />
        </Button>
      </div>
      <div
        className={styles["listSection"]}
        style={
          (editorState &&
            getHeadingStyleForTableOfContents(
              editorState.doc.attrs.headingStyles
            )) ??
          undefined
        }
      >
        {nestedHeadings && (
          <TableOfContentsList headings={nestedHeadings} level={1} />
        )}
      </div>
    </div>
  )
}

/**
 * This hook returns a component that renders the TableOfContents
 * in an aside portal of the currently active panel.
 */

export function useAsideTableOfContents() {
  const { panelId } = useContext(PanelContext) ?? {}
  const [mount, setMount] = useState<Element | null>(null)

  useLayoutEffect(() => {
    setMount(document.getElementById(`${panelId}-panel-toc`))
  }, [panelId])

  const TableOfContentsPanel = useMemo(() => {
    return mount ? createPortal(<TableOfContents />, mount) : null
  }, [mount])

  return TableOfContentsPanel
}
