import type { UUID } from "@/store/UUID.ts"
import { enqueueSnackbar } from "@/store/reducers/snackbars.ts"
import { apiSlice } from "@/store/slices/api.ts"
import { createAppThunk } from "@/store/store"

export const flashcardDeckDeleteClicked = createAppThunk(
  "flashcards/deleteDeck",
  ({ deckId }: { deckId: UUID }, { dispatch }) => {
    dispatch(apiSlice.endpoints.deleteFlashcardDeck.initiate({ deckId })).then(
      () => {
        window.location.href = "/"
      },
      () => {
        enqueueSnackbar({
          fluentId: "flashcards-deck-delete-failed",
          message: "Error deleting deck.",
          variant: "error",
        })
      }
    )
  }
)
