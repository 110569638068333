import { useEditorEffect } from "@nytimes/react-prosemirror"
import { useState } from "react"

export function useIsEditorEditable() {
  const [isEditable, setIsEditable] = useState(false)

  useEditorEffect((view) => {
    setIsEditable(!!view.editable)
  })

  return isEditable
}
