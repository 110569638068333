import { Localized } from "@fluent/react"
import {
  type NodeViewComponentProps,
  ProseMirror,
  ProseMirrorDoc,
} from "@nytimes/react-prosemirror"
import { EditorState } from "prosemirror-state"
import { forwardRef, useMemo, useRef } from "react"

import styles from "./citation.module.css"

import { generateGuid } from "@/components/editor/plugins/guid.ts"
import { chapterViewerNodeViews } from "@/components/viewer/Viewer"
import { useAppDispatch } from "@/store/hooks"
import { PanelType } from "@/store/slices/panels"
import { panelOpened } from "@/store/store"

export const Citation = forwardRef<HTMLDivElement, NodeViewComponentProps>(
  function Citation({ children: _children, nodeProps, ...props }, ref) {
    const openSourceLinkRef = useRef<HTMLAnchorElement | null>(null)
    const dispatch = useAppDispatch()
    const editorState = useMemo(
      () =>
        EditorState.create({
          doc: nodeProps.node,
        }),
      [nodeProps.node]
    )

    return (
      <div
        className={styles["wrapper"]}
        contentEditable={false}
        ref={ref}
        {...props}
      >
        <blockquote className={styles["blockquote"]}>
          <ProseMirror
            nodeViews={chapterViewerNodeViews}
            editable={() => false}
            state={editorState}
            dispatchTransaction={() => {}}
          >
            <ProseMirrorDoc />
          </ProseMirror>
        </blockquote>
        <a
          ref={openSourceLinkRef}
          className={styles["sourceLink"]}
          href="#"
          onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()

            dispatch(
              panelOpened({
                panel: {
                  panelId: generateGuid(),
                  panelType: PanelType.VIEWER,
                  documentId: nodeProps.node.attrs.source_document_id,
                  scrollToHighlight: nodeProps.node.attrs.annotation_id,
                },
              })
            )
          }}
        >
          <Localized id={"open-source"}>Open source</Localized>
        </a>
      </div>
    )
  }
)
