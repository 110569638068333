import { useEditorEffect } from "@nytimes/react-prosemirror"
import { useCallback, useContext, useState } from "react"

import { AtomViewContext } from "@/contexts/AtomViewContext"

/**
 * Ensures that the appropriate EditorView
 * (default or atom) is focused.
 *
 * @private
 *
 * In order to correctly focus a newly created
 * AtomView, the AtomViewContext needs to be read
 * in a new render cycle. To accomplish this, we
 * trigger a state update, and then utilize a layout
 * effect to actually handle the focusing.
 *
 * We can't simply rely on a layout effect in the AtomView
 * component, because react-prosemirror flushes dispatches
 * synchronously, so that layout effect will run first, and
 * then be clobbered by the remainder of the original caller,
 * which doesn't yet know about the atomView, and will
 * re-focus the default view.
 */
export function useFocusView() {
  const { atomView } = useContext(AtomViewContext)
  const [focusCount, setFocusCount] = useState(0)
  const focusView = useCallback(() => {
    setFocusCount((x) => x + 1)
  }, [])

  useEditorEffect(
    (view) => {
      view.focus()
      atomView?.focus()
    },
    [focusCount]
  )

  return focusView
}
