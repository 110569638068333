import { useContext } from "react"

import { Thread } from "./Thread.tsx"

import { RevisionContext } from "@/contexts/RevisionContext.ts"
import type { UUID } from "@/store/UUID.ts"
import { useAppSelector } from "@/store/hooks.ts"

export function Threads() {
  const { revisionId } = useContext(RevisionContext)

  const threads = useAppSelector((state) => {
    return Object.entries(state.threads[revisionId] ?? {})
  })

  return (
    <>
      {threads.map(([highlightId, thread]) => {
        return thread.isOpen ? (
          <Thread key={highlightId} highlightId={highlightId as UUID} />
        ) : null
      })}
    </>
  )
}
