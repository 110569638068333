import { useEffect, useRef } from "react"

import styles from "./ResourcesPanel.module.css"
import { CourtDecision } from "./contents/CourtDecision"
import { PDFViewer } from "./contents/PDFViewer"
import { ErrorPlaceholder } from "./contents/Placeholder"
import { StatuteSection } from "./contents/StatuteSection"

import { PanelType } from "@/store/slices/panels"
import { ErrorBoundary } from "@/utils/error"

type ResourcesViewerProps = {
  resourceType: PanelType
  resourceUrl?: string | null
  resourceTitle?: string | null
}

export function ResourcesViewer({
  resourceUrl,
  resourceType,
  resourceTitle,
}: ResourcesViewerProps) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (resourceType === PanelType.COURT_DECISION) {
      ref.current?.scrollTo({ top: 0, behavior: "smooth" })
    }
  }, [resourceUrl, resourceType])

  return (
    <div className={styles["resourcesViewer"]}>
      <div className={styles["resourcesViewerContent"]} ref={ref}>
        <ErrorBoundary key={resourceUrl} fallback={<ErrorPlaceholder />}>
          {resourceType === PanelType.COURT_DECISION && resourceUrl ? (
            <CourtDecision url={resourceUrl} />
          ) : resourceType === PanelType.STATUTE_SECTION && resourceUrl ? (
            <StatuteSection url={resourceUrl} />
          ) : resourceType === PanelType.PDF_VIEWER && resourceUrl ? (
            <PDFViewer url={resourceUrl} title={resourceTitle} />
          ) : (
            <ErrorPlaceholder />
          )}
        </ErrorBoundary>
      </div>
    </div>
  )
}
