import { useEditorEffect } from "@nytimes/react-prosemirror"
import { getVersion } from "prosemirror-collab"
import { EditorView } from "prosemirror-view"
import { useContext, useRef } from "react"
import { v4 as uuidv4 } from "uuid"

import { RevisionContext } from "@/contexts/RevisionContext"
import type { UUID } from "@/store/UUID"
import { useAppDispatch, useAppSelector } from "@/store/hooks"
import {
  getCurrentMark,
  getMarkerColor,
  getMarkerMode,
} from "@/store/selectors/viewerSelectors"
import { useCreateHighlightMutation } from "@/store/slices/api"
import { viewerSlice } from "@/store/slices/viewer"

const MIN_HIGHLIGHT_SIZE = 2

type iOSTouch = Touch & { touchType: "stylus" | "direct" }

function detectIos() {
  return (
    // Older iPads and current iPhones all announce themselves
    // with the user agent string
    /iPad|iPhone/.test(navigator.userAgent) ||
    // Newer iPads announce themselves as desktop Macs, but have touchscreen
    // support
    (navigator.userAgent.includes("Macintosh") && navigator.maxTouchPoints >= 1)
  )
}

const isIos = detectIos()

const isTouchScreen = navigator.maxTouchPoints >= 1

/**
 * If the user has touched just outside a node, adjust
 * their position to the nearest point inside the node's
 * DOM.
 */
function getAdjustedPos(view: EditorView, left: number, top: number) {
  const result = view.posAtCoords({
    left,
    top,
  })

  if (!result) return null

  if (result.inside === -1) {
    const domAtPos = view.domAtPos(result.pos + 1, 1)
    const dom =
      domAtPos.node instanceof HTMLElement
        ? domAtPos.node
        : domAtPos.node.parentElement

    if (!dom) return null

    const rect = dom.getBoundingClientRect()
    const adjusted = view.posAtCoords({
      left: rect.left,
      top,
    })

    if (!adjusted || adjusted.inside === -1) return null

    return adjusted
  }

  return result
}

export function useStylusHighlight() {
  const dispatch = useAppDispatch()
  const { revisionId, documentId: chapterId } = useContext(RevisionContext)
  const [createHighlight] = useCreateHighlightMutation()
  const isMarkerModeEnabled = useAppSelector(getMarkerMode)
  const markerColor = useAppSelector(getMarkerColor)
  const currentMark = useAppSelector(getCurrentMark)
  const currentMarkRef = useRef(currentMark)
  currentMarkRef.current = currentMark

  // We can't use useEditorEventListener here because we need
  // to pass additional options (namely, passive: false) for
  // our touchmove listener. The passive: false configuration
  // allows us to cancel the touchmove event and thereby prevent
  // the view from scrolling while the user drags to adjust their
  // selection.
  //
  // This should be fine, since there aren't any other event listeners
  // that we need to prevent from running currently listening on touch
  // events. If that changes, we may want to open an issue on react-prosemirror
  // to ask for additional event listener option support.
  useEditorEffect(
    (view) => {
      // We don't want to set up listeners for non-iOS/iPadOS touch devices.
      // There's no way to distinguish non-Apple-Pencil stylus events from
      // finger events, and if we prevent default behavior for finger touch
      // events then users won't be able to scroll by dragging with their
      // fingers.
      if ((isTouchScreen && !isIos) || !isMarkerModeEnabled) return

      function onMarkStart(eventInfo: { clientX: number; clientY: number }) {
        const result = getAdjustedPos(
          view,
          eventInfo.clientX,
          eventInfo.clientY
        )

        if (!result) return

        dispatch(viewerSlice.actions.currentMarkStarted({ anchor: result.pos }))
      }

      function onMarkMove(
        eventInfo: { clientX: number; clientY: number },
        preventDefault: () => void
      ) {
        const result = getAdjustedPos(
          view,
          eventInfo.clientX,
          eventInfo.clientY
        )

        if (!result) return

        const nextMarkHead = result.pos

        const markAnchor = currentMarkRef.current?.anchor
        const currentMarkHead = currentMarkRef.current?.head

        if (!markAnchor || !currentMarkHead) {
          return // If we get here, something went wrong with the previous listener
        }

        preventDefault()

        const hasHeadMoved = nextMarkHead !== currentMarkHead
        if (!hasHeadMoved) return

        const currentHighlightSize = Math.abs(currentMarkHead - markAnchor)
        const nextHighlightSize = Math.abs(nextMarkHead - markAnchor)
        const hasHighlightSizeIncreased =
          nextHighlightSize > currentHighlightSize

        // If the user is currently increasing the highlight size,
        // abort if the highlight is still smaller than the min size.
        // This lowers the likelihood of accidental highlights.
        if (hasHighlightSizeIncreased && nextHighlightSize < MIN_HIGHLIGHT_SIZE)
          return

        dispatch(viewerSlice.actions.currentMarkUpdated({ head: result.pos }))
      }

      function onMarkEnd() {
        if (!isMarkerModeEnabled || !markerColor || !currentMarkRef.current)
          return

        if (currentMarkRef.current.empty) {
          dispatch(viewerSlice.actions.currentMarkCancelled())
          return
        }

        createHighlight({
          chapterId,
          revisionId,
          version: getVersion(view.state),
          highlight: {
            id: uuidv4() as UUID,
            from: currentMarkRef.current.from,
            to: currentMarkRef.current.to,
            color: markerColor,
            revisionId,
          },
        })
      }

      function onTouchStart(event: TouchEvent) {
        if (!isMarkerModeEnabled) return

        const touches = Array.from(event.touches)
        const stylusTouch = touches.find(
          (touch) => (touch as iOSTouch).touchType === "stylus"
        )
        if (!stylusTouch) return

        onMarkStart(stylusTouch)
      }

      function onTouchMove(event: TouchEvent) {
        if (!isMarkerModeEnabled) return

        const touches = Array.from(event.touches)
        const stylusTouch = touches.find(
          (touch) => (touch as iOSTouch).touchType === "stylus"
        )
        if (!stylusTouch) return

        onMarkMove(stylusTouch, () => {
          event.preventDefault()
        })
      }

      function onTouchEnd() {
        onMarkEnd()
      }

      function onMouseDown(event: MouseEvent) {
        if (!isMarkerModeEnabled) return

        onMarkStart(event)
      }

      function onMouseMove(event: MouseEvent) {
        if (!isMarkerModeEnabled) return

        onMarkMove(event, () => {
          event.preventDefault()
        })
      }

      function onMouseUp() {
        onMarkEnd()
      }

      if (isIos) {
        view.dom.addEventListener("touchstart", onTouchStart)
        view.dom.addEventListener("touchmove", onTouchMove, { passive: false })
        view.dom.addEventListener("touchend", onTouchEnd)
      } else {
        view.dom.addEventListener("mousedown", onMouseDown)
        view.dom.addEventListener("mousemove", onMouseMove)
        view.dom.addEventListener("mouseup", onMouseUp)
      }

      return () => {
        if (isIos) {
          view.dom.removeEventListener("touchstart", onTouchStart)
          view.dom.removeEventListener("touchmove", onTouchMove)
          view.dom.removeEventListener("touchend", onTouchEnd)
        } else {
          view.dom.removeEventListener("mousedown", onMouseDown)
          view.dom.removeEventListener("mousemove", onMouseMove)
          view.dom.removeEventListener("mouseup", onMouseUp)
        }
      }
    },
    [
      chapterId,
      createHighlight,
      dispatch,
      isMarkerModeEnabled,
      markerColor,
      revisionId,
    ]
  )
}
