import { ReactLocalization } from "@fluent/react"
import type { Node } from "prosemirror-model"
import { EditorState } from "prosemirror-state"
import { Decoration, DecorationSet } from "prosemirror-view"

import { isEditorDocEmpty } from "@/utils/prosemirror"

type NODES_WITH_PLACEHOLDER =
  | "doc"
  | "front"
  | "back"
  | "definition_title"
  | "definition_content"
  | "review_schema_title"
  | "review_schema_description"
  | "review_item_title"
  | "review_item_content"

type PlaceholderConfig = {
  id: string
  fallback: string
  onChild?: boolean
  shouldShow: (node: Node) => boolean
}

export const DATA_PLACEHOLDER = "data-placeholder"
export const placeholderConfig: Record<
  NODES_WITH_PLACEHOLDER,
  PlaceholderConfig
> = {
  doc: {
    id: "doc-placeholder",
    fallback: "Start writing…",
    shouldShow: (node) => isEditorDocEmpty(node),
  },
  front: {
    id: "flashcard-front-placeholder",
    fallback: "Write the question here…",
    onChild: true,
    shouldShow: (node) => isEditorDocEmpty(node),
  },
  back: {
    id: "flashcard-back-placeholder",
    fallback: "Write the answer here…",
    onChild: true,
    shouldShow: (node) => isEditorDocEmpty(node),
  },
  definition_title: {
    id: "definition-title-placeholder",
    fallback: "Definition title…",
    shouldShow: (node) => node.content.size === 0,
  },
  definition_content: {
    id: "definition-content-placeholder",
    fallback: "Definition content…",
    shouldShow: (node) => node.content.size === 2,
  },
  review_schema_title: {
    id: "review-schema-title-placeholder",
    fallback: "Title…",
    shouldShow: (node) => node.content.size === 0,
  },
  review_schema_description: {
    id: "review-schema-description-placeholder",
    fallback: "Title…",
    shouldShow: (node) => node.content.size === 2,
  },
  review_item_title: {
    id: "review-item-title-placeholder",
    fallback: "Title…",
    shouldShow: (node) => node.content.size === 0,
  },
  review_item_content: {
    id: "review-item-content-placeholder",
    fallback: "Optional details…",
    shouldShow: (node) => node.content.size === 2,
  },
}

export function makeDocumentPlaceholder({
  l10n,
  doc,
}: {
  l10n: ReactLocalization
  doc: Node
}): Record<string, string> {
  const { id, fallback } = placeholderConfig.doc

  return isEditorDocEmpty(doc)
    ? { [DATA_PLACEHOLDER]: l10n.getString(id, null, fallback) }
    : {}
}

export function makePlaceholderDecorations({
  l10n,
}: {
  l10n: ReactLocalization
}): (state?: EditorState | null) => Decoration[] {
  return (state) => {
    const decorations: Decoration[] = []

    state?.doc.descendants((node, pos) => {
      const config = placeholderConfig[node.type.name as NODES_WITH_PLACEHOLDER]
      const shouldShow = config?.shouldShow(node)
      if (!shouldShow) return

      const decoration = Decoration.node(
        pos + (config.onChild ? 1 : 0),
        pos + node.nodeSize + (config.onChild ? -1 : 0),
        {
          [DATA_PLACEHOLDER]: l10n.getString(config.id, null, config.fallback),
        }
      )

      decorations.push(decoration)

      return
    })

    return decorations
  }
}

export function makePlaceholderDecorationSet({
  l10n,
}: {
  l10n: ReactLocalization
}): (state: EditorState) => DecorationSet {
  return (state) =>
    DecorationSet.create(state.doc, makePlaceholderDecorations({ l10n })(state))
}
