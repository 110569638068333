import type { EditorView } from "prosemirror-view"
import { type SetStateAction, createContext } from "react"

export type AtomViewContextValue = {
  atomView: EditorView | null
  setAtomView: (action: SetStateAction<EditorView | null>) => void
}

export const AtomViewContext = createContext<AtomViewContextValue>({
  atomView: null,
  setAtomView: () => {},
} as unknown as AtomViewContextValue)
