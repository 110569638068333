import { Button, useDialogStore } from "@ariakit/react"
import { Localized } from "@fluent/react"
import { clsx } from "clsx"

import styles from "./Permissions.module.css"
import {
  PermissionsAddUserDialog,
  type PermissionsAddUserDialogProps,
} from "./PermissionsAddUserDialog.tsx"

import { Plus } from "@/components/icons.tsx"
import sharedStyle from "@/components/shared.module.css"
import type { UUID } from "@/store/UUID.ts"

export function PermissionsAddEntity({
  resourceId,
  addEntity,
  searchEntities,
}: {
  resourceId: UUID
  addEntity: PermissionsAddUserDialogProps["addEntity"]
  searchEntities: PermissionsAddUserDialogProps["searchEntities"]
}) {
  const addUserDialog = useDialogStore({ defaultOpen: false })

  return (
    <>
      <Button
        onClick={addUserDialog.toggle}
        className={clsx(sharedStyle["defaultButton"], styles["addButton"])}
      >
        <Plus />
        <Localized id={"permissions-add-entities"}>
          Add users, institutions or groups
        </Localized>
      </Button>
      <PermissionsAddUserDialog
        resourceId={resourceId}
        addUserDialog={addUserDialog}
        addEntity={addEntity}
        searchEntities={searchEntities}
      />
    </>
  )
}
