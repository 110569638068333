type HeadingStyleDict = {
  [key: string]: HeadingStyles
}

export enum HeadingStyles {
  upperAlpha = "upper-alpha",
  upperRoman = "upper-roman",
  decimal = "decimal",
  lowerAlpha = "lower-alpha",
  doubleLowerAlpha = "lower-alpha-double",
  lowerRoman = "lower-roman",
  bracketedLowerAlpha = "lower-bracketed-alpha",
  bracketedDoubleLowerAlpha = "lower-bracketed-alpha-double",
  bracketedTripleLowerAlpha = "lower-bracketed-alpha-triple",
  bracketedLowerRoman = "lower-bracketed-roman",
  paragraph = "paragraph",
  none = "none",
}

/**
 * Function to create a string of CSS variables for the heading styles; example:
 *    --h1counter: counter(h1, upper-alpha) '. '; --h2counter: counter(h2, upper-roman) '. ';
 *    --h3counter: counter(h3, decimal) '. '; --h4counter: counter(h4, lower-alpha) ') ';
 *    --h5counter: counter(h5, lower-alpha) counter(h5, lower-alpha) ') '; --h6counter: counter(h6, lower-roman) ') ';
 * These variables are used in the CSS file to style the headings.
 * @param headingStyles
 */
export function templateStyleProperties(
  headingStyles: HeadingStyleDict
): string {
  // Iterate over all heading styles and create the string for
  // the corresponding CSS variable.
  if (!headingStyles) return ""
  const styleStrings = Object.keys(headingStyles).map((key) => {
    const headingStyle = headingStyles[key]
    const variableValue = headingStyle && getHeadingString(headingStyle, key)
    return `--${key}counter: ${variableValue}`
  })
  return styleStrings.join("; ")
}

export function getHeadingStyleForTableOfContents(
  headingStyles: HeadingStyleDict
) {
  const properties: Record<string, string> = {}
  if (!headingStyles) return properties
  Object.keys(headingStyles).forEach((key: string) => {
    const headingStyle = headingStyles[key]
    properties[`--${key}toccounter`] =
      (headingStyle && getHeadingString(headingStyle, key, "toc")) || ""
  })
  return properties
}

function getHeadingString(
  headingStyle: string,
  key: string,
  counterSuffix = ""
): string {
  if (headingStyle === "none") return "none"

  // Heading Styles with small letters use ")" as closing character.
  const char = headingStyle.startsWith("lower") ? "') '" : "'. '"

  let contentPrefix = ""
  if (headingStyle.includes("bracketed")) {
    headingStyle = headingStyle.replace("bracketed-", "")
    contentPrefix = "'('"
  }

  if (headingStyle === "paragraph") {
    return contentPrefix + `'§ ' counter(${key}${counterSuffix}, decimal) ' '`
  }

  // The heading style lower-roman-double "aa)" needs to use the counter twice.
  if (headingStyle.endsWith("double")) {
    const name = headingStyle.replace("-double", "")
    return (
      contentPrefix +
      `counter(${key}${counterSuffix}, ${name}) counter(${key}${counterSuffix}, ${name}) ${char}`
    )
  } else if (headingStyle.endsWith("triple")) {
    const name = headingStyle.replace("-triple", "")
    return (
      contentPrefix +
      `counter(${key}${counterSuffix}, ${name}) counter(${key}${counterSuffix}, ${name}) counter(${key}${counterSuffix}, ${name}) ${char}`
    )
  }

  // All other heading styles use the counter once.
  return (
    contentPrefix + `counter(${key}${counterSuffix}, ${headingStyle}) ${char}`
  )
}
