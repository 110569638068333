import { Button } from "@ariakit/react"
import { Localized } from "@fluent/react"

import { Minus, Plus } from "@/components/icons.tsx"
import sharedStyles from "@/components/shared.module.css"
import type { UUID } from "@/store/UUID.ts"
import { useToggleNotebookSavedMutation } from "@/store/slices/api.ts"

export function SaveToNotebooksButton({
  notebookId,
  isSaved,
  isOwner,
}: {
  notebookId: UUID
  isSaved: boolean
  isOwner: boolean
}) {
  const [toggleNotebookSaved] = useToggleNotebookSavedMutation()

  if (isOwner) return null

  return (
    <Button
      className={sharedStyles["defaultButton"]}
      onClick={() => {
        toggleNotebookSaved({ notebookId })
      }}
    >
      {isSaved ? (
        <>
          <Minus />
          <Localized id={"notebook-remove-from-my-notebooks"}>
            Remove from My Notebooks
          </Localized>
        </>
      ) : (
        <>
          <Plus />
          <Localized id={"notebook-add-to-my-notebooks"}>
            Save to My Notebooks
          </Localized>
        </>
      )}
    </Button>
  )
}
