import { Localized } from "@fluent/react"
import { useContext } from "react"

import frontmatterStyles from "./frontmatter.module.css"

import { RevisionContext } from "@/contexts/RevisionContext.ts"
import { useGetChapterQuery } from "@/store/slices/api"

export const FRONTMATTER_ID = "frontmatter"

export function Frontmatter() {
  const { documentId: chapterId } = useContext(RevisionContext)
  const { data: chapter, isSuccess, isLoading } = useGetChapterQuery(chapterId)

  if (!isSuccess || isLoading) return null

  return (
    <div id={FRONTMATTER_ID} className={frontmatterStyles["frontmatter"]}>
      <a href={chapter.scriptUrl}>
        <span>«</span>
        <Localized
          id={"back-to-script"}
          vars={{ scriptTitle: chapter.scriptTitle || "" }}
        >
          {"Back to script {$scriptTitle}"}
        </Localized>
      </a>
      <h1>{chapter.title}</h1>
      {chapter.description && <p>{chapter.description}</p>}
    </div>
  )
}
