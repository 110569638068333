import {
  Menu,
  MenuButton,
  MenuButtonArrow,
  MenuDescription,
  MenuItem,
  useMenuStore,
} from "@ariakit/react"
import { Localized } from "@fluent/react"
import { useContext } from "react"

import styles from "@/components/editor/toolbar/toolbar.module.css"
import {
  RevisionCurrent,
  RevisionDraft,
  RevisionOutdated,
} from "@/components/icons.tsx"
import { PanelContext } from "@/contexts/PanelContext"
import { RevisionContext } from "@/contexts/RevisionContext.ts"
import type { UUID } from "@/store/UUID"
import { useAppDispatch, useAppSelector } from "@/store/hooks.ts"
import {
  useGetChapterQuery,
  useGetChapterRevisionListQuery,
} from "@/store/slices/api"
import { type PanelState, panelsSlice } from "@/store/slices/panels.ts"
import { RevisionStatus } from "@/types/api"

export function RevisionMenuItem() {
  const { revisionId, documentId: chapterId } = useContext(RevisionContext)
  const { data: chapter } = useGetChapterQuery(chapterId)
  const { data: revisions } = useGetChapterRevisionListQuery(chapterId)

  const isLatestRevision = useAppSelector((state) => {
    const revision = state.revisions.revisions[revisionId]
    return revision?.id === chapter?.publishedRevisionId
  })

  const revisionStatus = useAppSelector((state) => {
    return state.revisions.revisions[revisionId]?.status
  })

  const menuStore = useMenuStore({
    orientation: "vertical",
  })
  const dispatch = useAppDispatch()

  const { panelId } = useContext(PanelContext) as unknown as PanelState
  const changeRevision = (revisionId: UUID) => () => {
    dispatch(
      panelsSlice.actions.panelRevisionIdUpdated({ panelId, revisionId })
    )
  }

  if (!revisions || revisions.length === 0) {
    return null
  }

  let revisionIcon
  if (revisionStatus === RevisionStatus.DRAFT) {
    revisionIcon = <RevisionDraft className={styles["yellow"]} />
  } else if (isLatestRevision) {
    revisionIcon = <RevisionCurrent className={styles["green"]} />
  } else {
    revisionIcon = <RevisionOutdated className={styles["red"]} />
  }

  const activeRevision = revisions.find(
    (revision) => revision.id === revisionId
  )

  return (
    <>
      <MenuButton
        store={menuStore}
        className={`${styles["editorToolbarItem"]} ${styles["editorToolbarMenuButton"]}`}
      >
        {revisionIcon}
        <span className={styles["toolbarItemLabel"]}>
          {activeRevision && activeRevision.name}
        </span>
        <MenuButtonArrow />
      </MenuButton>
      <Menu store={menuStore} className={styles["editorToolbarDropdownMenu"]}>
        <Localized id={"toolbar-available-revisions"}>
          <MenuDescription className={styles["editorToolbarMenuDescription"]}>
            <Localized id="available-revisions-popover-title">
              Available revisions for this chapter:
            </Localized>
          </MenuDescription>
        </Localized>
        {revisions.map((revision) => (
          <MenuItem
            className={`${styles["editorToolbarDropdownItem"]} ${
              revision.id === revisionId ? styles["selected"] : ""
            }`}
            key={revision.id}
            onClick={changeRevision(revision.id)}
          >
            {revision.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
