import { useEffect } from "react"

import { LoadingPlaceholder } from "./Placeholder"

import styles from "@/panels/ResourcesViewer/ResourcesPanel.module.css"
import type { UUID } from "@/store/UUID"
import { useGetStatuteSectionQuery } from "@/store/slices/api"

type StatuteSectionProps = {
  url: string
  selectedSection?: UUID
}

export function StatuteSection({ url, selectedSection }: StatuteSectionProps) {
  const { data, isError, isSuccess } = useGetStatuteSectionQuery(url)

  const selected = selectedSection ?? data?.selectedSectionUuid ?? undefined

  useEffect(() => {
    if (data?.sections?.length && selected) {
      const selectedSection = document.querySelector(
        `[data-section-uuid="${selected}"]`
      )
      selectedSection?.scrollIntoView()
    }
  }, [data?.sections?.length, selected])

  if (isError) throw new Error(`Error loading statute section: ${url}`)

  return isSuccess ? (
    <div>
      {data?.sections.map((section) => {
        return (
          <div
            key={section.uuid}
            data-section-uuid={section.uuid}
            className={
              section.uuid === selected
                ? styles["selectedStatuteSection"]
                : undefined
            }
          >
            <h2>
              {section.referer} {section.statuteAbbreviation}{" "}
              {section.title ? "–" : ""} {section.title}
            </h2>
            {section.body && (
              <div dangerouslySetInnerHTML={{ __html: section.body }} />
            )}
          </div>
        )
      })}
    </div>
  ) : (
    <LoadingPlaceholder />
  )
}
