import { EditorState } from "prosemirror-state"
import { EditorView } from "prosemirror-view"

import { DocAttrStep } from "@/steps/DocAttrStep"

export function setMarginNumbers(startingNumber?: number) {
  return function (state: EditorState, dispatch?: EditorView["dispatch"]) {
    const { tr } = state
    let marginNumberIndex = startingNumber ?? 1

    tr.doc.forEach((node, pos) => {
      const hasMarginNumberSpec = !!node.type.spec.attrs?.marginNumber
      const isEmpty = node.type.name === "paragraph" && node.childCount === 0
      const isBlockEnabled =
        tr.doc.attrs.marginNumbers?.[node.type.name] === true

      if (hasMarginNumberSpec && !isEmpty && isBlockEnabled) {
        tr.setNodeAttribute(pos, "marginNumber", `${marginNumberIndex}`)
        marginNumberIndex++
      }

      if (hasMarginNumberSpec && !isBlockEnabled) {
        tr.setNodeAttribute(pos, "marginNumber", null)
      }
    })

    if (dispatch) {
      dispatch(tr)
    }

    return true
  }
}

export function unsetMarginNumbers(
  state: EditorState,
  dispatch?: EditorView["dispatch"]
) {
  const { tr } = state
  tr.doc.descendants((node, pos) => {
    const hasMarginNumberSpec = !!node.type.spec.attrs?.marginNumber

    if (hasMarginNumberSpec) {
      tr.setNodeAttribute(pos, "marginNumber", null)
    }
  })

  if (dispatch) {
    dispatch(tr)
  }

  return true
}

export function enableMarginNumbers(blocks: Record<string, boolean>) {
  return function (state: EditorState, dispatch?: EditorView["dispatch"]) {
    const { tr } = state

    tr.step(
      new DocAttrStep("marginNumbers", {
        ...(state.doc.attrs.marginNumbers ?? {}),
        ...blocks,
      })
    )

    if (dispatch) {
      dispatch(tr)
    }

    return true
  }
}

export function disableMarginNumbers(
  state: EditorState,
  dispatch?: EditorView["dispatch"]
) {
  const { tr } = state
  tr.step(new DocAttrStep("marginNumbers", null))

  if (dispatch) {
    dispatch(tr)
  }

  return true
}
