import { BlockQuoteSection } from "./BlockQuoteSection"
import { BoxedSection } from "./BoxedSection"
import { CallOutSection } from "./CallOutSection"
import { DefinitionContentSection } from "./DefinitionContentSection.tsx"
import { DetailsSection } from "./DetailsSection"
import { FoldableSection } from "./FoldableSection"
import { HeadingSection } from "./HeadingSection"
import { ImageSection } from "./ImageSection"
import { ListSection } from "./ListSection"
import { ReviewSchemaSection } from "./ReviewSchemaSection.tsx"

import { DefinitionMenu } from "@/components/editor/toolbar/menus/DefinitionMenu.tsx"
import { ParagraphMenu } from "@/components/editor/toolbar/menus/ParagraphMenu"
import { QuizMenu } from "@/components/editor/toolbar/menus/QuizMenu"
import { TableMenu } from "@/components/editor/toolbar/menus/TableMenu"

export function ContextualSection() {
  return (
    <>
      <ParagraphMenu />
      <ListSection />
      <HeadingSection />

      <CallOutSection />
      <BlockQuoteSection />
      <DetailsSection />
      <BoxedSection />

      <TableMenu />
      <QuizMenu />
      <ReviewSchemaSection />
      <ImageSection />
      <FoldableSection />
      <DefinitionContentSection />
      <DefinitionMenu />
    </>
  )
}
