import type { Schema } from "prosemirror-model"
import type { EditorState } from "prosemirror-state"
import { TextSelection } from "prosemirror-state"
import { insertPoint } from "prosemirror-transform"
import type { EditorView } from "prosemirror-view"

export const createReviewItem = (
  schema: Schema<
    "review_item_title" | "review_item_content" | "paragraph" | "review_item"
  >
) => {
  const reviewTitle = schema.nodes.review_item_title.create(undefined)

  const reviewContent = schema.nodes.review_item_content.create(
    undefined,
    schema.nodes.paragraph.create(undefined)
  )
  return schema.nodes.review_item.create(undefined, [
    reviewTitle,
    reviewContent,
  ])
}

export function insertReviewSchema(
  state: EditorState,
  dispatch?: EditorView["dispatch"]
) {
  if (
    !(
      state.schema.nodes["review_schema"] &&
      state.schema.nodes["review_schema_title"] &&
      state.schema.nodes["review_schema_description"] &&
      state.schema.nodes["review_item_title"] &&
      state.schema.nodes["review_item_content"] &&
      state.schema.nodes["review_item"] &&
      state.schema.nodes["paragraph"]
    )
  ) {
    return false
  }

  const { tr } = state

  const validInsertPoint = insertPoint(
    state.doc,
    state.selection.from,
    state.schema.nodes["review_schema"]
  )

  if (validInsertPoint === null) return false

  if (dispatch) {
    const reviewSchema = state.schema.nodes["review_schema"].create({}, [
      state.schema.nodes["review_schema_title"].create(),
      state.schema.nodes["review_schema_description"].create(
        {},
        state.schema.nodes["paragraph"].create()
      ),
      createReviewItem(state.schema),
      createReviewItem(state.schema),
      createReviewItem(state.schema),
    ])
    tr.insert(validInsertPoint, reviewSchema)
    tr.setSelection(new TextSelection(tr.doc.resolve(validInsertPoint + 1)))
    dispatch(tr)
  }
  return true
}

/**
 * Return a command to add a new review item below the one the selection is currently in.
 */
export function addReviewItemBelow() {
  return function (state: EditorState, dispatch?: EditorView["dispatch"]) {
    if (!state.schema.nodes["review_item"]) {
      return false
    }

    const validInsertPoint = insertPoint(
      state.doc,
      state.selection.$to.end(state.selection.$to.depth - 1),
      state.schema.nodes["review_item"]
    )

    if (validInsertPoint === null) return false

    if (dispatch) {
      const { tr } = state
      tr.insert(validInsertPoint, createReviewItem(state.schema))
      tr.setSelection(new TextSelection(tr.doc.resolve(validInsertPoint + 1)))
      dispatch(tr)
    }
    return true
  }
}

/**
 * Return a command to delete the review item the selection is currently in.
 */
export function removeReviewItem() {
  return function (state: EditorState, dispatch?: EditorView["dispatch"]) {
    if (!state.schema.nodes["review_item"]) {
      return false
    }

    const { $from, $to } = state.selection

    for (let i = $from.depth; i > 0; i--) {
      const node = $from.node(i)
      if (node.type === state.schema.nodes["review_item"]) {
        if (dispatch) {
          // If there is only one review item, we delete the whole review schema
          // Otherwise, we delete the review item
          const parent = $from.node(i - 1)
          if (parent.childCount === 1) {
            dispatch(state.tr.delete($from.start(i - 1) - 1, $to.end(i) + 1))
          } else {
            dispatch(state.tr.delete($from.start(i) - 1, $to.end(i) + 1))
          }
        }
        return true
      }
    }
    return false
  }
}

/**
 * Return a command to set the review schema list style to the given value.
 */
export function setReviewSchemaListStyle(listStyle: string) {
  return function (state: EditorState, dispatch?: EditorView["dispatch"]) {
    if (!state.schema.nodes["review_schema"]) {
      return false
    }

    const { $from } = state.selection

    // const node = $from.node(1)
    // if (node.type !== dskrptSchema.nodes.review_schema) return false

    // if (dispatch) {
    //   dispatch(
    //     state.tr.setNodeMarkup($from.before(1), undefined, {
    //       ...node.attrs,
    //       listStyle,
    //     })
    //   )
    // }
    // return true

    for (let i = $from.depth; i > 0; i--) {
      const node = $from.node(i)
      if (node.type === state.schema.nodes["review_schema"]) {
        if (dispatch) {
          dispatch(
            state.tr.setNodeMarkup($from.before(i), undefined, {
              ...node.attrs,
              listStyle,
            })
          )
        }
        return true
      }
    }
    return false
  }
}

export function selectParentReviewSchema(
  state: EditorState,
  dispatch?: EditorView["dispatch"]
) {
  const { $from } = state.selection
  const blockNode = $from.node(1)

  if (!blockNode || blockNode.type !== state.schema.nodes["review_schema"]) {
    return false
  }

  if (dispatch) {
    let gap = 2
    const position = $from.start() - gap
    const nodeAtPosition = $from.doc.resolve(position).nodeBefore
    // If there is no node after the end of the current node, we need to go up one level
    if (!nodeAtPosition) {
      gap = 5
    } else if (
      nodeAtPosition.type === state.schema.nodes["review_item_title"]
    ) {
      gap = 3
    } else if (nodeAtPosition.type === state.schema.nodes["review_item"]) {
      gap = 7
    }

    dispatch(
      state.tr.setSelection(
        TextSelection.create(state.doc, $from.start() - gap)
      )
    )
  }
  return true
}

export function selectNextReviewItem(
  state: EditorState,
  dispatch?: EditorView["dispatch"]
) {
  const { $from } = state.selection
  const blockNode = $from.node(1)

  if (!blockNode || blockNode.type !== state.schema.nodes["review_schema"]) {
    return false
  }

  if (dispatch) {
    let gap = 3
    const position = $from.end() + gap
    const nodeAtPosition = $from.doc.resolve(position).nodeAfter
    // If there is no node after the end of the current node, we need to go up one level
    if (!nodeAtPosition) {
      gap = 7
    } else if (nodeAtPosition.type === state.schema.nodes["review_item"]) {
      gap = 5
    }

    dispatch(
      state.tr.setSelection(TextSelection.create(state.doc, $from.end() + gap))
    )
  }
  return true
}
