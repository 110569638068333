import { generateGuid } from "@/components/editor/plugins/guid.ts"
import type { UUID } from "@/store/UUID.ts"
import type {
  PanelDocumentUrlState,
  PanelState,
  PanelsState,
} from "@/store/slices/panels.ts"
import { PanelType } from "@/store/slices/panels.ts"

function isValidPanelType(panelType: string): panelType is PanelType {
  return Object.values(PanelType).includes(panelType as PanelType)
}

/**
 * Function to retrieve documentId and revisionId from DOM
 * as well as how the document should be displayed:
 * view-mode, edit-mode, or resource.
 */
export function getInitialStateFromDOM(): PanelsState["stack"] {
  const el = document.querySelector("#app") as HTMLElement
  const scrollToBlock = window.location.hash.slice(1) as UUID | undefined
  const url = new URL(window.location.href)
  const scrollToHighlight = url.searchParams.get("highlight")

  const panels: PanelState[] = []
  for (const datasetKey of ["primaryPanel", "secondaryPanel"]) {
    const panelType = el.dataset[`${datasetKey}Type`] as PanelType | undefined
    const documentId = el.dataset[`${datasetKey}DocumentUuid`] as
      | UUID
      | undefined

    if (!panelType || !documentId || !isValidPanelType(panelType)) continue

    if (
      panelType === PanelType.COURT_DECISION ||
      panelType === PanelType.STATUTE_SECTION ||
      panelType === PanelType.PDF_VIEWER
    ) {
      const panel: PanelDocumentUrlState = {
        panelId: generateGuid(),
        panelType: panelType,
        documentUrl: el.dataset[`${datasetKey}DocumentUrl`] || "",
        documentTitle: el.dataset[`${datasetKey}DocumentTitle`] || "",
        tableOfContents: false,
      }
      panels.push(panel)
    } else {
      const panel: PanelState = {
        panelId: generateGuid(),
        panelType: panelType,
        tableOfContents: false,
        documentId: documentId,
        revisionId:
          (el.dataset["primaryPanelRevisionUuid"] as UUID) || undefined,
        scrollToBlock: scrollToBlock,
        scrollToHighlight: scrollToHighlight as UUID | undefined,
      }
      panels.push(panel)
    }
  }

  return panels
}
