import {
  type NodeViewComponentProps,
  useEditorEffect,
} from "@nytimes/react-prosemirror"
import { updateColumnsOnResize } from "prosemirror-tables"
import { forwardRef, useRef } from "react"

const CELL_MIN_WIDTH = 25

export const Table = forwardRef<HTMLDivElement, NodeViewComponentProps>(
  function Table({ children, nodeProps, ...props }, ref) {
    const { node } = nodeProps

    const colGroup = useRef<HTMLTableColElement | null>(null)
    const table = useRef<HTMLTableElement | null>(null)

    useEditorEffect(() => {
      if (colGroup.current && table.current) {
        // Used as in https://github.com/ProseMirror/prosemirror-tables/blob/master/src/tableview.ts
        updateColumnsOnResize(
          node,
          colGroup.current,
          table.current,
          CELL_MIN_WIDTH
        )
      }
    }, [node])

    return (
      <div
        {...props}
        ref={ref}
        className="tableWrapper"
        data-guid={node.attrs.guid}
        data-margin-number={node.attrs.marginNumber}
        data-type="table"
      >
        <table ref={table}>
          <colgroup ref={colGroup} />
          <tbody>{children}</tbody>
        </table>
      </div>
    )
  }
)
