import { Button } from "@ariakit/react"
import { useEditorState } from "@nytimes/react-prosemirror"
import { getVersion } from "prosemirror-collab"
import { useContext } from "react"
import { v4 as uuidv4 } from "uuid"

import sharedStyles from "@/components/shared.module.css"
import { RevisionContext } from "@/contexts/RevisionContext"
import { useTextSelectionMenuContext } from "@/hooks/useTextSelectionMenu"
import type { UUID } from "@/store/UUID"
import { useCreateHighlightMutation } from "@/store/slices/api"
type Props = {
  color: string
  colorName: string
}

export function CreateHighlightButton({ color, colorName }: Props) {
  const menu = useTextSelectionMenuContext()
  const editorState = useEditorState()
  const { revisionId, documentId: chapterId } = useContext(RevisionContext)
  const [createHighlight, { isLoading }] = useCreateHighlightMutation()

  if (!editorState || !menu) return null

  return (
    <Button
      className={sharedStyles["contextMenuButton"]}
      contentEditable={false}
      disabled={isLoading}
      onClick={() => {
        createHighlight({
          chapterId,
          revisionId,
          version: getVersion(editorState),
          highlight: {
            id: uuidv4() as UUID,
            from: editorState.selection.from,
            to: editorState.selection.to,
            color,
            revisionId,
          },
        })
        menu.hide()
      }}
    >
      <ButtonColorIndicator color={color} />
      {colorName}
    </Button>
  )
}

export function ButtonColorIndicator({ color }: { color: string }) {
  const className = `${sharedStyles["buttonColorIndicator"]} ${
    sharedStyles[color as keyof typeof sharedStyles]
  }`
  return <div className={className} />
}
