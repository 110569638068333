import { useEditorState } from "@nytimes/react-prosemirror"
import { getVersion } from "prosemirror-collab"
import { useEffect, useMemo, useState } from "react"

import { useLongPollClient } from "@/communication/polling/useLongPollClient"
import type { UUID } from "@/store/UUID"
import { debounce } from "@/utils/debounce"

enum SyncStatus {
  SAVED = "saved",
  SAVING = "saving",
  ERROR = "error",
}

export function useEditorSyncStatus({
  documentType,
  documentId,
}: {
  documentType: "chapter" | "flashcard" | "note"
  documentId: UUID
}) {
  const [_, connection] = useLongPollClient(documentType, documentId)
  const isReconnecting = connection?.isReconnecting()

  const lastServerVersion = connection?.getLastServerVersion()
  const lastClientVersion = useLastClientVersion()

  const [status, setStatus] = useState<SyncStatus>(SyncStatus.SAVED)
  const debouncedSetStatus = useMemo(() => debounce(setStatus, 1000), [])

  useEffect(() => {
    if (!lastServerVersion || !lastClientVersion) return

    // While the saving status should be shown immediately to the user,
    // the saved status should only be shown after 1sec of no changes.
    // Thus, the user has a chance to see the changing saving status and
    // have a visual confirmation that the changes have been saved.
    if (lastServerVersion !== lastClientVersion) {
      setStatus(SyncStatus.SAVING)
    }
    if (lastServerVersion === lastClientVersion) {
      debouncedSetStatus(SyncStatus.SAVED)
    }
  }, [debouncedSetStatus, lastServerVersion, lastClientVersion])

  useEffect(() => {
    // If the connection is reconnecting, we show an error message.
    // After the max reconnect attempts, a dialog is shown to the user
    // that the connection was lost and the user has to reload the page.
    if (isReconnecting) setStatus(SyncStatus.ERROR)
  }, [isReconnecting])

  return status
}

function useLastClientVersion() {
  const [lastClientVersion, setLastClientVersion] = useState<number>()
  const state = useEditorState()

  useEffect(() => {
    if (state) setLastClientVersion(getVersion(state))
  }, [state])

  return lastClientVersion
}
