import { ToolbarItem } from "@ariakit/react"
import { useContext } from "react"

import styles from "@/components/editor/toolbar/toolbar.module.css"
import { ThreadClosed } from "@/components/icons.tsx"
import { RevisionContext } from "@/contexts/RevisionContext.ts"
import { useAppDispatch } from "@/store/hooks.ts"
import { toggleThreadsVisibility } from "@/store/store.ts"

export function ToggleThreadsItem() {
  const dispatch = useAppDispatch()
  const { revisionId } = useContext(RevisionContext)

  return (
    <ToolbarItem
      className={styles["editorToolbarItem"]}
      onClick={() => dispatch(toggleThreadsVisibility({ revisionId }))}
    >
      <ThreadClosed />
    </ToolbarItem>
  )
}
