import type { UUID } from "@/store/UUID.ts"
import { enqueueSnackbar } from "@/store/reducers/snackbars.ts"
import { apiSlice } from "@/store/slices/api.ts"
import { createAppThunk } from "@/store/store"

export const notebookDeleteClicked = createAppThunk(
  "notebook/deleteNotebook",
  ({ notebookId }: { notebookId: UUID }, { dispatch }) => {
    dispatch(apiSlice.endpoints.deleteNotebook.initiate({ notebookId })).then(
      () => {
        window.location.href = "/"
      },
      () => {
        enqueueSnackbar({
          fluentId: "notebook-delete-failed",
          message: "Error deleting notebook.",
          variant: "error",
        })
      }
    )
  }
)
