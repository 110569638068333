import { useEditorState } from "@nytimes/react-prosemirror"

import { deleteBlockNode } from "@/commands/deleteBlockNode.ts"
import { addAnswerToQuiz } from "@/commands/quizzes"
import { isNodeTypeSelected } from "@/commands/selection.ts"
import { CommandItem } from "@/components/editor/toolbar/items/CommandItem"
import { Plus, Trash } from "@/components/icons.tsx"
import { chapterSchema } from "@/schemas/chapter/schema"

export function QuizMenu() {
  const editorState = useEditorState()
  if (!isNodeTypeSelected(editorState, editorState?.schema.nodes["quiz"])) {
    return null
  }

  return (
    <>
      <CommandItem command={deleteBlockNode(chapterSchema.nodes.quiz)}>
        <Trash />
      </CommandItem>
      <CommandItem command={addAnswerToQuiz()}>
        <Plus />
      </CommandItem>
    </>
  )
}
