import { Button } from "@ariakit/react"
import { useLocalization } from "@fluent/react"

import { Flashcard, MagicWand } from "@/components/icons.tsx"
import sharedStyles from "@/components/shared.module.css"
import type { UUID } from "@/store/UUID"
import { useAppDispatch, useAppSelector } from "@/store/hooks"
import { flashcardToggled } from "@/store/thunks/flashcardToggled"
import type { Highlight } from "@/types/api"

type Props = {
  highlight: Highlight
  chapterId: UUID
  revisionId: UUID
  magic?: boolean
  onClick: () => void
}

export function AddFlashcardButton({
  highlight,
  chapterId,
  revisionId,
  magic = false,
  onClick,
}: Props) {
  const flashcardState = useAppSelector(
    (state) => state.flashcards[revisionId]?.[highlight.id]
  )
  const dispatch = useAppDispatch()
  const { l10n } = useLocalization()

  if (magic && flashcardState) return null

  // Create the button label and icon based on the state of the flashcard
  let label: string
  let icon: JSX.Element
  if (!flashcardState) {
    label = magic
      ? l10n.getString("flashcard-create-magic")
      : l10n.getString("flashcard-create")
    icon = magic ? <MagicWand /> : <Flashcard />
  } else {
    label = flashcardState.isOpen
      ? l10n.getString("flashcard-hide")
      : l10n.getString("flashcard-show")
    icon = <Flashcard />
  }

  // TODO: should we find a way to show a loading state while creating the flashcard?
  return (
    <Button
      className={sharedStyles["contextMenuButton"]}
      onClick={async () => {
        await dispatch(
          flashcardToggled({
            highlightId: highlight.id,
            chapterId,
            revisionId,
            magic,
          })
        )
        onClick()
      }}
    >
      {icon}
      {label}
    </Button>
  )
}
