import { Popover, usePopoverStore } from "@ariakit/react"
import {
  type NodeViewComponentProps,
  useEditorEffect,
} from "@nytimes/react-prosemirror"
import {
  type MouseEvent,
  forwardRef,
  useCallback,
  useRef,
  useState,
} from "react"

import { EditableTitle } from "./EditableTitle"
import styles from "./pdf.module.css"

import { generateGuid } from "@/components/editor/plugins/guid.ts"
import { ArrowUpRight, Document, Pencil } from "@/components/icons.tsx"
import { useFocusView } from "@/hooks/useFocusView"
import { useLocalizedString } from "@/hooks/useLocalizedString"
import { useAppDispatch } from "@/store/hooks"
import { PanelType } from "@/store/slices/panels"
import { panelOpened } from "@/store/thunks/panelOpened.ts"

export const PDF = forwardRef<HTMLDivElement, NodeViewComponentProps>(
  function PDF({ children: _, nodeProps, ...props }, ref) {
    const { node, pos, isSelected } = nodeProps

    const dispatch = useAppDispatch()
    const popover = usePopoverStore({ placement: "top-start" })

    const [isEditable, setIsEditable] = useState(false)
    const titleButtonRef = useRef<HTMLButtonElement | null>(null)
    const openLabel = useLocalizedString({ id: "view-pdf", fallback: "Open" })
    const editLabel = useLocalizedString({
      id: "update-pdf-title",
      fallback: "Update PDF title",
    })
    const focusView = useFocusView()

    useEditorEffect((view) => {
      setIsEditable(!!view.editable)
    })

    const onOpen = useCallback(
      (event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault()
        event.stopPropagation()

        const target = event.target as HTMLElement
        const element = target.closest(
          'div[role="button"][data-href]'
        ) as HTMLElement

        const href = element?.dataset.href
        if (!href) return

        dispatch(
          panelOpened({
            panel: {
              panelId: generateGuid(),
              panelType: PanelType.PDF_VIEWER,
              documentUrl: href,
              documentTitle: node.attrs.title,
            },
          })
        )
      },
      [dispatch, node.attrs.title]
    )

    const onExit = useCallback(() => {
      popover.setOpen(false)
      focusView()
    }, [focusView, popover])

    return (
      <div
        ref={ref}
        {...props}
        role="button"
        contentEditable={false}
        aria-label={openLabel}
        onClick={onOpen}
        data-href={node.attrs.src}
        data-guid={node.attrs.guid}
        data-margin-number={node.attrs.marginNumber}
        data-selected={isSelected}
        data-type="pdf"
      >
        <div className={styles.pdfBookmark}>
          <div className={styles.details}>
            <div className={styles.iconWrapper}>
              <Document />
            </div>
            <button
              ref={titleButtonRef}
              aria-label={editLabel}
              // We don't use the `disabled` attribute to allow
              // button clicks to permeate to the parent element
              // (necessary for the Viewer)
              data-disabled={!isEditable}
              aria-disabled={!isEditable}
              tabIndex={isEditable ? 0 : -1}
              onClick={(e) => {
                if (isEditable) {
                  e.preventDefault()
                  e.stopPropagation()
                  popover.setOpen(true)
                }
              }}
            >
              <div className={styles.title}>{node.attrs.title}</div>
              <div className={styles.edit}>
                <Pencil />
              </div>
            </button>
            <Popover
              portal
              store={popover}
              getAnchorRect={() => {
                if (!titleButtonRef.current) return null
                const rect = titleButtonRef.current.getBoundingClientRect()
                // Manually position the popover above the title button.
                // See: https://github.com/dskrpt/dskrpt_app/pull/701
                rect.y = rect.y + rect.height + 3
                return rect
              }}
            >
              <EditableTitle
                pos={pos}
                initialValue={node.attrs.title}
                width={titleButtonRef.current?.getBoundingClientRect().width}
                onExit={onExit}
              />
            </Popover>
          </div>
          <div className={styles.actions}>
            <button className={styles.openPdf}>
              {openLabel} <ArrowUpRight strokeWidth={2} />
            </button>
          </div>
        </div>
      </div>
    )
  }
)
