import { ToolbarItem } from "@ariakit/react"
import type { MouseEvent, ReactNode } from "react"

import styles from "@/components/editor/toolbar/toolbar.module.css"

type Props = {
  children: ReactNode
  disabled: boolean
  active?: boolean | undefined
  onClick: (event: MouseEvent) => void
}

export function EditorToolbarItem({
  children,
  disabled,
  active,
  onClick,
}: Props) {
  return (
    <ToolbarItem
      className={`${styles["editorToolbarItem"]} ${
        active ? styles["editorToolbarActive"] : ""
      } ${disabled ? styles["editorToolbarDisabled"] : ""}`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </ToolbarItem>
  )
}
