import { skipToken } from "@reduxjs/toolkit/query"
import { useContext, useLayoutEffect, useRef, useState } from "react"

import editorToolbarStyles from "@/components/editor/toolbar/toolbar.module.css"
import { RevisionContext } from "@/contexts/RevisionContext"
import { useGetChapterQuery, useGetNoteQuery } from "@/store/slices/api"

export function ChapterTitle({
  show,
  animate,
}: {
  show: boolean
  animate?: boolean
}) {
  const { documentId: chapterId, documentType } = useContext(RevisionContext)

  // This might appear odd, but it's a way to get the correct data
  // Since this component is used in both the editor and the note, we need to
  // select the correct query based on the document type.
  // Since hooks can't be conditionally called, we need to call both queries
  // but pass the skipToken to the one that's not being used depending on the
  // document type.
  const chapterData = useGetChapterQuery(
    documentType === "chapter" ? chapterId : skipToken
  )
  const noteData = useGetNoteQuery(
    documentType === "note" ? chapterId : skipToken
  )

  // We can then select the correct returned data based on the document type.
  const {
    data: chapter,
    isSuccess,
    isLoading,
  } = documentType === "note" ? noteData : chapterData

  const ref = useRef<HTMLSpanElement>(null)
  const [width, setWidth] = useState(0)

  useLayoutEffect(() => {
    if (ref.current) setWidth(ref.current.getBoundingClientRect().width)
  }, [show])

  if (!isSuccess || isLoading) return null

  return (
    <>
      <span
        style={
          animate !== false
            ? {
                width: show ? `${width}px` : 0,
                opacity: show ? 1 : 0,
                visibility: show ? "visible" : "hidden",
                padding: show ? undefined : 0,
                margin: show ? undefined : 0,
              }
            : undefined
        }
        className={editorToolbarStyles.editorToolbarChapterTitle}
      >
        {chapter.title}
      </span>
      {/**
       * This element is not rendered to the screen. It's only being
       * used to calculate the width of the title. Needed to animate the width.
       */}
      <span
        ref={ref}
        aria-hidden="true"
        className={editorToolbarStyles.editorToolbarChapterTitle}
        style={{ visibility: "hidden", position: "absolute" }}
      >
        {chapter.title}
      </span>
    </>
  )
}
