import { ToolbarItem } from "@ariakit/react"
import { useEditorState } from "@nytimes/react-prosemirror"
import { useCallback } from "react"

import { toggleAllFootnotes } from "@/commands/footnotes.ts"
import styles from "@/components/editor/toolbar/toolbar.module.css"
import { Footnote } from "@/components/icons.tsx"

export function ToggleFootnotesItem() {
  const state = useEditorState()
  const handleOnClick = useCallback(
    () => state && toggleAllFootnotes(state),
    [state]
  )

  if (!state) return null

  return (
    <ToolbarItem
      className={styles["editorToolbarItem"]}
      onClick={handleOnClick}
    >
      <Footnote />
    </ToolbarItem>
  )
}
