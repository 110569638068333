import { MenuItem } from "@ariakit/react"
import type { MouseEvent, ReactNode } from "react"

import toolbarStyles from "@/components/editor/toolbar/toolbar.module.css"

type Props = {
  children: ReactNode
  disabled: boolean
  onClick: (event: MouseEvent) => void
}

export function EditorMenuItem({ children, disabled, onClick }: Props) {
  return (
    <MenuItem
      className={`${toolbarStyles["editorToolbarDropdownItem"]} ${
        disabled ? toolbarStyles["editorToolbarDisabled"] : ""
      }`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </MenuItem>
  )
}
