import { useContext, useEffect, useState } from "react"

import { PanelContext } from "@/contexts/PanelContext"
import { useMediaQuery } from "@/hooks/useMediaQuery"

/**
 * This hooks acts as an interface to control
 * the aside drawer that is used on mobile.
 * Returns undefined on desktop.
 */

export function useMobileAsideDrawer() {
  const [panelOutlet, setPanelOutlet] = useState<HTMLElement | null>(null)
  const [isOpen, setIsOpen] = useState(false)

  const isMobile = useMediaQuery("(max-width: 768px)")
  const panel = useContext(PanelContext)

  useEffect(() => {
    if (!isMobile || !panel) return

    const element = document.getElementById(`${panel.panelId}-panel-outlet`)
    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((record) => {
        if (
          record.target instanceof HTMLElement &&
          record.type === "attributes" &&
          record.attributeName === "data-drawer-state"
        ) {
          setIsOpen(record.target.dataset.drawerState === "open")
        }
      })
    })

    if (element instanceof HTMLElement) {
      setPanelOutlet(element)
      mutationObserver.observe(element, {
        attributes: true,
      })
    } else {
      mutationObserver.disconnect()
      setPanelOutlet(null)
      setIsOpen(false)
    }

    return () => {
      mutationObserver.disconnect()
      setPanelOutlet(null)
      setIsOpen(false)
    }
  }, [isMobile, panel])

  if (!panelOutlet) return null

  return {
    isOpen,
    open: () => {
      panelOutlet.dataset.drawerState = "open"
    },
    close: () => {
      panelOutlet.dataset.drawerState = "closed"
    },
  }
}
