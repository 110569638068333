import { useEditorEffect } from "@nytimes/react-prosemirror"
import { NodeSelection } from "prosemirror-state"
import type { EditorView } from "prosemirror-view"
import { useCallback, useContext } from "react"

import { PanelContext } from "@/contexts/PanelContext.ts"
import { RevisionContext } from "@/contexts/RevisionContext.ts"
import { useAppDispatch } from "@/store/hooks"
import { apiSlice } from "@/store/slices/api.ts"
import { panelsSlice } from "@/store/slices/panels.ts"
import { store, threadOpened } from "@/store/store"

export function ScrollToPlugin() {
  const panel = useContext(PanelContext)
  const revision = useContext(RevisionContext)
  const blockId = panel?.scrollToBlock
  const highlightId = panel?.scrollToHighlight
  const dispatch = useAppDispatch()

  const resetScrollToBlockState = useCallback(() => {
    if (panel)
      dispatch(
        panelsSlice.actions.scrollToBlockUnset({ panelId: panel.panelId })
      )
  }, [dispatch, panel])

  const scrollToBlock = useCallback(
    ({ view, pos }: { view: EditorView; pos: number }) => {
      const selection = new NodeSelection(view.state.doc.resolve(pos))
      const tr = view.state.tr.setSelection(selection).scrollIntoView()
      view.dispatch(tr)

      // Reset the scrollToBlock state so that you can scroll to the same block again
      setTimeout(resetScrollToBlockState, 1000)
    },
    [resetScrollToBlockState]
  )

  useEditorEffect(
    (view) => {
      if (highlightId) {
        const { data: highlights } = apiSlice.endpoints.getHighlights.select({
          revisionId: revision?.revisionId,
          chapterId: revision?.documentId,
        })(store.getState())
        const highlight = highlights?.find((h) => h.id === highlightId)
        const pos = highlight?.from
        if (!pos) return
        dispatch(
          threadOpened({
            revisionId: highlight.revisionId,
            highlightId: highlight.id,
          })
        )
        return scrollToBlock({ view, pos })
      }

      if (!blockId) return

      view.state.doc.descendants((node, pos) => {
        if (!node.attrs.guid || node.attrs.guid !== blockId) return true

        // Workaround to fix scrollIntoView if there are two viewers opened side-by-side.
        // Since ProseMirror detects the scrollable container by the current dom selection,
        // leaving the selection in place might scroll the wrong view container (e.g. clicking
        // on a link that opens a new viewer, scrolls the current viewer instead of the new one)
        // https://github.com/ProseMirror/prosemirror-view/blob/master/src/index.ts#L234
        document.getSelection()?.removeAllRanges()

        scrollToBlock({ view, pos })
        return false
      })
    },
    [blockId]
  )

  return null
}
