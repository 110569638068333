import { LitElement, css, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { until } from "lit/directives/until.js";

type SearchResult = {
  type: "user";
  id: number
  username: string;
  first_name: string;
  last_name: string;
};
type EmailResult = {
  type: "email";
  email: string;
}
type PendingResult = {
  type: "pending";
  email: string;
  promise: Promise<SearchResult[]>;
}

@customElement("add-members")
export class AddMembersComponent extends LitElement {
  @property({ type: String }) inputText = "";
  @property({ type: Array }) searchResults: Array<SearchResult | EmailResult> = [];
  @property({ type: Array }) addedUsers: Array<SearchResult | EmailResult | PendingResult> = [];

  static override styles = css`
    :host {
      display: block;
      border-radius: 0 0 0 5px;
      font-family: dskrptSans, sans-serif;
      font-size: var(--fontSizeBase);
    }

    div.container {
      display: flex;
      gap: 0.375rem;
      width: 100%;
      min-height: 3rem;
      flex-wrap: wrap;
      position: relative;
      align-items: start;
      padding: 0.5rem;
      box-sizing: border-box;
    }

    input {
      flex: 1;
      display: block;
      width: calc(100% - 2rem);
      border: none;
      padding: 0.25rem;
      font-family: dskrptSans, sans-serif;
      font-size: var(--fontSizeBase);
    }

    input:focus {
      outline: none;
    }

    div.entry {
      background-color: var(--colorGray100);
      display: flex;
      align-items: center;
      gap: 0.25rem;
      padding: 0.25rem;
      border-radius: 5px;
    }

    div.remove {
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    div.remove:hover {
      color: var(--colorBrandColor);
    }

    div.action {
      display: flex;
      align-items: center;
      aspect-ratio: 1/1;
    }

    svg {
      width: 1rem;
      height: 1rem;
    }

    ul.search-results {
      list-style: none;
      padding: 0.5rem;
      margin: 0;
      position: absolute;
      background-color: white;
      border: 1px solid #ccc;
      border-radius: 5px;
    }

    ul.search-results li {
      padding: 0.25rem;
      cursor: pointer;
    }
  `;
  emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  private async search(query: string): Promise<SearchResult[]> {
    const response = await fetch(
      `/api/viewer/typeahead/?query=${encodeURIComponent(query)}`
    );
    return await response.json();
  }

  private async checkEmail(email: string): Promise<SearchResult[]> {
    const response = await fetch(
      `/api/viewer/typeahead/?email=${encodeURIComponent(email)}`
    );
    return await response.json();
  }

  get value() {
    return this.addedUsers;
  }

  private onInput(e: InputEvent) {
    const input = (e.target as HTMLInputElement).value.trim();
    // If there is an e-mail address in the input, check whether it exists
    // on the backend and add it to the list of added users if it does.
    // Otherwise, add it to the list of invited e-mail addresses.
    this.inputText = input;
    const emails = input.split(",");
    const cleanEmails = emails
      .filter((email) => this.emailRegex.test(email))
      .map((email) => email.trim());
    if (emails.length > 1) {
      this.addedUsers = [
        ...this.addedUsers,
        ...cleanEmails.map((email): PendingResult => {
          return {
            email: email,
            type: "pending",
            promise: this.checkEmail(email)
          };
        })
      ];
      cleanEmails.forEach((email) => {
        this.inputText = this.inputText.replace(email + ",", "");
      });
    }

    if (this.emailRegex.test(input)) {
      this.checkEmail(input).then((result) => {
        if (result.length > 0) {
          this.searchResults = result;
        } else {
          this.searchResults = [
            {
              type: "email",
              email: input
            }
          ];
        }
      });
    } else if (input.length > 1) {
      this.search(input).then((results) => {
        this.searchResults = results;
      });
    } else {
      this.searchResults = [];
    }
  }

  private onResultClick(result: SearchResult | EmailResult) {
    this.addedUsers = [...this.addedUsers, result];
    this.searchResults = [];
    this.inputText = "";
    this.requestUpdate();
  }

  private onKeyDown(e: KeyboardEvent) {
    if (e.key === "Backspace" && this.inputText === "") {
      this.addedUsers = this.addedUsers.slice(0, -1);
    }
  }

  private removeEntry(entry: SearchResult | EmailResult | PendingResult) {
    if (entry.type === "email" || entry.type === "pending") {
      this.addedUsers = this.addedUsers.filter(
        (e) => (e as EmailResult).email !== entry.email
      );
    } else {
      this.addedUsers = this.addedUsers.filter(
        (e) => (e as SearchResult).id !== entry.id
      );
    }
  }

  renderSearchResults() {
    return html`
      <ul class="search-results">
        ${this.searchResults.map((result) => {
          if (result.type === "email") {
            return html`
              <li @click="${() => this.onResultClick(result)}">
                Invite ${result.email}
              </li>
            `;
          } else {
            return html`
              <li @click="${() => this.onResultClick(result)}">
                ${result.first_name} ${result.last_name}
              </li>
            `;
          }
        })}
      </ul>
    `;
  }

  renderEntry(entry: SearchResult | EmailResult | PendingResult) {
    let label;
    let action;

    if (entry.type === "pending") {
      entry.promise.then((results) => {
        this.addedUsers = this.addedUsers.filter(
          (e) => (e as PendingResult).email !== entry.email
        );
        if (results.length > 0) {
          this.addedUsers = [...this.addedUsers, ...results];
        } else {
          this.addedUsers = [...this.addedUsers, { ...entry, type: "email" }];
        }
      });
    }

    const inviteIcon = html`
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="w-5 h-5"
      >
        <path
          d="M3 4a2 2 0 00-2 2v1.161l8.441 4.221a1.25 1.25 0 001.118 0L19 7.162V6a2 2 0 00-2-2H3z"
        />
        <path
          d="M19 8.839l-7.77 3.885a2.75 2.75 0 01-2.46 0L1 8.839V14a2 2 0 002 2h14a2 2 0 002-2V8.839z"
        />
      </svg>`;
    const addIcon = html`
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="w-5 h-5"
      >
        <path
          d="M11 5a3 3 0 11-6 0 3 3 0 016 0zM2.615 16.428a1.224 1.224 0 01-.569-1.175 6.002 6.002 0 0111.908 0c.058.467-.172.92-.57 1.174A9.953 9.953 0 018 18a9.953 9.953 0 01-5.385-1.572zM16.25 5.75a.75.75 0 00-1.5 0v2h-2a.75.75 0 000 1.5h2v2a.75.75 0 001.5 0v-2h2a.75.75 0 000-1.5h-2v-2z"
        />
      </svg>`;
    const pendingIcon = html`
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="w-5 h-5"
      >
        <path
          fill-rule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-13a.75.75 0 00-1.5 0v5c0 .414.336.75.75.75h4a.75.75 0 000-1.5h-3.25V5z"
          clip-rule="evenodd"
        />
      </svg>
    `;

    if (entry.type === "pending") {
      label = until(
        entry.promise.then((result) =>
          result.length
            ? `${result[0]?.first_name} ${result[0]?.last_name}`
            : entry.email
        ),
        entry.email
      );
      action = until(
        entry.promise.then((result) => (result.length ? addIcon : inviteIcon)),
        pendingIcon
      );
    } else if (entry.type === "email") {
      label = entry.email;
      action = inviteIcon;
    } else {
      label = `${entry.first_name} ${entry.last_name}`;
      action = addIcon;
    }
    return html`
      <div class="entry">
        <div
          class="remove"
          @click="${() => {
            this.removeEntry(entry);
          }}"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
            />
          </svg>
        </div>
        <div class="label">${label}</div>
        <div class="action">${action}</div>
      </div>
    `;
  }

  override render() {
    return html`
      <div class="container">
        ${this.addedUsers.map((user) => this.renderEntry(user))}
        <input
          type="text"
          .value="${this.inputText}"
          @input="${this.onInput}"
          @keydown="${this.onKeyDown}"
          placeholder="Search user or enter email address"
        />
      </div>
      ${this.searchResults.length > 0 ? this.renderSearchResults() : null}
    `;
  }
}
