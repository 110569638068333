import styles from "@/panels/ResourcesViewer/ResourcesPanel.module.css"
import { validateMediaURL } from "@/utils/url"

type PDFViewerProps = {
  url: string
  title?: string | null
}

export function PDFViewer({ url, title }: PDFViewerProps) {
  const src = validateMediaURL(url)

  return (
    <div className={styles.pdfViewer}>
      {title && <h2>{title}</h2>}
      <iframe src={src} title={title ?? undefined} />
    </div>
  )
}
