import { useEditorState } from "@nytimes/react-prosemirror"

import { deleteBlockNode } from "@/commands/deleteBlockNode.ts"
import { isNodeTypeSelected } from "@/commands/selection.ts"
import { CommandItem } from "@/components/editor/toolbar/items/CommandItem"
import { Trash } from "@/components/icons.tsx"
import { chapterSchema } from "@/schemas/chapter/schema"

export function DefinitionMenu() {
  const editorState = useEditorState()
  if (
    !isNodeTypeSelected(editorState, editorState?.schema.nodes["definition"])
  ) {
    return null
  }

  return (
    <>
      <CommandItem command={deleteBlockNode(chapterSchema.nodes.definition)}>
        <Trash />
      </CommandItem>
    </>
  )
}
