import { reactKeys } from "@nytimes/react-prosemirror"
import { history } from "prosemirror-history"
import { EditorState } from "prosemirror-state"

import { messageSchema } from "./schema"

import type { Message } from "@/types/api"

/**
 * Creates a new editor state from a message.
 * Based on the message schema and message plugin configuration.
 */

export function createEditorState(message?: Message) {
  return EditorState.create({
    schema: messageSchema,
    plugins: [history(), reactKeys()],
    doc: message?.content
      ? messageSchema.nodeFromJSON(message.content)
      : messageSchema.nodes.doc.create(),
  })
}
