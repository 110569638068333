import { CreateHighlightButton } from "./CreateHighlightButton"

import sharedStyles from "@/components/shared.module.css"
import { useTextSelectionMenu } from "@/hooks/useTextSelectionMenu"

export function CreateHighlightPopover() {
  const TextSelectionMenu = useTextSelectionMenu({ placement: "bottom" })

  if (!TextSelectionMenu) return null

  return (
    <TextSelectionMenu.Root className={sharedStyles["contextMenu"]}>
      <div className={sharedStyles["contextMenuButtons"]}>
        <CreateHighlightButton color="yellow" colorName="Yellow" />
        <CreateHighlightButton color="green" colorName="Green" />
        <CreateHighlightButton color="pink" colorName="Pink" />
        <CreateHighlightButton color="blue" colorName="Blue" />
        <CreateHighlightButton color="violet" colorName="Violet" />
      </div>
    </TextSelectionMenu.Root>
  )
}
