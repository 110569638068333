import { clsx } from "clsx"
import { useContext, useEffect, useRef } from "react"
import { type ImperativePanelHandle, Panel } from "react-resizable-panels"

import panelStyles from "./panel.module.css"
import { getPanelApplication } from "./utils.tsx"

import { Cancel } from "@/components/icons.tsx"
import { PanelContext } from "@/contexts/PanelContext.ts"
import type { UUID } from "@/store/UUID.ts"
import { useAppDispatch, useAppSelector } from "@/store/hooks.ts"
import { type PanelState } from "@/store/slices/panels.ts"
import { panelClosed } from "@/store/thunks/panelClosed.ts"
import { panelSelected } from "@/store/thunks/panelSelected.ts"
import { IconButton } from "@/ui/IconButton"

export interface ResizeablePanelProps {
  id: string
  order: number
  panelState: PanelState
}

export function ResizeablePanel({
  id,
  order,
  panelState,
}: ResizeablePanelProps) {
  const panelRef = useRef<ImperativePanelHandle>(null)
  const outletPanelRef = useRef<HTMLDivElement>(null)

  const panelApplication = getPanelApplication(panelState)

  const isRootPanel = useAppSelector(
    (state) =>
      state.panels.stack[0] === panelState && state.panels.doublePanelLayout
  )

  const showTabBar = useAppSelector((state) => state.panels.stack.length > 1)

  // Listen to scroll events on xRef and update the panelState

  useEffect(() => {
    if (!outletPanelRef.current) return
    if (panelState.scrollPosition !== undefined) {
      outletPanelRef.current.scrollTop = panelState.scrollPosition
    }
  })

  return (
    <PanelContext.Provider value={panelState}>
      <Panel
        id={id}
        order={order}
        ref={panelRef}
        className={panelStyles["panelWrapper"]}
      >
        {showTabBar && <PanelTabBar isRootPanel={isRootPanel} />}
        <div className={panelStyles["panel"]}>
          <div
            id={`${panelState.panelId}-panel-toc`}
            className={panelStyles["panelTableOfContents"]}
            hidden={!panelState.tableOfContents}
          />
          <div
            id={`${panelState.panelId}-panel-outlet`}
            ref={outletPanelRef}
            className={`${panelStyles["panelOutlet"]} ${panelStyles["asideDrawer"]}`}
          >
            {panelApplication}
          </div>
        </div>
      </Panel>
    </PanelContext.Provider>
  )
}

export function PanelTabBar({
  isRootPanel = false,
}: {
  isRootPanel?: boolean
}) {
  const panelState = useContext(PanelContext)

  const dispatch = useAppDispatch()

  const tabDocuments = useAppSelector((state) => {
    const { stack, doublePanelLayout } = state.panels
    return doublePanelLayout && isRootPanel
      ? stack.slice(0, 1)
      : doublePanelLayout
      ? stack.slice(1)
      : stack
  })

  if (!panelState) return null

  const handleRemoveFromStack = (panelId: UUID) => {
    dispatch(panelClosed({ panelId }))
  }

  const handleSelectPanel = (panelId: UUID) => {
    dispatch(panelSelected({ panelId }))
  }

  return (
    <div className={panelStyles["panelTabs"]}>
      {tabDocuments.map(({ panelId, panelTitle }, index) => (
        <div
          onClick={() => handleSelectPanel(panelId)}
          className={clsx(
            panelStyles["panelTab"],
            panelId === panelState.panelId && panelStyles["active"]
          )}
          key={index}
        >
          <span className={panelStyles["panelTabLabel"]}>{panelTitle}</span>
          <IconButton
            icon={<Cancel />}
            label={"Close"}
            size={"small"}
            onClick={() => handleRemoveFromStack(panelId)}
          />
        </div>
      ))}
    </div>
  )
}
