import {
  Button,
  Dialog,
  DialogDescription,
  DialogHeading,
  useDialogStore,
} from "@ariakit/react"
import { Localized, useLocalization } from "@fluent/react"
import type {
  MutationActionCreatorResult,
  QueryActionCreatorResult,
} from "@reduxjs/toolkit/dist/query/core/buildInitiate"
import type {
  BaseQueryFn,
  MutationDefinition,
  QueryDefinition,
} from "@reduxjs/toolkit/query"
import clsx from "clsx"
import { enqueueSnackbar } from "notistack"
import { type ChangeEvent, useState } from "react"

import styles from "./Permissions.module.css"

import sharedStyles from "@/components/shared.module.css"
import type { UUID } from "@/store/UUID.ts"
import {
  type PermissionEntity,
  type PermissionEntitySearchResult,
  PermissionEntityType,
  PermissionType,
} from "@/types/api.ts"
import { sendErrorToSentry } from "@/utils/sentry.ts"

export type PermissionsAddUserDialogProps = {
  resourceId: UUID
  addUserDialog: ReturnType<typeof useDialogStore>
  searchEntities: (args: {
    resourceId: UUID
    query: string
  }) => QueryActionCreatorResult<
    QueryDefinition<
      {
        resourceId: UUID
        query: string
      },
      BaseQueryFn,
      string,
      PermissionEntitySearchResult
    >
  >
  addEntity: (args: {
    resourceId: UUID
    permission: PermissionType
    entityType: PermissionEntityType
    entity: number
  }) => MutationActionCreatorResult<
    MutationDefinition<PermissionEntity, BaseQueryFn, string, void>
  >
}

export function PermissionsAddUserDialog({
  resourceId,
  addUserDialog,
  searchEntities,
  addEntity,
}: PermissionsAddUserDialogProps) {
  const [searchResults, setSearchResults] =
    useState<PermissionEntitySearchResult>({
      institutions: [],
      usergroups: [],
      users: [],
    })

  const [searchInput, setSearchInput] = useState("")
  const { l10n } = useLocalization()

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value)
    searchEntities({ resourceId: resourceId, query: e.target.value })
      .unwrap()
      .then((results: PermissionEntitySearchResult) => {
        setSearchResults(results)
      })
  }

  const handleAddEntity = (
    entityId: number,
    entityType: PermissionEntityType,
    permission = PermissionType.VIEW
  ) => {
    addEntity({
      resourceId: resourceId,
      permission: permission,
      entityType: entityType,
      entity: entityId,
    })
      .unwrap()
      .catch((error) => {
        sendErrorToSentry("Error adding entity", error)
        enqueueSnackbar(error.data.detail, { variant: "error" })
      })
      .finally(() => addUserDialog.toggle())
  }

  return (
    <Dialog store={addUserDialog} className={sharedStyles.dialog} portal>
      <DialogHeading className={sharedStyles["dialogHeading"]}>
        <Localized id={"permissions-add-entities"}>
          Add User, Institutions or User Groups
        </Localized>
      </DialogHeading>
      <DialogDescription className={sharedStyles["dialogDescription"]}>
        <Localized id={"permissions-add-entities-description"}>
          You can search for users, institutions and user groups to grant them
          access to this deck. You can enter email addresses to invite new
          users.
        </Localized>
      </DialogDescription>
      <input
        placeholder={l10n.getString(
          "permissions-add-entities-search-placeholder",
          null,
          "Search for users, institutions and user groups"
        )}
        type={"search"}
        value={searchInput}
        onChange={handleInput}
        className={styles["entitiesSearchInput"]}
      />
      <ul className={styles["entitiesSearchResults"]}>
        {searchInput &&
          Object.keys(searchResults).map((category) => {
            const items =
              searchResults[category as keyof PermissionEntitySearchResult]
            if (items.length === 0) return null
            return (
              <li key={category} className={styles["entitiesListCategory"]}>
                <span className={styles["entitiesListCategoryLabel"]}>
                  <Localized id={category}>{category}</Localized>
                </span>
                <ul className={styles["entitiesListCategoryList"]}>
                  {items.map((item) => (
                    <li
                      key={item.id}
                      className={styles["entitiesListCategoryListEntry"]}
                    >
                      {item.name}
                      <Button
                        className={clsx(
                          sharedStyles["defaultButton"],
                          sharedStyles["secondaryButton"],
                          sharedStyles["microButton"]
                        )}
                        onClick={() =>
                          handleAddEntity(item.id, item.entityType)
                        }
                      >
                        <Localized id={"add"}>Add</Localized>
                      </Button>
                    </li>
                  ))}
                </ul>
              </li>
            )
          })}
      </ul>

      <div className={sharedStyles["dialogButtons"]}>
        <Button
          onClick={addUserDialog.toggle}
          className={clsx(
            sharedStyles["defaultButton"],
            sharedStyles["secondaryButton"]
          )}
        >
          <Localized id={"cancel"}>Cancel</Localized>
        </Button>
      </div>
    </Dialog>
  )
}
