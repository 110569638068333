import { useCallback } from "react"

import {
  SocketEvents,
  type SubscriptionCallback,
} from "@/communication/websocket/websocketClient"
import { type UUID } from "@/store/UUID.ts"
import { useAppDispatch } from "@/store/hooks"
import {
  addToMessagesCache,
  removeFromMessagesCache,
  updateInHighlightsCache,
  updateInMessagesCache,
} from "@/store/slices/api"
import { threadRemoved } from "@/store/store.ts"

/**
 * Hook to handle threads and messages related events over the websocket
 * by updating the application's state accordingly
 */
export function useRemoteMessages({ revisionId }: { revisionId?: UUID }) {
  const dispatch = useAppDispatch()

  return useCallback<SubscriptionCallback>(
    (payload) => {
      if (payload.type === SocketEvents.ThreadStarted) {
        // When a new thread is started, we need to update the highlight so that
        // it is marked as having an attached thread.
        if (!revisionId) return
        dispatch(
          updateInHighlightsCache(
            {
              chapter_id: payload.meta.chapter_id,
              revision_id: revisionId,
            },
            payload.meta.highlight_id,
            payload.data.id
          )
        )
      }
      if (payload.type === SocketEvents.ThreadDeleted) {
        // When a thread is deleted, we need to update the highlight so that
        // it is marked as not having an attached thread.
        if (!revisionId) return
        dispatch(
          updateInHighlightsCache(
            {
              chapter_id: payload.meta.chapter_id,
              revision_id: revisionId,
            },
            payload.meta.highlight_id,
            null
          )
        )
        // We also need to remove the thread the aside panel if it is currently open.
        dispatch(threadRemoved({ highlightId: payload.meta.highlight_id }))
      }
      if (payload.type === SocketEvents.NewMessage) {
        dispatch(addToMessagesCache(payload.meta.highlight_id, payload.data))
      }
      if (payload.type === SocketEvents.MessageUpdated) {
        dispatch(updateInMessagesCache(payload.meta.highlight_id, payload.data))
      }
      if (payload.type === SocketEvents.MessageDeleted) {
        dispatch(
          removeFromMessagesCache(payload.meta.highlight_id, payload.data.id)
        )
      }
    },
    [dispatch, revisionId]
  )
}
