import { Editor } from "@/components/editor/Editor.tsx"
import { Viewer } from "@/components/viewer/Viewer.tsx"
import { FlashcardDeck } from "@/panels/FlashcardDeck/FlashcardDeck"
import { FlashcardStudyView } from "@/panels/FlashcardDeck/components/FlashcardStudyView/FlashcardStudyView.tsx"
import { Note } from "@/panels/Notebook/Note.tsx"
import { Notebook } from "@/panels/Notebook/Notebook.tsx"
import { ResourcesViewer } from "@/panels/ResourcesViewer/ResourcesViewer"
import { type PanelState, PanelType } from "@/store/slices/panels.ts"

/**
 * Returns the content of a panel based on its state. This is either
 * an editor, a viewer, or a resource viewer.
 * @param panelState
 */
export function getPanelApplication(panelState: PanelState) {
  switch (panelState.panelType) {
    case PanelType.EDITOR:
      if (!panelState.documentId) return null
      return <Editor chapterId={panelState.documentId} />
    case PanelType.FLASHCARD_DECK:
      if (!panelState.documentId) return null
      return <FlashcardDeck deckId={panelState.documentId} />
    case PanelType.FLASHCARD_STUDY_VIEW:
      if (!panelState.documentId) return null
      return <FlashcardStudyView sessionId={panelState.documentId} />
    case PanelType.VIEWER:
      if (!panelState.documentId) return null
      return (
        <Viewer
          chapterId={panelState.documentId}
          revisionId={panelState.revisionId}
        />
      )
    case PanelType.COURT_DECISION:
    case PanelType.STATUTE_SECTION:
    case PanelType.PDF_VIEWER:
      return (
        <ResourcesViewer
          resourceType={panelState.panelType}
          resourceUrl={panelState.documentUrl}
          resourceTitle={panelState.documentTitle}
        />
      )
    case PanelType.NOTEBOOK:
      if (!panelState.documentId) return null
      return <Notebook notebookId={panelState.documentId} />
    case PanelType.NOTE:
      if (!panelState.documentId) return null
      return <Note noteId={panelState.documentId} />
    default:
      return null
  }
}
