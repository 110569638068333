import { Button, Popover, usePopoverStore } from "@ariakit/react"
import {
  useEditorEffect,
  useEditorEventCallback,
  useEditorEventListener,
} from "@nytimes/react-prosemirror"
import { useCallback, useContext, useRef, useState } from "react"

import updateLinkStyles from "./updateLinkPopover.module.css"

import {
  type SelectLinkContext,
  SelectLinkHandler,
} from "@/components/editor/plugins/SelectLinkHandler"
import { Trash } from "@/components/icons.tsx"
import { AtomViewContext } from "@/contexts/AtomViewContext"
import { useLocalizedString } from "@/hooks/useLocalizedString"
import { Tooltip } from "@/ui/Tooltip"
import { getMarkRange } from "@/utils/prosemirror"

type UpdateLinkPopoverProps = SelectLinkContext

function UpdateLinkPopoverUI({ link, pos, openLink }: UpdateLinkPopoverProps) {
  const { atomView } = useContext(AtomViewContext) ?? {}

  const popoverRef = useRef<HTMLDivElement | null>(null)
  const popoverStore = usePopoverStore()

  const [linkNode, setLinkNode] = useState<HTMLElement | null>(null)

  useEditorEffect(
    (view) => {
      // Never show the popover for not editable views.
      if (!view.editable) return

      if (!link || !pos || !popoverRef.current) {
        popoverStore.setOpen(false)
      } else {
        popoverStore.setOpen(true)
        setLinkNode(view.domAtPos(pos).node.parentElement as HTMLElement)
      }
    },
    [link, pos, popoverStore]
  )

  useEditorEventListener("keydown", () => {
    popoverStore.setOpen(false)
  })

  const handleClickDelete = useEditorEventCallback((view) => {
    if (!link || !pos) return false

    const state = atomView ? atomView.state : view.state
    const dispatch = atomView ? atomView.dispatch : view.dispatch

    const range = getMarkRange(state.doc, link.type.name, pos)
    dispatch(state.tr.removeMark(range.from, range.to, link.type))

    popoverStore.setOpen(false)
    return true
  })

  const handleClickShow = useCallback(() => {
    if (!link?.attrs.href) return false

    openLink(link.attrs.href)
    popoverStore.setOpen(false)
    return true
  }, [link?.attrs.href, openLink, popoverStore])

  const deleteLabel = useLocalizedString({
    id: "delete-link",
    fallback: "Delete",
  })

  const showLabel = useLocalizedString({
    id: "show-link",
    fallback: "Open",
  })

  const displayedLink = (link?.attrs.title ?? link?.attrs.href)?.replace(
    /^https?:\/\//,
    ""
  )

  return (
    <Popover
      className={updateLinkStyles.updateLinkPopover}
      ref={popoverRef}
      store={popoverStore}
      autoFocusOnShow={false}
      getAnchorRect={() => {
        if (!linkNode) return null
        return linkNode.getBoundingClientRect()
      }}
    >
      <div className={updateLinkStyles.displayedLink}>
        <Button onClick={handleClickShow}>{displayedLink}</Button>
      </div>
      <div className={updateLinkStyles.linkActions}>
        <Tooltip title={deleteLabel}>
          <Button onClick={handleClickDelete}>
            <Trash />
          </Button>
        </Tooltip>
        <Button onClick={handleClickShow}>{showLabel}</Button>
      </div>
    </Popover>
  )
}

export function UpdateLinkPopover() {
  return (
    <SelectLinkHandler>
      {({ link, pos, openLink }) => (
        <UpdateLinkPopoverUI link={link} pos={pos} openLink={openLink} />
      )}
    </SelectLinkHandler>
  )
}
