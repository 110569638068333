/* eslint-disable @dword-design/import-alias/prefer-alias */
import "@shoelace-style/shoelace/dist/components/option/option.js"
import "@shoelace-style/shoelace/dist/components/select/select.js"
import "@shoelace-style/shoelace/dist/themes/light.css"
import * as htmx from "htmx.org"
import "prosemirror-view/style/prosemirror.css"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import Sortable from "sortablejs"

import "../css/dskrpt.scss"
// Core Components
import "../webcomponents/add-members"
import "../webcomponents/notification-menu"
import "../webcomponents/unsplash-input"

import { App } from "./components/App"
import "./prosemirror.css"
import { store } from "./store/store"

import { initSentry } from "@/utils/sentry"

declare global {
  interface Window {
    htmx: unknown
    Sortable: unknown
  }
}
document.addEventListener("DOMContentLoaded", () => {
  window.htmx = htmx
  window.Sortable = Sortable
})

// Localization
// import { configureLocalization } from "@lit/localize"
// import { sourceLocale, targetLocales } from "../webcomponents/locale/locales"

const username = document.querySelector("body")?.dataset.username
initSentry({ username })

const appMount =
  // This element is statically defined in the HTML
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  document.getElementById("app")!

if (appMount) {
  const root = createRoot(appMount)
  root.render(
    <Provider store={store}>
      <App />
    </Provider>
  )
}
