import { skipToken } from "@reduxjs/toolkit/query"
import { useContext } from "react"

import editorToolbarStyles from "@/components/editor/toolbar/toolbar.module.css"
import { RevisionContext } from "@/contexts/RevisionContext.ts"
import { useGetNoteQuery } from "@/store/slices/api.ts"

export function NotepadTitle() {
  const { documentId: noteId, documentType } = useContext(RevisionContext)

  const {
    currentData: note,
    isLoading,
    isSuccess,
  } = useGetNoteQuery(documentType === "note" ? noteId : skipToken)

  if (!isSuccess || isLoading || !note) return null

  return (
    <div
      style={{ display: "flex", marginRight: "0.5rem", alignItems: "center" }}
    >
      <span className={editorToolbarStyles.editorToolbarChapterTitle}>
        {note.title}
      </span>
    </div>
  )
}
