import clsx from "clsx"
import { DOMSerializer, type Node, type Schema } from "prosemirror-model"
import { type ComponentPropsWithoutRef, useMemo } from "react"

type ProseMirrorRendererProps = ComponentPropsWithoutRef<"div"> & {
  node: Node
  schema: Schema
  variant?: string
}

/**
 * This helper component serializes a ProseMirror node into HTML,
 * based on the provided schema.
 */

export function ProseMirrorRenderer({
  node,
  schema,
  className,
  variant,
  ...rest
}: ProseMirrorRendererProps) {
  const serializer = useMemo(() => DOMSerializer.fromSchema(schema), [schema])
  if (!serializer) return null

  const content = serializer.serializeNode(node) as HTMLElement
  if (!content) return null

  return (
    <span
      {...rest}
      // Keep the ProseMirror class and data-variant to ensure
      // all editor-related styles are applied correctly
      className={clsx("ProseMirror", className)}
      data-variant={variant}
      contentEditable={false}
      dangerouslySetInnerHTML={{
        __html: content.innerHTML,
      }}
    />
  )
}
