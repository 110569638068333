import {
  Menu,
  MenuButton,
  MenuButtonArrow,
  ToolbarItem,
  useMenuStore,
} from "@ariakit/react"
import { useEditorState } from "@nytimes/react-prosemirror"
import {
  addColumnAfter,
  addColumnBefore,
  addRowAfter,
  addRowBefore,
  deleteColumn,
  deleteRow,
  deleteTable,
  mergeCells,
  splitCell,
  toggleHeaderCell,
  toggleHeaderColumn,
  toggleHeaderRow,
} from "prosemirror-tables"

import { isFootnoteSelected } from "@/commands/footnotes"
import { isNodeTypeSelected } from "@/commands/selection"
import { CommandItem } from "@/components/editor/toolbar/items/CommandItem"
import { EditorMenuItem } from "@/components/editor/toolbar/items/EditorMenuItem"
import styles from "@/components/editor/toolbar/toolbar.module.css"

export function TableMenu() {
  const menuStore = useMenuStore()
  const editorState = useEditorState()

  if (
    !isNodeTypeSelected(editorState, editorState?.schema.nodes["table"]) ||
    (editorState && isFootnoteSelected(editorState.selection))
  ) {
    return null
  }

  return (
    <>
      <MenuButton
        className={`${styles["editorToolbarItem"]} ${styles["editorToolbarMenuButton"]}`}
        store={menuStore}
        render={<ToolbarItem />}
      >
        Table <MenuButtonArrow />
      </MenuButton>
      <Menu store={menuStore} className={styles["editorToolbarDropdownMenu"]}>
        <CommandItem command={addColumnBefore} as={EditorMenuItem}>
          Insert column before
        </CommandItem>
        <CommandItem command={addColumnAfter} as={EditorMenuItem}>
          Insert column after
        </CommandItem>
        <CommandItem command={deleteColumn} as={EditorMenuItem}>
          Delete column
        </CommandItem>
        <CommandItem command={addRowBefore} as={EditorMenuItem}>
          Insert row before
        </CommandItem>
        <CommandItem command={addRowAfter} as={EditorMenuItem}>
          Insert row after
        </CommandItem>
        <CommandItem command={deleteRow} as={EditorMenuItem}>
          Delete row
        </CommandItem>
        <CommandItem command={deleteTable} as={EditorMenuItem}>
          Delete table
        </CommandItem>
        <CommandItem command={mergeCells} as={EditorMenuItem}>
          Merge cells
        </CommandItem>
        <CommandItem command={splitCell} as={EditorMenuItem}>
          Split cell
        </CommandItem>
        <CommandItem command={toggleHeaderColumn} as={EditorMenuItem}>
          Toggle header column
        </CommandItem>
        <CommandItem command={toggleHeaderRow} as={EditorMenuItem}>
          Toggle header row
        </CommandItem>
        <CommandItem command={toggleHeaderCell} as={EditorMenuItem}>
          Toggle header cells
        </CommandItem>
      </Menu>
    </>
  )
}
