import { useEditorState } from "@nytimes/react-prosemirror"
import { NodeSelection } from "prosemirror-state"

import { deleteBlockNode } from "@/commands/deleteBlockNode.ts"
import { isFootnoteSelected } from "@/commands/footnotes"
import { CommandItem } from "@/components/editor/toolbar/items/CommandItem.tsx"
import { Trash } from "@/components/icons"
import { chapterSchema } from "@/schemas/chapter/schema"

export function ImageSection() {
  const editorState = useEditorState()

  if (
    !editorState ||
    !(editorState.selection instanceof NodeSelection) ||
    !(editorState.selection.node.type.name === "image") ||
    isFootnoteSelected(editorState.selection)
  ) {
    return null
  }

  return (
    <>
      <CommandItem command={deleteBlockNode(chapterSchema.nodes.image)}>
        <Trash />
      </CommandItem>
    </>
  )
}
