import TimeAgo from "javascript-time-ago"
import de from "javascript-time-ago/locale/de"
import en from "javascript-time-ago/locale/en"
import { DOMSerializer, Node } from "prosemirror-model"
import { useMemo, useState } from "react"

import { MessageEditor } from "./MessageEditor"
import styles from "./message.module.css"

import { Pencil, Trash } from "@/components/icons.tsx"
import { messageSchema } from "@/schemas/message/schema"
import type { UUID } from "@/store/UUID"
import { useDeleteMessageMutation } from "@/store/slices/api"
import type { Message } from "@/types/api"

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(de)

type MessageProps = {
  highlightId: UUID
  message: Message
}

export function Message({ message, highlightId }: MessageProps) {
  const [isEditing, setIsEditing] = useState(false)

  return isEditing ? (
    <MessageEditor
      highlightId={highlightId}
      message={message}
      unmount={() => setIsEditing(false)}
    />
  ) : (
    <MessageView
      highlightId={highlightId}
      message={message}
      onEdit={() => setIsEditing(true)}
    />
  )
}

type MessageViewProps = {
  highlightId: UUID
  message: Message
  onEdit: () => void
}

function MessageView({ highlightId, message, onEdit }: MessageViewProps) {
  const [deleteMessage, { isLoading: isDeleting }] = useDeleteMessageMutation()

  const serializedMessage = useMemo(() => {
    const serializer = DOMSerializer.fromSchema(messageSchema)
    const node = Node.fromJSON(messageSchema, message.content)
    const target = document.createElement("div")
    return serializer.serializeFragment(node.content, {}, target) as HTMLElement
  }, [message.content])

  const timeAgoString = useMemo(() => {
    if (!message.createdAt) return ""
    const timeAgo = new TimeAgo(["de-DE", "en-US"])
    return timeAgo.format(new Date(message.createdAt))
  }, [message.createdAt])

  return (
    <div className={styles["message"]}>
      <div className={styles["heading"]}>
        <div className={styles["textGroup"]}>
          <span className={styles["author"]}>{message.createdByName}</span>
          <span className={styles["createdAt"]}>{timeAgoString}</span>
        </div>
        <div className={styles["buttonGroup"]}>
          <button onClick={onEdit} className={styles["editButton"]}>
            <Pencil />
          </button>
          <button
            onClick={() =>
              deleteMessage({ highlightId, messageId: message.id })
            }
            disabled={isDeleting}
            className={styles["editButton"]}
          >
            <Trash />
          </button>
        </div>
      </div>
      <div dangerouslySetInnerHTML={{ __html: serializedMessage.innerHTML }} />
    </div>
  )
}
