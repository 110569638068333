import { Button } from "@ariakit/react"
import { useEditorState } from "@nytimes/react-prosemirror"
import {
  type CSSProperties,
  type ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react"
import { createPortal } from "react-dom"

import { useMobileAsideDrawer } from "./useMobileAsideDrawer"

import styles from "@/components/editor/editor.module.css"
import { ChevronsRight } from "@/components/icons"
import sharedStyles from "@/components/shared.module.css"
import { AsidePortalContext } from "@/contexts/AsidePortalContext"
import { PanelContext } from "@/contexts/PanelContext"
import { useMediaQuery } from "@/hooks/useMediaQuery"

type Props = {
  children: ReactNode
}

export function WithAside({ children }: Props) {
  const [asideElement, setAsideElement] = useState<HTMLElement | null>(null)
  const editorState = useEditorState()

  useLayoutEffect(() => {
    if (!asideElement) return

    function updateLayout() {
      if (!asideElement) return

      // Minimum distance between elements to avoid overlap
      const minDistance = 5

      const asideElements = Array.from(asideElement.children) as HTMLElement[]
      if (asideElements.length === 0) return

      const sortedAsideElements = [...asideElements].sort((a, b) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const preferredTopA = parseFloat(a.dataset["preferredTop"]!)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const preferredTopB = parseFloat(b.dataset["preferredTop"]!)
        const diff = preferredTopA - preferredTopB
        if (diff !== 0) return diff
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const tieBreakerA = parseFloat(a.dataset["tieBreaker"]!)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const tieBreakerB = parseFloat(b.dataset["tieBreaker"]!)
        if (!tieBreakerB) return 1
        if (!tieBreakerA) return -1
        return tieBreakerA - tieBreakerB
      })

      let lowestBottom = 0
      let highestTop = document.body.clientHeight
      let anchorIndex = sortedAsideElements.findIndex(
        (element) => element.dataset["isAnchor"] === "true"
      )
      anchorIndex = anchorIndex === -1 ? 0 : anchorIndex

      const anchor = sortedAsideElements[anchorIndex]

      if (!anchor) return

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const preferredAnchorTop = parseFloat(anchor.dataset["preferredTop"]!)
      // Partition the portals into those that wish to be above
      // the anchor and those that wish to be below
      const above = sortedAsideElements.slice(0, anchorIndex)
      const below = sortedAsideElements.slice(anchorIndex + 1)

      // Position the anchor first. The anchor always gets its preferred position
      anchor.style["position"] = "absolute"
      anchor.style["top"] = `${preferredAnchorTop}px`
      lowestBottom = preferredAnchorTop + anchor.getBoundingClientRect().height
      highestTop = preferredAnchorTop

      // Iterate through the portals above in reverse order,
      // starting from the "lowest" and going "up"
      for (const element of above.reverse()) {
        const nextAvailableTop = Math.min(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          parseFloat(element.dataset["preferredTop"]!),
          highestTop - element.getBoundingClientRect().height ?? 0
        )
        element.style["position"] = "absolute"
        element.style["top"] = `${nextAvailableTop}px`
        highestTop = nextAvailableTop
      }

      for (const element of below) {
        if (!element) continue
        const nextAvailableTop = Math.max(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          parseFloat(element.dataset["preferredTop"]!),
          lowestBottom + minDistance
        )
        element.style["position"] = "absolute"
        element.style["top"] = `${nextAvailableTop}px`
        lowestBottom = nextAvailableTop + element.getBoundingClientRect().height
      }
    }

    const mutationObserver = new MutationObserver(updateLayout)
    mutationObserver.observe(asideElement, {
      childList: true,
      subtree: true,
      characterData: true,
      attributeFilter: ["hidden"],
    })

    const resizeObserver = new ResizeObserver(updateLayout)
    resizeObserver.observe(asideElement)

    updateLayout()
    return () => {
      mutationObserver.disconnect()
      resizeObserver.disconnect()
    }
  }, [asideElement, editorState])

  const isMobile = useMediaQuery("(max-width: 768px)")

  return (
    <AsidePortalContext.Provider value={asideElement}>
      {children}
      <aside
        ref={setAsideElement}
        id={"aside-container"}
        className={styles["marginContainer"]}
      />
      {isMobile && <AsideDrawerCloseButton anchor={asideElement} />}
    </AsidePortalContext.Provider>
  )
}

/**
 * Renders a button to close the aside drawer on mobile.
 * Injects button as child of <ResizablePanel> to fix it's position.
 */

function AsideDrawerCloseButton({ anchor }: { anchor: HTMLElement | null }) {
  const [mount, setMount] = useState<HTMLElement | null>(null)
  const [inlineStyles, setInlineStyles] = useState<CSSProperties>({})

  const asideDrawer = useMobileAsideDrawer()
  const panel = useContext(PanelContext)

  useEffect(() => {
    if (!panel) return
    const element = document.querySelector(`[data-panel-id="${panel.panelId}"]`)
    if (element) setMount(element as HTMLElement)
  }, [panel])

  useEffect(() => {
    setInlineStyles({
      opacity: asideDrawer?.isOpen ? 1 : 0,
      left: (anchor?.getBoundingClientRect().left ?? 250) - 10,
    })
  }, [asideDrawer?.isOpen, anchor])

  if (!asideDrawer || !anchor || !mount) return null

  return createPortal(
    <Button
      aria-label="Close"
      title="Close"
      className={`${sharedStyles["buttonIconOnly"]} ${styles["marginContainerClose"]}`}
      onClick={asideDrawer.close}
      style={inlineStyles}
    >
      <ChevronsRight />
    </Button>,
    mount
  )
}
