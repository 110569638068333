import { Button } from "@ariakit/react"
import { Localized } from "@fluent/react"
import { useEditorState } from "@nytimes/react-prosemirror"

import styles from "./floatingMenuPopover.module.css"

import { Quiz } from "@/components/icons"
import { useTextSelectionMenu } from "@/hooks/useTextSelectionMenu"
import { useAppDispatch } from "@/store/hooks"
import { Dialog } from "@/store/slices/dialogs"
import { dialogOpened } from "@/store/store"

export function FloatingToolbar() {
  const dispatch = useAppDispatch()
  const state = useEditorState()

  const TextSelectionMenu = useTextSelectionMenu({ placement: "bottom" })
  if (!TextSelectionMenu) return null

  const items = makeFloatingToolbarItems({
    dispatch,
    editorState: state,
    toolbar: TextSelectionMenu.store,
  })

  const enabledItems = items.filter((i) => !i.isDisabled())
  if (enabledItems.length === 0) return null

  return (
    <TextSelectionMenu.Root className={styles["floatingMenu"]}>
      {enabledItems.map(({ name, label, Icon, onClick }) => {
        return (
          <Button key={name} onClick={onClick} render={<button />}>
            <Icon />
            <span>
              <Localized id={name}>{label}</Localized>
            </span>
          </Button>
        )
      })}
    </TextSelectionMenu.Root>
  )
}

const makeFloatingToolbarItems = ({
  dispatch,
  editorState,
  toolbar,
}: {
  toolbar: NonNullable<ReturnType<typeof useTextSelectionMenu>>["store"]
  editorState: ReturnType<typeof useEditorState>
  dispatch: ReturnType<typeof useAppDispatch>
}) => [
  {
    name: "floating-toolbar-create-magic-quiz",
    label: "Create Magic Quiz",
    Icon: Quiz,
    isDisabled: () => {
      if (!editorState) return true

      const { from, to } = editorState.selection
      return editorState.doc.textBetween(from, to).length < 10
    },
    onClick: () => {
      toolbar.setOpen(false)
      dispatch(dialogOpened(Dialog.INSERT_MAGIC_QUIZ))
    },
  },
]
