import { Button, useDialogStore } from "@ariakit/react"
import { Localized, useLocalization } from "@fluent/react"

import styles from "./Notebook.module.css"
import { NoteList } from "./components/NoteList.tsx"
import { NotebookSettingsDialog } from "./components/NotebookSettingsDialog.tsx"
import { SaveToNotebooksButton } from "./components/SaveToNotebooksButton.tsx"

import { Cog } from "@/components/icons.tsx"
import sharedStyles from "@/components/shared.module.css"
import { NotebookContext } from "@/contexts/NotebookContext.ts"
import type { UUID } from "@/store/UUID.ts"
import {
  useGetNotebookPermissionsQuery,
  useGetNotebookQuery,
} from "@/store/slices/api.ts"
import { DetailStatic } from "@/ui/DetailEditor/detailEditor.tsx"
import { PageAside } from "@/ui/PageAside/PageAside.tsx"
import { PageContent } from "@/ui/PageContent/PageContent.tsx"
import { PageHeader } from "@/ui/PageHeader/PageHeader.tsx"
import { ReadOnlyPermissionsList } from "@/ui/Permissions/ReadOnlyPermissionsList.tsx"
import { Subheader } from "@/ui/Subheader/subheader.tsx"

export function Notebook({ notebookId }: { notebookId: UUID }) {
  const { data: notebook, isLoading } = useGetNotebookQuery({ notebookId })
  const { data: permissions } = useGetNotebookPermissionsQuery(notebookId)

  const { l10n } = useLocalization()

  const settingsDialog = useDialogStore()

  if (isLoading) {
    return (
      <Localized id={"loading"}>
        <div>Loading...</div>
      </Localized>
    )
  }

  if (!notebook) {
    return <div>An error occurred</div>
  }

  return (
    <NotebookContext.Provider value={{ notebookId }}>
      <div className={styles["notebookPanel"]}>
        <PageHeader title={notebook.title} description={notebook.description}>
          <SaveToNotebooksButton
            notebookId={notebookId}
            isSaved={notebook.hasUserSavedNotebook}
            isOwner={notebook.isUserOwner}
          />
          <Button
            className={sharedStyles["defaultButton"]}
            onClick={() => settingsDialog.show()}
          >
            <Cog />
            <Localized id={"settings"}>Settings</Localized>
          </Button>
        </PageHeader>
        <PageContent>
          <NoteList notebook={notebook} />
        </PageContent>
        <PageAside>
          <DetailStatic
            title={l10n.getString("description", null, "Description")}
            body={notebook.description || ""}
            emptyPlaceholder={l10n.getString(
              "description-empty-placeholder",
              null,
              "No description available"
            )}
          />
          <Subheader>
            {l10n.getString("permissions", null, "Permissions")}
          </Subheader>
          {permissions && (
            <ReadOnlyPermissionsList
              users={permissions.users}
              usergroups={permissions.usergroups}
              institutions={permissions.institutions}
            />
          )}
        </PageAside>
      </div>
      <NotebookSettingsDialog store={settingsDialog} />
    </NotebookContext.Provider>
  )
}
