import {
  Menu,
  MenuButton,
  MenuItem,
  ToolbarItem,
  useMenuStore,
} from "@ariakit/react"
import { Localized } from "@fluent/react"
import { clsx } from "clsx"

import styles from "./togglemarkermodeitem.module.css"

import toolbarStyles from "@/components/editor/toolbar/toolbar.module.css"
import { MarkerPen } from "@/components/icons.tsx"
import { ButtonColorIndicator } from "@/components/viewer/highlights/CreateHighlightButton"
import { useStylusHighlight } from "@/hooks/useStylusHighlight"
import { useAppDispatch, useAppSelector } from "@/store/hooks"
import { getMarkerColor } from "@/store/selectors/viewerSelectors"
import { viewerSlice } from "@/store/slices/viewer"

export function ToggleMarkerMode() {
  useStylusHighlight()

  const menuStore = useMenuStore()
  const markerColor = useAppSelector(getMarkerColor)
  const highlightColorVariable = markerColor
    ? `var(--colorHighlight${
        markerColor.charAt(0).toUpperCase() + markerColor.slice(1)
      })`
    : undefined

  return (
    <>
      <MenuButton
        className={toolbarStyles["editorToolbarItem"]}
        store={menuStore}
        render={<ToolbarItem />}
      >
        <div
          className={toolbarStyles["editorToolbarItem"]}
          style={{
            borderRadius: "999px",
            backgroundColor: highlightColorVariable,
          }}
        >
          <MarkerPen
            style={{ height: "1.5rem", width: "1.5rem", padding: 0 }}
          />
        </div>
      </MenuButton>
      <Menu
        store={menuStore}
        className={toolbarStyles["editorToolbarDropdownMenu"]}
      >
        <ToggleMarkerModeItem markerColor={markerColor} itemColor="yellow" />
        <ToggleMarkerModeItem markerColor={markerColor} itemColor="pink" />
        <ToggleMarkerModeItem markerColor={markerColor} itemColor="blue" />
        <ToggleMarkerModeItem markerColor={markerColor} itemColor="green" />
        <ToggleMarkerModeItem markerColor={markerColor} itemColor="violet" />
      </Menu>
    </>
  )
}

export function ToggleMarkerModeItem(props: {
  markerColor: "yellow" | "green" | "pink" | "blue" | "violet" | null
  itemColor: "yellow" | "green" | "pink" | "blue" | "violet"
}) {
  const dispatch = useAppDispatch()
  const { markerColor, itemColor } = props

  const handleClick = () => {
    if (markerColor === itemColor) {
      dispatch(viewerSlice.actions.markerModeDisabled())
    } else {
      dispatch(viewerSlice.actions.markerModeEnabled({ color: itemColor }))
    }
  }

  return (
    <MenuItem
      className={clsx(
        toolbarStyles["editorToolbarDropdownItem"],
        markerColor === itemColor ? styles["active"] : ""
      )}
      onClick={handleClick}
    >
      <ButtonColorIndicator color={itemColor} />
      <Localized id={`viewer-marker-${itemColor}`}>
        {itemColor.charAt(0).toUpperCase() + itemColor.slice(1)}
      </Localized>
    </MenuItem>
  )
}
