import {
  Button,
  Dialog,
  DialogDismiss,
  DialogHeading,
  useDialogStore,
  useFormStore,
} from "@ariakit/react"
import { Localized, useLocalization } from "@fluent/react"
import { clsx } from "clsx"
import { useContext, useEffect, useState } from "react"

import { FlashcardDeckPermissions } from "./FlashcardDeckPermissions.tsx"

import sharedStyles from "@/components/shared.module.css"
import { FlashcardDeckContext } from "@/contexts/FlashcardDeckContext.ts"
import {
  Form,
  FormField,
  FormInput,
  FormSubmit,
} from "@/micro_apps/components/forms/form.tsx"
import { useAppDispatch } from "@/store/hooks.ts"
import {
  useGetFlashcardDeckQuery,
  useUpdateFlashcardDeckMutation,
} from "@/store/slices/api.ts"
import { flashcardDeckDeleteClicked } from "@/store/thunks/flashcardDeckDeleteClicked.ts"

export function FlashcardDeckSettingsDialog(props: {
  showSettings: boolean
  setShowSettings: (show: boolean) => void
}) {
  const settingsDialog = useDialogStore({ open: props.showSettings })

  const { deckId } = useContext(FlashcardDeckContext)
  const { data, isLoading } = useGetFlashcardDeckQuery({
    deckId: deckId,
  })
  const dispatch = useAppDispatch()

  const [title, setTitle] = useState(data?.title)
  const [description, setDescription] = useState(data?.description)

  useEffect(() => {
    setTitle(data?.title)
    setDescription(data?.description)
  }, [data?.description, data?.title])

  const { l10n } = useLocalization()

  const formStore = useFormStore({
    defaultValues: {
      title: "",
      description: "",
    },
    values: { title: title, description: description },
    setValues: (values) => {
      setTitle(values.title)
    },
  })

  const [updateDeck] = useUpdateFlashcardDeckMutation()

  formStore.useSubmit(async (state) => {
    if (!state.values.title) return
    await updateDeck({
      deckId: deckId,
      title: state.values.title,
      description: state.values.description || "",
    })
    props.setShowSettings(false)
  })

  const handleDeleteClick = () => {
    if (confirm("Are you sure you want to delete this deck?")) {
      dispatch(flashcardDeckDeleteClicked({ deckId }))
    }
  }

  if (isLoading || !data) return null

  return (
    <Dialog store={settingsDialog} className={sharedStyles.dialog} portal>
      <DialogHeading className={sharedStyles["dialogHeading"]}>
        Deck Settings
      </DialogHeading>
      <Form store={formStore}>
        <FormField
          style={{ gridColumnStart: 1, gridColumnEnd: 5 }}
          name={formStore.names.title}
          label={l10n.getString("title", null, "Title")}
        >
          <FormInput
            name={formStore.names.title}
            disabled={!data.canUserEdit}
          />
        </FormField>
        <FormField
          style={{ gridColumnStart: 1, gridColumnEnd: 5 }}
          name={formStore.names.description}
          label={l10n.getString("description", null, "Description")}
        >
          <FormInput
            name={formStore.names.description}
            disabled={!data.canUserEdit}
          />
        </FormField>
      </Form>
      {data.canUserEdit && <FlashcardDeckPermissions deckId={deckId} />}
      {data.canUserEdit && (
        <Button
          className={clsx(
            sharedStyles["defaultButton"],
            sharedStyles["warning"]
          )}
          onClick={handleDeleteClick}
          style={{ marginTop: "1rem" }}
        >
          <Localized id={"flashcard-deck-delete"}>Delete Deck</Localized>
        </Button>
      )}
      <div className={sharedStyles["dialogButtons"]}>
        {data.canUserEdit && (
          <FormSubmit
            store={formStore}
            className={sharedStyles["defaultButton"]}
            onClick={() => formStore.submit()}
          >
            <Localized id={"save"}>Save</Localized>
          </FormSubmit>
        )}
        <DialogDismiss
          className={clsx(
            sharedStyles["defaultButton"],
            sharedStyles["secondaryButton"]
          )}
          store={settingsDialog}
          onClick={() => props.setShowSettings(false)}
        >
          <Localized id={"cancel"}>Cancel</Localized>
        </DialogDismiss>
      </div>
    </Dialog>
  )
}
