import { useEditorState } from "@nytimes/react-prosemirror"

import { deleteBlockNode } from "@/commands/deleteBlockNode.ts"
import { isNodeTypeSelected } from "@/commands/selection"
import { CommandItem } from "@/components/editor/toolbar/items/CommandItem"
import { Trash } from "@/components/icons"
import { chapterSchema } from "@/schemas/chapter/schema"

export function FoldableSection() {
  const editorState = useEditorState()

  if (!isNodeTypeSelected(editorState, editorState?.schema.nodes["foldable"])) {
    return null
  }

  return (
    <>
      <CommandItem command={deleteBlockNode(chapterSchema.nodes.foldable)}>
        <Trash />
      </CommandItem>
    </>
  )
}
