import { Localized } from "@fluent/react"

import type { UUID } from "@/store/UUID.ts"
import {
  useAddEntityToNotebookPermissionsMutation,
  useGetNotebookPermissionsQuery,
  useLazyGetNotebookPermissionsSearchQuery,
  useRemoveEntityFromNotebookPermissionsMutation,
  useUpdateEntityOnNotebookPermissionsMutation,
} from "@/store/slices/api.ts"
import { GlobalPermissions } from "@/ui/Permissions/GlobalPermissions.tsx"
import { PermissionsAddEntity } from "@/ui/Permissions/PermissionsAddEntity.tsx"
import { PermissionsEntitiesList } from "@/ui/Permissions/PermissionsEntitiesList.tsx"
import { Subheader } from "@/ui/Subheader/subheader.tsx"

export function NotebookPermissions({ notebookId }: { notebookId: UUID }) {
  const { data } = useGetNotebookPermissionsQuery(notebookId)
  const [addEntity] = useAddEntityToNotebookPermissionsMutation()
  const [removeEntity] = useRemoveEntityFromNotebookPermissionsMutation()
  const [updatePermission] = useUpdateEntityOnNotebookPermissionsMutation()
  const [searchEntities] = useLazyGetNotebookPermissionsSearchQuery()

  if (!data) return null

  return (
    <>
      <Subheader text="Permissions">
        <Localized id={"permissions"}>Permissions</Localized>
      </Subheader>
      <GlobalPermissions
        canPublishGlobally={true}
        isOpenAccess={data.isOpenAccess}
        openAccessGroupId={data.openAccessGroupId}
        isPlatformWide={data.isPlatformWide}
        platformWideGroupId={data.platformWideGroupId}
        resourceId={notebookId}
        addEntity={addEntity}
        removeEntity={removeEntity}
      />
      <PermissionsEntitiesList
        data={data}
        updatePermission={updatePermission}
        removeEntity={removeEntity}
        resourceId={notebookId}
      />
      <PermissionsAddEntity
        resourceId={notebookId}
        addEntity={addEntity}
        searchEntities={searchEntities}
      />
    </>
  )
}
