import type { DialogStore } from "@ariakit/react"
import { Localized, useLocalization } from "@fluent/react"
import { ProseMirror, ProseMirrorDoc } from "@nytimes/react-prosemirror"
import { skipToken } from "@reduxjs/toolkit/dist/query"
import { clsx } from "clsx"
import type { EditorState, Transaction } from "prosemirror-state"
import { useEffect, useMemo } from "react"

import styles from "./FlashcardEditor.module.css"
import { FlashcardEditorToolbar } from "./FlashcardEditorToolbar"

import { useLongPollClient } from "@/communication/polling/useLongPollClient"
import { Keymap } from "@/components/Keymap"
import { EditorErrorFallback } from "@/components/editor/Editor"
import { DialogManager } from "@/components/editor/dialogs/DialogManager"
import { InsertLinkDialog } from "@/components/editor/dialogs/InsertLinkDialog"
import { EditorLifecycleEvents } from "@/components/editor/plugins/EditorLifecycleEvents"
import { makePlaceholderDecorationSet } from "@/components/editor/plugins/placeholder"
import { UpdateLinkPopover } from "@/components/editor/popovers/UpdateLinkPopover"
import shared from "@/components/shared.module.css"
import { RevisionContext } from "@/contexts/RevisionContext"
import { useFocusView } from "@/hooks/useFocusView"
import type { UUID } from "@/store/UUID"
import { useAppSelector } from "@/store/hooks"
import { getRevisionEditorState } from "@/store/selectors/documentsSelectors"
import {
  useGetFlashcardQuery,
  useGetFlashcardRevisionQuery,
  useGetLinkableResourcesQuery,
} from "@/store/slices/api"
import {
  useDispatchTransaction,
  useRemoteSteps,
} from "@/store/slices/revisions"
import { ErrorBoundary } from "@/utils/error"

type FlashcardEditorProps = {
  id: UUID
  dialog?: DialogStore
}

export function FlashcardEditor({ id, dialog }: FlashcardEditorProps) {
  const { data: flashcard, isLoading: isFlashcardLoading } =
    useGetFlashcardQuery(id)

  const revisionId = flashcard?.draftRevisionId

  const { isLoading: isRevisionLoading } = useGetFlashcardRevisionQuery(
    revisionId ? { flashcardId: id, revisionId } : skipToken
  )

  const revisionContextValue = useMemo(
    () =>
      revisionId && {
        revisionId,
        documentType: "flashcard" as const,
        documentId: id,
      },
    [revisionId, id]
  )

  const editorState = useAppSelector((state) =>
    getRevisionEditorState(state, revisionId)
  )

  const dispatchTransaction = useDispatchTransaction({
    documentType: "flashcard",
    documentId: id,
    revisionId,
  })

  const handlers = useRemoteSteps({
    documentType: "flashcard",
    documentId: id,
    revisionId: revisionId,
  })

  const [initLongPolling, connection] = useLongPollClient(
    "flashcard",
    id,
    handlers
  )

  useGetLinkableResourcesQuery()

  useEffect(() => {
    if (editorState && !connection?.isActive) initLongPolling({ editorState })
  }, [editorState, connection, initLongPolling])

  return !isFlashcardLoading &&
    !isRevisionLoading &&
    editorState &&
    revisionContextValue ? (
    <RevisionContext.Provider value={revisionContextValue}>
      <ErrorBoundary fallback={<EditorErrorFallback />}>
        <DedicatedFlashcardEditor
          editorState={editorState}
          dispatchTransaction={dispatchTransaction}
          dialog={dialog}
        />
      </ErrorBoundary>
    </RevisionContext.Provider>
  ) : (
    <>Loading...</>
  )
}

function DedicatedFlashcardEditor({
  editorState,
  dispatchTransaction,
  dialog,
}: {
  editorState: EditorState
  dispatchTransaction: (tr: Transaction) => void
  dialog?: DialogStore
}) {
  const { l10n } = useLocalization()

  const placeholders = useMemo(
    () => makePlaceholderDecorationSet({ l10n }),
    [l10n]
  )

  return (
    <div className={styles["flashcard"]}>
      <ProseMirror
        className={styles["flashcardEditor"]}
        decorations={placeholders}
        dispatchTransaction={dispatchTransaction}
        state={editorState}
      >
        <ProseMirrorDoc />
        <Keymap />
        <UpdateLinkPopover />
        <div className={styles.flashcardEditorActionsContainer}>
          <FlashcardEditorToolbar />
          {dialog && <FlashcardEditorActions dialog={dialog} />}
        </div>
        <FlashcardEditorDialogs />

        <EditorLifecycleEvents
          onMount={(view) => {
            view.focus()
          }}
        />
      </ProseMirror>
    </div>
  )
}

function FlashcardEditorActions({ dialog }: { dialog: DialogStore }) {
  const handleClick = () => {
    dialog.hide()
  }

  return (
    <div className={styles.flashcardEditorActions}>
      <button className={clsx(shared["defaultButton"])} onClick={handleClick}>
        <Localized id="save">Save</Localized>
      </button>
      <button
        className={clsx(shared["defaultButton"], shared["secondaryButton"])}
        onClick={handleClick}
      >
        <Localized id="close">Close</Localized>
      </button>
    </div>
  )
}

function FlashcardEditorDialogs() {
  const focusView = useFocusView()

  return (
    <DialogManager>
      <InsertLinkDialog onConfirm={focusView} />
    </DialogManager>
  )
}
