import { setBlockType } from "prosemirror-commands"
import type { EditorState } from "prosemirror-state"
import type { EditorView } from "prosemirror-view"

export function setHeadingToParagraph(
  state: EditorState,
  dispatch?: EditorView["dispatch"]
) {
  if (!state.schema.nodes["paragraph"]) return false
  const { $from } = state.selection
  const node = $from.node()

  if (node.type !== state.schema.nodes["heading"]) {
    return false
  }
  return setBlockType(state.schema.nodes["paragraph"])(state, dispatch)
}

/**
 * If the caret is in the beginning of a heading, reset the heading to a paragraph
 * @param state
 * @param dispatch
 */
export function resetHeading(
  state: EditorState,
  dispatch?: EditorView["dispatch"]
) {
  if (!state.schema.nodes["paragraph"]) return false
  const { $from } = state.selection
  const { heading } = state.schema.nodes
  if ($from.parent.type !== heading || $from.parentOffset !== 0 || !dispatch) {
    return false
  }
  return setBlockType(state.schema.nodes["paragraph"])(state, dispatch)
}
