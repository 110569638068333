import { Button } from "@ariakit/react"
import { Localized, useLocalization } from "@fluent/react"
import { ProseMirror, ProseMirrorDoc } from "@nytimes/react-prosemirror"
import type { EditorState } from "prosemirror-state"
import { useEffect, useMemo } from "react"

import { DeckSelect } from "./DeckSelect"
import styles from "./flashcard.module.css"

import { useLongPollClient } from "@/communication/polling/useLongPollClient"
import { Keymap } from "@/components/Keymap"
import { EditorLifecycleEvents } from "@/components/editor/plugins/EditorLifecycleEvents"
import { makePlaceholderDecorationSet } from "@/components/editor/plugins/placeholder"
import { FlashcardEditorToolbar } from "@/panels/FlashcardDeck/components/FlashcardEditor/FlashcardEditorToolbar.tsx"
import type { UUID } from "@/store/UUID"
import { useGetFlashcardQuery } from "@/store/slices/api"
import {
  useDispatchTransaction,
  useRemoteSteps,
} from "@/store/slices/revisions"

type Props = {
  editorState: EditorState
  flashcardId: UUID
  onDone: () => void
}

export function FlashcardEditor({ editorState, flashcardId, onDone }: Props) {
  const { l10n } = useLocalization()

  const placeholders = useMemo(
    () => makePlaceholderDecorationSet({ l10n }),
    [l10n]
  )

  const { data: flashcard } = useGetFlashcardQuery(flashcardId)

  const revisionId = flashcard?.draftRevisionId

  const dispatchTransaction = useDispatchTransaction({
    documentType: "flashcard",
    documentId: flashcardId,
    revisionId,
  })

  const handlers = useRemoteSteps({
    documentType: "flashcard",
    documentId: flashcardId,
    revisionId,
  })

  const [initLongPolling, connection] = useLongPollClient(
    "flashcard",
    flashcardId,
    handlers
  )

  useEffect(() => {
    if (editorState && !connection?.isActive) initLongPolling({ editorState })
  }, [editorState, connection, initLongPolling])

  return (
    <>
      <div className={styles["flashcard"]}>
        <ProseMirror
          decorations={placeholders}
          dispatchTransaction={dispatchTransaction}
          state={editorState}
        >
          <ProseMirrorDoc />
          <Keymap />
          <EditorLifecycleEvents
            onMount={(view) => {
              view.focus()
            }}
          />
          <FlashcardEditorToolbar size={"mini"} />
        </ProseMirror>
      </div>
      <div className={styles["flashcardEditorButtonGroup"]}>
        <Button className="blue-button" onClick={onDone}>
          <Localized id="save">Save</Localized>
        </Button>
        <DeckSelect flashcardId={flashcardId} />
      </div>
    </>
  )
}
