import {
  Toolbar,
  ToolbarContainer,
  ToolbarSeparator,
  useToolbarStore,
} from "@ariakit/react/toolbar"
import clsx from "clsx"

import styles from "./FlashcardEditor.module.css"

import { ParagraphMenu } from "@/components/editor/toolbar/menus/ParagraphMenu"
import { BlockQuoteSection } from "@/components/editor/toolbar/sections/BlockQuoteSection"
import { FormattingSection } from "@/components/editor/toolbar/sections/FormattingSection"
import { HeadingSection } from "@/components/editor/toolbar/sections/HeadingSection"
import { HistorySection } from "@/components/editor/toolbar/sections/HistorySection"
import { ListSection } from "@/components/editor/toolbar/sections/ListSection"
import toolbarStyles from "@/components/editor/toolbar/toolbar.module.css"

export function FlashcardEditorToolbar({ size }: { size?: "mini" | "normal" }) {
  size = size ?? "normal"

  const toolbarStore = useToolbarStore({
    orientation: "horizontal",
  })

  return (
    <Toolbar
      className={clsx(
        toolbarStyles.editorToolbar,
        styles.flashcardEditorToolbar,
        {
          [styles.miniFlashcardEditorToolbar as string]: size === "mini",
        }
      )}
      store={toolbarStore}
    >
      <ToolbarContainer>
        <FormattingSection disabled={["footnote"]} />
        <HistorySection />
        <ToolbarSeparator />
        <ParagraphMenu />
        <ListSection />
        <HeadingSection />
        <BlockQuoteSection />
      </ToolbarContainer>
    </Toolbar>
  )
}
