import {
  Menu,
  MenuButton,
  MenuButtonArrow,
  ToolbarItem,
  useMenuStore,
} from "@ariakit/react"
import { Localized } from "@fluent/react"
import { useEditorState } from "@nytimes/react-prosemirror"

import { isFootnoteSelected } from "@/commands/footnotes"
import { isNodeTypeSelected } from "@/commands/selection"
import { SetNodeTypeItem } from "@/components/editor/toolbar/items/SetNodeTypeItem"
import { WrapInItem } from "@/components/editor/toolbar/items/WrapInItem"
import styles from "@/components/editor/toolbar/toolbar.module.css"
import {
  BlockQuote,
  Boxed,
  BulletList,
  CallOut,
  Detail,
  Heading,
  OrderedList,
} from "@/components/icons"

export function ParagraphMenu() {
  const editorState = useEditorState()
  const menuStore = useMenuStore()

  if (
    !editorState ||
    !isNodeTypeSelected(editorState, editorState.schema.nodes["paragraph"]) ||
    isFootnoteSelected(editorState.selection)
  ) {
    return null
  }

  return (
    <>
      <MenuButton
        className={`${styles["editorToolbarItem"]} ${styles["editorToolbarMenuButton"]}`}
        store={menuStore}
        render={<ToolbarItem />}
      >
        <Heading /> <MenuButtonArrow />
      </MenuButton>
      <Menu store={menuStore} className={styles["editorToolbarDropdownMenu"]}>
        <SetNodeTypeItem
          nodeType={editorState.schema.nodes["heading"]}
          attrs={{ level: 1 }}
        >
          <Localized id={"heading"} vars={{ level: 1 }}>
            {"Heading {$level}"}
          </Localized>
        </SetNodeTypeItem>
        <SetNodeTypeItem
          nodeType={editorState.schema.nodes["heading"]}
          attrs={{ level: 2 }}
        >
          <Localized id={"heading"} vars={{ level: 2 }}>
            {"Heading {$level}"}
          </Localized>
        </SetNodeTypeItem>
        <SetNodeTypeItem
          nodeType={editorState.schema.nodes["heading"]}
          attrs={{ level: 3 }}
        >
          <Localized id={"heading"} vars={{ level: 3 }}>
            {"Heading {$level}"}
          </Localized>
        </SetNodeTypeItem>
        <SetNodeTypeItem
          nodeType={editorState.schema.nodes["heading"]}
          attrs={{ level: 4 }}
        >
          <Localized id={"heading"} vars={{ level: 4 }}>
            {"Heading {$level}"}
          </Localized>
        </SetNodeTypeItem>
        <SetNodeTypeItem
          nodeType={editorState.schema.nodes["heading"]}
          attrs={{ level: 5 }}
        >
          <Localized id={"heading"} vars={{ level: 5 }}>
            {"Heading {$level}"}
          </Localized>
        </SetNodeTypeItem>
        <SetNodeTypeItem
          nodeType={editorState.schema.nodes["heading"]}
          attrs={{ level: 6 }}
        >
          <Localized id={"heading"} vars={{ level: 6 }}>
            {"Heading {$level}"}
          </Localized>
        </SetNodeTypeItem>
        <SetNodeTypeItem
          nodeType={editorState.schema.nodes["heading"]}
          attrs={{ level: 7 }}
        >
          <Localized id={"heading"} vars={{ level: 7 }}>
            {"Heading {$level}"}
          </Localized>
        </SetNodeTypeItem>
        <SetNodeTypeItem
          nodeType={editorState.schema.nodes["heading"]}
          attrs={{ level: 8 }}
        >
          <Localized id={"heading"} vars={{ level: 8 }}>
            {"Heading {$level}"}
          </Localized>
        </SetNodeTypeItem>
        <SetNodeTypeItem
          nodeType={editorState.schema.nodes["heading"]}
          attrs={{ level: 9 }}
        >
          <Localized id={"heading"} vars={{ level: 9 }}>
            {"Heading {$level}"}
          </Localized>
        </SetNodeTypeItem>
      </Menu>
      <WrapInItem
        nodeType={editorState.schema.nodes["blockquote"]}
        as={ToolbarItem}
      >
        {/* Make icon size visually more equal to adjacent icons */}
        <BlockQuote style={{ width: "1rem" }} />
      </WrapInItem>
      <WrapInItem
        nodeType={editorState.schema.nodes["callout"]}
        as={ToolbarItem}
      >
        <CallOut />
      </WrapInItem>
      <WrapInItem
        nodeType={editorState.schema.nodes["details"]}
        as={ToolbarItem}
      >
        <Detail />
      </WrapInItem>
      <WrapInItem nodeType={editorState.schema.nodes["boxed"]} as={ToolbarItem}>
        <Boxed />
      </WrapInItem>
      <WrapInItem
        nodeType={editorState.schema.nodes["bullet_list"]}
        as={ToolbarItem}
      >
        <BulletList />
      </WrapInItem>
      <WrapInItem
        nodeType={editorState.schema.nodes["ordered_list"]}
        as={ToolbarItem}
      >
        <OrderedList />
      </WrapInItem>
    </>
  )
}
