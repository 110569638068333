import { Localized, useLocalization } from "@fluent/react"
import { ProseMirror, ProseMirrorDoc } from "@nytimes/react-prosemirror"
import { EditorView } from "prosemirror-view"
import {
  type ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"

import { CitationDragRenderer } from "./CitationDragRenderer.tsx"
import { StepThroughMenu } from "./StepThroughMenu.tsx"
import { Flashcards } from "./flashcards/Flashcards.tsx"
import { CreateHighlightPopover } from "./highlights/CreateHighlightPopover"
import { UpdateHighlightPopover } from "./highlights/UpdateHighlightPopover"
import { makeHighlightDecorations } from "./highlights/makeHighlightsDecoration.ts"
import { useRemoteHighlights } from "./highlights/useRemoteHighlights.tsx"
import {
  Quiz,
  QuizAnswer,
  QuizAnswers,
  QuizExplanation,
  QuizQuestion,
} from "./nodeViews/Quiz.tsx"
import { Threads } from "./threads/Threads.tsx"
import { useRemoteMessages } from "./threads/useRemoteMessages.tsx"
import { ViewerToolbar } from "./toolbar/ViewerToolbar.tsx"
import "./viewer.css"

import {
  SocketTopics,
  type WebsocketClientConnection,
  websocketClient,
} from "@/communication/websocket/websocketClient.ts"
import { Keymap } from "@/components/Keymap"
import { ScrollToPlugin } from "@/components/ScrollToBlock.ts"
import { WithAside } from "@/components/aside/WithAside"
import { EditorErrorFallback } from "@/components/editor/Editor.tsx"
import { DialogManager } from "@/components/editor/dialogs/DialogManager.tsx"
import { ExpiredSessionDialog } from "@/components/editor/dialogs/ExpiredSessionDialog.tsx"
import editorStyles from "@/components/editor/editor.module.css"
import {
  Foldable,
  FoldableContent,
  FoldableSummary,
} from "@/components/editor/nodeViews/Foldable/Foldable.node.tsx"
import { Footnote } from "@/components/editor/nodeViews/Footnote/Footnote.node.tsx"
import { useFootnoteEnumeration } from "@/components/editor/nodeViews/Footnote/useFootnoteEnumeration.tsx"
import { PDF } from "@/components/editor/nodeViews/PDF/PDF.node.tsx"
import { ReviewSchema } from "@/components/editor/nodeViews/ReviewSchema/ReviewSchema.node.tsx"
import { Table } from "@/components/editor/nodeViews/Table/Table.node.tsx"
import { EditorDebugger } from "@/components/editor/plugins/EditorDebugger.tsx"
import { SelectLinkHandler } from "@/components/editor/plugins/SelectLinkHandler.tsx"
import { makePlaceholderDecorations } from "@/components/editor/plugins/placeholder.ts"
import { BlockHandleMenu } from "@/components/editor/popovers/BlockHandleMenu.tsx"
import { Frontmatter } from "@/components/frontmatter/Frontmatter.tsx"
import { useAsideTableOfContents } from "@/components/tableOfContents/TableOfContents.tsx"
import { AtomViewContext } from "@/contexts/AtomViewContext.ts"
import {
  RevisionContext,
  type RevisionContextValue,
} from "@/contexts/RevisionContext"
import type { UUID } from "@/store/UUID.ts"
import { useAppDispatch, useAppSelector } from "@/store/hooks"
import { Snackbar, enqueueSnackbar } from "@/store/reducers/snackbars.ts"
import {
  getCurrentMark,
  getMarkerColor,
  getViewerEditorState,
  getViewerKey,
} from "@/store/selectors/viewerSelectors.ts"
import {
  useGetChapterQuery,
  useLazyGetChapterRevisionQuery,
  useLazyGetHighlightsQuery,
} from "@/store/slices/api.ts"
import { PanelType } from "@/store/slices/panels.ts"
import { useDispatchTransaction } from "@/store/slices/revisions.ts"
import { setFootnoteNumbers } from "@/store/store.ts"
import { ErrorBoundary } from "@/utils/error.ts"
import { templateStyleProperties } from "@/utils/headingStyles.ts"

export const chapterViewerNodeViews: ComponentProps<
  typeof ProseMirror
>["nodeViews"] = {
  quiz_answer: QuizAnswer,
  quiz_answers: QuizAnswers,
  quiz_explanation: QuizExplanation,
  quiz_question: QuizQuestion,
  quiz: Quiz,
  foldable: Foldable,
  foldable_content: FoldableContent,
  foldable_summary: FoldableSummary,
  footnote: Footnote,
  pdf: PDF,
  review_schema: ReviewSchema,
  table: Table,
}

function ViewerProvider({
  chapterId,
  revisionId: targetRevisionId,
}: {
  chapterId: UUID
  revisionId?: UUID | null
}) {
  const [atomView, setAtomView] = useState<EditorView | null>(null)

  const { data: chapter, isError: isChapterError } =
    useGetChapterQuery(chapterId)

  // Use either the requested revision or the published revision
  // If neither are available, use the draft revision which only authors can see
  const revisionId =
    targetRevisionId || chapter?.publishedRevisionId || chapter?.draftRevisionId

  const atomViewContextValue = useMemo(
    () => ({ atomView, setAtomView }),
    [atomView]
  )

  const revisionContextValue = useMemo(
    () => ({ documentType: "chapter", documentId: chapterId, revisionId }),
    [chapterId, revisionId]
  )

  const AsideTableOfContents = useAsideTableOfContents()

  if (isChapterError) return <EditorErrorFallback />

  if (!revisionContextValue.revisionId) return null

  return (
    <RevisionContext.Provider
      value={revisionContextValue as RevisionContextValue}
    >
      <AtomViewContext.Provider value={atomViewContextValue}>
        {AsideTableOfContents}
        <ErrorBoundary fallback={<EditorErrorFallback />}>
          <ViewerComponent
            chapterId={chapterId}
            revisionId={revisionContextValue.revisionId}
          />
        </ErrorBoundary>
      </AtomViewContext.Provider>
    </RevisionContext.Provider>
  )
}

export const Viewer = ViewerProvider

/**
 * This is the component that renders the ProseMirror view.
 * We keep this in a separate component so that we avoid re-rendering
 * the ViewerProvider each time the editor state changes.
 */

function ViewerComponent({
  chapterId,
  revisionId,
}: {
  chapterId: UUID
  revisionId: UUID
}) {
  const { l10n } = useLocalization()

  const [
    requestRevision,
    { isLoading: isLoadingRevision, isError: isRevisionError },
  ] = useLazyGetChapterRevisionQuery()

  // Since the websocket connection is closed when the user navigates away
  // from the document, we need to refetch the highlights when the user returns
  const options = { refetchOnFocus: true }
  const [
    requestHighlights,
    { data: highlights, isLoading: isLoadingHighlights },
  ] = useLazyGetHighlightsQuery(options)

  const viewerKey = useAppSelector((state) => getViewerKey(state, revisionId))
  const editorState = useAppSelector((state) =>
    getViewerEditorState(state, revisionId)
  )

  const dispatch = useAppDispatch()
  useFootnoteEnumeration(editorState, (footnotes) => {
    dispatch(setFootnoteNumbers(footnotes))
  })

  const dispatchTransaction = useDispatchTransaction({
    documentType: "chapter",
    documentId: chapterId,
    revisionId,
  })

  const currentMark = useAppSelector(getCurrentMark)
  const markerColor = useAppSelector(getMarkerColor)

  const placeholders = useMemo(
    () => makePlaceholderDecorations({ l10n }),
    [l10n]
  )

  const highlightsDecoration = useMemo(
    () =>
      makeHighlightDecorations(
        editorState,
        highlights,
        markerColor,
        currentMark,
        placeholders(editorState)
      ),
    [currentMark, editorState, highlights, placeholders, markerColor]
  )

  const getDecorations = useCallback(
    () => highlightsDecoration,
    [highlightsDecoration]
  )

  useEffect(() => {
    requestRevision({ chapterId, revisionId })
    requestHighlights({ chapterId, revisionId })
  }, [chapterId, revisionId, requestRevision, requestHighlights])

  const remoteHighlightsHandler = useRemoteHighlights({ revisionId })
  const remoteMessagesHandler = useRemoteMessages({ revisionId })

  // We schedule the connection to the websocket server so that this request
  // doesn't block more critical requests, such as fetching revision data.
  const scheduleWebsocketConnect = useCallback(
    ({ chapterId, revisionId }: { revisionId: UUID; chapterId: UUID }) => {
      let ws: WebsocketClientConnection | null = null

      const timeout = setTimeout(() => {
        ws = websocketClient.connect(chapterId, revisionId, {
          pauseConnectionOnIdleClient: true,
          onReconnectSuccess: () =>
            enqueueSnackbar(Snackbar.ReconnectWebsocket),
          onConnectionError: () =>
            enqueueSnackbar(Snackbar.LostWebsocketConnection),
        })
        ws.subscribe(SocketTopics.Highlights, remoteHighlightsHandler)
        ws.subscribe(SocketTopics.Threads, remoteMessagesHandler)
      }, 500)

      return () => {
        clearTimeout(timeout)
        if (ws) ws.closeConnection()
      }
    },
    [remoteHighlightsHandler, remoteMessagesHandler]
  )

  useEffect(() => {
    if (isRevisionError) return

    const cleanupConnection = scheduleWebsocketConnect({
      chapterId,
      revisionId,
    })

    return cleanupConnection
  }, [chapterId, revisionId, isRevisionError, scheduleWebsocketConnect])

  if (isLoadingHighlights || isLoadingRevision)
    return (
      <Localized id={"loading-document"}>
        <div className={editorStyles["warning"]}>Loading document…</div>
      </Localized>
    )

  // The error is caught by the ErrorBoundary in ViewerProvider
  if (isRevisionError) throw new Error("Error loading revision")

  if (!editorState) return null

  return (
    <ProseMirror
      key={viewerKey}
      state={editorState}
      nodeViews={chapterViewerNodeViews}
      dispatchTransaction={dispatchTransaction}
      editable={() => false}
      decorations={getDecorations}
      attributes={{
        "data-variant": PanelType.VIEWER,
        style: templateStyleProperties(editorState.doc.attrs.headingStyles),
      }}
    >
      <WithAside>
        <div className={editorStyles["editor"]}>
          <ViewerToolbar />
          <Frontmatter />
          <CreateHighlightPopover />
          <UpdateHighlightPopover />
          <SelectLinkHandler />
          <BlockHandleMenu />
          <DialogManager>
            <ExpiredSessionDialog />
          </DialogManager>
          <Keymap />
          <ScrollToPlugin />
          <Threads />
          <Flashcards />
          <ProseMirrorDoc />
          <EditorDebugger />
          <StepThroughMenu />
          <CitationDragRenderer />
        </div>
      </WithAside>
    </ProseMirror>
  )
}
