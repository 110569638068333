import { Button } from "@ariakit/react"
import { useLocalization } from "@fluent/react"
import { ProseMirror, ProseMirrorDoc } from "@nytimes/react-prosemirror"
import clsx from "clsx"
import type { Transaction } from "prosemirror-state"
import { EditorView } from "prosemirror-view"
import { type ComponentProps, useCallback, useMemo, useState } from "react"

import styles from "./messageEditor.module.css"

import { Keymap } from "@/components/Keymap.ts"
import { makeDocumentPlaceholder } from "@/components/editor/plugins/placeholder"
import { Cancel, Plane } from "@/components/icons.tsx"
import { Mention } from "@/components/viewer/notes/Mention.node"
import { MentionSelect } from "@/components/viewer/notes/MentionSelect"
import { AtomViewContext } from "@/contexts/AtomViewContext"
import { createEditorState } from "@/schemas/message/createEditorState"
import type { UUID } from "@/store/UUID.ts"
import {
  useCreateMessageMutation,
  useUpdateMessageMutation,
} from "@/store/slices/api"
import type { Message } from "@/types/api"
import { isEditorDocEmpty } from "@/utils/prosemirror"
import { sendErrorToSentry } from "@/utils/sentry"

const reactNodeViews: ComponentProps<typeof ProseMirror>["nodeViews"] = {
  mention: Mention,
}

type MessageEditorProps = {
  highlightId: UUID
  message?: Message
  unmount?: () => void
}

export function MessageEditor({
  highlightId,
  message: initialMessage,
  unmount,
}: MessageEditorProps) {
  const [atomView, setAtomView] = useState<EditorView | null>(null)

  const atomViewContextValue = useMemo(
    () => ({ atomView, setAtomView }),
    [atomView]
  )

  const { l10n } = useLocalization()
  const [createMessage, { isLoading: isCreatingMessage }] =
    useCreateMessageMutation()
  const [updateMessage, { isLoading: isUpdatingMessage }] =
    useUpdateMessageMutation()

  const memoizedCreateMessage = useCallback(createMessage, [createMessage])
  const memoizedUpdateMessage = useCallback(updateMessage, [updateMessage])

  const [editorState, setEditorState] = useState(
    createEditorState(initialMessage)
  )

  const handleSaveClick = useCallback(async () => {
    try {
      const content = editorState.doc.toJSON()
      await memoizedCreateMessage({ highlightId, content }).unwrap()
      setEditorState(createEditorState(undefined))
    } catch (error) {
      sendErrorToSentry(`Could not create message`, error)
    }
  }, [editorState, highlightId, memoizedCreateMessage])

  const handleUpdateClick = useCallback(async () => {
    if (!initialMessage) return
    try {
      await memoizedUpdateMessage({
        highlightId,
        messageId: initialMessage.id,
        content: editorState.doc.toJSON(),
      }).unwrap()
      unmount?.()
    } catch (error) {
      sendErrorToSentry(`Could not update message`, error)
    }
  }, [editorState, highlightId, initialMessage, unmount, memoizedUpdateMessage])

  const shortcuts = useMemo(
    () => ({
      "Mod-Enter": () => {
        if (initialMessage) handleUpdateClick()
        else handleSaveClick()
        return true
      },
    }),
    [handleSaveClick, handleUpdateClick, initialMessage]
  )

  const isEditorEmpty = useMemo(
    () => isEditorDocEmpty(editorState.doc),
    [editorState]
  )

  const dispatchTransaction = useCallback((tr: Transaction) => {
    setEditorState((currentState) => {
      try {
        return currentState.apply(tr)
      } catch (error) {
        sendErrorToSentry(`Could not apply message editor transaction`, error)
        return currentState
      }
    })
  }, [])

  return (
    <AtomViewContext.Provider value={atomViewContextValue}>
      <div
        className={clsx(
          styles["messageEditorWrapper"],
          initialMessage && styles["editing"]
        )}
      >
        <div className={styles["inputWrapper"]}>
          <ProseMirror
            state={editorState}
            nodeViews={reactNodeViews}
            dispatchTransaction={dispatchTransaction}
            attributes={{
              "data-variant": "message",
              class: styles["messageEditor"] ?? "",
              ...makeDocumentPlaceholder({ l10n, doc: editorState.doc }),
            }}
          >
            {/* Keymap should be placed before MentionSelect */}
            <Keymap additionalShortcuts={shortcuts} />
            <MentionSelect />
            {/* so that event listeners of MentionSelect have higher priority */}
            <ProseMirrorDoc />
          </ProseMirror>
        </div>
        <div className={styles["buttons"]}>
          <Button
            className={styles["sendButton"]}
            disabled={isEditorEmpty || isCreatingMessage || isUpdatingMessage}
            onClick={initialMessage ? handleUpdateClick : handleSaveClick}
          >
            <Plane />
          </Button>
          {initialMessage && unmount && (
            <Button onClick={unmount} className={styles["sendButton"]}>
              <Cancel />
            </Button>
          )}
        </div>
      </div>
    </AtomViewContext.Provider>
  )
}
