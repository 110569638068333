import {
  Button,
  Dialog,
  DialogDescription,
  DialogDismiss,
  DialogHeading,
  useDialogStore,
} from "@ariakit/react"
import { Localized } from "@fluent/react"
import { clsx } from "clsx"
import { useLayoutEffect, useState } from "react"

import styles from "./FlashcardDeck.module.css"
import { FlashcardDeckProgressIndicator } from "./FlashcardDeckProgressIndicator.tsx"
import { Flashcard, FlashcardSkeleton } from "./components/Flashcard"
import { FlashcardDeckMenu } from "./components/FlashcardDeckMenu"
import { FlashcardDeckSettingsDialog } from "./components/FlashcardDeckSettingsDialog.tsx"
import { FlashcardEditor } from "./components/FlashcardEditor/FlashcardEditor"

import dialogStyles from "@/components/editor/dialogs/dialog.module.css"
import { generateGuid } from "@/components/editor/plugins/guid.ts"
import { Minus, Plus } from "@/components/icons.tsx"
import sharedStyles from "@/components/shared.module.css"
import { FlashcardDeckContext } from "@/contexts/FlashcardDeckContext"
import type { UUID } from "@/store/UUID"
import { useAppDispatch, useAppSelector } from "@/store/hooks.ts"
import {
  useGetFlashcardDeckQuery,
  useToggleFlashcardDeckSavedMutation,
} from "@/store/slices/api"
import { flashcardsLearningSlice } from "@/store/slices/flashcardLearning.ts"
import { PanelType, panelsSlice } from "@/store/slices/panels.ts"
import { studyFlashcardsClicked } from "@/store/thunks/studyFlashcardsClicked.ts"
import { PageHeader } from "@/ui/PageHeader/PageHeader.tsx"

type FlashcardDeckProps = {
  deckId: UUID
}

export function FlashcardDeck({ deckId }: FlashcardDeckProps) {
  const [editorCardId, setEditorCardId] = useState<UUID | undefined>()
  const [showSettings, setShowSettings] = useState(false)

  const editorDialog = useDialogStore({ open: !!editorCardId })

  const { data: deck, isError } = useGetFlashcardDeckQuery({ deckId })
  const [toggleDeckSaved] = useToggleFlashcardDeckSavedMutation()

  const dispatch = useAppDispatch()

  const { showPopover } = useAppSelector((state) => state.flashcardsLearning)
  const studySessionDialog = useDialogStore({ open: showPopover })

  const handleToggleDeckSaved = () => {
    toggleDeckSaved({ deckId })
  }

  // Check if the URL has a sessionId search parameter and if so, open the
  // study view for that session
  useLayoutEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.get("sessionId")) {
      dispatch(
        panelsSlice.actions.panelsReplaced({
          panelId: generateGuid(),
          panelType: PanelType.FLASHCARD_STUDY_VIEW,
          documentId: searchParams.get("sessionId") as UUID,
        })
      )
    }
  }, [deckId, dispatch])

  if (isError)
    return (
      <div>
        <Localized id="flashcard-deck-error-loading">
          An error occurred.
        </Localized>
      </div>
    )

  return (
    <FlashcardDeckContext.Provider value={{ deckId }}>
      <div className={styles.flashcardDeckPanel}>
        <PageHeader title={deck?.title} description={deck?.description}>
          <>
            <FlashcardDeckProgressIndicator deck={deck} />
            {!deck?.isUserOwner && (
              <Button
                className={sharedStyles["defaultButton"]}
                onClick={handleToggleDeckSaved}
              >
                {deck?.hasUserSavedDeck ? (
                  <>
                    <Minus />
                    <Localized id={"flashcards-deck-remove-from-my-decks"}>
                      Remove from My Decks
                    </Localized>
                  </>
                ) : (
                  <>
                    <Plus />
                    <Localized id={"flashcards-deck-add-to-my-decks"}>
                      Save to My Decks
                    </Localized>
                  </>
                )}
              </Button>
            )}
          </>
        </PageHeader>
        <div className={styles.flashcardDeckGrid}>
          {deck?.flashcards.length ? (
            deck.flashcards.map((flashcard) => (
              <Flashcard
                key={flashcard.id}
                flashcard={flashcard}
                openFlashcardEditor={setEditorCardId}
              />
            ))
          ) : (
            <FlashcardSkeleton />
          )}
        </div>
        {/*  */}
        <FlashcardDeckMenu
          openFlashcardEditor={setEditorCardId}
          openSettings={setShowSettings}
        />
        {/*  */}
        <Dialog
          store={editorDialog}
          onClose={() => setEditorCardId(undefined)}
          hideOnInteractOutside={false}
          hideOnEscape={false}
          backdrop={<div className={dialogStyles.backdrop} />}
          className={dialogStyles.dialog}
          style={{ padding: 0 }}
          portal
        >
          {editorCardId ? (
            <FlashcardEditor id={editorCardId} dialog={editorDialog} />
          ) : null}
        </Dialog>
        <Dialog
          store={studySessionDialog}
          onClose={() =>
            dispatch(
              flashcardsLearningSlice.actions.setPopover({ show: false })
            )
          }
          hideOnInteractOutside={false}
          hideOnEscape={false}
          backdrop={<div className={dialogStyles.backdrop} />}
          className={sharedStyles.dialog}
          portal
        >
          <DialogHeading className={sharedStyles["dialogHeading"]}>
            <Localized id={"flashcard-learning-no-cards-due-title"}>
              No cards due today 🎉
            </Localized>
          </DialogHeading>
          <DialogDescription>
            <Localized id="flashcard-learning-no-cards-due-description">
              There are no flashcards due today. You can take a break or study
              ahead.
            </Localized>
          </DialogDescription>
          <div className={sharedStyles["dialogButtons"]}>
            <Button
              className={sharedStyles["defaultButton"]}
              onClick={() =>
                dispatch(
                  studyFlashcardsClicked({ deckId, ignoreDueDate: true })
                )
              }
            >
              <Localized id="flashcard-learning-study-ahead">
                Study Ahead
              </Localized>
            </Button>
            <DialogDismiss
              className={clsx(
                sharedStyles["defaultButton"],
                sharedStyles["secondaryButton"]
              )}
              store={studySessionDialog}
              onClick={() =>
                dispatch(
                  flashcardsLearningSlice.actions.setPopover({ show: false })
                )
              }
            >
              <Localized id={"cancel"}>Cancel</Localized>
            </DialogDismiss>
          </div>
        </Dialog>
        <FlashcardDeckSettingsDialog
          showSettings={showSettings}
          setShowSettings={setShowSettings}
        />
      </div>
    </FlashcardDeckContext.Provider>
  )
}
