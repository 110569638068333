import { MenuItem } from "@ariakit/react"
import { useEditorState } from "@nytimes/react-prosemirror"
import { useCallback } from "react"

import { insertPDF } from "@/commands/pdfs"
import styles from "@/components/editor/toolbar/toolbar.module.css"
import { Document } from "@/components/icons.tsx"
import { useAppDispatch } from "@/store/hooks"
import { Dialog } from "@/store/slices/dialogs"
import { dialogOpened } from "@/store/store"

export function InsertPDFItem() {
  const dispatch = useAppDispatch()

  const onClick = useCallback(() => {
    dispatch(dialogOpened(Dialog.INSERT_PDF))
  }, [dispatch])

  const editorState = useEditorState()

  const disabled = !editorState || !insertPDF()(editorState)

  return (
    <>
      <MenuItem
        onClick={onClick}
        className={styles["editorToolbarDropdownItem"]}
        disabled={disabled}
      >
        <Document />
        <span>PDF</span>
      </MenuItem>
    </>
  )
}
