import { Localized, useLocalization } from "@fluent/react"
import { ProseMirror, ProseMirrorDoc } from "@nytimes/react-prosemirror"
import { type EditorView } from "prosemirror-view"
import {
  type ComponentProps,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"

import { Dialogs } from "./dialogs/Dialogs.tsx"
import styles from "./editor.module.css"
import {
  Foldable,
  FoldableContent,
  FoldableSummary,
} from "./nodeViews/Foldable/Foldable.node.tsx"
import { Footnote } from "./nodeViews/Footnote/Footnote.node.tsx"
import { useFootnoteEnumeration } from "./nodeViews/Footnote/useFootnoteEnumeration.tsx"
import { ImportWarning } from "./nodeViews/ImportWarning/ImportWarning.node.tsx"
import { PDF } from "./nodeViews/PDF/PDF.node.tsx"
import {
  QuizAnswer,
  QuizAnswers,
  QuizExplanation,
  QuizQuestion,
} from "./nodeViews/Quiz/Quiz.node.tsx"
import { ReviewSchema } from "./nodeViews/ReviewSchema/ReviewSchema.node.tsx"
import { StepMarker } from "./nodeViews/StepMarker/StepMarker.node.tsx"
import { Table } from "./nodeViews/Table/Table.node.tsx"
import { EditorDebugger } from "./plugins/EditorDebugger.tsx"
import {
  makeDocumentPlaceholder,
  makePlaceholderDecorationSet,
} from "./plugins/placeholder.ts"
import { BlockHandleMenu } from "./popovers/BlockHandleMenu.tsx"
import { FloatingToolbar } from "./popovers/FloatingToolbar.tsx"
import { UpdateLinkPopover } from "./popovers/UpdateLinkPopover.tsx"
import { EditorToolbar } from "./toolbar/EditorToolbar"

import { useLongPollClient } from "@/communication/polling/useLongPollClient.ts"
import { Keymap } from "@/components/Keymap"
import { WithAside } from "@/components/aside/WithAside"
import { Frontmatter } from "@/components/frontmatter/Frontmatter.tsx"
import { useAsideTableOfContents } from "@/components/tableOfContents/TableOfContents"
import { AtomViewContext } from "@/contexts/AtomViewContext"
import {
  RevisionContext,
  type RevisionContextValue,
} from "@/contexts/RevisionContext"
import type { UUID } from "@/store/UUID.ts"
import { useAppDispatch, useAppSelector } from "@/store/hooks"
import { getRevisionEditorState } from "@/store/selectors/documentsSelectors.ts"
import {
  useGetChapterQuery,
  useGetLinkableResourcesQuery,
  useLazyGetChapterRevisionQuery,
} from "@/store/slices/api.ts"
import { PanelType } from "@/store/slices/panels"
import {
  useDispatchTransaction,
  useRemoteSteps,
} from "@/store/slices/revisions.ts"
import { setFootnoteNumbers } from "@/store/store.ts"
import { ErrorBoundary } from "@/utils/error.ts"
import { templateStyleProperties } from "@/utils/headingStyles.ts"

const reactNodeViews: ComponentProps<typeof ProseMirror>["nodeViews"] = {
  review_schema: ReviewSchema,
  quiz_answer: QuizAnswer,
  quiz_answers: QuizAnswers,
  quiz_explanation: QuizExplanation,
  quiz_question: QuizQuestion,
  foldable: Foldable,
  foldable_content: FoldableContent,
  foldable_summary: FoldableSummary,
  footnote: Footnote,
  pdf: PDF,
  table: Table,
  step_marker: StepMarker,
  import_warning: ImportWarning,
}

function EditorProvider({ chapterId }: { chapterId: UUID }) {
  const [atomView, setAtomView] = useState<EditorView | null>(null)

  const { data: chapter, isLoading } = useGetChapterQuery(chapterId)
  const revisionId = chapter?.draftRevisionId

  const atomViewContextValue = useMemo(
    () => ({ atomView, setAtomView }),
    [atomView]
  )

  const revisionContextValue = useMemo(
    () => ({ revisionId, documentType: "chapter", documentId: chapterId }),
    [revisionId, chapterId]
  )

  const AsideTableOfContents = useAsideTableOfContents()

  return (
    <RevisionContext.Provider
      value={revisionContextValue as RevisionContextValue}
    >
      <AtomViewContext.Provider value={atomViewContextValue}>
        {AsideTableOfContents}
        {isLoading ? null : (
          <ErrorBoundary fallback={<EditorErrorFallback />}>
            <EditorComponent chapterId={chapterId} />
          </ErrorBoundary>
        )}
      </AtomViewContext.Provider>
    </RevisionContext.Provider>
  )
}

export const Editor = EditorProvider

/**
 * This is the component that renders the ProseMirror view.
 * We keep this in a separate component so that we avoid re-rendering
 * the EditorProvider each time the editor state changes.
 */

function EditorComponent({ chapterId }: { chapterId: UUID }) {
  const { l10n } = useLocalization()
  const { revisionId } = useContext(RevisionContext)

  const dispatchTransaction = useDispatchTransaction({
    documentType: "chapter",
    documentId: chapterId,
    revisionId,
  })
  const [requestRevision, { isLoading, isError: isRevisionError }] =
    useLazyGetChapterRevisionQuery()

  useGetLinkableResourcesQuery()

  useEffect(() => {
    if (revisionId) requestRevision({ chapterId, revisionId })
  }, [revisionId, chapterId, requestRevision])

  const editorState = useAppSelector((state) =>
    getRevisionEditorState(state, revisionId)
  )

  const dispatch = useAppDispatch()

  useFootnoteEnumeration(editorState, (footnotes) => {
    dispatch(setFootnoteNumbers(footnotes))
  })

  const handlers = useRemoteSteps({
    documentType: "chapter",
    documentId: chapterId,
    revisionId,
  })
  const [initLongPolling, connection] = useLongPollClient(
    "chapter",
    chapterId,
    handlers
  )

  useEffect(() => {
    if (editorState && !connection?.isActive) initLongPolling({ editorState })
  }, [editorState, connection, initLongPolling])

  const placeholders = useMemo(
    () => makePlaceholderDecorationSet({ l10n }),
    [l10n]
  )

  if (isLoading)
    return <Localized id="loading-document">Loading document…</Localized>

  // The error is handled by the ErrorBoundary in EditorProvider
  if (isRevisionError) throw new Error("Error loading revision.")

  if (!editorState || !revisionId) return null

  return (
    <ProseMirror
      state={editorState}
      decorations={placeholders}
      nodeViews={reactNodeViews}
      dispatchTransaction={dispatchTransaction}
      attributes={{
        "data-variant": PanelType.EDITOR,
        style: templateStyleProperties(editorState.doc.attrs.headingStyles),
        ...makeDocumentPlaceholder({ l10n, doc: editorState.doc }),
      }}
    >
      <WithAside>
        <div className={styles["editor"]}>
          <EditorToolbar />
          <Frontmatter />
          <Keymap />
          <FloatingToolbar />
          <Dialogs />
          <UpdateLinkPopover />
          <BlockHandleMenu />
          <ProseMirrorDoc />
          <EditorDebugger />
        </div>
      </WithAside>
    </ProseMirror>
  )
}

/**
 * A fallback component to display when the editor/viewer fails to load.
 */

export function EditorErrorFallback() {
  return (
    <div className={styles["warning"]}>
      <Localized id={"error-loading-document"}>
        Error loading document.
      </Localized>
      <Localized id={"error-loading-document-description"}>
        <span>Please try reloading the page.</span>
      </Localized>
    </div>
  )
}
