import { useEffect, useRef, useState } from "react"

import toolbarStyles from "@/components/editor/toolbar/toolbar.module.css"

/**
 * Progress bar showing the scroll progress of the document
 */
export function ProgressBar() {
  const [progress, setProgress] = useState(0)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const panel = ref.current?.closest(`[data-panel]`)
    const handleScroll = () => {
      if (!panel) return
      const { scrollTop, scrollHeight, clientHeight } = panel
      const newProgress = (scrollTop / (scrollHeight - clientHeight)) * 100
      setProgress(newProgress)
    }
    panel?.addEventListener("scroll", handleScroll)
    return () => {
      panel?.removeEventListener("scroll", handleScroll)
    }
  }, [ref])

  return (
    <div
      ref={ref}
      className={toolbarStyles["progressBar"]}
      style={{ width: `${progress}%` }}
    />
  )
}
