import { ConflictingChangesDialog } from "./ConflictingChangesDialog"
import { DialogManager } from "./DialogManager"
import { ExpiredSessionDialog } from "./ExpiredSessionDialog"
import { GenerateQuizDialog } from "./GenerateQuizDialog.tsx"
import { InsertFileDialog } from "./InsertFileDialog"
import { InsertLinkDialog } from "./InsertLinkDialog"
import { InsertVideoDialog } from "./InsertVideoDialog"
import { LostConnectionDialog } from "./LostConnectionDialog"
import { SettingsChapter } from "./SettingsChapter/SettingsChapter.tsx"

import { useFocusView } from "@/hooks/useFocusView"

export function Dialogs() {
  const focusView = useFocusView()
  return (
    <DialogManager>
      <InsertLinkDialog onConfirm={focusView} />
      <InsertFileDialog onConfirm={focusView} />
      <InsertVideoDialog onConfirm={focusView} />
      <GenerateQuizDialog />
      <LostConnectionDialog />
      <ConflictingChangesDialog />
      <ExpiredSessionDialog />
      <SettingsChapter />
    </DialogManager>
  )
}
