import {
  Button,
  Popover,
  PopoverArrow,
  PopoverDescription,
  PopoverDisclosure,
  PopoverDismiss,
  PopoverHeading,
  usePopoverStore,
} from "@ariakit/react"
import { Localized } from "@fluent/react"
import {
  useEditorEventCallback,
  useEditorState,
} from "@nytimes/react-prosemirror"
import clsx from "clsx"
import { useContext, useMemo } from "react"

import styles from "@/components/editor/toolbar/toolbar.module.css"
import { Steps } from "@/components/icons.tsx"
import sharedStyles from "@/components/shared.module.css"
import { RevisionContext } from "@/contexts/RevisionContext.ts"
import { chapterSchema } from "@/schemas/chapter/schema"
import { useAppDispatch, useAppSelector } from "@/store/hooks.ts"
import { getStepThroughState } from "@/store/selectors/viewerSelectors"
import { viewerSlice } from "@/store/slices/viewer"
import { getScrollContainer } from "@/utils/scroll"

export function ToggleStepThroughItem() {
  const dispatch = useAppDispatch()
  const { revisionId } = useContext(RevisionContext)
  const stepThroughState = useAppSelector((state) =>
    getStepThroughState(state, revisionId)
  )
  const editorState = useEditorState()

  const numStepMarkers = useMemo(() => {
    if (!editorState) return 0

    let count = 0
    editorState.doc.forEach((node) => {
      if (node.type === chapterSchema.nodes.step_marker) {
        count++
      }
    })
    return count
  }, [editorState])

  const getScrollTop = useEditorEventCallback((view) => {
    const scrollContainer = getScrollContainer(view.dom)
    return scrollContainer.scrollTop
  })

  const popoverStore = usePopoverStore({
    defaultOpen: !stepThroughState?.enabled,
  })

  const handleStepThroughToggle = () => {
    popoverStore.setOpen(false)
    dispatch(
      viewerSlice.actions.stepThroughEnabled({
        revisionId,
        length: numStepMarkers + 1,
        scroll: getScrollTop(),
      })
    )
  }

  if (!numStepMarkers) return null

  return (
    <>
      <PopoverDisclosure
        store={popoverStore}
        className={styles["editorToolbarItem"]}
        disabled={stepThroughState?.enabled}
        style={{ position: "relative" }}
        onClick={() => handleStepThroughToggle()}
      >
        <Steps />
        <Localized id={"step-through-mode"}>Step Through Mode</Localized>
      </PopoverDisclosure>
      <Popover
        store={popoverStore}
        className={styles["editorToolbarPopover"]}
        hideOnInteractOutside={false}
      >
        <PopoverArrow />
        <PopoverHeading className={styles["editorToolbarPopoverHeading"]}>
          <Localized id={"step-through-mode"}>Step Through Mode</Localized>
        </PopoverHeading>
        <PopoverDescription
          className={styles["editorToolbarPopoverDescription"]}
        >
          <Localized id={"step-through-mode-description"}>
            This document can be navigated step-by-step to help you work through
            the content in a structured way.
          </Localized>
        </PopoverDescription>
        <div className={styles["editorToolbarPopoverButtons"]}>
          <Button
            className={sharedStyles["defaultButton"]}
            onClick={() => handleStepThroughToggle()}
          >
            <Localized id={"begin"}>Begin</Localized>
          </Button>
          <PopoverDismiss
            className={clsx(
              sharedStyles["defaultButton"],
              sharedStyles["secondaryButton"]
            )}
          >
            <Localized id={"close"}>Close</Localized>
          </PopoverDismiss>
        </div>
      </Popover>
    </>
  )
}
