import { useEditorEventListener } from "@nytimes/react-prosemirror"
import { Fragment, Node } from "prosemirror-model"
import { DecorationSet } from "prosemirror-view"
import { useContext, useLayoutEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"

import styles from "./citationDragRenderer.module.css"

import { Citation } from "@/components/note/nodeViews/Citation"
import { RevisionContext } from "@/contexts/RevisionContext"
import { noteSchema } from "@/schemas/note/schema"
import { useAppDispatch } from "@/store/hooks"
import { citationsSlice } from "@/store/slices/citations"

export function CitationDragRenderer() {
  const { documentId, revisionId } = useContext(RevisionContext)
  const dispatch = useAppDispatch()

  const dataTransferRef = useRef<DataTransfer | null>(null)
  const [container, setContainer] = useState<HTMLDivElement | null>(null)
  const [imageElement, setImageElement] = useState<HTMLDivElement | null>(null)
  const [citationNode, setCitationNode] = useState<Node | null>(null)

  useLayoutEffect(() => {
    if (dataTransferRef.current && imageElement) {
      const { width } = imageElement.getBoundingClientRect()
      dataTransferRef.current.setDragImage(imageElement, width, 0)
    }
  }, [imageElement])

  useEditorEventListener("dragend", (_view, event) => {
    if (event.dataTransfer?.dropEffect === "none") {
      dispatch(citationsSlice.actions.citationDragAborted())
    }

    if (container) {
      container.remove()
      setContainer(null)
    }

    return false
  })

  useEditorEventListener("dragstart", (view, event) => {
    const dataTransfer = event.dataTransfer
    if (dataTransfer) {
      dataTransfer.effectAllowed = "link"
      dataTransferRef.current = dataTransfer
    }

    const newContainer = document.createElement("div")
    newContainer.style.position = "absolute"
    newContainer.style.left = `-2000px`
    newContainer.style.top = "0"
    document.body.append(newContainer)
    setContainer(newContainer)

    const slice = view.state.doc.slice(
      view.state.selection.from,
      view.state.selection.to
    )

    const noteFragment = Fragment.fromJSON(noteSchema, slice.content.toJSON())
    const wrapping = noteSchema.nodes.citation.contentMatch.findWrapping(
      noteFragment.child(0).type
    )
    const wrappingTypes = wrapping ? [...wrapping].reverse() : wrapping
    const wrapped = noteSchema.nodes.citation.createAndFill(
      null,
      wrappingTypes?.reduce(
        (acc, wrapperType) => Fragment.from(wrapperType.create(null, acc)),
        noteFragment
      )
    )

    setCitationNode(wrapped)

    dispatch(
      citationsSlice.actions.citationDragStarted({
        documentId,
        revisionId,
        from: view.state.selection.from,
        to: view.state.selection.to,
      })
    )
    return true
  })

  return (
    container &&
    citationNode &&
    createPortal(
      <div className={styles["wrapper"]} ref={setImageElement}>
        <Citation
          nodeProps={{
            node: citationNode,
            pos: 0,
            decorations: [],
            isSelected: false,
            innerDecorations: DecorationSet.empty,
          }}
        />
      </div>,
      container
    )
  )
}
