import { Button, Checkbox, GroupLabel, useCheckboxStore } from "@ariakit/react"
import {
  type NodeViewComponentProps,
  useEditorEventCallback,
} from "@nytimes/react-prosemirror"
import { forwardRef } from "react"

import quizStyles from "./quiz.module.css"

import {
  addAnswerToQuiz,
  removeAnswerFromQuiz,
  setAnswerIsCorrect,
} from "@/commands/quizzes"
import { Plus, Trash } from "@/components/icons.tsx"
import { useLocalizedString } from "@/hooks/useLocalizedString"
import { Tooltip } from "@/ui/Tooltip"

export const QuizQuestion = forwardRef<HTMLDivElement, NodeViewComponentProps>(
  function QuizQuestion({ children, nodeProps: _, ...props }, ref) {
    const label = useLocalizedString({
      id: "quiz-question",
      fallback: "Question",
    })

    return (
      <div ref={ref} {...props}>
        <span contentEditable={false} className={quizStyles.label}>
          {label}
        </span>
        <GroupLabel>{children}</GroupLabel>
      </div>
    )
  }
)

export const QuizAnswers = forwardRef<HTMLOListElement, NodeViewComponentProps>(
  function QuizAnswers({ children, nodeProps: _, ...props }, ref) {
    return (
      <ol ref={ref} {...props} className={quizStyles.answers}>
        {children}
      </ol>
    )
  }
)

export const QuizAnswer = forwardRef<HTMLLIElement, NodeViewComponentProps>(
  function QuizAnswer({ children, nodeProps, ...props }, ref) {
    const { node, pos } = nodeProps

    const onCheckboxChange = useEditorEventCallback(
      (view, isCorrect: boolean) => {
        setAnswerIsCorrect(pos, isCorrect)(view.state, view.dispatch)
      }
    )

    const onAddClick = useEditorEventCallback((view) => {
      if (!view) return
      addAnswerToQuiz(pos + 1)(view.state, view.dispatch)
      view.focus()
    })

    const onDeleteClick = useEditorEventCallback((view) => {
      removeAnswerFromQuiz(node, pos)(view.state, view.dispatch)
    })

    const checkboxStore = useCheckboxStore({
      value: node.attrs["isCorrect"] as boolean,
      setValue: onCheckboxChange,
    })

    const addTooltip = useLocalizedString({
      id: "tooltip-quiz-node-add-answer",
    })

    const removeTooltip = useLocalizedString({
      id: "tooltip-quiz-node-delete-answer",
    })

    return (
      <li
        ref={ref}
        {...props}
        className={quizStyles.answer}
        data-is-correct={node.attrs["isCorrect"] ?? false}
      >
        <div className={quizStyles.checkboxWrapper} contentEditable={false}>
          <Checkbox store={checkboxStore}></Checkbox>
        </div>
        <div className={quizStyles.inputWrapper}>{children}</div>
        <div className={quizStyles.answerActions} contentEditable={false}>
          <Tooltip title={removeTooltip}>
            <Button
              onClick={onDeleteClick}
              contentEditable={false}
              className={quizStyles.button}
            >
              <Trash />
            </Button>
          </Tooltip>
          <Tooltip title={addTooltip}>
            <Button
              onClick={onAddClick}
              contentEditable={false}
              className={quizStyles.button}
            >
              <Plus />
            </Button>
          </Tooltip>
        </div>
      </li>
    )
  }
)

export const QuizExplanation = forwardRef<
  HTMLDivElement,
  NodeViewComponentProps
>(function QuizExplanation({ children, nodeProps: _, ...props }, ref) {
  const label = useLocalizedString({
    id: "quiz-explanation",
    fallback: "Explanation",
  })

  return (
    <div ref={ref} {...props}>
      <span contentEditable={false} className={quizStyles.label}>
        {label}
      </span>
      {children}
    </div>
  )
})
