import { VisuallyHidden } from "@ariakit/react"
import clsx from "clsx"
import { type ComponentPropsWithRef, type ReactNode, forwardRef } from "react"

import styles from "./IconButton.module.css"

type IconButtonProps = {
  icon: ReactNode
  label: string
  size: "small" | "medium" | "large"
} & ComponentPropsWithRef<"button">

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  function IconButton(props, ref) {
    const { icon, className, label, size = "medium", ...rest } = props
    return (
      <button
        {...rest}
        className={clsx(className, styles.iconButton, styles[size])}
        ref={ref}
      >
        <VisuallyHidden>{label}</VisuallyHidden>
        {icon}
      </button>
    )
  }
)
