import { useEditorEffect } from "@nytimes/react-prosemirror"
import { type EditorState, TextSelection } from "prosemirror-state"
import type { EditorView } from "prosemirror-view"

import { DocAttrStep } from "@/steps/DocAttrStep"
import { type JSONContent, type JSONSelection } from "@/types/utils"
import { padLogger } from "@/utils/debug"

declare global {
  interface Node {
    pmView?: EditorView
    pmState?: () => EditorState
    pmSetContent?: (doc: JSONContent) => void
    pmSetSelection?: (selection: number | JSONSelection) => void
  }
}

/**
 * This plugin attaches the ProseMirror state to the DOM node.
 * This helps inspecting the state in the console as well as
 * retrieving the state within E2E tests.
 */

export function EditorDebugger() {
  useEditorEffect((view) => {
    padLogger("Mounting ReactEditorView", view.state.doc.attrs)

    view.dom.pmState = () => view.state
    view.dom.pmSetSelection = (selection) => {
      view.dispatch(
        view.state.tr.setSelection(
          typeof selection === "number"
            ? TextSelection.near(view.state.doc.resolve(selection))
            : TextSelection.fromJSON(view.state.doc, selection)
        )
      )
    }
    view.dom.pmSetContent = (doc) => {
      view.dispatch(
        view.state.tr
          .replaceWith(
            0,
            view.state.doc.content.size,
            view.state.schema.nodeFromJSON(doc)
          )
          .step(
            new DocAttrStep(
              "marginNumbers",
              doc.attrs?.marginNumbers ?? view.state.doc.attrs.marginNumbers
            )
          )
          .step(
            new DocAttrStep(
              "headingStyles",
              doc.attrs?.headingStyles ?? view.state.doc.attrs.headingStyles
            )
          )
          .setMeta("EditorDebugger_SetContent", true)
      )
    }
    view.dom.pmView = view

    return () => {
      padLogger("Unmounting ReactEditorView", view.state.doc.attrs)

      delete view.dom.pmView
      delete view.dom.pmState
      delete view.dom.pmSetContent
      delete view.dom.pmSetSelection
    }
  }, [])

  return null
}
