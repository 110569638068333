import {
  useEditorEffect,
  useEditorEventListener,
} from "@nytimes/react-prosemirror"
import type { ResolvedPos } from "prosemirror-model"
import type { EditorState } from "prosemirror-state"
import { Decoration, DecorationSet } from "prosemirror-view"
import { useState } from "react"

export const DEFAULT_SUGGESTION_CLASS = "suggestion"

type SuggestionProps = {
  char: string
  minLength?: number
  decorationClass?: string
  allowSpaces?: boolean
  allowedPrefixes?: string[]
  startOfLine?: boolean
  allow?: (args: {
    state: EditorState
    range: { from: number; to: number }
  }) => boolean
}

export function useSuggestion({
  char,
  minLength = 1,
  decorationClass,
  allowSpaces = false,
  allowedPrefixes = [" "],
  startOfLine = false,
  allow = () => true,
}: SuggestionProps) {
  const [isDiscarded, setIsDiscarded] = useState(false)
  const [state, setState] = useState<SuggestionMatch | null>()

  useEditorEventListener("keydown", (_, event) => {
    if (event.key === "Escape" && state) {
      event.preventDefault()
      setState(null)
      setIsDiscarded(true)
    }
    if (event.key === char && isDiscarded) {
      setIsDiscarded(false)
    }
  })

  useEditorEffect((view) => {
    if (!view.editable || isDiscarded) return

    const { $from, from, to, empty } = view.state.selection

    if (!empty && !view.composing) {
      if (state) setState(null)
      return
    }

    const isBeyondRange =
      state && (from < state.range.from || to > state.range.to)

    if (!view.composing && isBeyondRange) {
      if (state) setState(null)
      return
    }

    const match = findSuggestionMatch({
      char,
      minLength,
      allowSpaces,
      allowedPrefixes,
      startOfLine,
      $position: $from,
    })

    if (match && allow({ state: view.state, range: match.range })) {
      if (!state || state.query !== match.query) setState(match)
    } else {
      if (state) setState(null)
    }
  })

  useEditorEffect(
    (view) => {
      view.props.decorations = (editorState) => {
        return state
          ? DecorationSet.create(editorState.doc, [
              Decoration.inline(state.range.from, state.range.to, {
                class: `${DEFAULT_SUGGESTION_CLASS} ${decorationClass ?? ""}`,
              }),
            ])
          : DecorationSet.create(editorState.doc, [])
      }
      view.update(view.props)
    },
    [state]
  )

  return [state, setState] as const
}

/**
 * HELPER
 */

export type Trigger = {
  char: string
  minLength: number
  allowSpaces: boolean
  allowedPrefixes: string[] | null
  startOfLine: boolean
  $position: ResolvedPos
}

export type SuggestionMatch = {
  range: { from: number; to: number }
  query: string
  text: string
} | null

// https://github.com/ueberdosis/tiptap/blob/develop/packages/suggestion/src/findSuggestionMatch.ts
export function findSuggestionMatch(config: Trigger): SuggestionMatch {
  const {
    char,
    minLength,
    allowSpaces,
    allowedPrefixes,
    startOfLine,
    $position,
  } = config

  const escapedChar = char.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&")
  const suffix = new RegExp(`\\s${escapedChar}$`)
  const prefix = startOfLine ? "^" : ""
  const regexp = allowSpaces
    ? new RegExp(`${prefix}${escapedChar}.*?(?=\\s${escapedChar}|$)`, "gm")
    : new RegExp(`${prefix}(?:^)?${escapedChar}[^\\s${escapedChar}]*`, "gm")

  const text = $position.nodeBefore?.isText && $position.nodeBefore.text

  if (!text) {
    return null
  }

  const textFrom = $position.pos - text.length
  const match = Array.from(text.matchAll(regexp)).pop()

  if (!match || match.input === undefined || match.index === undefined) {
    return null
  }

  // JavaScript doesn't have lookbehinds. This hacks a check that first character
  // is a space or the start of the line
  const matchPrefix = match.input.slice(
    Math.max(0, match.index - 1),
    match.index
  )
  const matchPrefixIsAllowed = new RegExp(
    `^[${allowedPrefixes?.join("")}\0]?$`
  ).test(matchPrefix)

  if (allowedPrefixes !== null && !matchPrefixIsAllowed) {
    return null
  }

  // The absolute position of the match in the document
  const from = textFrom + match.index
  let to = from + match[0].length

  // Edge case handling; if spaces are allowed and we're directly in between
  // two triggers
  if (allowSpaces && suffix.test(text.slice(to - 1, to + 1))) {
    match[0] += " "
    to += 1
  }

  // If the $position is located within the matched substring, return that range
  if (
    from < $position.pos &&
    to >= $position.pos &&
    match[0].length - char.length >= minLength
  ) {
    return {
      range: {
        from,
        to,
      },
      query: match[0].slice(char.length),
      text: match[0],
    }
  }

  return null
}
