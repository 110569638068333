import {
  Button,
  Form,
  FormInput,
  FormSubmit,
  useFormStore,
} from "@ariakit/react"
import { Localized } from "@fluent/react"
import { useState } from "react"

import styles from "./Notepad.module.css"

import type { UUID } from "@/store/UUID.ts"
import { useAppDispatch } from "@/store/hooks.ts"
import {
  useCreateNoteMutation,
  useCreateNotebookMutation,
  useGetNotebooksQuery,
} from "@/store/slices/api.ts"
import { panelsSlice } from "@/store/slices/panels.ts"
import type { Notebook } from "@/types/api.ts"

export function NotepadNoteSelector() {
  const { data, isLoading, isError } = useGetNotebooksQuery()
  const dispatch = useAppDispatch()

  if (isLoading) return <div>Loading...</div>
  if (isError || !data) return <div>An error occured</div>

  return (
    <div className={styles["notepadSelector"]}>
      <h3 className={styles["notepadSelectorHeading"]}>
        <Localized id={"notebook-select-no-note-selected"}>
          No note selected
        </Localized>
      </h3>
      <p className={styles["notepadSelectorDescription"]}>
        <Localized id={"notebook-select-description"}>
          Open a note from your notebooks from the list below
        </Localized>
      </p>
      <NewNotebook />
      <ol className={styles["notepadSelectorListNotebook"]}>
        {data.map((notebook) => (
          <NotebookItem notebook={notebook} key={notebook.id} />
        ))}
      </ol>
      <Button
        className={styles["notepadButton"]}
        onClick={() => dispatch(panelsSlice.actions.notepadToggled(false))}
      >
        <Localized id={"close"}>Close</Localized>
      </Button>
    </div>
  )
}

export function NewNotebook() {
  const [isCreateNewNotebook, setIsCreateNewNotebook] = useState(false)
  const [createNotebook] = useCreateNotebookMutation()

  const formStore = useFormStore({
    defaultValues: {
      title: "",
    },
  })

  formStore.useSubmit(async (values) => {
    const { title } = values.values
    createNotebook({ title }).finally(() => setIsCreateNewNotebook(false))
  })

  if (!isCreateNewNotebook) {
    return (
      <Button
        className={styles["notepadButton"]}
        onClick={() => setIsCreateNewNotebook(true)}
      >
        <Localized id={"notebook-notebook-new"}>New notebook</Localized>
      </Button>
    )
  }
  return (
    <Form store={formStore} className={styles["notepadCreateNotebookForm"]}>
      <FormInput name={"title"} placeholder={"Title of the notebook"} />
      <FormSubmit>
        <Localized id={"create"}>Create</Localized>
      </FormSubmit>
    </Form>
  )
}

export function NotebookItem({ notebook }: { notebook: Notebook }) {
  const [isCreateNewNote, setIsCreateNewNote] = useState(false)
  const [createNote] = useCreateNoteMutation()

  const dispatch = useAppDispatch()

  const formStore = useFormStore({
    defaultValues: {
      title: "",
    },
  })

  formStore.useSubmit(async (values) => {
    const { title } = values.values
    createNote({
      title,
      notebookId: notebook.id,
      description: "",
    }).finally(() => setIsCreateNewNote(false))
  })

  const handleSelect = (noteId: UUID) => {
    dispatch(panelsSlice.actions.notepadNoteIdUpdated(noteId))
  }

  return (
    <li className={styles["notepadSelectorListItemNotebook"]}>
      <div className={styles["notepadSelectorListItemNotebookTitle"]}>
        {notebook.title}
        <Button
          className={styles["notepadSelectorButton"]}
          onClick={() => setIsCreateNewNote(true)}
        >
          <Localized id={"notebook-note-new"}>New note</Localized>
        </Button>
      </div>
      <ol className={styles["notepadSelectorListNote"]}>
        {notebook.notes.map((note) => (
          <li
            key={note.id}
            className={styles["notepadSelectorListItemNote"]}
            onClick={() => handleSelect(note.id)}
          >
            {note.title}
          </li>
        ))}
      </ol>
      {isCreateNewNote && (
        <Form store={formStore} className={styles["notepadCreateNoteForm"]}>
          <FormInput name={"title"} placeholder={"Title of the note"} />
          <FormSubmit>
            <Localized id={"create"}>Create</Localized>
          </FormSubmit>
        </Form>
      )}
    </li>
  )
}
