import type { EditorState } from "prosemirror-state"
import { useEffect, useMemo } from "react"

import type { UUID } from "@/store/UUID"
import { debounce } from "@/utils/debounce"

type UseFootnoteEnumerationEffect = (
  footnotes: { guid: UUID; number: number }[]
) => void

/**
 * This hook defines the number of each footnote in the document.
 * Shall be used at the top level of the editor component, so that
 * the effect is run only once, and not for each footnote.
 */

export function useFootnoteEnumeration(
  state: EditorState | null | undefined,
  effect: UseFootnoteEnumerationEffect
) {
  const memoizedEffect = useMemo(() => effect, [effect])

  const debouncedCalculation = useMemo(
    () =>
      debounce(
        (state: EditorState, effect: UseFootnoteEnumerationEffect) => {
          const footnotes: { guid: UUID; number: number }[] = []

          state.doc.descendants((node) => {
            if (node.type.name === "footnote" && node.attrs.guid) {
              footnotes.push({
                guid: node.attrs.guid,
                number: footnotes.length + 1,
              })
            }
          })

          effect(footnotes)
        },
        500,
        // Run the effect immediately. Needed for the initial calculation
        // when the editor mounts, for example.
        { leading: true }
      ),
    []
  )

  useEffect(() => {
    if (state) debouncedCalculation(state, memoizedEffect)
  }, [state, debouncedCalculation, memoizedEffect])
}
