import { Button } from "@ariakit/react"
import { Localized, useLocalization } from "@fluent/react"

import styles from "./FlashcardStudyView.module.css"
import { FlashcardStudyViewCard } from "./FlashcardStudyViewCard.tsx"
import { FlashcardStudyViewProgressIndicator } from "./FlashcardStudyViewProgressIndicator.tsx"

import { generateGuid } from "@/components/editor/plugins/guid.ts"
import { Cancel } from "@/components/icons.tsx"
import sharedStyles from "@/components/shared.module.css"
import type { UUID } from "@/store/UUID.ts"
import { useAppDispatch } from "@/store/hooks.ts"
import { useGetStudySessionQuery } from "@/store/slices/api.ts"
import { PanelType, panelsSlice } from "@/store/slices/panels.ts"
import { IconButton } from "@/ui/IconButton"

export function FlashcardStudyView({ sessionId }: { sessionId: UUID }) {
  const { data, isError, isLoading } = useGetStudySessionQuery({
    sessionId,
  })
  const dispatch = useAppDispatch()

  const { l10n } = useLocalization()
  const nextFlashcardLearningState = data?.completedAt === null

  const handleBackToDeck = () => {
    // Remove sessionId search parameter from URL
    const newUrl = new URL(window.location.href)
    newUrl.searchParams.delete("sessionId")
    window.history.pushState({}, "", newUrl.toString())
    if (!data) return
    dispatch(
      panelsSlice.actions.panelsReplaced({
        panelId: generateGuid(),
        panelType: PanelType.FLASHCARD_DECK,
        documentId: data.deck,
      })
    )
  }

  if (isLoading) return <div>Loading...</div>
  if (isError || !data) return <div>An error occurred.</div>

  return (
    <div className={styles["studyViewWrapper"]}>
      <IconButton
        size={"large"}
        label={l10n.getString("Close")}
        icon={<Cancel />}
        style={{ position: "absolute" }}
        onClick={() => handleBackToDeck()}
      />
      <FlashcardStudyViewProgressIndicator history={data.history} />
      {nextFlashcardLearningState ? (
        <FlashcardStudyViewCard
          flashcardId={data.nextFlashcard.id}
          revisionId={data.nextFlashcard.draftRevisionId}
          sessionId={sessionId}
          intervals={data.nextFlashcardIntervals}
        />
      ) : (
        <div className={styles["flashcardWrapper"]}>
          <div className={styles["flashcard"]}>
            <div className={styles["flashcardContent"]}>
              <Localized id={"flashcard-learning-congratulations"}>
                Congratulations! You are done. 🎉
              </Localized>
            </div>
            <Button
              className={sharedStyles["defaultButton"]}
              onClick={handleBackToDeck}
            >
              <Localized id={"flashcard-learning-back-to-deck"}>
                Back to deck
              </Localized>
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
