import {
  TooltipAnchor,
  type TooltipAnchorProps,
  TooltipProvider,
  type TooltipProviderProps,
  Tooltip as TooltipTitle,
  VisuallyHidden,
} from "@ariakit/react"
import { type ReactNode } from "react"

import styles from "./Tooltip.module.css"

type TooltipProps = {
  children: TooltipAnchorProps["render"]
  title?: ReactNode
  placement?: TooltipProviderProps["placement"]
}

export function Tooltip({ children, title, placement }: TooltipProps) {
  return (
    <TooltipProvider placement={placement}>
      <VisuallyHidden>{title}</VisuallyHidden>
      <TooltipAnchor render={children} />
      {title && (
        <TooltipTitle
          render={<span className={styles.tooltip}>{title}</span>}
        />
      )}
    </TooltipProvider>
  )
}
