import { createSelector } from "@reduxjs/toolkit"
import { getVersion } from "prosemirror-collab"
import { EditorState } from "prosemirror-state"
import { Transform } from "prosemirror-transform"

import { getLinkables, getRevisionEditorState } from "./documentsSelectors"

import { chapterSchema } from "@/schemas/chapter/schema"
import type { UUID } from "@/store/UUID"
import { getChapterPlugins } from "@/store/slices/revisions"
import type { RootState } from "@/store/store"

export function getMarkerMode(state: RootState) {
  return state.viewer.marker.enabled
}

function getMarkerCurrentState(state: RootState) {
  return state.viewer.marker.current
}

export const getCurrentMark = createSelector(
  getMarkerCurrentState,
  (markerCurrentState) => {
    return markerCurrentState
      ? {
          anchor: markerCurrentState.anchor,
          head: markerCurrentState.head,
          from: Math.min(markerCurrentState.anchor, markerCurrentState.head),
          to: Math.max(markerCurrentState.anchor, markerCurrentState.head),
          empty: markerCurrentState.anchor === markerCurrentState.head,
        }
      : null
  }
)

export function getMarkerColor(state: RootState) {
  return state.viewer.marker.color
}

export function getStepThroughState(state: RootState, revisionId: UUID) {
  return state.viewer.stepThrough[revisionId]
}

export const getViewerEditorState = createSelector(
  getRevisionEditorState,
  getStepThroughState,
  getLinkables,
  (editorState, stepThroughState, linkables) => {
    if (!editorState || !stepThroughState?.enabled) return editorState

    const { doc } = editorState

    const newDoc = doc.type.create(doc.attrs, null, doc.marks)
    const tr = new Transform(newDoc)
    let nodeIndex = 0
    let stepCount = 0
    while (stepCount <= stepThroughState.step && nodeIndex < doc.childCount) {
      const node = doc.child(nodeIndex)

      if (node.type === chapterSchema.nodes.step_marker) {
        stepCount += 1
      }

      if (stepCount <= stepThroughState.step) {
        tr.insert(tr.doc.nodeSize - 2, node)
        nodeIndex += 1
      }
    }

    const version = getVersion(editorState)

    return EditorState.create({
      doc: tr.doc,
      plugins: getChapterPlugins(version, linkables),
    })
  }
)

/**
 * Return a React key suitable to be used for the Viewer's
 * ProseMirror component.
 *
 * @private
 *
 * In order to properly clean up old plugin state, etc, when
 * we do a complete swap of the EditorState (e.g. when in step-through
 * mode), we want to actually unmount and remount the ProseMirror
 * component, so as to trigger destruction and recreation of the
 * underlying ProseMirror Editor View
 */
export function getViewerKey(state: RootState, revisionId: UUID) {
  const stepThroughState = getStepThroughState(state, revisionId)
  if (!stepThroughState?.enabled) return "default"

  return `step-${stepThroughState.step}`
}

export function getStoredScroll(state: RootState) {
  return state.viewer.storedScroll
}
