import {
  Button,
  Separator,
  Toolbar,
  ToolbarContainer,
  ToolbarSeparator,
  useToolbarStore,
} from "@ariakit/react"
import { Localized } from "@fluent/react"
import clsx from "clsx"

import { NoteInsertBlockMenu } from "./NoteInsertBlockMenu.tsx"
import { NotepadTitle } from "./NotepadTitle.tsx"

import { ChapterSyncStatus } from "@/components/editor/toolbar/items/ChapterSyncStatus.tsx"
import { ContextualSection } from "@/components/editor/toolbar/sections/ContextualSection.tsx"
import { FormattingSection } from "@/components/editor/toolbar/sections/FormattingSection.tsx"
import { HistorySection } from "@/components/editor/toolbar/sections/HistorySection.tsx"
import toolbarStyles from "@/components/editor/toolbar/toolbar.module.css"
import sharedStyles from "@/components/shared.module.css"
import { useAppDispatch } from "@/store/hooks.ts"
import { panelsSlice } from "@/store/slices/panels.ts"

export function NotepadToolbar() {
  const toolbarStore = useToolbarStore({
    orientation: "horizontal",
  })

  const dispatch = useAppDispatch()

  return (
    <Toolbar className={toolbarStyles.editorToolbar} store={toolbarStore}>
      <ToolbarContainer data-toolbar-slot="controls">
        <NotepadTitle />
        <Separator />
        <HistorySection />
        <ChapterSyncStatus />
        <Button
          className={clsx(
            sharedStyles["defaultButton"],
            sharedStyles["secondaryButton"],
            sharedStyles["miniButton"]
          )}
          onClick={() =>
            dispatch(panelsSlice.actions.notepadNoteIdUpdated(null))
          }
        >
          <Localized id={"close"}>Close</Localized>
        </Button>
      </ToolbarContainer>
      <ToolbarContainer data-toolbar-slot="formatting">
        <NoteInsertBlockMenu />
        <ToolbarSeparator />
        <FormattingSection />
        <ToolbarSeparator />
        <ContextualSection />
      </ToolbarContainer>
    </Toolbar>
  )
}
