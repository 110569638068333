import { useEditorState } from "@nytimes/react-prosemirror"
import { toggleMark } from "prosemirror-commands"
import type { MarkType } from "prosemirror-model"
import type { EditorState } from "prosemirror-state"
import { type ReactNode, useContext } from "react"

import { CommandItem } from "./CommandItem"

import { RevisionContext } from "@/contexts/RevisionContext"
import { useAppSelector } from "@/store/hooks"
import { getAtomState } from "@/store/selectors/documentsSelectors"

type Props = {
  children: ReactNode
  markType: MarkType | undefined
}

function isMarkActive(editorState: EditorState | null, markType: MarkType) {
  if (!editorState) return false

  const { $head, from, to, empty } = editorState.selection
  return empty
    ? !!markType.isInSet(editorState.storedMarks || $head.marks())
    : editorState.doc.rangeHasMark(from, to, markType)
}

export function ToggleMarkItem({ children, markType }: Props) {
  const { revisionId } = useContext(RevisionContext)
  const atomState = useAppSelector((state) => getAtomState(state, revisionId))

  const editorState = useEditorState()
  if (!markType) return null

  const active = isMarkActive(atomState ?? editorState, markType)

  return (
    <CommandItem command={toggleMark(markType)} active={active}>
      {children}
    </CommandItem>
  )
}
