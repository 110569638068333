import { useEditorState } from "@nytimes/react-prosemirror"
import {
  baseKeymap,
  chainCommands,
  joinDown,
  joinUp,
  lift,
  selectAll,
  selectParentNode,
  setBlockType,
  toggleMark,
} from "prosemirror-commands"
import { redo, undo } from "prosemirror-history"
import { undoInputRule } from "prosemirror-inputrules"
import {
  liftListItem,
  sinkListItem,
  splitListItem,
  wrapInList,
} from "prosemirror-schema-list"
import type { Command, EditorState } from "prosemirror-state"
import type { EditorView } from "prosemirror-view"
import { useMemo } from "react"

import {
  moveCursorToFlashcardBack,
  moveCursorToFlashcardFront,
} from "@/commands/flashcards.ts"
import { insertFootnote, toggleAllFootnotes } from "@/commands/footnotes"
import { resetHeading, setHeadingToParagraph } from "@/commands/headings"
import { insertParagraphInListItem } from "@/commands/lists.ts"
import {
  selectNextQuizItem,
  selectParentQuiz,
  selectPreviousQuizItem,
} from "@/commands/quizzes"
import {
  selectNextReviewItem,
  selectParentReviewSchema,
} from "@/commands/reviewSchemas"
import {
  moveCursorToNextTableCell,
  moveCursorToPreviousTableCell,
} from "@/commands/tables.ts"
import { useKeymap } from "@/hooks/useKeymap"
import { Dialog } from "@/store/slices/dialogs"
import { dialogOpened, store } from "@/store/store.ts"

const mac =
  typeof navigator != "undefined"
    ? /Mac|iP(hone|[oa]d)/.test(navigator.platform)
    : false

type KeymapProps = {
  additionalShortcuts?: Record<string, Command>
}

export function Keymap(props: KeymapProps) {
  // We don't render the ProseMirror component or its children if
  // editorState is null
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const editorState = useEditorState()!
  const { schema } = editorState

  const keymap = useMemo(
    () => ({
      "Shift-Tab": chainCommands(
        selectPreviousQuizItem,
        selectParentReviewSchema,
        moveCursorToPreviousTableCell,
        moveCursorToFlashcardFront,
        ...(schema.nodes["list_item"]
          ? [liftListItem(schema.nodes["list_item"])]
          : [])
      ),
      "Shift-Enter": chainCommands(insertParagraphInListItem),
      Tab: chainCommands(
        selectNextQuizItem,
        selectNextReviewItem,
        moveCursorToNextTableCell,
        moveCursorToFlashcardBack,
        ...(schema.nodes["list_item"]
          ? [sinkListItem(schema.nodes["list_item"])]
          : [])
      ),
      "Mod-a": chainCommands(selectParentQuiz, selectAll),
      ...(schema.nodes["list_item"] && {
        Enter: chainCommands(
          splitListItem(schema.nodes["list_item"]),
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          baseKeymap["Enter"]!
        ),
      }),
      "Mod-z": undo,
      "Shift-Mod-z": redo,
      ...(mac && { "Mod-Y": redo }),
      Backspace: chainCommands(
        undoInputRule,
        resetHeading,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        baseKeymap["Backspace"]!
      ),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      Delete: baseKeymap["Backspace"]!,
      "Alt-ArrowUp": joinUp,
      "Alt-ArrowDown": joinDown,
      Escape: selectParentNode,
      ...(schema.marks["strong"] && {
        "Mod-b": toggleMark(schema.marks["strong"]),
      }),
      ...(schema.marks["em"] && {
        "Mod-i": toggleMark(schema.marks["em"]),
      }),
      "Shift-Mod-f": insertFootnote,
      "Shift-Mod-Alt-F": toggleAllFootnotes,
      ...(schema.nodes["heading"] && {
        "Shift-Ctrl-1": setBlockType(schema.nodes["heading"], { level: 1 }),
        "Shift-Ctrl-2": setBlockType(schema.nodes["heading"], { level: 2 }),
        'Shift-Ctrl-"': setBlockType(schema.nodes["heading"], { level: 2 }),
        "Shift-Ctrl-3": setBlockType(schema.nodes["heading"], { level: 3 }),
        "Shift-Ctrl-4": setBlockType(schema.nodes["heading"], { level: 4 }),
        "Shift-Ctrl-5": setBlockType(schema.nodes["heading"], { level: 5 }),
        "Shift-Ctrl-6": setBlockType(schema.nodes["heading"], { level: 6 }),
        "Shift-Ctrl-7": setBlockType(schema.nodes["heading"], { level: 7 }),
        "Shift-Ctrl-8": setBlockType(schema.nodes["heading"], { level: 8 }),
        "Shift-Ctrl-9": setBlockType(schema.nodes["heading"], { level: 9 }),
        "Shift-Ctrl-0": chainCommands(
          setHeadingToParagraph,
          ...(schema.nodes["list_item"]
            ? [liftListItem(schema.nodes["list_item"])]
            : []),
          lift
        ),
        "Shift-Ctrl-=": chainCommands(
          setHeadingToParagraph,
          ...(schema.nodes["list_item"]
            ? [liftListItem(schema.nodes["list_item"])]
            : []),
          lift
        ),
      }),
      "Mod-k": () => {
        store.dispatch(dialogOpened(Dialog.INSERT_LINK))
        return true
      },
      ...(schema.nodes["bullet_list"] && {
        "Shift-Ctrl-8": wrapInList(schema.nodes["bullet_list"]),
      }),
      ...(schema.nodes["ordered_list"] && {
        "Shift-Ctrl-9": wrapInList(schema.nodes["ordered_list"]),
      }),
      ...(schema.nodes["paragraph"] && {
        "Shift-Ctrl--": setBlockType(schema.nodes["paragraph"]),
      }),
      ...props.additionalShortcuts,
    }),
    [schema.marks, schema.nodes, props.additionalShortcuts]
  )

  useKeymap(keymap)

  return null
}

type FootnoteKeymapProps = {
  outerState: EditorState
  outerDispatch: EditorView["dispatch"]
}

export function FootnoteKeymap(props: FootnoteKeymapProps) {
  // We don't render the ProseMirror component or its children if
  // editorState is null
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const editorState = useEditorState()!
  const { schema } = editorState
  const { outerState, outerDispatch } = props

  const keymap = useMemo(
    () => ({
      "Mod-a": chainCommands(selectParentQuiz, selectAll),
      ...(schema.nodes["list_item"] && {
        Enter: chainCommands(
          splitListItem(schema.nodes["list_item"]),
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          baseKeymap["Enter"]!
        ),
      }),
      "Mod-z": () => undo(outerState, outerDispatch),
      "Shift-Mod-z": () => redo(outerState, outerDispatch),
      ...(mac && { "Mod-Y": () => redo(outerState, outerDispatch) }),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      Backspace: chainCommands(undoInputRule, baseKeymap["Backspace"]!),
      ...(schema.marks["strong"] && {
        "Mod-b": toggleMark(schema.marks["strong"]),
      }),
      ...(schema.marks["em"] && {
        "Mod-i": toggleMark(schema.marks["em"]),
      }),
      "Mod-k": () => {
        store.dispatch(dialogOpened(Dialog.INSERT_LINK))
        return true
      },
    }),
    [schema.marks, schema.nodes, outerState, outerDispatch]
  )
  useKeymap(keymap)

  return null
}
