import { Button } from "@ariakit/react"
import { Localized } from "@fluent/react"
import {
  type NodeViewComponentProps,
  useEditorEventCallback,
} from "@nytimes/react-prosemirror"
import { forwardRef } from "react"

import styles from "./stepMarker.module.css"

import { Cancel } from "@/components/icons"

export const StepMarker = forwardRef<HTMLDivElement, NodeViewComponentProps>(
  function StepMarker({ children: _children, nodeProps, ...props }, ref) {
    const handleRemoveClick = useEditorEventCallback((view) => {
      const tr = view.state.tr
      tr.delete(nodeProps.pos, nodeProps.pos + nodeProps.node.nodeSize)
      view.dispatch(tr)
    })

    return (
      <div ref={ref} {...props} className={styles["container"]}>
        <hr className={styles["rule"]} />
        <div className={styles["flexWrapper"]}>
          <div className={styles["stepMarker"]}>
            <span>
              <Localized id="step-marker-click-step">Click step</Localized>
            </span>
            <Button
              className={styles["removeButton"]}
              onClick={handleRemoveClick}
            >
              <Localized id="remove">Remove</Localized>{" "}
              <Cancel className={styles["cancelIcon"]} />
            </Button>
          </div>
        </div>
      </div>
    )
  }
)
