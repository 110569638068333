import { useEditorEffect } from "@nytimes/react-prosemirror"
import { useContext } from "react"

import { AtomViewContext } from "@/contexts/AtomViewContext"

export function AtomView() {
  const { setAtomView } = useContext(AtomViewContext)

  useEditorEffect(
    (view) => {
      setAtomView(view)
      view.focus()
      return () => {
        setAtomView(null)
      }
    },
    [setAtomView]
  )

  return null
}
