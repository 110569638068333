/**
 * Given an HTML Element, return its scroll container;
 * its nearest scrollable ancestor.
 */
export function getScrollContainer(
  element: HTMLElement,
  excludeStaticParent?: boolean
): HTMLElement {
  const style = getComputedStyle(element)
  const shouldExcludeStaticParent =
    excludeStaticParent ?? style.position === "absolute"
  const overflowRegex = /(auto|scroll)/

  if (style.position === "fixed") return document.body

  if (overflowRegex.test(style.overflow)) return element

  const parent = element.parentElement

  if (!parent) return document.body

  return getScrollContainer(parent, shouldExcludeStaticParent)
}
