import { useEditorEventCallback } from "@nytimes/react-prosemirror"
import type { Mark } from "prosemirror-model"
import { type ReactElement, useCallback, useEffect, useState } from "react"

import { generateGuid } from "./guid.ts"

import type { UUID } from "@/store/UUID.ts"
import { useAppDispatch, useAppSelector } from "@/store/hooks"
import { isDialogOpen } from "@/store/selectors/dialogsSelectors"
import { PanelType } from "@/store/slices/panels.ts"
import { panelOpened } from "@/store/thunks/panelOpened.ts"
import { parseURL } from "@/utils/url.ts"

export type SelectLinkContext = {
  link: Mark | undefined
  pos: number
  openLink: (href: string) => Promise<boolean>
}

type SelectLinkHandlerProps = {
  children?: (ctx: SelectLinkContext) => ReactElement
}

/**
 * This handler keeps track of selected links within the editor.
 * If a link gets selected via mouse, it opens the link by default
 * (unless there are child components specified that handle the click).
 */

export function SelectLinkHandler({ children }: SelectLinkHandlerProps) {
  const dispatch = useAppDispatch()
  const isDialogOpened = useAppSelector(isDialogOpen)

  const [currentLink, setCurrentLink] = useState<Mark>()
  const [currentPos, setCurrentPos] = useState(0)

  // We need to use a native event handler because of the behavior for editable
  // and not editable views. The default browser behavior for links in a not editable
  // element is to open the link as usual. However, since we want to open specific
  // links in the secondary panel, we need to prevent the default behavior. This
  // has to be done before ProseMirror handles the event and updates the selection.
  const handleNativeMouseClick = useEditorEventCallback(
    (view, e: MouseEvent | TouchEvent) => {
      if (isDialogOpened || !(e.target as HTMLElement).closest("a")) {
        setCurrentLink(undefined)
        setCurrentPos(0)
        return false
      }

      // We can't use `selection.anchor` since it is not updated yet (see above).
      let pos
      let isValidMouseOrTouchEvent = true
      if (e instanceof MouseEvent) {
        pos = view.posAtCoords({ left: e.clientX, top: e.clientY })?.pos
        isValidMouseOrTouchEvent = e.button === 0
      } else if (e instanceof TouchEvent) {
        const [firstTouch] = e.touches
        if (!firstTouch) return false
        isValidMouseOrTouchEvent = e.touches.length === 1
        pos = view.posAtCoords({
          left: firstTouch.clientX,
          top: firstTouch.clientY,
        })?.pos
      }

      if (!pos) return false

      const node = view.state.doc.nodeAt(pos)
      const link = node?.marks.find((mark) => mark.type.name === "link")

      if (view.state.selection.empty && isValidMouseOrTouchEvent) {
        setCurrentLink(link)
        setCurrentPos(pos)
      } else {
        setCurrentLink(undefined)
        setCurrentPos(0)
      }

      if (!link?.attrs.href) return false

      e.preventDefault()
      e.stopPropagation()

      if (!children?.length || !view.editable) openLink(link.attrs.href)

      return true
    }
  )

  useEffect(() => {
    document.addEventListener("click", handleNativeMouseClick)
    document.addEventListener("touchstart", handleNativeMouseClick)
    return () => {
      document.removeEventListener("click", handleNativeMouseClick)
      document.removeEventListener("touchstart", handleNativeMouseClick)
    }
  }, [handleNativeMouseClick])

  const openLink = useCallback(
    async (href: string) => {
      const url = await parseURL(href)
      if (!url) return false

      if (url.type === "external") {
        window.open(url.url, "_blank")
      } else {
        if (url.resourceType === PanelType.VIEWER) {
          dispatch(
            panelOpened({
              panel: {
                panelId: generateGuid(),
                panelType: url.resourceType,
                documentId: url.documentId,
                revisionId: url.revisionId || null,
                scrollToBlock: url.scrollToBlock,
              },
            })
          )
        } else {
          dispatch(
            panelOpened({
              panel: {
                panelId: generateGuid() as UUID,
                panelType: url.resourceType,
                documentUrl: url.resourceUrl,
              },
            })
          )
        }
      }

      return true
    },
    [dispatch]
  )

  return children
    ? children({ link: currentLink, pos: currentPos, openLink })
    : null
}
