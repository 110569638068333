import type { ReactNode } from "react"

import { useAppSelector } from "@/store/hooks"
import { Dialog } from "@/store/slices/dialogs"

/**
 * This component takes care of rendering the currently open dialog.
 * Use it as a wrapper around the dialog components, to avoid unnecessary
 * rendering of the dialog components when the dialog is not open.
 */

export function DialogManager({ children }: { children: ReactNode }) {
  const isOpen = useAppSelector(
    ({ dialogs }) => dialogs.openDialog !== Dialog.NONE
  )

  if (!isOpen) {
    return null
  }

  return <>{children}</>
}
