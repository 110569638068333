import {
  ProseMirror,
  ProseMirrorDoc,
  useEditorEventCallback,
  useEditorState,
} from "@nytimes/react-prosemirror"
import { Node } from "prosemirror-model"
import { EditorState, TextSelection, Transaction } from "prosemirror-state"
import { type MouseEventHandler, useCallback, useState } from "react"

import styles from "./footnote.module.css"

import { FootnoteKeymap } from "@/components/Keymap"
import { useIsEditorEditable } from "@/components/editor/hooks/useIsEditorEditable"
import { AtomView } from "@/components/editor/nodeViews/AtomView"
import { ImportWarning } from "@/components/editor/nodeViews/ImportWarning/ImportWarning.node.tsx"
import {
  EditorLifecycleEvents,
  type OnMountEditorCallback,
} from "@/components/editor/plugins/EditorLifecycleEvents"
import { UpdateLinkPopover } from "@/components/editor/popovers/UpdateLinkPopover"
import { ProseMirrorRenderer } from "@/components/prosemirrorRenderer/ProseMirrorRenderer"
import { chapterSchema } from "@/schemas/chapter/schema"
import type { UUID } from "@/store/UUID"
import { useAppDispatch, useAppSelector } from "@/store/hooks"
import { getAtomState } from "@/store/selectors/documentsSelectors"
import { atomNodeActivated } from "@/store/store"
import { transactionDispatched } from "@/store/thunks/transactionDispatched"

type FootnoteEditorProps = {
  footnote: Node
  chapterId: UUID
  revisionId: UUID
  pos: number
  isSelected: boolean
}

/**
 * This components renders the atom editor for a footnote.
 * To avoid having to render a heavy editor instance for every
 * footnote in the document, we only render the editor when the
 * footnote is selected (i.e. when the user clicks on it).
 */

export function FootnoteEditor({
  chapterId,
  revisionId,
  pos,
  footnote,
  isSelected,
}: FootnoteEditorProps) {
  const [interactionCoords, setInteractionCoords] = useState<
    { top: number; left: number } | undefined
  >()

  const editorState = useAppSelector((state) => getAtomState(state, revisionId))
  const outerEditorState = useEditorState()
  const outerEditorIsEditable = useIsEditorEditable()

  const dispatch = useAppDispatch()
  const dispatchTransaction = useEditorEventCallback(
    (_, transaction: Transaction) => {
      dispatch(
        transactionDispatched({
          documentType: "chapter",
          documentId: chapterId,
          revisionId,
          transaction,
          fromAtom: true,
          atomPos: pos,
        })
      )
    }
  )

  const onClickOnRenderer = useCallback<MouseEventHandler>(
    (event) => {
      setInteractionCoords({ top: event.clientY, left: event.clientX })
      dispatch(atomNodeActivated({ revisionId, pos }))
    },
    [dispatch, pos, revisionId]
  )

  const onMountEditor = useCallback<OnMountEditorCallback>(
    (view) => {
      if (!interactionCoords) return

      const { pos } = view.posAtCoords(interactionCoords) ?? {}
      if (!pos) return

      const { dispatch, state } = view
      const initialSelection = TextSelection.create(state.tr.doc, pos)
      dispatch(state.tr.setSelection(initialSelection))
    },
    [interactionCoords]
  )

  if (!isSelected || !outerEditorState || !editorState?.doc.eq(footnote))
    return (
      <ProseMirrorRenderer
        variant="footnote"
        node={footnote}
        schema={chapterSchema}
        onClick={onClickOnRenderer}
        className={styles["footnoteRenderer"]}
      />
    )

  return (
    <ProseMirror
      state={editorState ?? EditorState.create({ doc: footnote })}
      nodeViews={{ import_warning: ImportWarning }}
      dispatchTransaction={dispatchTransaction}
      editable={() => outerEditorIsEditable}
      attributes={{
        "data-variant": "footnote",
        class: styles["footnoteEditor"] ?? "",
      }}
    >
      {outerEditorIsEditable && <AtomView />}
      <FootnoteKeymap outerState={outerEditorState} outerDispatch={dispatch} />
      <UpdateLinkPopover />
      <EditorLifecycleEvents onMount={onMountEditor} />
      <ProseMirrorDoc as={<span />} />
    </ProseMirror>
  )
}
