import { useEditorEventListener } from "@nytimes/react-prosemirror"
import { keydownHandler } from "prosemirror-keymap"
import type { Command } from "prosemirror-state"
import type { EditorView } from "prosemirror-view"
import { useCallback } from "react"

export function useKeymap(keymap: Record<string, Command>) {
  const handler = useCallback(
    (view: EditorView, event: KeyboardEvent) =>
      keydownHandler(keymap)(view, event),
    [keymap]
  )
  useEditorEventListener("keydown", (view, event) => {
    if (handler(view, event)) {
      event.preventDefault()
      return true
    }
    return false
  })
}
