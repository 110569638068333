export function makeSupportMailLink({
  subject,
  body,
}: {
  subject?: string
  body?: string
}) {
  const address = "hi@dskrpt.de"
  const formattedSubject = `?subject=${subject ?? ""}`
  const formattedBody = `&body=${body}`

  return `mailto:${address}${formattedSubject}${formattedBody}`
}
