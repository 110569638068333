import { useLocalization } from "@fluent/react"
import { type NodeViewComponentProps } from "@nytimes/react-prosemirror"
import { forwardRef } from "react"

import styles from "./importWarning.module.css"

import { Warning } from "@/components/icons.tsx"
import { Tooltip } from "@/ui/Tooltip"

export const ImportWarning = forwardRef<HTMLDivElement, NodeViewComponentProps>(
  function ImportWarning({ children: _children, nodeProps, ...props }, ref) {
    const { l10n } = useLocalization()
    const tooltipTitle = l10n.getString(
      "importer-import-warning-tooltip",
      {
        elementName: nodeProps.node.attrs.removedType,
      },
      "Element removed or moved at import. You can remove this."
    )
    return (
      <Tooltip title={tooltipTitle}>
        <span
          ref={ref}
          {...props}
          className={styles["container"]}
          contentEditable={false}
        >
          <Warning />
          Import
        </span>
      </Tooltip>
    )
  }
)
