import {
  Dialog,
  DialogDismiss,
  DialogHeading,
  VisuallyHidden,
  useDialogStore,
} from "@ariakit/react"
import { Localized } from "@fluent/react"
import { useEditorEventCallback } from "@nytimes/react-prosemirror"
import { clsx } from "clsx"
import {
  type ChangeEvent,
  type DragEvent,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"

import dialogStyles from "./dialog.module.css"

import { insertImage } from "@/commands/images"
import { insertPDF } from "@/commands/pdfs"
import { Cancel, Document, DocumentCheck, Picture } from "@/components/icons"
import { useLocalizedString } from "@/hooks/useLocalizedString"
import { type UUID } from "@/store/UUID"
import { useAppDispatch, useAppSelector } from "@/store/hooks"
import { getOpenedInsertFileDialog } from "@/store/selectors/dialogsSelectors"
import { useUploadFileMutation } from "@/store/slices/api"
import { Dialog as DialogType } from "@/store/slices/dialogs"
import { dialogClosed } from "@/store/store"
import { stripFileExtension } from "@/utils/files"
import { sendErrorToSentry } from "@/utils/sentry"

const AllowedMimeTypes = {
  [DialogType.INSERT_IMAGE]: "image/*",
  [DialogType.INSERT_PDF]: "application/pdf",
} as const

const FileDialogError = {
  INVALID_MIME_TYPE: "invalid-mime-type",
} as const

type FileDialogType = DialogType.INSERT_IMAGE | DialogType.INSERT_PDF

type FileAttrs = {
  title: string
  url: string
  mediaObjectId: UUID
  size: number
}

const FileDialogContext = createContext<
  | {
      attrs: FileAttrs | undefined
      setAttrs: React.Dispatch<React.SetStateAction<FileAttrs | undefined>>
    }
  | undefined
>(undefined)

function useFileDialogContext() {
  const context = useContext(FileDialogContext)

  if (context === undefined) {
    throw new Error(
      "useFileDialogContext must be used within a FileDialogContext"
    )
  }

  return context
}

type Props = {
  onConfirm: () => void
}

export function InsertFileDialog({ onConfirm }: Props) {
  const dispatch = useAppDispatch()
  const openDialog = useAppSelector(getOpenedInsertFileDialog)
  const [uploadFile, { isError: isUploadError }] = useUploadFileMutation()

  const confirmButtonRef = useRef<HTMLButtonElement>(null)
  const [attrs, setAttrs] = useState<FileAttrs | undefined>()
  const [isComponentError, setIsComponentError] = useState<
    (typeof FileDialogError)[keyof typeof FileDialogError] | boolean
  >(false)

  const dialogStore = useDialogStore({
    open: !!openDialog,
    setOpen(open) {
      if (!open) {
        dispatch(dialogClosed())
      }
    },
  })

  const handleConfirm = useEditorEventCallback<
    { type: FileDialogType; attrs: FileAttrs }[],
    void
  >((view, { type, attrs }) => {
    dialogStore.setOpen(false)

    if (type === DialogType.INSERT_IMAGE) {
      insertImage({
        src: attrs.url,
        title: attrs.title,
      })(view.state, view.dispatch)
    }

    if (type === DialogType.INSERT_PDF) {
      insertPDF({
        src: attrs.url,
        title: attrs.title,
      })(view.state, view.dispatch)
    }

    onConfirm()
  })

  const onFileSelected = useCallback(
    async (event: DragEvent | ChangeEvent<HTMLInputElement>) => {
      try {
        if (!openDialog) return

        event.preventDefault()
        setIsComponentError(false)

        const fileToUpload = isDragEvent(event)
          ? event.dataTransfer?.files[0]
          : event.target instanceof HTMLInputElement
          ? event.target.files?.[0]
          : null

        if (!fileToUpload) throw new Error("No file to upload")

        if (!fileToUpload.type.match(AllowedMimeTypes[openDialog])) {
          throw new Error(FileDialogError.INVALID_MIME_TYPE)
        }

        const { file } = await uploadFile(fileToUpload).unwrap()

        setAttrs({
          ...file,
          title: stripFileExtension(fileToUpload.name),
          size: fileToUpload.size,
        })
      } catch (error) {
        if (
          error instanceof Error &&
          error.message === FileDialogError.INVALID_MIME_TYPE
        ) {
          setIsComponentError(FileDialogError.INVALID_MIME_TYPE)
        } else {
          setIsComponentError(true)
        }

        sendErrorToSentry("InsertFileDialog", error)
      }
    },
    [uploadFile, openDialog]
  )

  useEffect(() => {
    const handleEnter = (event: KeyboardEvent) => {
      if (event.key === "Enter") confirmButtonRef.current?.click()
    }
    document.addEventListener("keydown", handleEnter)
    return () => document.removeEventListener("keydown", handleEnter)
  }, [])

  const HeadingText = useMemo(() => {
    if (openDialog === DialogType.INSERT_IMAGE) {
      return <Localized id={"insert-image"}>Insert Image</Localized>
    }

    if (openDialog === DialogType.INSERT_PDF) {
      return <Localized id={"insert-pdf"}>Insert PDF</Localized>
    }

    return null
  }, [openDialog])

  return (
    <FileDialogContext.Provider value={{ attrs, setAttrs }}>
      <Dialog store={dialogStore} portal className={dialogStyles["dialog"]}>
        <DialogHeading>{HeadingText}</DialogHeading>
        <Dropzone
          type={openDialog}
          onFileSelected={onFileSelected}
          isError={isComponentError || isUploadError}
        />
        <div className={dialogStyles["dialogActions"]}>
          <DialogDismiss className={"blue-button"}>
            <Cancel />
            <Localized id={"cancel"}>Cancel</Localized>
          </DialogDismiss>
          {openDialog && attrs && (
            <button
              ref={confirmButtonRef}
              onClick={() => handleConfirm({ type: openDialog, attrs })}
              disabled={attrs.title.length === 0}
              className={"blue-button"}
            >
              {openDialog === DialogType.INSERT_IMAGE && <Picture />}
              {openDialog === DialogType.INSERT_PDF && <Document />}
              <Localized id={"insert"}>Insert</Localized>
            </button>
          )}
        </div>
      </Dialog>
    </FileDialogContext.Provider>
  )
}

type DropzoneProps = {
  type: FileDialogType | false
  onFileSelected: (event: DragEvent | ChangeEvent<HTMLInputElement>) => void
  isError?: boolean | (typeof FileDialogError)[keyof typeof FileDialogError]
}

function Dropzone({ type, isError, onFileSelected }: DropzoneProps) {
  const { attrs } = useFileDialogContext()
  const [isMouseOver, setIsMouseOver] = useState(false)
  const filePickerRef = useRef<HTMLInputElement>(null)

  const EmptyState = useMemo(() => {
    if (type === DialogType.INSERT_IMAGE) {
      return (
        <Localized id={"drop-image-here"}>
          Drop an image here or click to select one
        </Localized>
      )
    }

    if (type === DialogType.INSERT_PDF) {
      return (
        <Localized id={"drop-pdf-here"}>
          Drop a PDF here or click to select one
        </Localized>
      )
    }

    return null
  }, [type])

  const ErrorState = useMemo(() => {
    if (isError === FileDialogError.INVALID_MIME_TYPE) {
      return (
        <Localized id={"upload-invalid-mime-type"}>
          Invalid file type. Please select a different file.
        </Localized>
      )
    }

    return (
      <Localized id={"upload-failed"}>
        That did not work - please try again
      </Localized>
    )
  }, [isError])

  const isEmpty = !!type && !attrs

  return (
    <>
      <div
        className={clsx(
          dialogStyles["dropzone"],
          isEmpty && dialogStyles["empty"],
          isMouseOver && dialogStyles["dashed"],
          isError && dialogStyles["error"]
        )}
        onClick={() => filePickerRef.current?.click()}
        onDragOver={(event) => {
          event.preventDefault()
        }}
        onDragEnter={() => setIsMouseOver(true)}
        onDragLeave={() => setIsMouseOver(false)}
        onDrop={(event) => {
          setIsMouseOver(false)
          onFileSelected(event)
        }}
      >
        {isError ? (
          ErrorState
        ) : type && attrs ? (
          <FilePreview type={type} />
        ) : (
          EmptyState
        )}
      </div>
      {isEmpty && (
        <input
          ref={filePickerRef}
          type="file"
          hidden
          multiple={false}
          accept={AllowedMimeTypes[type]}
          onChange={onFileSelected}
        />
      )}
    </>
  )
}

type FilePreviewProps = {
  type: FileDialogType
}

function FilePreview({ type }: FilePreviewProps) {
  const { attrs, setAttrs } = useFileDialogContext()

  const label = useLocalizedString({
    id: "update-pdf-title",
    fallback: "Update PDF title",
  })

  const formattedSize = useMemo(() => {
    const bytes = attrs?.size ?? 0
    if (bytes >= 1048576) return (bytes / 1048576).toFixed(0) + " MB"
    if (bytes >= 1024) return (bytes / 1024).toFixed(0) + " KB"
    return null
  }, [attrs?.size])

  if (attrs && type === DialogType.INSERT_IMAGE) {
    return (
      <img
        data-preview-type="image"
        data-uuid={attrs.mediaObjectId}
        alt={attrs.title ?? ""}
        src={attrs.url}
      />
    )
  }

  if (attrs && type === DialogType.INSERT_PDF) {
    return (
      <div data-preview-type="pdf" data-uuid={attrs.mediaObjectId}>
        <DocumentCheck />
        <VisuallyHidden>
          <label id="pdf-preview-title">{label}</label>
        </VisuallyHidden>
        <input
          autoFocus
          aria-labelledby="pdf-preview-title"
          placeholder={label}
          value={attrs.title}
          style={{ width: `${attrs.title.length}ch` }}
          onChange={(event) =>
            setAttrs({ ...attrs, title: event.target.value })
          }
        />
        <div className={dialogStyles.pdfMeta}>
          {formattedSize && <span>{formattedSize}</span>}
        </div>
      </div>
    )
  }

  return null
}

const isDragEvent = (event: DragEvent | ChangeEvent): event is DragEvent => {
  return (event as DragEvent).dataTransfer !== undefined
}
