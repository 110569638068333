import { Localized, useLocalization } from "@fluent/react"
import { ProseMirror, ProseMirrorDoc } from "@nytimes/react-prosemirror"
import { clsx } from "clsx"
import type { EditorView } from "prosemirror-view"
import {
  type ComponentProps,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"

import { NoteFrontmatter } from "./components/NoteFrontmatter.tsx"
import notepadStyles from "./components/Notepad.module.css"
import { NotepadToolbar } from "./components/NotepadToolbar.tsx"

import { useLongPollClient } from "@/communication/polling/useLongPollClient.ts"
import { Keymap } from "@/components/Keymap.ts"
import { WithAside } from "@/components/aside/WithAside.tsx"
import { EditorErrorFallback } from "@/components/editor/Editor.tsx"
import { Dialogs } from "@/components/editor/dialogs/Dialogs.tsx"
import styles from "@/components/editor/editor.module.css"
import {
  Foldable,
  FoldableContent,
  FoldableSummary,
} from "@/components/editor/nodeViews/Foldable/Foldable.node.tsx"
import { Footnote } from "@/components/editor/nodeViews/Footnote/Footnote.node.tsx"
import { useFootnoteEnumeration } from "@/components/editor/nodeViews/Footnote/useFootnoteEnumeration.tsx"
import { PDF } from "@/components/editor/nodeViews/PDF/PDF.node.tsx"
import {
  QuizAnswer,
  QuizAnswers,
  QuizExplanation,
  QuizQuestion,
} from "@/components/editor/nodeViews/Quiz/Quiz.node.tsx"
import { ReviewSchema } from "@/components/editor/nodeViews/ReviewSchema/ReviewSchema.node.tsx"
import { Table } from "@/components/editor/nodeViews/Table/Table.node.tsx"
import { EditorDebugger } from "@/components/editor/plugins/EditorDebugger.tsx"
import {
  makeDocumentPlaceholder,
  makePlaceholderDecorationSet,
} from "@/components/editor/plugins/placeholder.ts"
import { BlockHandleMenu } from "@/components/editor/popovers/BlockHandleMenu.tsx"
import { FloatingToolbar } from "@/components/editor/popovers/FloatingToolbar.tsx"
import { UpdateLinkPopover } from "@/components/editor/popovers/UpdateLinkPopover.tsx"
import { EditorToolbar } from "@/components/editor/toolbar/EditorToolbar.tsx"
import { Citation } from "@/components/note/nodeViews/Citation.tsx"
import { AtomViewContext } from "@/contexts/AtomViewContext.ts"
import {
  RevisionContext,
  type RevisionContextValue,
} from "@/contexts/RevisionContext.ts"
import type { UUID } from "@/store/UUID.ts"
import { useAppDispatch, useAppSelector } from "@/store/hooks.ts"
import { getRevisionEditorState } from "@/store/selectors/documentsSelectors.ts"
import {
  useGetLinkableResourcesQuery,
  useGetNoteQuery,
  useLazyGetNoteRevisionQuery,
  useLogNoteOpenedMutation,
} from "@/store/slices/api.ts"
import { PanelType } from "@/store/slices/panels.ts"
import {
  useDispatchTransaction,
  useRemoteSteps,
} from "@/store/slices/revisions.ts"
import { setFootnoteNumbers } from "@/store/store.ts"
import { ErrorBoundary } from "@/utils/error.ts"
import { templateStyleProperties } from "@/utils/headingStyles.ts"

export const reactNodeViews: ComponentProps<typeof ProseMirror>["nodeViews"] = {
  review_schema: ReviewSchema,
  quiz_answer: QuizAnswer,
  quiz_answers: QuizAnswers,
  quiz_explanation: QuizExplanation,
  quiz_question: QuizQuestion,
  foldable: Foldable,
  foldable_content: FoldableContent,
  foldable_summary: FoldableSummary,
  footnote: Footnote,
  pdf: PDF,
  table: Table,
  citation: Citation,
}

export type NoteProviderProps = {
  noteId: UUID
  asNotepad?: boolean
}

function NoteProvider({ noteId, asNotepad }: NoteProviderProps) {
  const [logNoteOpened] = useLogNoteOpenedMutation()

  const [atomView, setAtomView] = useState<EditorView | null>(null)

  const { currentData: note, isLoading } = useGetNoteQuery(noteId)
  const revisionId = note?.draftRevisionId

  const atomViewContextValue = useMemo(
    () => ({ atomView, setAtomView }),
    [atomView]
  )

  const revisionContextValue = useMemo(
    () => ({ revisionId, documentType: "note", documentId: noteId }),
    [revisionId, noteId]
  )

  useEffect(() => {
    if (noteId && asNotepad) logNoteOpened({ noteId })
  }, [asNotepad, logNoteOpened, noteId])

  return (
    <RevisionContext.Provider
      value={revisionContextValue as RevisionContextValue}
    >
      <AtomViewContext.Provider value={atomViewContextValue}>
        {isLoading ? null : (
          <div className={notepadStyles["notepadOutlet"]}>
            <ErrorBoundary fallback={<EditorErrorFallback />}>
              <EditorComponent noteId={noteId} asNotepad={asNotepad} />
            </ErrorBoundary>
          </div>
        )}
      </AtomViewContext.Provider>
    </RevisionContext.Provider>
  )
}

export const Note = NoteProvider

/**
 * This is the component that renders the ProseMirror view.
 * We keep this in a separate component so that we avoid re-rendering
 * the EditorProvider each time the editor state changes.
 */

function EditorComponent({
  noteId,
  asNotepad,
}: {
  noteId: UUID
  asNotepad?: boolean
}) {
  const { l10n } = useLocalization()
  const { revisionId } = useContext(RevisionContext)

  const dispatchTransaction = useDispatchTransaction({
    documentType: "note",
    documentId: noteId,
    revisionId,
  })
  const [requestRevision, { isLoading, isError: isRevisionError }] =
    useLazyGetNoteRevisionQuery()

  useGetLinkableResourcesQuery()

  useEffect(() => {
    if (revisionId) requestRevision({ noteId: noteId, revisionId })
  }, [revisionId, noteId, requestRevision])

  const editorState = useAppSelector((state) =>
    getRevisionEditorState(state, revisionId)
  )

  const dispatch = useAppDispatch()

  useFootnoteEnumeration(editorState, (footnotes) => {
    dispatch(setFootnoteNumbers(footnotes))
  })

  const handlers = useRemoteSteps({
    documentType: "note",
    documentId: noteId,
    revisionId,
  })
  const [initLongPolling, connection] = useLongPollClient(
    "note",
    noteId,
    handlers
  )

  useEffect(() => {
    if (editorState && !connection?.isActive) initLongPolling({ editorState })
  }, [editorState, connection, initLongPolling])

  const placeholders = useMemo(
    () => makePlaceholderDecorationSet({ l10n }),
    [l10n]
  )

  if (isLoading)
    return <Localized id="loading-document">Loading document…</Localized>

  // The error is handled by the ErrorBoundary in EditorProvider
  if (isRevisionError) throw new Error("Error loading revision.")

  if (!editorState || !revisionId) return null

  return (
    <ProseMirror
      state={editorState}
      decorations={placeholders}
      nodeViews={reactNodeViews}
      dispatchTransaction={dispatchTransaction}
      attributes={{
        "data-variant": asNotepad ? PanelType.EDITOR : "notepad",
        style: templateStyleProperties(editorState.doc.attrs.headingStyles),
        ...makeDocumentPlaceholder({ l10n, doc: editorState.doc }),
      }}
    >
      <WithAside>
        <div
          className={clsx(styles["editor"], {
            [styles["notepad"] as string]: asNotepad,
          })}
        >
          {asNotepad ? <NotepadToolbar /> : <EditorToolbar />}
          {!asNotepad && <NoteFrontmatter />}
          <Keymap />
          <FloatingToolbar />
          <Dialogs />
          <UpdateLinkPopover />
          <BlockHandleMenu />
          <ProseMirrorDoc />
          <EditorDebugger />
        </div>
      </WithAside>
    </ProseMirror>
  )
}
