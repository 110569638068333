import * as RadixSwitch from "@radix-ui/react-switch"
import { type ReactNode, forwardRef } from "react"

import styles from "./Switch.module.css"

type SwitchProps = {
  checked?: boolean
  onChange?: (checked: boolean) => void
  label?: ReactNode
  id?: string
  layout?: "horizontal" | "vertical"
}

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(function Switch(
  { id, label, checked, onChange, layout },
  ref
) {
  return (
    <div
      ref={ref}
      className={styles.switch}
      style={{ flexDirection: layout === "horizontal" ? "row" : "column" }}
    >
      {label && (
        <label className={styles.label} htmlFor={id}>
          {label}
        </label>
      )}
      <RadixSwitch.Root
        className={styles.switchRoot}
        id={id}
        checked={checked}
        onCheckedChange={onChange}
      >
        <RadixSwitch.Thumb className={styles.switchThumb} />
      </RadixSwitch.Root>
    </div>
  )
})
