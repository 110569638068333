import type { EditorState } from "prosemirror-state"
import { TextSelection } from "prosemirror-state"
import { insertPoint } from "prosemirror-transform"
import type { EditorView } from "prosemirror-view"

export function insertStepMarker(
  state: EditorState,
  dispatch?: EditorView["dispatch"]
) {
  if (!state.schema.nodes["step_marker"]) {
    return false
  }

  const validInsertPoint = insertPoint(
    state.doc,
    state.selection.from,
    state.schema.nodes["step_marker"]
  )

  if (validInsertPoint === null) return false

  if (dispatch) {
    const { tr } = state
    const foldable = state.schema.nodes["step_marker"].create()
    tr.insert(validInsertPoint, foldable)

    tr.setSelection(TextSelection.create(tr.doc, validInsertPoint + 1))

    dispatch(tr)
  }
  return true
}
