import { MenuItem, ToolbarItem } from "@ariakit/react"
import {
  useEditorEventCallback,
  useEditorState,
} from "@nytimes/react-prosemirror"
import { wrapIn } from "prosemirror-commands"
import type { Attrs, NodeType } from "prosemirror-model"
import type { ComponentType, MouseEvent, ReactNode } from "react"

import styles from "@/components/editor/toolbar/toolbar.module.css"
import { useFocusView } from "@/hooks/useFocusView"

type Props = {
  nodeType: NodeType | undefined
  attrs?: Attrs
  children: ReactNode
  as?: ComponentType<{
    className: string
    children: ReactNode
    onClick: (event: MouseEvent) => void
    disabled: boolean
    active?: boolean | undefined
  }>
}

export function WrapInItem({
  nodeType,
  attrs,
  children,
  as: Component = MenuItem,
}: Props) {
  const focusView = useFocusView()
  const onClick = useEditorEventCallback((view) => {
    command(view.state, view.dispatch)
    focusView()
  })

  const editorState = useEditorState()

  if (!nodeType) return null

  const command = wrapIn(nodeType, attrs)

  const disabled = !editorState || !command(editorState)

  const className =
    Component === ToolbarItem
      ? styles["editorToolbarItem"] || ""
      : styles["editorToolbarDropdownItem"] || ""

  return (
    <Component className={className} onClick={onClick} disabled={disabled}>
      {children}
    </Component>
  )
}
