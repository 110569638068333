import { Button, Popover, usePopoverStore } from "@ariakit/react"
import { useEditorEventListener } from "@nytimes/react-prosemirror"
import { Decoration, DecorationSet, EditorView } from "prosemirror-view"
import { useCallback, useContext, useRef, useState } from "react"

import { useMobileAsideDrawer } from "@/components/aside/useMobileAsideDrawer"
import { Pencil, Thread, ThreadClosed, Trash } from "@/components/icons.tsx"
import sharedStyles from "@/components/shared.module.css"
import { AddFlashcardButton } from "@/components/viewer/flashcards/AddFlashcardButton"
import { RevisionContext } from "@/contexts/RevisionContext"
import { useAppDispatch, useAppSelector } from "@/store/hooks"
import { getCurrentMark } from "@/store/selectors/viewerSelectors"
import { useDeleteHighlightMutation } from "@/store/slices/api"
import { threadClosed, threadOpened } from "@/store/store.ts"
import type { Highlight } from "@/types/api"

function decorationSize(decoration: Decoration): number {
  return decoration.to - decoration.from
}

/**
 * A popover that appears when a user clicks on an existing highlight.
 * It allows the user to delete the highlight or add a note to it.
 */
export function UpdateHighlightPopover() {
  const dispatch = useAppDispatch()
  const { documentId: chapterId, revisionId } = useContext(RevisionContext)

  const popoverRef = useRef<HTMLDivElement | null>(null)
  const popoverStore = usePopoverStore()

  const [currentHighlight, setCurrentHighlight] = useState<Highlight>()
  const currentThread: false | { isOpen: boolean } = useAppSelector((state) => {
    if (!currentHighlight || !revisionId) return false
    const revisionThreads = state.threads[revisionId]
    if (!revisionThreads) return false
    const thread = revisionThreads[currentHighlight.id]
    if (!thread) return false
    return thread
  })

  const [currentHighlightNode, setCurrentHighlightNode] =
    useState<HTMLElement | null>(null)

  const [deleteHighlight, { isLoading: isDeleting }] =
    useDeleteHighlightMutation()

  const asideDrawer = useMobileAsideDrawer()

  const currentMark = useAppSelector(getCurrentMark)

  const handleThreadButtonClick = useCallback(() => {
    if (!currentHighlight) return
    if (currentThread && currentThread?.isOpen && !asideDrawer) {
      dispatch(
        threadClosed({
          revisionId: currentHighlight.revisionId,
          highlightId: currentHighlight.id,
        })
      )
    } else {
      dispatch(
        threadOpened({
          revisionId: currentHighlight.revisionId,
          highlightId: currentHighlight.id,
        })
      )
      asideDrawer?.open()
    }
    popoverStore.setOpen(false)
  }, [currentHighlight, currentThread, dispatch, popoverStore, asideDrawer])

  const onHighlightClick = (view: EditorView, event: PointerEvent) => {
    if (!document.getSelection()?.empty) return
    // The pointerup event is dispatched before the
    // touchend event. If currentMark is empty, then
    // touchend is going to cancel the mark, so we
    // should treat this like a tap and continue
    if (currentMark && !currentMark.empty) return

    const { clientX, clientY } = event as MouseEvent

    const posResult = view.posAtCoords({
      left: clientX,
      top: clientY,
    })
    if (!posResult) return

    const { pos } = posResult

    const decorationSet =
      (view.props.decorations?.(view.state) as DecorationSet) ??
      DecorationSet.empty

    const clickedDecorations = decorationSet.find(
      pos,
      pos,
      (spec) => spec.type === "highlight"
    )

    if (!clickedDecorations.length) {
      setCurrentHighlight(undefined)
      popoverStore.setOpen(false)
      return
    }

    const smallestDecoration = clickedDecorations.reduce((acc, decoration) =>
      decorationSize(acc) < decorationSize(decoration) ? acc : decoration
    )
    setCurrentHighlight(smallestDecoration.spec.highlight)
    popoverStore.setOpen(true)

    setCurrentHighlightNode(
      view.domAtPos(smallestDecoration.from + 1).node.parentElement
    )
  }

  // Position the popover next to the highlight
  useEditorEventListener("pointerup", onHighlightClick)

  if (!currentHighlight) return null

  return (
    <Popover
      className={sharedStyles["contextMenu"]}
      ref={popoverRef}
      store={popoverStore}
      autoFocusOnShow={false}
      getAnchorRect={() => {
        return currentHighlightNode?.getBoundingClientRect() ?? null
      }}
    >
      <div className={sharedStyles["contextMenuButtons"]}>
        {currentHighlight && (
          <Button
            className={sharedStyles["contextMenuButton"]}
            disabled={isDeleting}
            onClick={() => {
              deleteHighlight({
                highlightId: currentHighlight.id,
                revisionId: currentHighlight.revisionId,
                chapterId,
              })
              popoverStore.setOpen(false)
            }}
          >
            <Trash />
            Delete Highlight
          </Button>
        )}
        <Button
          render={<button />}
          className={sharedStyles["contextMenuButton"]}
          onClick={handleThreadButtonClick}
        >
          {currentThread && currentThread?.isOpen && !asideDrawer ? (
            <ThreadClosed />
          ) : currentHighlight?.threadId ? (
            <Thread />
          ) : (
            <Pencil />
          )}
          {currentThread && currentThread?.isOpen && !asideDrawer
            ? "Hide note"
            : currentHighlight?.threadId
            ? "Show Note"
            : "Add Note"}
        </Button>
        <AddFlashcardButton
          highlight={currentHighlight}
          chapterId={chapterId}
          revisionId={revisionId}
          onClick={() => {
            popoverStore.setOpen(false)
          }}
        />
        <AddFlashcardButton
          highlight={currentHighlight}
          chapterId={chapterId}
          revisionId={revisionId}
          magic
          onClick={() => {
            popoverStore.setOpen(false)
          }}
        />
      </div>
    </Popover>
  )
}
