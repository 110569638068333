import * as AriaKit from "@ariakit/react"
import { type ReactNode, useEffect, useState } from "react"

import styles from "./Disclosure.module.css"

type DisclosureProps = {
  heading: ReactNode
  children: ReactNode
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

export function Disclosure({
  heading,
  children,
  open,
  onOpenChange,
}: DisclosureProps) {
  const [isOpen, setIsOpen] = useState(open ?? false)

  const disclosure = AriaKit.useDisclosureStore({
    open: isOpen,
    setOpen: (open) => {
      setIsOpen(open)
      onOpenChange?.(open)
    },
  })

  useEffect(() => {
    setIsOpen(open ?? false)
  }, [open])

  return (
    <div>
      <AriaKit.Disclosure
        className={styles.disclosureButton}
        store={disclosure}
      >
        {heading}
        <Chevron />
      </AriaKit.Disclosure>
      <AriaKit.DisclosureContent
        className={styles.disclosureContentWrapper}
        store={disclosure}
      >
        <div>
          <div className={styles.content}>{children}</div>
        </div>
      </AriaKit.DisclosureContent>
    </div>
  )
}

function Chevron() {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      width="16"
      height="16"
      aria-hidden="true"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m19.5 8.25-7.5 7.5-7.5-7.5"
      ></path>
    </svg>
  )
}
