import { Message } from "./Message.tsx"

import type { UUID } from "@/store/UUID.ts"
import { useGetMessagesQuery } from "@/store/slices/api.ts"

type MessagesListProps = {
  highlightId: UUID
}

export function MessagesList({ highlightId }: MessagesListProps) {
  // Since we the websocket connection is closed when the user navigates away
  // from the document, we need to refetch the messages when the user returns
  const options = { refetchOnFocus: true }
  const { data, isError, isLoading, isSuccess } = useGetMessagesQuery(
    highlightId,
    options
  )

  if (isLoading || isError || !isSuccess) return null

  return (
    <div data-thread-id={data?.[0]?.threadId}>
      {data.map((message) => {
        return (
          <Message
            key={message.id}
            highlightId={highlightId}
            message={message}
          />
        )
      })}
    </div>
  )
}
