import { Dialog } from "@/store/slices/dialogs"
import type { RootState } from "@/store/store"

export function getOpenedInsertFileDialog(state: RootState) {
  const { openDialog } = state.dialogs

  if (openDialog === Dialog.INSERT_PDF) return Dialog.INSERT_PDF
  if (openDialog === Dialog.INSERT_IMAGE) return Dialog.INSERT_IMAGE

  return false
}

export function isInsertVideoDialogOpen(state: RootState) {
  return state.dialogs.openDialog === Dialog.INSERT_VIDEO
}

export function isAddLinkDialogOpen(state: RootState) {
  return state.dialogs.openDialog === Dialog.INSERT_LINK
}

export function isDialogOpen(state: RootState) {
  return state.dialogs.openDialog !== Dialog.NONE
}
