import { updateScrollPositionsFromDom } from "./panelOpened.ts"

import type { UUID } from "@/store/UUID.ts"
import { panelsSlice } from "@/store/slices/panels"
import { createAppThunk } from "@/store/store"

/*
 * This thunk is called when a panel is selected. We need this, to get the scrolling position
 * of all other panels and update their panelState in the stack accordingly.
 */
export const panelSelected = createAppThunk(
  "panels/panelSelected",
  ({ panelId }: { panelId: UUID }, { dispatch, getState }) => {
    // Update scroll positions of all panels
    updateScrollPositionsFromDom(dispatch, getState)
    // If the panel is already visible, we don't need to do anything
    const isRootPanel =
      getState().panels.stack[0]?.panelId === panelId &&
      getState().panels.doublePanelLayout
    const isTopPanel = getState().panels.activePanelUuid === panelId
    // Run actual opening action on the panelsSlice
    if (!isRootPanel && !isTopPanel) {
      dispatch(panelsSlice.actions.panelSelected({ panelId }))
    }
  }
)
