import type { NodeViewComponentProps } from "@nytimes/react-prosemirror"
import { forwardRef } from "react"

import styles from "./Mention.module.css"

export const Mention = forwardRef<HTMLSpanElement, NodeViewComponentProps>(
  function Mention({ children: _, nodeProps, ...props }, ref) {
    const { node } = nodeProps

    return (
      <span
        ref={ref}
        {...props}
        className={styles.mention}
        contentEditable={false}
      >
        @{node.attrs.label ?? "Unknown user"}
      </span>
    )
  }
)
